import React, { useMemo, useRef } from "react"

import { pull } from "lodash"

import { Link } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import { ReactComponent as AbnormalResultsIcon } from "app/assets/icons/rupa-blood-dashboards/abnormal-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import { PatientPortalPractitioner } from "app/patient-portal/types"
import { colors, navy } from "app/theme"
import { Practitioner } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { BloodReportResult } from "types/blood-report-result"
import { BodySystem } from "types/body-system"

import BloodLabDashboardsSnapshotBodySystemCards from "./BloodLabDashboardsSnapshotBodySystemCards"
import { BloodLabDashboardsSnapshotOutOfRangeCard } from "./BloodLabDashboardsSnapshotOutOfRangeCard"

const useStyles = makeAppStyles(() => ({
  container: {
    width: "100%",
    scrollBehavior: "smooth",
  },
  heading: {
    fontSize: 19,
    fontWeight: 600,
    lineHeight: "135%",
    color: navy,
    paddingBottom: 5,
    width: "100%",
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    marginBottom: 30,
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  outOfRange: {
    marginBottom: 35,
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  sectionOutOfRangeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  divider: {
    width: "100%",
    height: 1,
    background: colors.blueGray[200],
    marginTop: 24,
  },
  disclaimerText: {
    color: colors.blueGray[500],
    marginTop: 25,
    marginBottom: 25,
  },
  disclaimerTextLink: {
    color: colors.blueGray[500],
    textDecoration: "underline !important",
    cursor: "pointer",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 14,
  },
  noResultsText: {
    color: colors.blueGray[400],
    textAlign: "center",
  },
}))

interface Props {
  searchValue: string
  bloodReportResults?: BloodReportResult[]
  abnormalBloodReportResults?: BloodReportResult[]
  biomarkers: Biomarker[]
  bodySystems: BodySystem[]
  practitioner?: PatientPortalPractitioner | Practitioner
  clinicName?: string
  isLoading: boolean
  orderedResultFile?: string
  snapshotCreationDate: string
  labCompanyName?: string
  getMatchingBloodReportResultByBiomarkerId: (
    bioamrkerId?: string
  ) => BloodReportResult | undefined
  showHighLowDescriptions: boolean
}

export function BloodLabDashboardsSnapshotPatientReport({
  searchValue,
  bloodReportResults,
  abnormalBloodReportResults,
  biomarkers,
  bodySystems,
  practitioner,
  clinicName,
  isLoading,
  orderedResultFile,
  snapshotCreationDate,
  labCompanyName,
  getMatchingBloodReportResultByBiomarkerId,
  showHighLowDescriptions,
}: Props) {
  const classes = useStyles()

  const ref = useRef<any>()

  const filteredBiomarkers: string[] | null = useMemo(() => {
    if (isLoading) {
      return null
    }

    if (searchValue === "") {
      return biomarkers.map((biomarker) => biomarker.id)
    }

    return biomarkers
      .filter(
        (biomarker) =>
          biomarker.attributes.short_name
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          biomarker.attributes.long_name
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      )
      .map((biomarker) => biomarker.id)
  }, [searchValue, biomarkers, isLoading])

  // Group the biomarkers by body system. Keep track of which biomarkers are not a part of any body system as well.
  let { bodySystemBioMarkerGroups, biomarkersNotInABodySystem } =
    useMemo(() => {
      // A record of body system id the body system and the relevant bio markers.
      let bodySystemAndBiomarkersByBodySystemId: Record<
        string,
        { biomarkerIds: string[]; bodySystem: BodySystem }
      > = {}

      let biomarkersIdsCopy = (filteredBiomarkers ?? []).slice()
      for (let bodySystem of bodySystems) {
        for (let biomarker of bodySystem.relationships.biomarkers.data) {
          if (biomarkersIdsCopy.includes(biomarker.id)) {
            pull(biomarkersIdsCopy, biomarker.id)
            if (!bodySystemAndBiomarkersByBodySystemId[bodySystem.id]) {
              bodySystemAndBiomarkersByBodySystemId[bodySystem.id] = {
                biomarkerIds: [],
                bodySystem: bodySystem,
              }
            }
            bodySystemAndBiomarkersByBodySystemId[
              bodySystem.id
            ].biomarkerIds.push(biomarker.id)
          }
        }
      }

      return {
        bodySystemBioMarkerGroups: Object.values(
          bodySystemAndBiomarkersByBodySystemId
        ),
        // Biomarkers that remain in the original list are not associated with a body system.
        biomarkersNotInABodySystem: biomarkersIdsCopy,
      }
    }, [bodySystems, filteredBiomarkers])

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <Skeleton animation="wave" height={80} width="25%" />

        <Skeleton animation="wave" height={60} width="100%" />
        <Skeleton animation="wave" height={60} width="100%" />

        <Skeleton animation="wave" height={80} width="25%" />

        <Skeleton animation="wave" height={50} width="25%" />

        <Skeleton animation="wave" height={50} width="20%" />

        <Skeleton animation="wave" height={50} width="20%" />
      </div>
    )
  }

  return (
    <div className={classes.container} ref={ref} id={"outOfRange"}>
      {searchValue === "" && Boolean(abnormalBloodReportResults?.length) && (
        <div className={classes.outOfRange}>
          <div
            className={classes.heading}
            data-cy="abnormal-heading-patient-experience-blood-labs"
          >
            <AbnormalResultsIcon />
            Abnormal
          </div>
          <div className={classes.sectionOutOfRangeContainer}>
            {abnormalBloodReportResults?.map((bloodReportResult) => (
              <BloodLabDashboardsSnapshotOutOfRangeCard
                key={bloodReportResult.id}
                bloodReportResult={bloodReportResult}
                practitioner={practitioner}
                clinicName={clinicName}
                snapshotCreationDate={snapshotCreationDate}
                labCompanyName={labCompanyName}
                showHighLowDescriptions={showHighLowDescriptions}
              />
            ))}
          </div>
        </div>
      )}

      {Boolean(bloodReportResults?.length) &&
      (filteredBiomarkers === null || Boolean(filteredBiomarkers?.length)) ? (
        <div>
          <div className={classes.heading}>All Markers</div>
          <div className={classes.sectionContainer}>
            {bodySystemBioMarkerGroups.map(({ bodySystem, biomarkerIds }) => (
              <BloodLabDashboardsSnapshotBodySystemCards
                bodySystem={bodySystem}
                filteredBiomarkers={biomarkerIds}
                practitioner={practitioner}
                clinicName={clinicName}
                getMatchingBloodReportResultByBiomarkerId={
                  getMatchingBloodReportResultByBiomarkerId
                }
                snapshotCreationDate={snapshotCreationDate}
                labCompanyName={labCompanyName}
                showHighLowDescriptions={showHighLowDescriptions}
              />
            ))}

            {/* Show the biomarkers that are not a part of a body system */}
            <BloodLabDashboardsSnapshotBodySystemCards
              filteredBiomarkers={biomarkersNotInABodySystem}
              practitioner={practitioner}
              clinicName={clinicName}
              getMatchingBloodReportResultByBiomarkerId={
                getMatchingBloodReportResultByBiomarkerId
              }
              snapshotCreationDate={snapshotCreationDate}
              labCompanyName={labCompanyName}
              showHighLowDescriptions={showHighLowDescriptions}
            />
          </div>
        </div>
      ) : (
        <BodyText weight={"semibold"} className={classes.noResultsText}>
          We couldn't find any markers that match your search. 🤔
        </BodyText>
      )}

      <div className={classes.divider}></div>

      <DisclaimerText orderedResultFile={orderedResultFile} />
    </div>
  )
}

const DisclaimerText = ({
  orderedResultFile,
}: {
  orderedResultFile?: string
}) => {
  const classes = useStyles()

  return (
    <BodyText size="xxs" className={classes.disclaimerText}>
      This dashboard is intended for informational purposes only and should not
      be used as a substitute for professional medical advice, diagnosis, or
      treatment. If you have any questions or concerns related to this dashboard
      or a possible medical condition, seek the advice of your healthcare
      provider. Never disregard the advice of your healthcare provider. Your{" "}
      <Link
        className={classes.disclaimerTextLink}
        onClick={() => orderedResultFile && window.open(orderedResultFile)}
      >
        original lab report
      </Link>{" "}
      (the “Report”) is the official documentation of your results; please
      always refer back to the Report. Rupa is not liable for any discrepancies
      between the Report and this visualization.{" "}
    </BodyText>
  )
}
