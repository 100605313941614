import makeAppStyles from "app/utils/makeAppStyles"

import { TYPE_KEYS } from "../constants"
import { PanelBuilderIdentifier } from "../types"
import BiomarkerGroupingPill from "./BiomarkerGroupingPill"
import BiomarkerPill from "./BiomarkerPill"

const useStyles = makeAppStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    gap: 8,
  },
}))

export default function SelectionPills({
  selectedItems,
  onRemove,
}: {
  selectedItems: PanelBuilderIdentifier[]
  onRemove: (identifier: PanelBuilderIdentifier) => void
}) {
  const classes = useStyles()
  const hideRemoveButton = selectedItems.length === 1

  return (
    <div className={classes.container}>
      {selectedItems.map((identifier) =>
        identifier.type === TYPE_KEYS.BIOMARKER ? (
          <BiomarkerPill
            key={`biomarker-${identifier.id}`}
            biomarkerIdentifier={identifier}
            onRemove={() => onRemove(identifier)}
            hideRemoveButton={hideRemoveButton}
          />
        ) : (
          <BiomarkerGroupingPill
            key={`grouping-${identifier.id}`}
            biomarkerGroupingIdentifier={identifier}
            onRemove={() => onRemove(identifier)}
            hideRemoveButton={hideRemoveButton}
          />
        )
      )}
    </div>
  )
}
