import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { useMediaQuery, Theme, makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

import { UserPaths } from "app/Routes"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import Separator from "app/main/settings/components/Separator"
import { updatePractitioner } from "app/store/actions"
import { colors, navy } from "app/theme"
import { UserResultStatus, RootState, Practitioner } from "app/types"

import SideNav from "./SideNav"
import NotificationsTable from "./components/NotificationsTable"
import PreliminaryResultNotificationsToggle from "./components/PreliminaryResultToggle"

const styles = () => ({
  notificationWrapper: {
    background: colors.coolGray[50],
    border: `1px solid ${colors.coolGray[200]}`,
    minHeight: "100vh",
    padding: 24,
  },
  explanation: {
    color: navy,
  },
})
const useStyles = makeStyles(styles)

const Notifications = () => {
  const classes = useStyles()
  const [checked, setChecked] = useState(false)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const dispatch = useDispatch()

  const practitioner = useSelector<RootState, Practitioner>(
    ({ practitioner }) => practitioner
  )

  const [preliminaryResultNotificationsEnabled] = useFeatureFlag(
    FeatureFlag.PreliminaryResultNotifications
  )

  const enableSendingPreliminaryNotifications =
    practitioner?.notify_for_result_statuses?.includes(
      UserResultStatus.PRELIMINARY
    ) ?? false

  useEffect(() => {
    setChecked(enableSendingPreliminaryNotifications)
  }, [enableSendingPreliminaryNotifications])

  const toggleDefaultPreliminaryResultNotificationsEnabled = useCallback(() => {
    const newEnabled = !checked
    if (newEnabled) {
      dispatch(
        updatePractitioner({
          notify_for_result_statuses:
            practitioner?.notify_for_result_statuses.concat([
              UserResultStatus.PRELIMINARY,
            ]),
        })
      )
    } else {
      dispatch(
        updatePractitioner({
          notify_for_result_statuses:
            practitioner?.notify_for_result_statuses.filter(
              (feature) => feature !== UserResultStatus.PRELIMINARY
            ),
        })
      )
    }
    setChecked(newEnabled)
  }, [practitioner, dispatch, checked])

  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },
            {
              label: "Notifications",
            },
          ]}
          title="Notifications"
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title={"Notifications"}
              greyBackground={true}
              fullBorder={true}
            />
          )}
          <div className={classes.notificationWrapper}>
            {preliminaryResultNotificationsEnabled && (
              <div>
                <PreliminaryResultNotificationsToggle
                  value={checked}
                  onToggle={toggleDefaultPreliminaryResultNotificationsEnabled}
                />

                <Separator />
              </div>
            )}

            {preliminaryResultNotificationsEnabled && (
              <p className="font-semibold text-lg pb-6">Clinic Notifications</p>
            )}
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12}>
                <BodyText className={classes.explanation}>
                  Assign a team member to be the point of contact for all order
                  updates, emails, and support messages. Please note:{" "}
                  <b>
                    only the point of contact will receive updates, results
                    emails, and notifications from Rupa.
                  </b>
                </BodyText>
              </Grid>
              <Grid item xs={12}>
                <NotificationsTable />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default Notifications
