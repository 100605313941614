import { ORDERED_TEST_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-portal-types"
import { getPhlebotomistFriendlyName } from "../../utils"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({ orderedTest, order }: StatusConfigurationProps) => {
  const appointmentDate =
    orderedTest.attributes.date_sample_collection_scheduled
  if (!appointmentDate) return null

  const dateIsInThePast = new Date(appointmentDate) < new Date()

  const formattedDate = formatDateForHuman(appointmentDate)
  const phlebotomyProviderText =
    order?.attributes.phlebotomy_providers.length === 1
      ? ` with ${getPhlebotomistFriendlyName(
          order.attributes.phlebotomy_providers[0]
        )}`
      : ""

  return `You ${
    dateIsInThePast
      ? "had a scheduled collection appointment on"
      : "have a scheduled collection appointment for"
  } ${formattedDate}${phlebotomyProviderText}.`
}

const collectionAppointmentScheduledStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { orderedTestStatus: ORDERED_TEST_STATUS.COLLECTION_SCHEDULED },
    title: "Collection Appointment Scheduled",
    subTitle: SubTitle,
    progressPercentage: PROGRESS_PERCENTAGES.COLLECTION_SCHEDULED,
  }

export default collectionAppointmentScheduledStatusConfiguration
