import _ from "lodash"

import authService from "app/auth/services/simplejwt-auth-service"

export const LOGIN_ERROR = "LOGIN_ERROR"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export function submitLogin(
  { email, password }: { email: string; password: string },
  role: string
) {
  return async (dispatch) => {
    try {
      await authService.login(email, password, role)

      return dispatch({
        type: LOGIN_SUCCESS,
      })
    } catch (error: any) {
      const details =
        error.response?.data?.detail ||
        error?.errors?.error?.[0] ||
        error?.message
      const errorText = _.isEmpty(details)
        ? "Invalid username or password"
        : details

      return dispatch({
        type: LOGIN_ERROR,
        payload: { password: errorText },
      })
    }
  }
}
