import { memo } from "react"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"

import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  RadioGroupProps,
} from "@material-ui/core"

import { InjectedProps, WrapperProps } from "formsy-react/dist/withFormsy"
import pick from "lodash/pick"

// Declare the fields we pass through to RadioGroup so we can pick them out.
// Add more as needed.
const RADIO_GROUP_PROP_KEYS = ["onChange", "children"] as const

export type RadioGroupFormsyProps = Omit<WrapperProps<string>, "required"> &
  Pick<RadioGroupProps, typeof RADIO_GROUP_PROP_KEYS[number]> & {
    label?: React.ReactNode
    className?: string
    required?: boolean
  }

function RadioGroupFormsy({
  value = "",
  label,
  setValue,
  errorMessage,
  className,
  ...props
}: RadioGroupFormsyProps & InjectedProps<string>) {
  const radioGroupProps = pick(props, RADIO_GROUP_PROP_KEYS)

  function changeValue(
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) {
    setValue(value)
    props?.onChange?.(event, value)
  }

  return (
    <FormControl error={Boolean(errorMessage)} className={className}>
      <FormControl
        component="fieldset"
        required={props.required}
        error={Boolean(errorMessage)}
      >
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <RadioGroup {...radioGroupProps} value={value} onChange={changeValue} />
        {Boolean(errorMessage) && (
          <FormHelperText>{errorMessage}</FormHelperText>
        )}
      </FormControl>
    </FormControl>
  )
}

export default memo(withFormsy(RadioGroupFormsy))
