import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { API } from "app/api"
import Tooltip from "app/components/Tooltip"
import RupaButton from "app/components/design-system/Button"
import StartOrderModal from "app/components/modals/StartOrderModal"
import { COUPON_TYPE } from "app/constants"
import {
  getDisabledOrderingAccessReasonForLabCompany,
  getUnavailableReasonForLabTest,
  hasOrderingRightsForLabCompany,
} from "app/dataServices/labTestDataService"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import {
  markTestToAddToNextOrder,
  unmarkTestToAddToNextOrder,
} from "app/main/checkout/store/actions"
import reducer from "app/main/dashboard/store/reducers"
import * as Actions from "app/store/actions"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import withReducer from "app/store/withReducer"
import { Coupon, labTestLocation } from "app/types"
import { handleApiError } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

import OverrideAccessModal from "../modals/OverrideAccessModal"

const useStyles = makeAppStyles((theme) => ({
  button: {
    marginBottom: 5,
    width: "100%",
  },
}))

interface Props {
  coupon: Coupon
}

function PromotionsCouponStartOrderButton(props: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [hideTooltip, setHideTooltip] = useState(false)
  const [isStartOrderModalOpen, setIsStartOrderModalOpen] = useState(false)

  const labCompanies = useAppSelector(({ labCompany }) => labCompany.list)
  const labTestOverride = useAppSelector(
    ({ overrideLabTest }) => overrideLabTest
  )
  const practitioner = useAppSelector(selectPractitioner)

  const [isOrderingAccessOverrideHoverEnabled] = useFeatureFlag(
    FeatureFlag.OrderAccessOverrideHover
  )

  const overrideTestAction = (unavailableState?: string) => {
    if (isOrderingAccessOverrideHoverEnabled) {
      dispatch(
        Actions.setLabTestForOverride(
          props.coupon.lab_company
            ? props.coupon.lab_company
            : props.coupon.lab_test.lab_company,
          unavailableState
        )
      )
      setHideTooltip(true)
    }
  }

  const handleHiddenTooltip = () => {
    if (hideTooltip) {
      setHideTooltip(false)
    }
  }

  const onClickStartOrder = (coupon: Coupon) => {
    if (coupon.lab_test) {
      dispatch(markTestToAddToNextOrder(coupon.lab_test))
    }
    window.sessionStorage.setItem("coupon_code_for_checkout", coupon.code)

    if (coupon.is_first_order_only) {
      API.Coupon.createCouponUser(coupon.code).catch((error) => {
        // TODO: remove this once we have a better way to handle
        //  pre-existing coupon users
        if (
          error.response.status === 400 &&
          error.response.data.error.includes("Coupon has already been used")
        ) {
          return
        } else {
          dispatch(handleApiError(error))
        }
      })
    }

    setIsStartOrderModalOpen(true)
  }

  const onClickClose = (coupon: Coupon) => {
    if (coupon.lab_test) {
      dispatch(unmarkTestToAddToNextOrder(coupon.lab_test))
    }
    window.sessionStorage.removeItem("coupon_code_for_checkout")

    if (coupon.is_first_order_only) {
      // TODO: remove this once we have a better way to handle
      //  pre-existing coupon users
      API.Coupon.deleteCouponUser(coupon.id).catch((error) =>
        dispatch(handleApiError(error))
      )
    }

    setIsStartOrderModalOpen(false)
  }

  useEffect(() => {
    dispatch(Actions.getLabCompanyList())
  }, [])

  const allowedLabCompanies = useMemo(() => {
    if (!labCompanies) {
      return []
    }

    return labCompanies.filter((labCompany) => {
      const hasRegisteredLabCompany =
        practitioner?.registered_lab_companies?.includes(labCompany.id)

      const [hasOrderingRights, disallowedBecauseOfState] =
        hasOrderingRightsForLabCompany(labCompany, practitioner)

      return (
        (hasRegisteredLabCompany === true || hasOrderingRights === true) &&
        disallowedBecauseOfState === false
      )
    })
  }, [labCompanies, practitioner])

  const disabledReason = useMemo(() => {
    if (props.coupon.type === COUPON_TYPE.DISCOUNTED_LAB_TEST) {
      const unavailableReason = getUnavailableReasonForLabTest(
        props.coupon.lab_test,
        isOrderingAccessOverrideHoverEnabled,
        practitioner,
        practitioner,
        // TODO: replace labTestLocation.CHECKOUT with labTestLocation.PROMOTIONS at later date
        // Using CHECKOUT for now meets the behavior requirements, but  it'd be ideal to use PROMOTIONS
        labTestLocation.CHECKOUT,
        false,
        overrideTestAction,
        false
      )

      if (unavailableReason !== "") {
        return unavailableReason
      }

      return false
    }

    if (props.coupon.type === COUPON_TYPE.LAB_WIDE_DISCOUNT) {
      const hasOrderingAccess = allowedLabCompanies.some(
        (allowedLabCompany) =>
          allowedLabCompany.key === props.coupon.lab_company.key
      )

      if (!hasOrderingAccess) {
        return getDisabledOrderingAccessReasonForLabCompany(
          hasOrderingAccess,
          isOrderingAccessOverrideHoverEnabled,
          props.coupon.lab_company,
          practitioner,
          overrideTestAction
        )
      }

      return false
    }

    if (props.coupon.type === COUPON_TYPE.DISCOUNT) {
      return false
    }
  }, [
    allowedLabCompanies,
    isOrderingAccessOverrideHoverEnabled,
    overrideTestAction,
    props.coupon.lab_company,
    props.coupon.lab_test,
    practitioner,
  ])

  return (
    <>
      {!disabledReason ? (
        <>
          <RupaButton
            size="small"
            color="primary"
            onClick={() => onClickStartOrder(props.coupon)}
            className={classes.button}
          >
            Start an Order
          </RupaButton>
          <StartOrderModal
            open={isStartOrderModalOpen}
            onClose={() => onClickClose(props.coupon)}
            shouldHideCreatePatientButton
          />
        </>
      ) : (
        <Tooltip
          title={hideTooltip ? "" : disabledReason}
          placement="top"
          arrow
          disableHoverListener={!disabledReason}
          interactive
        >
          <div onMouseOver={handleHiddenTooltip}>
            <RupaButton
              size="small"
              color="primary"
              onClick={() => onClickStartOrder(props.coupon)}
              className={classes.button}
              disabled={true}
            >
              Start an Order
            </RupaButton>
          </div>
        </Tooltip>
      )}
      {isOrderingAccessOverrideHoverEnabled &&
        Boolean(labTestOverride.labCompany) && (
          <OverrideAccessModal
            open={Boolean(labTestOverride.labCompany)}
            title={`Confirm Ordering Access for ${labTestOverride.labCompany?.name}`}
            licenseType={practitioner?.primary_practitioner_type?.name}
            state={labTestOverride.unavailableState}
            labCompany={labTestOverride.labCompany}
            practitionerId={practitioner?.id}
          />
        )}
    </>
  )
}

export default withReducer(
  "dashboard",
  reducer
)(PromotionsCouponStartOrderButton)
