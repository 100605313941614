import ViewBundleIcon from "app/assets/icons/bundles/tooltip-nav-icons/bundle-action.svg"
import ViewBundleBloodIcon from "app/assets/icons/bundles/tooltip-nav-icons/bundle-blood-action.svg"
import CopyBundleIcon from "app/assets/icons/bundles/tooltip-nav-icons/copy-action.svg"
import DeleteIcon from "app/assets/icons/bundles/tooltip-nav-icons/delete-action.svg"
import RenameBundleIcon from "app/assets/icons/bundles/tooltip-nav-icons/edit-action.svg"
import CopyLinkIcon from "app/assets/icons/bundles/tooltip-nav-icons/link-action.svg"
import ShareBundleIcon from "app/assets/icons/bundles/tooltip-nav-icons/share-action.svg"
import { NavItem } from "app/components/design-system/TooltipNav/constants"
import {
  trackBundleShareWithClinicClick,
  trackBundleViewEditClick,
} from "app/services/segment"
import { cancelRed, colors } from "app/theme"
import {
  LabTestBundle,
  LabTestBundleType,
  Practitioner,
  PractitionerLabTestBundle,
  labTestLocation,
} from "app/types"

interface useBundleNavItemsProps extends getNavItemsProps {
  openPanelBuilderModal: () => void
}

interface getNavItemsProps {
  panelBuilderEnabled?: boolean
  bundle: LabTestBundle | PractitionerLabTestBundle
  location: labTestLocation
  practitioner: Practitioner
  handleNavItemClick: (e: any, callback: () => void) => void
  openModal: () => void
  setRenameBundleModalOpen: (isOpen: boolean) => void
  createBundleCopy: (bundle?: LabTestBundle | LabTestBundle) => void
  handleActionClick: (e: any, callback: () => void) => void
  handleCopyLinkClick: () => void
  handleRemoveBundle: () => void
  shareWithClinic: (e: any) => void
  unshareWithClinic: (bundle: any) => void
}

interface BundleNavItems {
  navItems: NavItem[]
}

export function useBundleNavItems({
  panelBuilderEnabled,
  bundle,
  location,
  practitioner,
  handleNavItemClick,
  openModal,
  openPanelBuilderModal,
  setRenameBundleModalOpen,
  createBundleCopy,
  handleActionClick,
  handleCopyLinkClick,
  handleRemoveBundle,
  shareWithClinic,
  unshareWithClinic,
}: useBundleNavItemsProps): BundleNavItems {
  if (!panelBuilderEnabled) {
    return {
      // Nav items with panel builder feature flag disabled
      navItems: getLegacyNavItems({
        bundle,
        location,
        practitioner,
        handleNavItemClick,
        openModal,
        setRenameBundleModalOpen,
        createBundleCopy,
        handleActionClick,
        handleCopyLinkClick,
        handleRemoveBundle,
        shareWithClinic,
        unshareWithClinic,
      }),
    }
  }
  if (bundle.bundle_type === LabTestBundleType.PANEL) {
    // Nav items for panel builder panel bundles
    return {
      navItems: getBloodBundleNavItems({
        bundle,
        location,
        practitioner,
        handleNavItemClick,
        openModal: openPanelBuilderModal,
        setRenameBundleModalOpen,
        createBundleCopy,
        handleActionClick,
        handleCopyLinkClick,
        handleRemoveBundle,
        shareWithClinic,
        unshareWithClinic,
      }),
    }
  } else {
    // Nav items for panel builder basic bundles
    return {
      navItems: getBasicBundleNavItems({
        bundle,
        location,
        practitioner,
        handleNavItemClick,
        openModal,
        setRenameBundleModalOpen,
        createBundleCopy,
        handleActionClick,
        handleCopyLinkClick,
        handleRemoveBundle,
        shareWithClinic,
        unshareWithClinic,
      }),
    }
  }
}

function getBasicBundleNavItems({
  bundle,
  location,
  practitioner,
  handleNavItemClick,
  openModal,
  setRenameBundleModalOpen,
  createBundleCopy,
  handleActionClick,
  handleCopyLinkClick,
  handleRemoveBundle,
  shareWithClinic,
  unshareWithClinic,
}: getNavItemsProps): NavItem[] {
  return [
    {
      id: "view-edit",
      icon: ViewBundleIcon,
      name: "Edit Bundle",
      onClick: (e) =>
        handleNavItemClick(e, () => {
          trackBundleViewEditClick(
            practitioner.id,
            practitioner?.clinic?.id?.toString(),
            bundle.id.toString()
          )
          openModal()
        }),
    },
    {
      id: "rename",
      icon: RenameBundleIcon,
      name: "Rename",
      onClick: (e) =>
        handleNavItemClick(e, () => {
          setRenameBundleModalOpen(true)
        }),
    },
    ...(location !== labTestLocation.CHECKOUT
      ? [
          {
            id: "make-a-copy",
            icon: CopyBundleIcon,
            name: "Make a Copy",
            onClick: (e) => handleNavItemClick(e, createBundleCopy),
          },
        ]
      : []),
    ...(location !== labTestLocation.CHECKOUT
      ? [
          {
            id: "share-with-clinic",
            icon: ShareBundleIcon,
            name: bundle.is_shared_bundle
              ? "Remove from Clinic"
              : "Share with Clinic",
            onClick: (e) =>
              handleActionClick(e, () => {
                if (bundle.is_shared_bundle) {
                  unshareWithClinic(bundle)
                  trackBundleShareWithClinicClick(
                    practitioner?.id,
                    practitioner?.clinic?.id.toString(),
                    bundle?.id.toString(),
                    "Unshare"
                  )
                } else {
                  shareWithClinic(bundle)
                  trackBundleShareWithClinicClick(
                    practitioner?.id,
                    practitioner?.clinic?.id.toString(),
                    bundle?.id.toString(),
                    "Share"
                  )
                }
              }),
          },
        ]
      : []),
    {
      id: "copy-link",
      icon: CopyLinkIcon,
      name: "Copy Link",
      onClick: (e) => handleNavItemClick(e, handleCopyLinkClick),
    },
    {
      id: "delete-bundle",
      icon: DeleteIcon,
      name: "Delete Bundle",
      onClick: (e) => handleNavItemClick(e, handleRemoveBundle),
      color: cancelRed,
      background: colors.red[50],
    },
  ]
}

function getBloodBundleNavItems({
  bundle,
  location,
  practitioner,
  handleNavItemClick,
  openModal,
  setRenameBundleModalOpen,
  createBundleCopy,
  handleActionClick,
  handleCopyLinkClick,
  handleRemoveBundle,
  shareWithClinic,
  unshareWithClinic,
}: getNavItemsProps): NavItem[] {
  return [
    {
      id: "view-edit",
      icon: ViewBundleBloodIcon,
      name: "Edit Panel",
      onClick: (e) =>
        handleNavItemClick(e, () => {
          trackBundleViewEditClick(
            practitioner.id,
            practitioner?.clinic?.id?.toString(),
            bundle.id.toString()
          )
          openModal()
        }),
    },
    {
      id: "rename",
      icon: RenameBundleIcon,
      name: "Rename",
      onClick: (e) =>
        handleNavItemClick(e, () => {
          setRenameBundleModalOpen(true)
        }),
    },
    ...(location !== labTestLocation.CHECKOUT
      ? [
          {
            id: "make-a-copy",
            icon: CopyBundleIcon,
            name: "Make a Copy",
            onClick: (e) => handleNavItemClick(e, createBundleCopy),
          },
        ]
      : []),
    ...(location !== labTestLocation.CHECKOUT
      ? [
          {
            id: "share-with-clinic",
            icon: ShareBundleIcon,
            name: bundle.is_shared_bundle
              ? "Remove from Clinic"
              : "Share with Clinic",
            onClick: (e) =>
              handleActionClick(e, () => {
                if (bundle.is_shared_bundle) {
                  unshareWithClinic(bundle)
                  trackBundleShareWithClinicClick(
                    practitioner?.id,
                    practitioner?.clinic?.id.toString(),
                    bundle?.id.toString(),
                    "Unshare"
                  )
                } else {
                  shareWithClinic(bundle)
                  trackBundleShareWithClinicClick(
                    practitioner?.id,
                    practitioner?.clinic?.id.toString(),
                    bundle?.id.toString(),
                    "Share"
                  )
                }
              }),
          },
        ]
      : []),
    {
      id: "copy-link",
      icon: CopyLinkIcon,
      name: "Copy Link",
      onClick: (e) => handleNavItemClick(e, handleCopyLinkClick),
    },
    {
      id: "delete-bundle",
      icon: DeleteIcon,
      name: "Delete Panel",
      onClick: (e) => handleNavItemClick(e, handleRemoveBundle),
      color: cancelRed,
      background: colors.red[50],
    },
  ]
}

function getLegacyNavItems({
  bundle,
  location,
  practitioner,
  handleNavItemClick,
  openModal,
  setRenameBundleModalOpen,
  createBundleCopy,
  handleActionClick,
  handleCopyLinkClick,
  handleRemoveBundle,
  shareWithClinic,
  unshareWithClinic,
}: getNavItemsProps): NavItem[] {
  return [
    {
      id: "view-edit",
      icon: ViewBundleIcon,
      name: "View & Edit",
      onClick: (e) =>
        handleNavItemClick(e, () => {
          trackBundleViewEditClick(
            practitioner.id,
            practitioner?.clinic?.id?.toString(),
            bundle.id.toString()
          )
          openModal()
        }),
    },
    {
      id: "rename",
      icon: RenameBundleIcon,
      name: "Rename",
      onClick: (e) =>
        handleNavItemClick(e, () => {
          setRenameBundleModalOpen(true)
        }),
    },
    ...(location !== labTestLocation.CHECKOUT
      ? [
          {
            id: "make-a-copy",
            icon: CopyBundleIcon,
            name: "Make a Copy",
            onClick: (e) => handleNavItemClick(e, createBundleCopy),
          },
        ]
      : []),
    ...(location !== labTestLocation.CHECKOUT
      ? [
          {
            id: "share-with-clinic",
            icon: ShareBundleIcon,
            name: bundle.is_shared_bundle
              ? "Remove from Clinic"
              : "Share with Clinic",
            onClick: (e) =>
              handleActionClick(e, () => {
                if (bundle.is_shared_bundle) {
                  unshareWithClinic(bundle)
                  trackBundleShareWithClinicClick(
                    practitioner?.id,
                    practitioner?.clinic?.id.toString(),
                    bundle?.id.toString(),
                    "Unshare"
                  )
                } else {
                  shareWithClinic(bundle)
                  trackBundleShareWithClinicClick(
                    practitioner?.id,
                    practitioner?.clinic?.id.toString(),
                    bundle?.id.toString(),
                    "Share"
                  )
                }
              }),
          },
        ]
      : []),
    {
      id: "copy-link",
      icon: CopyLinkIcon,
      name: "Copy Link",
      onClick: (e) => handleNavItemClick(e, handleCopyLinkClick),
    },
    {
      id: "delete-bundle",
      icon: DeleteIcon,
      name: "Delete Bundle",
      onClick: (e) => handleNavItemClick(e, handleRemoveBundle),
      color: cancelRed,
      background: colors.red[50],
    },
  ]
}
