import NiceModal, { useModal } from "@ebay/nice-modal-react"

import { PanelBuilderModal } from "./PanelBuilderModal"

interface PanelBuilderModalProps {
  onClose: () => void
  existingBundleId?: number
  existingBundleName?: string
  existingBundleRequestedBiomarkerIds?: string[]
  existingBundleRequestedBiomarkerGroupingIds?: string[]
  existingPanelLabCompanyKey?: string
}

const PanelBuilderNiceModal = NiceModal.create<PanelBuilderModalProps>(
  (props) => {
    return (
      <PanelBuilderModal
        open
        onClose={props.onClose}
        existingBundleId={props.existingBundleId}
        existingBundleName={props.existingBundleName}
        existingBundleRequestedBiomarkerIds={
          props.existingBundleRequestedBiomarkerIds
        }
        existingBundleRequestedBiomarkerGroupingIds={
          props.existingBundleRequestedBiomarkerGroupingIds
        }
        existingPanelLabCompanyKey={props.existingPanelLabCompanyKey}
      />
    )
  }
)

export default function usePanelBuilderModal() {
  return useModal(PanelBuilderNiceModal)
}
