import { useState } from "react"
import { useDispatch } from "react-redux"

import clsx from "clsx"
import _ from "lodash"

import Typography from "@material-ui/core/Typography"

import PersonCheckmarkIcon from "app/assets/icons/person-checkmark.svg"
import PersonQuestionMarkIcon from "app/assets/icons/person-question-mark.svg"
import useAppSelector from "app/hooks/useAppSelector"

import expandIcon from "../../../assets/images/expand.svg"
import { hasInstantRequisitions } from "../../../models/order"
import * as OrderActions from "../store/actions/orders.actions"
import { PatientInfoModal } from "./PatientInfoModal"

export function PatientInfoButton() {
  const [open, setOpen] = useState(false)
  const patient = useAppSelector(({ orders }) => orders.orders.patient)
  const order = useAppSelector(({ orders }) => orders.orders.order)
  const infoComplete = patient?.has_order_info
  const dispatch = useDispatch()

  const onClick = () => {
    setOpen(true)
  }

  return (
    <div>
      <div
        className={clsx(
          "flex justify-between shadow rounded-lg border border-gray-300 cursor-pointer pl-2 pr-4 py-3 mb-4 hover:bg-gray-200 select-none"
        )}
        onClick={onClick}
      >
        <img
          src={infoComplete ? PersonCheckmarkIcon : PersonQuestionMarkIcon}
          alt={
            infoComplete ? "Person with checkmark" : "Person with question mark"
          }
          width={30}
          height={25}
        />
        <div className="flex-grow pl-3">
          <Typography
            color="textPrimary"
            className="text-base15 font-500 inline"
          >
            {hasInstantRequisitions(order) ? (
              <>
                <span className="font-semibold">Requisition Info</span>{" "}
                <span className="font-bold">
                  {infoComplete ? "Complete" : "Incomplete"}
                </span>
              </>
            ) : (
              <>
                <span className="font-semibold">Shipping Info </span>
                {infoComplete ? (
                  <span className="font-bold">Complete</span>
                ) : (
                  "(Optional)"
                )}
              </>
            )}
            {!hasInstantRequisitions(order) && !infoComplete && (
              <span className="opacity-75">
                <br />
                We’ll email {patient?.first_name} to get their information, or
                you can fill it out now.
              </span>
            )}
          </Typography>
          {/* This is only required information if the order has instant requisitions */}
          {hasInstantRequisitions(order) && (
            <Typography color="error" className="font-semibold inline">
              {" *"}
            </Typography>
          )}
        </div>

        <img src={expandIcon} alt="Expand" width={19} height={19} />
      </div>

      {!_.isEmpty(patient) && (
        <PatientInfoModal
          patient={patient}
          open={open}
          onClose={() => {
            setOpen(false)
            // Refresh pricing and ordering issues when closing modal for when patient info changes
            dispatch(OrderActions.refreshPricing(order.id))
          }}
          requiresVendorPhysicianAuthorization={
            order.requires_vendor_physician_authorization
          }
        />
      )}
    </div>
  )
}
