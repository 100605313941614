import {
  PANEL_BUILDER_TRACKING_EVENTS,
  trackPanelBuilderEvent,
} from "app/services/segment"
import { Biomarker } from "types/biomarker"

import { PanelBuilderIdentifier } from "./types"

export function getBiomarkerDisplayName(biomarker: Biomarker) {
  return biomarker.attributes.long_name === biomarker.attributes.short_name
    ? biomarker.attributes.long_name
    : `${biomarker.attributes.long_name} (${biomarker.attributes.short_name})`
}

export function trackSelectBiomarkerOrGrouping(
  identifier: PanelBuilderIdentifier
) {
  if (identifier.type === "biomarker") {
    trackPanelBuilderEvent(
      PANEL_BUILDER_TRACKING_EVENTS.PANEL_BUILDER_BIOMARKER_SELECTED,
      { biomarker_id: identifier.id }
    )
  } else {
    trackPanelBuilderEvent(
      PANEL_BUILDER_TRACKING_EVENTS.PANEL_BUILDER_BIOMARKER_GROUPING_SELECTED,
      { biomarker_grouping_id: identifier.id }
    )
  }
}
