import * as React from "react"
import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import axios from "axios"
import { omit } from "lodash"

import {
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  makeStyles,
  styled,
} from "@material-ui/core"
import Typography from "@material-ui/core/Typography"

import AlertTriangleGrayIcon from "app/assets/icons/alert-triangle-gray.svg"
import { ReactComponent as SelectionIcon } from "app/assets/icons/selection-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import TextField from "app/components/forms/TextFieldComponent"
import {
  Dialog,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "app/components/modals"
import useFeatureFlag from "app/hooks/use-feature-flag"
import * as Actions from "app/main/dashboard/store/actions"
import { DuplicateEmailError } from "app/main/dashboard/store/actions"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { showMessage } from "app/store/actions"
import { colors, primaryColor } from "app/theme"
import { Practitioner } from "app/types"
import { getStartDraftText, handleApiError } from "app/utils"

import InfoTextTooltip from "../design-system/InfoTextTooltip"

const styles = (theme: Theme) => ({
  paper: {
    [theme.breakpoints.up("md")]: {
      minWidth: 380,
    },
    margin: 15,
    borderRadius: 7,
  },
  warningStyles: {
    "& .MuiFormLabel-root.Mui-error": {
      color: `${colors.yellow[600]} !important`,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: `${colors.yellow[600]} !important`,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: `${colors.yellow[600]} !important`,
    },
  },
  formControl: {
    backgroundColor: "white",
  },
  select: {
    "& .MuiSelect-root": {
      backgroundColor: "white",
    },
  },
  statePlaceholderText: {
    color: colors.gray[400],
  },
  beastCoastTooltip: {
    padding: 5,
    verticalAlign: "middle",
  },
  beastCoastCheckbox: {
    padding: "10px 5px 10px 0px",
  },
})

const useStyles = makeStyles(styles)

const StyledButton = styled(Button)({
  color: "white !important", // This is to fix the blue button color in Safari - iOS 14.0
  minWidth: "128px",
})

interface NewPatientModalProps {
  // Workaround to allow us to re-use this modal for the food plan flow
  customOnSubmit?: (patient: any) => void
  messages?: {
    customSubmitText?: string
    patientTerm?: string
  }
  name: string
  open: boolean
  onClose: (closeStartOrder: boolean) => void
  shouldHideCreatePatientButton: boolean
  practitioner: Practitioner
}

export default function NewPatientModal({
  customOnSubmit,
  messages = {},
  name,
  open,
  onClose,
  shouldHideCreatePatientButton,
  practitioner,
}: NewPatientModalProps) {
  messages = {
    customSubmitText: "Create",
    patientTerm: "Patient",
    ...messages,
  }

  const [isBeastCoastEnabled] = useFeatureFlag(FeatureFlag.BeastCoast)

  const classes = useStyles(styles)

  const baseFirstName = name.split(" ").slice(0, 1).join(" ")

  const baseLastName = name.split(" ").slice(1).join(" ")

  const [firstName, setFirstName] = useState(baseFirstName)
  const [lastName, setLastName] = useState(baseLastName)
  const [email, setEmail] = useState("")
  const [patientInSpecialSate, setPatientInSpecialSate] = useState(false)
  const [selectedState, setSelectedState] = useState("")
  const [validationErrors, setValidationErrors] = useState<{
    firstName?: string
    lastName?: string
    email?: string
  }>({})

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target
    setSelectedState(value as string)
  }

  const dirty = () =>
    !firstName.trim().length ||
    !lastName.trim().length ||
    !isEmail(email) ||
    (patientInSpecialSate && !selectedState)

  const dispatch = useDispatch()

  const [duplicateEmailModalOpen, setDuplicateEmailModalOpen] = useState(false)
  const [startNewOrder, setStartNewOrder] = useState(false)

  useEffect(() => {
    // clear out validation errors when the modal closes
    setValidationErrors({})
  }, [open])

  const cleanClose = (closeStartOrder = false) => {
    setDuplicateEmailModalOpen(false)
    setEmail("")
    onClose(closeStartOrder)
  }

  useEffect(() => {
    setFirstName(baseFirstName)
    setLastName(baseLastName)
    setEmail("")
  }, [name])

  const shippingState = useMemo(() => {
    if (patientInSpecialSate) {
      return selectedState
    }
    return ""
  }, [patientInSpecialSate, selectedState])

  const createPatient = async (
    confirmDuplicateEmail: boolean,
    startNewOrder: boolean,
    shippingState: string
  ) => {
    const isStartingNewOrder = startNewOrder && !customOnSubmit

    try {
      // clear validation errors upon re-submission
      setValidationErrors({})

      const patient: any = await dispatch(
        Actions.createPatient({
          firstName,
          lastName,
          email: email.trim(),
          confirmDuplicateEmail,
          startNewOrder: isStartingNewOrder,
          shippingState,
        })
      )

      if (customOnSubmit) {
        customOnSubmit(patient.id)
      } else if (!isStartingNewOrder) {
        cleanClose(true)
        dispatch(
          showMessage({
            message: `${firstName} ${lastName} added`,
            variant: "success",
            anchorOrigin: {
              vertical: "bottom", //top bottom
              horizontal: "left", //left center right
            },
          })
        )
      }
    } catch (error) {
      if (error instanceof DuplicateEmailError) {
        setDuplicateEmailModalOpen(true)
        setStartNewOrder(isStartingNewOrder)
      } else if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        setValidationErrors(omit(error.response.data.errors, "code"))
      } else {
        dispatch(handleApiError(error))
      }
    }
  }

  return (
    <Dialog
      onClose={() => cleanClose(true)}
      aria-labelledby="new-patient-modal-title"
      open={open}
      classes={{ paper: classes.paper }}
    >
      <div>
        <div className="tour-onboarding-new-patient-modal">
          {duplicateEmailModalOpen ? (
            <>
              <ModalHeader
                onClose={cleanClose}
                id="new-patient-modal-title"
                title={"Shared Email Address?"}
              />
              <ModalContent>
                <div className="flex items-start bg-white my-2 mx-1 p-1 rounded">
                  <img
                    src={AlertTriangleGrayIcon}
                    alt="Exclamation mark inside a triangle"
                    className="mr-1 mt-px w-1.5"
                  />
                  <Typography
                    color="textPrimary"
                    className="text-sm font-semibold fs-exclude"
                  >
                    The email address {email} is already in use.
                    <br />
                    <br />
                    You will be creating a new patient that will share the same
                    email address.
                  </Typography>
                </div>
              </ModalContent>
              <ModalActions>
                <Button
                  color="secondary"
                  className="mr-1.5 min-w-128"
                  variant="contained"
                  onClick={() => setDuplicateEmailModalOpen(false)}
                >
                  Go Back
                </Button>
                <Button
                  color="primary"
                  className="min-w-128"
                  variant="contained"
                  onClick={() =>
                    createPatient(true, startNewOrder, shippingState)
                  }
                >
                  Confirm
                </Button>
              </ModalActions>
            </>
          ) : (
            <>
              <ModalHeader
                onClose={cleanClose}
                id="new-patient-modal-title"
                title={`New ${messages.patientTerm}`}
              />

              <ModalContent>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className="fs-exclude"
                      error={Boolean(validationErrors.firstName)}
                      fullWidth
                      helperText={validationErrors.firstName}
                      label="First Name"
                      margin="dense"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFirstName(e.target.value)
                      }
                      value={firstName}
                      variant="outlined"
                      InputProps={{
                        className: "bg-white",
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      className="fs-exclude"
                      error={Boolean(validationErrors.lastName)}
                      fullWidth
                      helperText={validationErrors.lastName}
                      label="Last Name"
                      margin="dense"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setLastName(e.target.value)
                      }
                      value={lastName}
                      variant="outlined"
                      InputProps={{
                        className: "bg-white",
                      }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      autoFocus
                      className={"fs-exclude"}
                      error={
                        (!!email && !isEmail(email)) ||
                        Boolean(validationErrors.email)
                      }
                      fullWidth
                      helperText={validationErrors.email}
                      label="Email"
                      margin="dense"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                      }
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        e.key === "Enter" &&
                        !dirty() &&
                        createPatient(false, true, shippingState)
                      }
                      value={email}
                      variant="outlined"
                      InputProps={{
                        className: "bg-white",
                      }}
                    />
                  </Grid>
                  {isBeastCoastEnabled && (
                    <Grid item md={6} xs={12}>
                      <Checkbox
                        disabled={false}
                        checked={patientInSpecialSate}
                        color="primary"
                        onChange={(event) =>
                          setPatientInSpecialSate(event.target.checked)
                        }
                        className={classes.beastCoastCheckbox}
                      />
                      <BodyText
                        weight="semibold"
                        size="xs"
                        style={{ display: "inline" }}
                      >
                        {messages.patientTerm} lives in NY, NJ, or RI
                      </BodyText>
                      <InfoTextTooltip className={classes.beastCoastTooltip}>
                        Select testing is available for{" "}
                        {`${messages.patientTerm?.toLocaleLowerCase()}s`} in NY,{" "}
                        NJ and RI. Please check this box if your{" "}
                        {messages.patientTerm?.toLocaleLowerCase()} is based{" "}
                        there to enable proper catalog filtering{" "}
                        <a href="https://help.rupahealth.com/en/articles/8067351-what-can-i-order-for-patients-who-live-in-new-york-new-jersey-or-rhode-island">
                          Learn More
                        </a>
                        .
                      </InfoTextTooltip>
                    </Grid>
                  )}
                  {isBeastCoastEnabled && patientInSpecialSate && (
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth className={classes.formControl}>
                        <Select
                          type="text"
                          className={classes.select}
                          labelId="state-select-label"
                          id="state-select"
                          value={selectedState}
                          label="Select State"
                          onChange={handleSelectChange}
                          variant="standard"
                          IconComponent={() => null}
                          displayEmpty
                          input={
                            <OutlinedInput
                              margin="dense"
                              endAdornment={
                                <SelectionIcon
                                  width={16}
                                  height={16}
                                  fill={primaryColor}
                                />
                              }
                            />
                          }
                        >
                          <MenuItem value={""}>
                            <span className={classes.statePlaceholderText}>
                              State...
                            </span>
                          </MenuItem>
                          <MenuItem value={"NY"}>New York</MenuItem>
                          <MenuItem value={"NJ"}>New Jersey</MenuItem>
                          <MenuItem value={"RI"}>Rhode Island</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </ModalContent>
              <ModalActions>
                {!shouldHideCreatePatientButton && (
                  <Button
                    disabled={dirty()}
                    onClick={() => createPatient(false, false, shippingState)}
                    color="secondary"
                  >
                    Create {messages.patientTerm}
                  </Button>
                )}
                {!customOnSubmit ? (
                  <StyledButton
                    disabled={dirty()}
                    onClick={() => createPatient(false, true, shippingState)}
                    color="primary"
                  >
                    {getStartDraftText(practitioner)}
                  </StyledButton>
                ) : (
                  <StyledButton
                    disabled={dirty()}
                    onClick={() => createPatient(false, false, shippingState)}
                    color="primary"
                  >
                    {messages.customSubmitText}
                  </StyledButton>
                )}
              </ModalActions>
            </>
          )}
        </div>
      </div>
    </Dialog>
  )
}

function isEmail(email) {
  return /\S+@\S+\.\S+/.test(email)
}
