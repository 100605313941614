import { useState } from "react"
import { useDispatch } from "react-redux"

import axios from "axios"

import { useModal } from "@ebay/nice-modal-react"

import { InteractiveReportModal } from "app/components/modals/InteractiveReportModal"
import { useAcknowledgeUnreadResult } from "app/dashboard/components/TableRowDetailRenderer/actions/DownloadResultsDropdownMenuAction"
import { trackInteractiveReportView } from "app/services/segment.typed"
import { showMessage } from "app/store/actions"
import { getNormalizedApiUrl } from "app/utils"

export function useEmbeddableResultModal(
  emmbeddableUserResultId: string | null,
  orderId: string,
  labCompanyId: string | undefined,
  labTestName: string | undefined,
  downloadableResultUrls: string[],
  userType: "patient" | "practitioner",
  latestOrderedResultId?: string,
  shouldRevalidate: boolean = true
) {
  const dispatch = useDispatch()
  const interactiveReportModal = useModal(InteractiveReportModal)
  const [embeddableSrc, setEmbeddableSrc] = useState<{
    src?: string
    expiration?: number
  }>({})
  const acknowledgeUnreadResult = useAcknowledgeUnreadResult()

  const showEmbeddableResult = async () => {
    if (!emmbeddableUserResultId) {
      return
    }

    let currentSrcState = embeddableSrc.src
    let currentExpirationState = embeddableSrc.expiration

    if (
      !currentSrcState ||
      (currentExpirationState && currentExpirationState < Date.now())
    ) {
      try {
        const {
          data: {
            data: { src, expiration },
          },
        }: { data: { data: { src: string; expiration: number } } } =
          await axios.get(
            getNormalizedApiUrl() +
              `/user_results/${emmbeddableUserResultId}/embeddable`
          )
        setEmbeddableSrc({ src, expiration })
        currentSrcState = src
        currentExpirationState = expiration
      } catch (error) {
        console.error("Error fetching embeddable src", error)
      }
    }

    if (currentSrcState) {
      interactiveReportModal.show({
        onClose: () => {
          interactiveReportModal.remove()
        },
        src: currentSrcState,
        reportName: labTestName,
        downloadableResultUrls,
      })

      trackInteractiveReportView(userType, orderId, labCompanyId)

      if (latestOrderedResultId) {
        // Let this promise run asynchronously.
        acknowledgeUnreadResult(latestOrderedResultId, shouldRevalidate)
      }
    } else {
      dispatch(
        showMessage({
          message:
            "The interactive report is currently unavailable. Please refresh to try again or reach out to hello@rupahealth.com for support with this issue.",
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        })
      )
    }
  }

  return { showEmbeddableResult }
}
