import { useState } from "react"
import { useDispatch } from "react-redux"

import useEventCallback from "app/hooks/use-event-callback"
import useAppSelector from "app/hooks/useAppSelector"
import * as Actions from "app/store/actions/practitioner.actions"
import { PractitionerLabTestBundle } from "app/types"

/**
 * Provide bundles a set of mutators for favoriting bundles.
 *
 * @returns mutators to favorite bundles and the loading state of the requests.
 * @example
 *
 * const { favoriteBundle, isBundleFavoriteLoading } = useBundleFavorites()
 *
 * function onClick(bundle: PractitionerLabTestBundle) {
 *   favoriteBundle(bundle)
 * }
 *
 * <Button onClick={onClick} disabled={isBundleFavoriteLoading} loading={isBundleFavoriteLoading}>Favorite Bundle</Button>
 */
export default function useBundleFavorites() {
  const [isBundleFavoriteLoading, setIsBundleFavoriteLoading] = useState(false)
  const dispatch = useDispatch()
  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const favoriteBundle = useEventCallback(
    async (bundle: PractitionerLabTestBundle) => {
      setIsBundleFavoriteLoading(true)
      await dispatch(Actions.addFavoriteBundle(practitioner, bundle))
      setIsBundleFavoriteLoading(false)
    }
  )

  const unfavoriteBundle = useEventCallback(
    async (bundle: PractitionerLabTestBundle) => {
      setIsBundleFavoriteLoading(true)
      await dispatch(Actions.removeFavoriteBundle(practitioner, bundle))
      setIsBundleFavoriteLoading(false)
    }
  )

  return {
    favoriteBundle,
    unfavoriteBundle,
    isBundleFavoriteLoading,
  }
}
