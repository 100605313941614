import { useMemo } from "react"

import { Dialog, Link, Typography } from "@material-ui/core"

import ArrowTopRight from "app/assets/icons/arrow-top-right.svg"
import InstantReqCheckbox from "app/assets/images/instant-req.svg"
import DesignSystemButton from "app/components/design-system/Button"
import { Order } from "app/types"

import { getInstantRequisitionTestNames } from "./helpers"

interface Props {
  order: Order
  open: boolean
  onClose: () => void
  onConfirm: () => Promise<void>
}

const InstantRequisitionModal: React.FC<Props> = ({
  order,
  open,
  onClose,
  onConfirm,
}) => {
  const { instantRequisitionTestNames, dropShipTestNames } = useMemo(
    () => getInstantRequisitionTestNames(order),
    [order]
  )

  const title = !!dropShipTestNames.length
    ? "Are you sure? This order contains a mix of drop ship and in office kits."
    : "Are You Sure?"

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="instant-requisition-title"
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      classes={{
        root: "rounded-xl",
      }}
    >
      <div className="flex flex-col items-center pt-16 pb-12">
        <img
          src={InstantReqCheckbox}
          alt="Instant req checkbox"
          className="max-h-[100px]"
        />
        <Typography
          color="textPrimary"
          variant="h5"
          className="mt-8 mb-6 font-semibold px-8 text-center"
          id="instant-requisition-titls"
        >
          {title}
        </Typography>

        <Typography
          color="textPrimary"
          className="fs-exclude max-w-lg text-center whitespace-pre-wrap font-bold text-base15"
        >
          The following{" "}
          {instantRequisitionTestNames.length === 1 ? "kit" : "kits"} will not
          be shipped to {order?.patient?.first_name}:
        </Typography>

        <ul className="pl-5">
          {instantRequisitionTestNames &&
            instantRequisitionTestNames.map((testName) => (
              <li key={testName}>
                <Typography
                  color="textPrimary"
                  className="fs-exclude text-left whitespace-pre-wrap mt-2 text-sm"
                >
                  {testName}
                </Typography>
              </li>
            ))}
        </ul>

        {!!dropShipTestNames.length && (
          <>
            <Typography
              color="textPrimary"
              className="fs-exclude max-w-lg text-center whitespace-pre-wrap font-bold text-base15 mt-4"
            >
              The following {dropShipTestNames.length === 1 ? "kit" : "kits"}{" "}
              will be shipped to {order?.patient?.first_name}:
            </Typography>

            <ul className="pl-5">
              {dropShipTestNames.map((testName) => (
                <li key={testName}>
                  <Typography
                    color="textPrimary"
                    className="fs-exclude text-left whitespace-pre-wrap mt-2 text-sm"
                  >
                    {testName}
                  </Typography>
                </li>
              ))}
            </ul>
          </>
        )}

        <DesignSystemButton
          className="mt-6 font-semibold text-base15"
          color="primary"
          onClick={onConfirm}
          size="medium"
        >
          Yes, I Have{" "}
          {instantRequisitionTestNames.length === 1 ? "This Kit" : "These Kits"}{" "}
          In-Office
        </DesignSystemButton>

        <DesignSystemButton
          className="mt-6 font-semibold text-base15"
          color="secondary"
          onClick={onClose}
          size="medium"
        >
          No, Take Me Back to the Order
        </DesignSystemButton>

        <Link
          href="https://www.rupahealth.com/in-office-kits"
          target="_blank"
          className="mt-3"
        >
          Learn more about In Office Kits
          <img className="ml-1" src={ArrowTopRight} alt="" />
        </Link>
      </div>
    </Dialog>
  )
}

export default InstantRequisitionModal
