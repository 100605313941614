import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useBloodReportActionsEnabledForOrderedResult from "app/main/blood-lab-dashboards/hooks/use-blood-report-actions-enabled-for-ordered-result"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import makeAppStyles from "app/utils/makeAppStyles"
import { Clinic } from "types/clinic"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardOrderedResult } from "types/dashboard/dashboard-ordered-result"
import { Practitioner } from "types/practitioner"

import BloodReportAction from "./actions/BloodReportsAction"
import DownloadResultsAction from "./actions/DownloadResultsAction"
import MarkResultsReviewedAction from "./actions/MarkResultsReviewedAction"
import OpenEmbeddableResultAction from "./actions/OpenEmbeddableResultAction"
import ScheduleClinicConsultAction from "./actions/ScheduleClinicConsultAction"
import SendResultsAction from "./actions/SendResultsAction"
import ViewRequisitionAction from "./actions/ViewRequisitionAction"

const useStyles = makeAppStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.0),
  },
}))

export interface TableRowDetailKitActionsProps {
  kit: DashboardKit
  order: DashboardOrder
}

export default function TableRowDetailKitActions({
  kit,
  order,
}: TableRowDetailKitActionsProps) {
  const classes = useStyles()

  const [embeddableResultsEnabled] = useFeatureFlag(
    FeatureFlag.EmbeddableResults
  )

  const practitioner = useCachedResource<Practitioner>(
    order.relationships.practitioner?.data ||
      order.relationships.signing_practitioner?.data
  )

  const clinic = useCachedResource<Clinic>(
    practitioner?.relationships.clinic.data
  )

  const latestOrderedResult = useCachedResource<DashboardOrderedResult>(
    kit.relationships.latest_ordered_result?.data
  )

  const { isBloodReportActionEnabled } =
    useBloodReportActionsEnabledForOrderedResult({
      supportsBloodReport:
        latestOrderedResult?.attributes?.supports_blood_report,
      hasSnapshots:
        !!latestOrderedResult?.relationships
          ?.blood_lab_dashboard_report_snapshots.data?.length,
      clinicFeatures: clinic?.attributes.clinic_features,
    })

  return (
    <div className={classes.root}>
      {kit.relationships.latest_ordered_result?.data ? (
        <MarkResultsReviewedAction kit={kit} order={order} />
      ) : (
        <ViewRequisitionAction kit={kit} order={order} />
      )}

      <ScheduleClinicConsultAction kit={kit} order={order} />

      <SendResultsAction kit={kit} order={order} />

      {isBloodReportActionEnabled ? (
        <BloodReportAction kit={kit} />
      ) : embeddableResultsEnabled &&
        kit?.relationships.embeddable_user_result?.data ? (
        <OpenEmbeddableResultAction kit={kit} order={order} />
      ) : (
        <DownloadResultsAction kit={kit} order={order} />
      )}
    </div>
  )
}
