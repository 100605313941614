// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"

import { Typography, TypographyProps } from "@material-ui/core"

import { InjectedProps, WrapperProps } from "formsy-react/dist/withFormsy"

type Props = WrapperProps<unknown> & {
  align?: TypographyProps["align"]
  className?: string
}

function ErrorLabelFormsy({
  align,
  className,
  errorMessage,
}: Props & InjectedProps<unknown>) {
  return (
    <Typography align={align} color="error" className={className}>
      {errorMessage}
    </Typography>
  )
}

export default withFormsy(ErrorLabelFormsy)
