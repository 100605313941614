import { useState } from "react"
import { useDispatch } from "react-redux"

import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { resendRegistrationEmail } from "app/auth/store/actions/user.actions"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import SignatureAndLicenseModalButton, {
  signatureAndLicenseButtonTitle,
} from "app/components/modals/SignatureAndLicenseModal/SignatureAndLicenseModalButton"
import useFeatureFlag from "app/hooks/use-feature-flag"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { Practitioner } from "app/types"

import { PractitionerSetting } from "../PersonalSettings"
import InOfficeKitsModal from "./InOfficeKitsModal"
import Separator from "./Separator"
import { StyledFormControlLabel } from "./StyledFormControlLabel"
import { StyledSwitch } from "./StyledSwitch"

interface Props {
  practitioner: Practitioner
  handleToggle: (key: PractitionerSetting) => void
}

const PersonalPreferences: React.FC<Props> = ({
  practitioner,
  handleToggle,
}) => {
  const dispatch = useDispatch()
  const [inOfficeKitsV2, isLoading] = useFeatureFlag(FeatureFlag.InOfficeKitsV2)
  const [showInOfficeKitsModal, setShowInOfficeKitsModal] =
    useState<boolean>(false)

  const requestKitsButton =
    practitioner.allow_in_office_kits && !inOfficeKitsV2 && !isLoading ? (
      <DesignSystemButton
        color="secondary"
        className="mt-3 ml-11"
        onClick={() => setShowInOfficeKitsModal(true)}
      >
        Request Kits to be Shipped to Your Office
      </DesignSystemButton>
    ) : null

  // If the practitioner has full verification info, or is a clinic staff, they can use in-office kits
  // Clinic staff choose from a list of practitioners to order from
  const allowPracInOfficeDelivery =
    practitioner.has_full_verification_info || practitioner.is_clinic_staff

  const onClickResendEmailVerification = () => {
    dispatch(resendRegistrationEmail({ email: practitioner.user?.email }))
  }

  const handleInOfficeKitsToggleSwitch = () => {
    if (!practitioner.allow_in_office_kits) {
      setShowInOfficeKitsModal(true)
    } else {
      // Turning off
      handleToggle("allow_in_office_kits")
    }
  }

  const handleInOfficeKitsModalSubmit = () => {
    // First submission; turn on switch
    if (!practitioner.allow_in_office_kits) {
      handleToggle("allow_in_office_kits")
    }

    setShowInOfficeKitsModal(false)
  }

  const inOfficeKitTooltipText = () => {
    if (!practitioner.user?.is_verified) {
      return "You'll need to verify your email address before enabling In-Office Kits."
    } else if (!practitioner.has_signature) {
      return "Please provide your e-signature and upload a copy of your license to use In-Office Kits."
    } else {
      return "Please upload a copy of your license to use In-Office Kits."
    }
  }

  const inOfficeKitTooltipCTA = (
    <div style={{ marginTop: "8px" }}>
      {!practitioner.user?.is_verified ? (
        <DesignSystemButton
          color="text"
          variant="text"
          className="text-base15"
          onClick={onClickResendEmailVerification}
        >
          Resend verification email
        </DesignSystemButton>
      ) : (
        <SignatureAndLicenseModalButton
          location="personal_preferences"
          autoClose
        >
          {signatureAndLicenseButtonTitle(practitioner)}
        </SignatureAndLicenseModalButton>
      )}
    </div>
  )

  const inOfficeKitTooltip = (
    <div style={{ textAlign: "center" }}>
      <BodyText>{inOfficeKitTooltipText}</BodyText>
      <div style={{ marginTop: "8px" }}>{inOfficeKitTooltipCTA}</div>
    </div>
  )

  return (
    <>
      <div className="flex items-center">
        <BodyText weight="semibold" size="lg">
          General Preferences
        </BodyText>
      </div>
      <StyledFormControlLabel
        control={
          <StyledSwitch
            checked={!!practitioner.patient_results_release_automatically}
            onChange={() =>
              handleToggle("patient_results_release_automatically")
            }
            name="patient_results_release_automatically"
          />
        }
        label={
          <div>
            <BodyText weight="semibold">Release results automatically</BodyText>
            {practitioner.patient_results_release_automatically ? (
              <BodyText>
                This setting is on, meaning your patients will automatically
                receive a secure link to view their results in their Patient
                Portal as soon as they come in, even if all biomarkers aren't
                complete.
              </BodyText>
            ) : (
              <BodyText>
                This setting is off, meaning your patients won't receive their
                results until you release them. Don't worry though, if you
                forget, results will still be made available in the Patient
                Portal automatically after 30 days.
              </BodyText>
            )}
          </div>
        }
      />
      {!inOfficeKitsV2 && (
        <StyledFormControlLabel
          control={
            <StyledSwitch
              checked={!!practitioner.allow_in_office_kits}
              onChange={handleInOfficeKitsToggleSwitch}
              name="allow_in_office_kits"
              tooltiptext={
                !allowPracInOfficeDelivery ? inOfficeKitTooltip : null
              }
              disabled={!allowPracInOfficeDelivery}
            />
          }
          label={
            <div>
              <BodyText weight="semibold">
                Show <span style={{ fontStyle: "italic" }}>In-Office</span>{" "}
                delivery method
              </BodyText>
              <BodyText>
                Rupa can handle orders for kits that you hand to patients
                in-person. We'll still take care of the payment, requisition
                forms, instructions and follow-ups for your patients.
                <a
                  href="https://www.rupahealth.com/in-office-kits"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary font-semibold sm:ml-1"
                >
                  Learn more <FontAwesomeIcon icon={faArrowUpRight} />
                </a>
                <br />
              </BodyText>
              <InOfficeKitsModal
                onClose={() => setShowInOfficeKitsModal(false)}
                onSubmit={handleInOfficeKitsModalSubmit}
                open={showInOfficeKitsModal}
              />
            </div>
          }
        />
      )}
      {requestKitsButton}
      <Separator />
    </>
  )
}

export default PersonalPreferences
