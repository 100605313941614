import * as React from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"

import axios from "axios"

import { makeStyles } from "@material-ui/core"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import { API } from "app/api"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import TextField from "app/components/forms/TextFieldComponent"
import { Dialog } from "app/components/modals"
import { colors, navy } from "app/theme"
import { handleApiSuccess } from "app/utils"

interface Props {
  open: boolean
  title?: string
  confirmButtonTitle?: string
  onClose: () => void
}

const styles = (theme) => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
  },
  modalContainer: {
    display: "flex",
    flexFlow: "column",
    alignItems: "stretch",
    width: "100%",
    // position: "relative",
    [theme.breakpoints.up("md")]: {
      flexFlow: "row",
    },

    "& .labTestCardGridItem": {
      "@media (min-width: 2000px)": {
        flexBasis: "20%",
        width: "20%",
      },
    },
  },
  styledContent: {
    padding: 22.5,
    backgroundColor: colors.coolGray[50],
  },
  styledDialogTitle: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    "flex-wrap": "nowrap",
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.blueGray[200],
    alignItems: "center",
    padding: "8px 24px",
  },
  actionsContainer: {
    margin: 0,
    padding: "8px 20px",
  },
  titleAndInfoContainer: {
    display: "flex",
    "flex-direction": "column",
    "flex-wrap": "wrap",
    marginRight: 24,
  },
  closeButton: {
    color: navy,
    position: "relative" as "relative",
    top: 1,
    right: 0,
    marginRight: -12,
  },
})

const useStyles = makeStyles(styles)

/**
 * Add Coupon Modal
 * @param open - The modal will be open if this is true
 * @param title - The title of the modal
 * @param confirmButtonTitle - The confirm button title
 * @param onClose - The action which needs to be done when the modal closes
 */
const AddCouponModal = ({
  open,
  title = "Enter a coupon code",
  confirmButtonTitle = "Apply Coupon",
  onClose,
}: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles(styles)

  const [couponCode, setCouponCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [codeValidationError, setCodeValidationError] = useState("")

  const handleCouponCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(e.target.value)
    codeValidationError !== "" && setCodeValidationError("")
  }

  const handleSubmitCouponCode = async () => {
    try {
      setLoading(true)
      await API.Coupon.createCouponUser(couponCode)
      dispatch(handleApiSuccess(`Successfully added Coupon Code ${couponCode}`))
      setCouponCode("")
      onClose()
    } catch (error) {
      if (axios.isAxiosError(error) && error && error.response) {
        setCodeValidationError(error.response.data.error[0])
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="lab-test-bundle-title"
      open={open}
      className={"fs-unmask"}
      classes={{
        paper: classes.dialogPaper,
      }}
      fullWidth
      maxWidth="xs"
    >
      <TitleSection title={title} onClose={onClose} />
      <MuiDialogContent className={classes.styledContent}>
        <div className={classes.modalContainer}>
          <TextField
            className="fs-exclude"
            placeholder="Enter Code"
            error={Boolean(codeValidationError)}
            fullWidth
            helperText={codeValidationError}
            label={null}
            margin="dense"
            onChange={handleCouponCodeChange}
            value={couponCode}
            variant="outlined"
            InputProps={{
              className: "bg-white",
            }}
            required={true}
          />
        </div>
      </MuiDialogContent>
      <MuiDialogActions className={classes.actionsContainer}>
        <DesignSystemButton
          color="primary"
          loading={loading}
          onClick={handleSubmitCouponCode}
          disabled={couponCode === ""}
        >
          {confirmButtonTitle}
        </DesignSystemButton>
      </MuiDialogActions>
    </Dialog>
  )
}

const TitleSection = ({ title, onClose }) => {
  const classes = useStyles(styles)
  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.styledDialogTitle}>
      <div className={classes.titleAndInfoContainer}>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}

export default AddCouponModal
