import { SelectProps, TextFieldProps } from "@material-ui/core"

export function ariaLabel(
  props: Pick<
    TextFieldProps | SelectProps,
    "inputProps" | "label" | "name" | "placeholder"
  >
): string {
  let ariaLabel = props.inputProps?.["aria-label"]

  if (!ariaLabel && typeof props.label === "string") {
    ariaLabel = props.label
  } else if (!ariaLabel) {
    ariaLabel = props.name ?? props.placeholder ?? ""
  }

  return ariaLabel
}
