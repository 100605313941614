import { ReactComponent as FoodPlanDocumentIcon } from "app/assets/images/food-plan-document-icon.svg"
import useDownloadFoodPlan from "app/food-plans/hooks/use-download-food-plan"
import useRedirectToEditFoodPlan from "app/food-plans/hooks/use-redirect-to-edit-food-plan"
import useEventCallback from "app/hooks/use-event-callback"
import { Patient } from "app/types"
import { Document } from "types/patient-with-documents-v2"

import DocumentCardActionsMenu from "./DocumentCardActionsMenu"
import PatientDocumentCard from "./PatientDocumentCard"

interface Props {
  patient: Patient
  document: Document
  onDelete: (document: Document) => void
}

const FoodPlanDocumentCard: React.FC<Props> = ({
  patient,
  document,
  onDelete,
}) => {
  const { downloadFoodPlan, isDownloadingFoodPlan } = useDownloadFoodPlan()
  const redirectToEditFoodPlan = useRedirectToEditFoodPlan()

  const canDownload = document.meta.can_read
  const canEdit =
    document.meta.can_write && document.relationships.food_plan?.data.id
  const canDelete = document.meta.can_delete

  const onClick = useEventCallback(() => {
    if (canEdit) {
      onEdit()
    } else if (canDownload) {
      onDownload()
    }
  })

  const onEdit = useEventCallback(() => {
    if (canEdit) {
      redirectToEditFoodPlan(
        patient.id,
        document.relationships.food_plan!.data.id
      )
    }
  })

  const onDownload = useEventCallback(() => {
    if (canDownload) {
      downloadFoodPlan(document.relationships.food_plan!.data.id)
    }
  })

  return (
    <PatientDocumentCard
      onClick={onClick}
      Icon={(className) => <FoodPlanDocumentIcon className={className} />}
      title="Food Plan"
      subTitle={document.attributes.origin_description}
      actions={
        <DocumentCardActionsMenu
          onDelete={canDelete ? () => onDelete(document) : undefined}
          onDownload={canDownload ? onDownload : undefined}
          isDownloading={isDownloadingFoodPlan}
          onEdit={canEdit ? onEdit : undefined}
        />
      }
    />
  )
}

export default FoodPlanDocumentCard
