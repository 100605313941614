import { Feature, FeatureCollection, Point } from "geojson"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"

import { FuseMessageVariant } from "@fuse/components/FuseMessage/FuseMessage"
import { SnackbarProps } from "@material-ui/core"

import { EditorState } from "types/editor-state"
import { SpecimenIssue } from "types/specimen-issue"

import {
  COUPON_TYPE,
  LAB_COMPANY_ACCOUNT_STATUS,
  LAB_COMPANY_KEY,
  LAB_TEST_TYPE_KEY,
  ORDER_LINE_ITEM_TYPE,
  OrderingIssueKey,
  OrderingIssueLevel,
  OrderingIssueSourceType,
  OrderingIssueType,
} from "./constants"
import {
  OrderPayerUnion,
  OrderStatusUnion,
  OrderedTestStatusUnion,
  SampleCollectionCoordinator,
  SpecimenIssueStatusUnion,
} from "./constants.typed"
import { DashboardState } from "./main/dashboard/store/reducers/dashboard.reducer"
import { OnboardingState } from "./main/dashboard/store/reducers/onboarding.reducer"
import { OrderRequestStatus } from "./main/patient-checkout/store/actions"
import { SpecimenIssueEmailRecipientId } from "./main/settings/constants"

// Used to get a union of the values of an object. That object needs to be annotated
// using `as const` in order for TS to accurately build the value type.
type ValueOf<T> = T[keyof T]

export type OptionType<T = string> = {
  value: T
  label: string
  description?: React.ReactNode
}

export type ReactSVGIconType = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>

export interface LimitOffsetPaginatedResponse<T> {
  count: number
  next: string | null
  previous: string | null
  results: T[]
  offset: number
}

export interface PageNumberPaginatedResponse<T> {
  count: number
  next: string | null
  previous: string | null
  page: number
  results: T[] | null
}

export interface User {
  uuid: string
  first_name: string
  last_name: string
  full_name: string
  email: string
  is_verified?: boolean
  force_password_change?: boolean
  can_modify_email?: boolean
}

export interface AuthUser {
  auth_provider: string
  auto_logout: boolean
  email: string
  first_name: string
  last_name: string
  full_name: string
  force_password_change: boolean
  id: number
  is_staff: boolean
  is_provider: boolean
  is_verified: boolean
  username: string
  uuid: string
  user_hash: string
  has_patients: boolean
  data: {
    photoURL: string
  }
  shouldShowInitialPasswordSetMessage: boolean
  shouldShowEmailVerifiedMessage: boolean
  // TODO: Figure out if webflow cookie is still needed for role
  role: string
}

export interface LabTestBundle {
  id: number
  name: string
  lab_tests: LabTest[]
  practitioner: Practitioner
  is_shared_bundle: boolean
  is_favorited: boolean
  shared_by: string
  requested_biomarkers: string[]
  requested_biomarker_groupings: string[]
  bundle_type: LabTestBundleType
  panel_lab_company_key: string
  ordering_rights_status?: OrderingRightsStatus
}

export enum LabTestBundleType {
  PANEL = "PANEL",
  BASIC = "BASIC",
}

export interface LabTestShareBundle {
  id: string
  name: string
  lab_test_bundle: string
  lab_tests: LabTest[]
  description: string
  bundle_type: LabTestBundleType
}

export type PractitionerLabTestBundle = Omit<LabTestBundle, "practitioner">

export enum KnownPractitionerType {
  MD = "MD",
  DO = "DO",
  ND = "ND",
  DC = "DC",
  DPM = "DPM",
  DDS_DMD = "DDS/DMD",
  OD = "OD",
  PHARM_D = "Pharm. D.",
  LAC = "L.Ac.",
  DAOM_OMD = "DAOM/OMD",
  PA = "PA",
  CRNA = "CRNA",
  APRN = "APRN",
  CNM = "CNM",
  CNS_NURSE = "CNS - Nurse",
  CNP = "CNP",
  NP = "NP",
  RN = "RN",
  PT = "PT",
  PHD = "PhD",
  RD = "RD",
  LD = "LD",
  LDN = "LDN",
  CHC = "CHC",
  FDP = "FDP",
  FDN_P = "FDN-P",
  NTP = "NTP",
  RDN = "RDN",
  NUTRITIONIST = "Nutritionist",
  CNS_NUTRITIONIST = "CNS - Nutritionist",
  CN = "CN",
  OT = "OT",
  OTHER = "OTHER",
}

export interface PractitionerType {
  id: string
  known_type: KnownPractitionerType | null
  name: string
  display_name: string | null
  has_medicare_permission: boolean
  must_be_included_in_lab_company_ordering_rights: boolean | null
}

export enum OnboardingSelections {
  ONE_ON_ONE = "one_on_one",
  GROUP_DEMO = "group_demo",
  DEMO_VIDEO = "demo_video",
  SELF = "self",
  SKIP = "skip",
}

export enum OnboardingVersions {
  V1 = "v1",
}

export enum OnboardingSurveyStatus {
  // User is excluded from this tour. This could be because they are an existing user that does not require it.
  USER_EXCLUDED = "User Excluded",

  // User is not eligible to receive the survey. This could be because they are clinic staff.
  INELIGIBLE = "Ineligible",

  // Tour will be started once the user performs the required trigger action (such as logging in)
  AWAITING_USER_TRIGGER = "Awaiting User Trigger",

  // Tour has been started, but has not been completed.
  STARTED_AND_INCOMPLETE = "Started And Incomplete",

  // The Tour was manually skipped by the user
  SKIPPED = "Skipped",

  // The tour was fully completed by the user.
  COMPLETED = "Complete",
}

export enum DashboardCard {
  EXPLORE_TEST_CATALOG = "Explore test catalog",
  STEP_BY_STEP_GUIDE = "Step by step guide",
  PRICING_AND_PAYMENT = "Pricing and payment",
  HOW_BLOOD_DRAWS_WORK = "How blood draws work",
  PATIENT_EXPERIENCE = "Patient experience",
  SCHEDULE_1_ON_1 = "Schedule 1 on 1",
  ORDERING_BLOODWORK = "Ordering bloodwork",
  LEARN_FUNCTIONAL_LABS = "Learn functional labs",
  INVITE_CLINIC_STAFF = "Invite clinic staff",
}
export interface Profile {
  user: User
}

export enum UserResultStatus {
  FINAL = "final",
  PRELIMINARY = "preliminary",
  PARTIAL = "partial",
  CORRECTED = "corrected",
}

export interface Practitioner {
  id: string
  npi_number: string
  is_clinic_staff: boolean
  user: User
  shipping_address: string
  billing_address: string
  primary_practitioner_type?: PractitionerType
  other_practitioner_type: PractitionerType[]
  onboarding_survey_status: OnboardingSurveyStatus
  onboarding_survey_questions: {
    name: string
    label: string
    options?: OptionType[]
    type?: "number"
    info?: string
  }[]
  onboarding_survey_answers: { [key: string]: string }
  onboarding_version: OnboardingVersions
  onboarding_selection: OnboardingSelections
  dashboard_cards: DashboardCard[]
  dismissed_dashboard_cards: string[]
  clinic: Clinic
  has_license: boolean
  has_valid_license: boolean
  has_signature: boolean
  has_signing_permission: boolean
  has_full_verification_info: boolean
  hide_insurance: boolean
  hide_practitioner_pay: boolean
  is_signing_practitioner_on_active_order: boolean
  first_order_date: string
  first_order_paid_date: string
  signing_practitioner: SigningPractitioner
  physician_authorization: PhysicianAuthorizationType
  registered_lab_companies: string[]
  titled_full_name: string
  disable_portal_notifications: boolean
  favorite_lab_tests: LabTest[]
  favorite_lab_test_ids: string[]
  allow_in_office_kits?: boolean
  patient_results_release_automatically?: boolean
  // TODO - can be removed after intercom rollout
  created_at: string

  // TODO: The rest of these
  // "states_licensed_in",
  lab_company_accounts?: LabCompanyAccount[]

  // Utility props created client-side
  favorite_lab_test_id_set: Set<string>

  // Lab Companies that have had their override access redacted
  redacted_ordering_rights_logs: RedactedOrderingRightsLog[]
  vendor_physician_authorization_enabled: boolean
  default_physician_authorization: boolean
  is_vendor_physician_authorization_clinician: boolean
  notify_for_result_statuses: string[]
  referral_source: string
  use_physician_services_terminology: boolean
  order_terminology: string
  has_esignature_blocked_orders: boolean
  physician_services_intention:
    | "self_credential"
    | "physician_services_and_self"
    | "physician_services"
  phone_number: string
  physician_services_blocked: boolean
  physician_services_enable_method: string
  has_published_physician_services_labshop: boolean
  practitioner_settings: string[]
}

export type SimplePractitioner = Pick<Practitioner, "id" | "titled_full_name">

export interface RedactedOrderingRightsLog {
  lab_company: SimpleLabCompany
  source: string
  action: string
}

export interface SimpleLabCompany {
  id: string
  key: string
}

export interface Location {
  latitude: string
  longitude: string
}

export interface SigningPractitioner {
  id: string
  primary_practitioner_type: PractitionerType | null
  has_license: boolean
  has_signature: boolean
  has_full_verification_info: boolean
  clinic: Clinic
  titled_full_name: string
  physician_authorization: string
  registered_lab_companies: string[]
  is_clinic_staff: boolean
  user: User
  lab_company_accounts: LabCompanyAccount[]
  phone_number: string | null
  vendor_physician_authorization_enabled: boolean
}

export interface LabCompany {
  id: string
  name: string
  short_name: string
  results_name: string
  details: string
  public: boolean
  key: ValueOf<typeof LAB_COMPANY_KEY>
  notifies_sample_at_lab: boolean
  provides_results_eta: boolean
  ordering_rights: LabCompanyOrderingRight[]
  certifications: string[]
  additional_fees?: string | null
  has_shipment_tracking_number?: boolean | null
  shipping_carrier?: string | null
  consultation_availability?: string | null
  about_url?: string | null
  logo: string | null
  specialties: string[]
  is_available_for_labshops: boolean
  only_in_house_phlebotomy: boolean
}

export interface LabCompanyOrderingRight {
  practitioner_types: string[]
  disallowed_states: string[]
}

export interface LabTestType {
  id: string
  name: string
  key: ValueOf<typeof LAB_TEST_TYPE_KEY>
  phlebotomy_required: boolean
}

export interface LabTestCompletionLocation {
  id: number
  name: string
}

export interface ComboGroup {
  id: string
  name: string
  min_selections: number
  max_selections: number
}

export interface ComboGroupOption {
  id: string
  name: string
  lab_test: LabTest
}

export interface BaseLabTest {
  id: string
  name: string
  url: string
  rupa_url?: string
  details: string
  lab_company: LabCompany
  lab_test_types: LabTestType[]
  completion_locations: LabTestCompletionLocation[]
  unavailable_reason: string
  shipping_days_min: number | null
  shipping_days_max: number | null
  estimated_days_for_results: number | null
  min_age: number | null
  msrp_price: string
  rupa_price: string
  initial_insurance_price: string
  insurance_max_out_of_pocket_price: string
  medicare_price: string
  has_instant_requisition: boolean
  has_handfilled_requisition_forms: boolean
  vibrant_minimum_excluded: boolean
  is_available: boolean
  is_available_for_labshops: boolean
  active_shipping_alert?: LabCompanyAlert
  cheatsheet_url: string
  sample_report_url: string
  biomarkers: Biomarker[]
  physician_authorization_approved: boolean
  processing_methods: string[]
  collection_days_min?: number | null
  collection_days_max?: number | null
  /**
   * A duration string of the format "DD HH:MM:SS", e.g. "14 00:00:00" would be a duration of 2 weeks.
   *
   * This may be formatted using `moment#duration`:
   * @example
   * ```
   * moment.duration(labTest.preparation_starts).humanize({ h: 24, d: 7, w: 4, m: 12 })
   * // "14 00:00:00" => "2 Weeks"
   * ```
   */
  preparation_starts?: string | null
  state_ordering_restrictions: object
  combo_group?: string
  combo_num_selections?: number
  requires_fasting?: boolean
  fasting_duration: number | null
  is_partial_serialization?: boolean
  ordering_rights_status?: OrderingRightsStatus
}

export interface LabTestAddon extends BaseLabTest {
  add_ons: string[]
  parents: string[]
  physician_authorization_approved: boolean
}

export interface LabTest extends BaseLabTest {
  add_ons: LabTestAddon[]
  parents: LabTestAddon[]
  min_age: number | null
  physician_authorization_approved: boolean
}

export type AnyLabTest = LabTest | LabTestAddon
// LabTestListItem is a simpler model of LabTest used in the lab test list. Often we just use the id on the lab test
// and AnyLimitedLabTest is sufficient. If you are starting with or need more data, use AnyLabTest.
export type AnyLimitedLabTest = LabTest | LabTestAddon | LabTestListItem

export interface LabTestListItemCompany {
  id: string
  key: keyof typeof LAB_COMPANY_KEY
  name: string
  short_name: string
  ordering_rights: LabCompanyOrderingRight[]
  is_available_for_labshops: boolean
}
export interface LabTestListItemParentOrAddOn {
  id: string
  name: string
}
export interface LabTestListItem {
  id: string
  is_partial_serialization: boolean
  lab_company: LabTestListItemCompany
  lab_test_types: LabTestType[]
  name: string
  add_ons: LabTestListItemParentOrAddOn[]
  parents: LabTestListItemParentOrAddOn[]
  unavailable_reason: string
  state_ordering_restrictions: object
  // Available if authenticated & user is staff / practitioner
  rupa_price?: string
  physician_authorization_approved?: boolean
  is_available?: boolean
  is_available_for_labshops?: boolean
  combo_group?: string
  ordering_rights_status?: OrderingRightsStatus
}

export interface FullUser {
  id: string
  first_name: string
  last_name: string
  full_name: string
  email: string
  uuid: string
  can_modify_email?: boolean
  has_password_set?: boolean
}

export interface PatientPortalLabTest extends LabTest {
  cpt_codes: string[]
}

export interface PatientPortalOrderedTest {
  id: number
  instant_requisition: boolean
  lab_test: PatientPortalLabTest
  shipping_days_min: number | null
  shipping_days_max: number | null
  ordering_rights_status: OrderingRightsStatus
  requires_waiver: boolean
  combo_group_options?: ComboGroupOption[]
  sample_collection_coordinator: SampleCollectionCoordinator
}

export interface PatientPortalNameOnlyUser {
  first_name: string
  last_name: string
  full_name: string
}

export interface PatientPortalPractitioner {
  user: PatientPortalNameOnlyUser
  titled_full_name: string
  clinic_name: string
  clinic_branding_enabled: boolean
}

export interface PatientInsurance {
  claims_street: string | null
  claims_city: string | null
  claims_state: string | null
  claims_zipcode: string | null
  claims_country: string | null
  claims_phone_number: string | null
  provider: string
  subscribers_first_name: string
  subscribers_last_name: string
  subscribers_birthday: string | null
  subscribers_relationship: string
  subscribers_id_number: string
  group_number: string | null
  patient: number
}

export interface ScarletData {
  blood_draw_zipcode: string
}

export interface PatientPortalPatient {
  id: number
  user: FullUser
  insurance: PatientInsurance | null
  first_name: string
  last_name: string
  full_name: string
  phone_number: string
  birthday: string
  biological_sex: string
  default_shipping_address: {
    street?: string
    street_1: string
    street_2?: string
    city: string
    state: string
    zipcode: string
  }
  can_modify_name: boolean
}

export interface PatientPortalPhlebotomist {
  id: number
  name: string
  external_notes: string
  address: string
  phone: string
  fee: string
  hours: string
}

export interface PatientPortalCoupon {
  id: number
  patient_title: string
  patient_subtitle: string
  type: keyof typeof COUPON_TYPE
  discount: string
  lab_test: number
  disable_notes_to_rupa: number
}

export interface PatientPortalOrderLineItemBase {
  id: number
  ordered_test: PatientPortalOrderedTest | null
  coupon: PatientPortalCoupon | null
  title: string
  type: ValueOf<typeof ORDER_LINE_ITEM_TYPE>
  cost: string
  discounted_cost: string
  date_disabled: string | null
}

// Whenever a more specific LineItem type is required, add it to the union type
// `PatientPortalOrderLineItem` and omit it from this `Other` type.
export interface PatientPortalOrderLineItemOther
  extends PatientPortalOrderLineItemBase {
  type: Exclude<ValueOf<typeof ORDER_LINE_ITEM_TYPE>, "Lab Test" | "Coupon">
}

export interface PatientPortalOrderLineItemLabTest
  extends PatientPortalOrderLineItemBase {
  type: "Lab Test"
  ordered_test: PatientPortalOrderedTest
}

export interface PatientPortalOrderLineItemCoupon
  extends PatientPortalOrderLineItemBase {
  type: "Coupon"
  coupon: PatientPortalCoupon
}

export type PatientPortalOrderLineItem =
  | PatientPortalOrderLineItemOther
  | PatientPortalOrderLineItemLabTest
  | PatientPortalOrderLineItemCoupon

export enum PatientInstructionTypes {
  LAB_TEST_COMPLETION_INSTRUCTIONS = "lab_test_completion_instructions",
  PRINT_REQ_FORMS = "print_req_forms",
  FILL_OUT_REQ_FORMS = "fill_out_req_forms",
  SCHEDULE_BLOOD_DRAW = "schedule_blood_draw",
  WAIVER_FOR_STATE_COLLECTION = "waiver_for_state_collection",
}

export interface LabTestCompletionInstruction {
  title: string
  cheatsheet_url: string
  estimated_arrival_date: string | null
  lab_company_key: string
}

export interface PatientInstructionTestCompletionInstructions {
  type: PatientInstructionTypes.LAB_TEST_COMPLETION_INSTRUCTIONS
  title: string
  step: number
  lab_test_completion_instructions: LabTestCompletionInstruction[]
}

export interface ReqFormForCompany {
  title: string
  description: string | null
  req_form: string | null
}

export interface PatientInstructionPrintReqForms {
  type: PatientInstructionTypes.PRINT_REQ_FORMS
  title: string
  step: number
  req_form_for_companies: ReqFormForCompany[]
}

export interface PatientInstructionStateWaiver {
  type: PatientInstructionTypes.WAIVER_FOR_STATE_COLLECTION
  title: string
  step: number
  kit_names: string[]
}

export interface SampleFormForCompany {
  title: string
  instructions: string | null
  sample_requisition_link: string | null
}

export interface PatientInstructionFillOutReqForm {
  type: PatientInstructionTypes.FILL_OUT_REQ_FORMS
  title: string
  step: number
  sample_form_for_companies: SampleFormForCompany[]
}

export type PhlebotomistLocationType = "mobile" | "clinic"

export interface ClinicRecommendedPhlebotomist {
  name: string
  url: string
  email: string
  external_notes: string
  location_types: PhlebotomistLocationType[]
  address: string
  phone: string
  hours: string
  fee: string
  distance: string
}

export interface LabCompanyRecommendedPhlebotomist {
  title: string
  phlebotomy_cost_details: string | null
  online_directory_title?: string
  online_directory_url?: string
  online_directory_name?: string
  customer_service_description?: string
  customer_service_email?: string
  customer_service_phone?: string
}

export interface LabCompanySchedulingData {
  requisition_form_url?: string
  scheduling_url: string
  uses_insurance?: boolean
  should_show_schedule_link: boolean
  selected_order_fulfillment_company?: ValueOf<typeof LAB_COMPANY_KEY>
}

export interface AxleSchedulingData {
  phlebotomy_booking_link: string
  axle_reschedule_link: string
  address: string
  visit_start: string
  visit_end: string
  visit_status: string
}

export interface PatientInstructionScheduleBloodDraw {
  type: PatientInstructionTypes.SCHEDULE_BLOOD_DRAW
  title: string
  step: number
  intro_title?: string
  intro_copy: string
  end_copy?: string
  footer_copy?: string
  requires_fasting?: boolean
  fasting_duration?: number | null
  clinic_recommended_phlebotomists_title?: string
  clinic_recommended_phlebotomists: ClinicRecommendedPhlebotomist[]
  rupa_recommended_phlebotomists_title?: string
  rupa_recommended_phlebotomists: ClinicRecommendedPhlebotomist[]
  post_custom_recommendations_copy?: string
  lab_company_recommended_phlebotomists: LabCompanyRecommendedPhlebotomist[]
  labcorp_scheduling_data?: LabCompanySchedulingData
  quest_scheduling_data?: LabCompanySchedulingData
  empire_scheduling_data?: LabCompanySchedulingData
  axle_scheduling_data?: AxleSchedulingData
}

export type PatientInstruction =
  | PatientInstructionTestCompletionInstructions
  | PatientInstructionPrintReqForms
  | PatientInstructionFillOutReqForm
  | PatientInstructionScheduleBloodDraw
  | PatientInstructionStateWaiver

export const PATIENT_CHECKOUT_REQUIREMENTS = [
  "patient_info",
  "payment",
  "identity_verification",
] as const

export type PatientCheckoutRequirement =
  typeof PATIENT_CHECKOUT_REQUIREMENTS[number]

export const PHLEBOTOMY_PROVIDERS = {
  AXLE: "axle",
  EMPIRE: "empire",
  LABCORP: "labcorp",
  QUEST: "quest",
  PATIENT_CHOSEN: "patient_chosen",
} as const

export const SELECTABLE_PHLEBOTOMY_PROVIDERS: string[] = [
  PHLEBOTOMY_PROVIDERS.EMPIRE,
  PHLEBOTOMY_PROVIDERS.AXLE,
  PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN,
]

export type PhlebotomyProviderName =
  typeof PHLEBOTOMY_PROVIDERS[keyof typeof PHLEBOTOMY_PROVIDERS]

export interface PhlebotomyProvider {
  provider_name: PhlebotomyProviderName
}

export const PHLEBOTOMY_PROVIDERS_FRIENDLY_NAMES = {
  [PHLEBOTOMY_PROVIDERS.AXLE]: "Axle Mobile Phlebotomy",
  [PHLEBOTOMY_PROVIDERS.EMPIRE]: "Empire City Labs",
  [PHLEBOTOMY_PROVIDERS.LABCORP]: "Labcorp",
  [PHLEBOTOMY_PROVIDERS.QUEST]: "Quest",
  [PHLEBOTOMY_PROVIDERS.PATIENT_CHOSEN]: "Patient Chosen Phlebotomist",
} as const

export interface PatientPortalOrder {
  id: string
  patient: PatientPortalPatient
  practitioner: PatientPortalPractitioner
  msrp_total: string
  physician_authorization_total: string
  processing_price: string
  subtotal: string
  rupa_discount: number
  total_price: string
  credit_card_fee_price: number
  is_practitioner_paying: boolean
  line_items: PatientPortalOrderLineItem[]
  use_insurance: boolean
  patient_has_medicare: boolean
  insurance_enabled_for: string[]
  notes_to_patient: string
  ordered_tests: PatientPortalOrderedTest[]
  is_demo: boolean
  date_unpause_scheduled: string
  patient_instructions: PatientInstruction[]
  requirements: PatientCheckoutRequirement[]
  faqs: { question: string; answer: string }[]
  flags: { CHECK_SCARLET_ELIGIBILITY: boolean; HYBRID_SCARLET_PHLEB: boolean }
  // Integration-specific fields
  scarlet_zipcode: string | null
  requires_vendor_physician_authorization: boolean
  // Used to selectively render the email (as storefront patients have a fake email before they
  // provide their real email)
  storefront_id: number | null
  billing_state: string | null
  selected_payment_method: SelectedPaymentMethodType | null
  is_medicare_verified: boolean
  sample_collection_state: string | null
  is_labshops_order: boolean
  shipping_state: string | null
  patient_portal_token: string | null
  phlebotomy_providers: PhlebotomyProvider[]
  phlebotomy_booking_url: string | null
  lab_company_names_blood_draw_not_included: string[]
  coupon_discount: number
  guardian_first_name: string
  guardian_last_name: string
  labshop_name: string | null
}

export interface LabCompanyAccount {
  account_id: string
  status: keyof typeof LAB_COMPANY_ACCOUNT_STATUS
  lab_companies: LabCompany[]
}

export interface Clinic {
  id: string
  name: string
  multiple_ordering_practitioners: boolean
  phone_number: string
  location: Location
  state: string
  allow_handfilled_requisition_forms: boolean
  custom_fees_enabled: boolean
  street: string
  city: string
  zipcode: string
  country: string
  logo: string
  onboarding_point_of_contact: string
  clinic_features: string[]
  clinic_settings: string[]
  province_region: string
  is_international_clinic: boolean
  in_office_kits_disabled_reason: string
  custom_fees_disabled_reason: string
  has_active_prac_license_labshop: boolean
  clinic_has_practitioner_with_uploaded_license: boolean
  specimen_issue_email_recipient: SpecimenIssueEmailRecipientId | null
}

export enum ClinicFeatures {
  LOGO_EMAIL = "logo_email",
  LOGO_FOOD_PLANS = "logo_food_plans",
  LOGO_RESULTS_SUMMARY = "logo_results_summary",
  NO_CLINIC_BRANDING = "no_clinic_branding",
  USE_PHYSICIAN_SERVICES_PROMOTIONAL_PRICING = "use_physician_services_promotional_pricing",
  ALLOW_IN_OFFICE_KITS = "allow_in_office_kits",
  DEFAULT_PRACTITIONER_PAY = "default_practitioner_pay",
  RUPA_BLOOD_REPORTS = "rupa_blood_reports",
}

export interface Patient {
  id: string
  practitioner: string
  user: FullUser
  has_order_info: boolean
  given_consent: boolean
  insurance: PatientInsurance | null
  first_name: string
  last_name: string
  full_name: string
  phone_number: string | null
  birthday: string | null
  biological_sex: string
  default_shipping_address: {
    street?: string
    street_1: string
    street_2?: string
    city: string
    state: string
    zipcode: string
  } | null
  patient_settings: string[]
  guardian_first_name: string | null
  guardian_last_name: string | null
  can_modify_name?: boolean
}

export interface PatientListPatient {
  id: string
  first_name: string
  last_name: string
  full_name: string
  has_order_info: boolean
  given_consent: boolean
  user: UserListUser & { is_verified: boolean }
}

export interface UserListUser {
  id: number
  first_name: string
  last_name: string
  full_name: string
  uuid: string
  email: string
}

export interface OrderedResult {
  id: number | string
  result_file: string
  date_result_reviewed: string
  updated_at: string
  created_at: string
  ordered_tests: string[]
  supports_blood_report: boolean
  latest_blood_lab_dashboard_snapshot_report_id: string | null
}

/**
 * Note: This type may be used in list views where less is fetched than the full type.
 * TODO: Break out separate type for list views, or wait for normalized refactor.
 */
export interface OrderedTest {
  id: string
  instant_requisition: boolean
  lab_test: LabTest
  order: Order
  date_ordered_by_practitioner: string
  date_ordered_by_rupa: string
  date_kit_shipped_from_lab: string
  date_kit_expected_delivery: string
  date_kit_delivered_to_patient: string
  date_sample_collection_scheduled: string
  date_sample_received_at_lab: string
  date_results_expected_from_lab: string
  date_results_received_from_lab: string
  date_results_sent_to_patient: string
  estimated_arrival_date: string
  estimated_results_date: string
  accession_id: string
  results: string | null
  requisition: string | null
  status: OrderedTestStatusUnion
  updated_at: string
  created_at: string
  is_ordered_test_result_unread?: boolean
  latest_ordered_result: OrderedResult | null
  ordering_rights_status?: OrderingRightsStatus
  combo_group?: string
  results_delayed_reason?: string

  combo_group_options?: ComboGroupOption[]
  requires_fasting?: boolean

  specimen_issue?: SpecimenIssue
  active_alerts?: LabCompanyAlert[]
  shipment_tracking_link?: string | null
  acknowledge_unread_result?: boolean | null
  sample_collection_coordinator?: SampleCollectionCoordinator

  embeddable_user_result_id?: string
}

export interface Coupon {
  id: number
  practitioner_title: string
  practitioner_subtitle: string
  patient_title: string
  patient_subtitle: string
  type: keyof typeof COUPON_TYPE
  discount: string
  lab_test: LabTest
  disable_notes_to_rupa: number
  end_date: string
  percent_discount: string
  code: string
  is_practitioner_self_order_only: boolean
  is_first_order_only: boolean
  lab_company: LabCompany
  allow_physician_services: boolean
}

export interface WebhookEvent {
  id: string
  created_at: string
  api_version: string
  event_type: string
  status: string
}
export interface OrderEvent {
  id: string
  key: string
  text: string
  display_date: string
  order: Order
  metadata: { [id: string]: string }
  actor: string
  children: OrderEvent[]
  webhook_event: WebhookEvent
  lab_test_names: string
  oauth_application_name: string
}

export interface ResendOrderEventBody {
  patient_address: {
    street_1: string
    street_2?: string
    city: string
    state: string
    zipcode: string
  } | null
  reason_for_resend: string
  other_reason_message?: string
}

export interface OrderLineItemBase {
  id: string
  title: string
  type: ValueOf<typeof ORDER_LINE_ITEM_TYPE>
  cost: string
  discounted_cost: string
  ordered_test: OrderedTest | null
  coupon: Coupon | null
  date_disabled: string | null
}

// Whenever a more specific LineItem type is required, add it to the union type
// `PatientPortalOrderLineItem` and omit it from this `Other` type.
export interface OrderLineItemOther extends OrderLineItemBase {
  type: Exclude<ValueOf<typeof ORDER_LINE_ITEM_TYPE>, "Lab Test" | "Coupon">
}

export interface OrderLineItemLabTest extends OrderLineItemBase {
  type: "Lab Test"
  ordered_test: OrderedTest
}

export interface OrderLineItemCoupon extends OrderLineItemBase {
  type: "Coupon"
  coupon: Coupon
}

export type OrderLineItem =
  | OrderLineItemOther
  | OrderLineItemLabTest
  | OrderLineItemCoupon

export interface BankAccount {
  id: number
  created_at: string
  user: number
  stripe_id: string
  bank_name: string
  last_4_digits: string
  is_shared_with_clinic: boolean
}

export interface PaymentCard {
  id: number
  created_at: string
  user: number
  stripe_id: string
  last_4_digits: string
  brand: string
  exp_month: number
  exp_year: number
  is_shared_with_clinic: boolean
}

export interface ICD10Code {
  id: number
  code: string
  short_description: string
  long_description: string
}

export interface OrderIntent {
  id: string
  return_url: string | null
  oauth_app_name: string | null
  oauth_app_id: string | null
}

export interface OrderLabTestBundle {
  id: number
  name: string
  lab_tests: string[]
}

export interface Order {
  id: string
  created_at: string
  updated_at: string
  created_by: UserListUser
  patient: Patient
  practitioner: Practitioner | null
  signing_practitioner: Practitioner | null
  clinic: Clinic
  status: OrderStatusUnion
  msrp_total: string
  physician_authorization_total: string
  processing_price: string
  subtotal: string
  rupa_discount: string
  total_price: string
  date_paid: string | null
  date_order_placed: string
  date_patient_checkout: string
  date_paused: string
  date_unpaused: string
  date_completed: string
  date_started: string
  date_sent_to_patient: string
  date_results_reviewed: string
  date_cancelled: string
  date_scheduled: string
  line_items: OrderLineItem[]
  bank_account: BankAccount | null
  payment_card: PaymentCard | null
  payer: OrderPayerUnion
  use_insurance: boolean
  patient_has_medicare: boolean
  notes_to_rupa: string
  notes_to_patient: string
  notification_method: string
  ordered_tests: OrderedTest[]
  is_demo: boolean
  icd10_codes_discrete: ICD10Code[]
  coupon: OrderedTest[]
  is_practitioner_paying: boolean
  order_payer: string
  requires_physician_authorization: boolean
  requires_vendor_physician_authorization: boolean
  flags: {
    CHECK_SCARLET_ELIGIBILITY: boolean
    HYBRID_SCARLET_PHLEB: boolean
  }
  lab_test_bundles: OrderLabTestBundle[]
  shipping_state: string
  insurance_enabled_for: string[]
  confirmation_image?: OrderConfirmationImage
  order_intent?: OrderIntent

  // { [orderedTestId: string]: LabTest }
  associated_uninsured_tests_by_ordered_test_id: Record<string, LabTest>

  // Utility props created client-side
  ordered_tests_by_lab_test_id: { [id: string]: OrderedTest }
  lab_tests_by_id: { [labTestId: string]: LabTest }
  total_completed_ordered_tests: number
  total_ordered_tests: number
  custom_fee_line_items: CustomFeeLineItem[]
}

export interface PatientOrdersClinic {
  id: number
  name: string
  phone_number: string
  street: string
  city: string
  state: string
  zipcode: string
  allow_handfilled_requisition_forms: boolean
  custom_fees_enabled: boolean
  clinic_features: string[]
  clinic_settings: string[]
}

export interface PatientOrdersPractitioner {
  id: string
  user: UserListUser
  lab_company_accounts: LabCompanyAccount[]
  npi_number: string
  clinic: PatientOrdersClinic
  titled_full_name: string
}

export interface SimpleStorefront {
  id: string
  name: string
}

export interface StorefrontOrderApproval {
  id: string
  status: string
  status_updated_at: string
}

export interface PatientOrdersOrder {
  id: string
  created_at: string
  updated_at: string
  created_by: UserListUser
  patient: Patient
  practitioner: PatientOrdersPractitioner
  signing_practitioner: PatientOrdersPractitioner
  clinic: Clinic
  status: OrderStatusUnion
  msrp_total: string
  physician_authorization_total: string
  processing_price: string
  subtotal: string
  rupa_discount: string
  total_price: string
  date_paid: string
  date_order_placed: string
  date_patient_checkout: string
  date_paused: string
  date_unpaused: string
  date_completed: string
  date_started: string
  date_sent_to_patient: string
  date_results_reviewed: string
  date_cancelled: string
  date_scheduled: string
  line_items: number[]
  bank_account: number
  payment_card: number
  payer: OrderPayerUnion
  use_insurance: boolean
  patient_has_medicare: boolean
  notes_to_rupa: string
  notes_to_patient: string
  ordered_tests: OrderedTest[]
  is_demo: boolean
  icd10_codes_discrete: number[]
  coupon: number[]
  is_practitioner_paying: boolean
  requires_physician_authorization: boolean
  requires_vendor_physician_authorization: boolean
  total_ordered_tests: number
  total_completed_ordered_tests: number
  custom_fee_line_items: CustomFeeLineItem[]
  physician_authorization_status: PhysicianAuthorizationApprovalStatus
  storefront: SimpleStorefront
  has_ordered_results: boolean
  storefront_order_approval?: StorefrontOrderApproval
  confirmation_image?: OrderConfirmationImage
  specimen_issue_status: SpecimenIssueStatusUnion | null
  phlebotomy_providers: string[]
}

export interface OrderConfirmationImage {
  image: string
}

export interface Contact {
  id: number
  display_name: string
  image: string
  is_stafff: boolean
  is_provider: boolean
}

export enum Role {
  GUEST = "guest",
  PROVIDER = "provider",
  PATIENT = "patient",
  STAFF = "staff",
}

export type AppThunkAction<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>

export interface SplitItInitiateResponse {
  installment_plan?: any // TODO: type
  learn_more_url?: string
  privacy_policy_url?: string
  public_token?: string
  terms_and_conditions_url?: string
}

export interface SplitItState {
  data: SplitItInitiateResponse | null
  error: any
  isInitiating: boolean
}

export type PatientOrders = LimitOffsetPaginatedResponse<PatientOrdersOrder>

// TODO: Over time fill this out as needed
export interface RootState {
  auth: {
    user: AuthUser
    login?: {
      success: boolean | null
      error: {
        password: string | null
      }
    }
  }
  fuse: {
    message: {
      state: boolean | undefined
      options: SnackbarProps & {
        variant: FuseMessageVariant
      }
    }
  }
  profile: Profile
  practitioner: Practitioner
  practitionerBundles: {
    error?: Error
    results: PractitionerLabTestBundle[]
    pending: boolean
  }
  emrIntegrations: {
    error?: Error
    results: EMRIntegration[]
    pending: boolean
  }
  // Async reducers
  orders: {
    orders: {
      patient: Patient
      order: Order
      practitioners: Practitioner[]
      marked_for_next_order?: {
        lab_test: AnyLabTest
        // additional params to include in the POST /orderedtest request body
        additionalData?: { instant_requisition?: boolean }
      }
    }
    labTests: any
    tour: any
    bankAccounts?: {
      bankAccounts: BankAccount[]
      pending: boolean
      addAccountPending: boolean
    }
    customFees?: {
      stripeConnectAccounts: StripeConnectAccount[]
      customFees: CustomFee[]
    }
    paymentCards?: {
      paymentCards: PaymentCard[]
      pending: boolean
      useCardChecked: boolean
    }
  }
  dashboard?: {
    onboarding: OnboardingState
    dashboard: DashboardState
  }
  onboarding?: {
    onboarding: OnboardingState
    dashboard: DashboardState
  }
  ecommerce: {
    ordersPending: boolean
    orders: {
      count: number
      results: [PatientOrdersOrder]
      total_orders_in_clinic: number
    }
    unreadResultNotifications: {
      count: number
    }
  }
  patient: {
    patient: Patient
    orders: PatientOrders | null
    scheduledOrders: PatientOrders | null
  }
  patientCheckout: {
    orders: {
      errorCode?: string
      status: OrderRequestStatus
      order?: PatientPortalOrder
    }
    splitit: SplitItState
  }
  patientPortal: {
    order: PatientPortalOrder | null
  }
  phlebotomist: PatientPortalPhlebotomist[]
  phlebotomistMap: {
    featureById: {
      [phlebotomistId: string]: PhlebotomistFeature
    }
    selectedFeatureId?: string
    featureIds: string[]
  }
  healthCategory: {
    sortedByName: HealthCategory[] | null
    sortedByPositionInCatalog: HealthCategory[] | null
  }
  labCompany: {
    list: LabCompany[] | null
  }
  labTestType: {
    list: LabTestType[] | null
  }
  overrideLabTest: {
    labCompany: LabCompany | null
    unavailableState: string | undefined
  }
  customFees: {
    stripeConnectAccounts: StripeConnectAccount[]
    customFees: CustomFee[]
  }
  teamMember: PractitionerListItem[]
  storefront: { storefront: Storefront | null }
  shareBundle: LabTestShareBundle | null
}

export const CHECKOUT_DRAFT_FIELDS = [
  "icd10_codes_discrete",
  "practitioner",
  "notification_method",
  "notes_to_patient",
  "notes_to_rupa",
  "payment_card",
  "bank_account",
  "date_scheduled",
  "signing_practitioner",
  "requires_vendor_physician_authorization",
  "shipping_state",
  "order_payer",
] as const

/**
 * The draft state for the practitioner checkout page.
 *
 * This is used to manage the values of the fields defined in {@link CHECKOUT_DRAFT_FIELDS}.
 * As changes are made by the user, they are included in this draft state.
 * A debounced effect is set up to push these batch of changes to the API.
 */
export type CheckoutDraftState = {
  /**
   * Whether or not the state has changes yet to be pushed.
   */
  dirty: boolean
  /**
   * An error that occurred while attempting to push the changes.
   * Handled by reverting the state to the last known remote values.
   */
  error?: Error
  /**
   * Whether or not the state changes have been pushed and are pending request completion.
   */
  pending: boolean
  /**
   * The current set of draft changes, which is a subset of {@link Order} defined by the fields in {@link CHECKOUT_DRAFT_FIELDS}.
   */
  values: Partial<Pick<Order, typeof CHECKOUT_DRAFT_FIELDS[number]>>
}

export enum OrderPayer {
  PATIENT = "patient",
  PRACTITIONER = "practitioner",
  API_CLIENT = "api_client",
}

export enum labTestLocation {
  BUNDLES = "bundles",
  BUNDLES_SETTINGS = "bundles-settings",
  CATALOG = "catalog",
  CHECKOUT = "checkout",
  COMPARISON = "comparison",
  ECOMMERCE = "ecommerce",
  ECOMMERCE_BUNDLES = "ecommerce-bundles",
}

export enum PhysicianAuthorizationType {
  ALWAYS_REQUIRED = "always_required",
  OFF = "off",
}

export enum AlertTypes {
  SHIPPING_DELAY = "shipping_delay",
  RESULTS_DELAY = "results_delay",
  NOTICE = "notice",
}

export interface LabCompanyAlert {
  type: AlertTypes.SHIPPING_DELAY | AlertTypes.RESULTS_DELAY
  title: string
  subtitle: string
  date_alert_began: string
  date_alert_ended: string
  days_delayed: number | null
  patient_facing_issue_description: string
}

export interface NoticeAlert {
  title: string
  subtitle: string
  link_url?: string
  link_text?: string
}

export enum PaymentMethodType {
  CARD = "CARD",
  SPLITIT = "SPLITIT",
  ACH = "ACH",
}

export enum SelectedPaymentMethodType {
  CREDIT_CARD = "credit_card",
  SPLITIT = "splitit",
  ACH = "ach",
}

export interface SplitItPaymentMethodDetails {
  billing_address: {
    street_1: string
    street_2?: string
    city: string
    state: string
    zipcode: string
  }
  last4: string
}

export interface CardPaymentMethodDetails {
  last4: string
  payment_method_id: string
}

export interface AchPaymentMethodDetails {
  account_id: string
  bank_token: string
  account_name: string
  bank_name: string
  last4: string
}

export interface PaymentMethodDetails {
  type: PaymentMethodType
  splitit?: SplitItPaymentMethodDetails
  card?: CardPaymentMethodDetails
  ach?: AchPaymentMethodDetails
}

export interface PatientPaymentMethodFormData {
  type: PaymentMethodType
  splitit?: {
    billing_address: {
      street_1: string
      street_2?: string
      city: string
      state: string
      zipcode: string
    }
    is_billing_same_as_shipping: boolean
    is_terms_accepted: boolean
  }
}

export interface PatientCheckoutFormData {
  birthday: string
  consent: boolean
  brl_consent?: boolean
  symptom_attestation?: boolean
  email: string
  first_name: string
  biological_sex: string
  insurance?: Partial<PatientInsurance>
  scarlet?: ScarletData
  last_name: string
  payment_method?: PatientPaymentMethodFormData
  phone_number: string
  default_shipping_address: {
    street_1: string
    street_2?: string
    city: string
    state: string
    zipcode: string
  }
  sample_collection_state: string
  order_insurance?: {
    provider: string
    subscribers_relationship: string
    subscribers_id_number: string
    subscribers_first_name: string
    subscribers_last_name: string
    group_number: string
  }
  guardian_first_name: string
  guardian_last_name: string
}

export interface PatientSignupFormData {
  first_name: string
  last_name: string
  email: string
  given_patient_marketing_consent: boolean
  terms_of_service: boolean
  verification_code: string
  patient_portal_token: string
  password: string
  confirm_password: string
}

export enum PatientSignupErrors {
  INVALID_PASSWORD = "INVALID_PASSWORD",
  INVALID_EMAIL = "INVALID_EMAIL",
  EMAIL_IN_USE_BY_PATIENT = "EMAIL_IN_USE_BY_PATIENT",
  EMAIL_IN_USE_BY_PRACTITIONER = "EMAIL_IN_USE_BY_PRACTITIONER",
  EMAIL_IN_USE = "EMAIL_IN_USE",
  MISSING_FIELD = "MISSING_FIELD",
  INVALID_VERIFICATION_CODE = "INVALID_VERIFICATION_CODE",
}

export interface PatientSignupError {
  code: string
  errors: string[]
}

export interface CardPaymentMethodPayload {
  payment_method_id: string
}

export interface AchPaymentMethodPayload {
  account_id: string
  bank_token: string
  account_name: string
  last4: string
  bank_name: string
}

export interface PatientPaymentMethodPayload {
  type: PaymentMethodType
  card?: CardPaymentMethodPayload
  ach?: AchPaymentMethodPayload
}

export interface PatientCheckoutPayload {
  birthday: string
  consent: boolean
  email: string
  first_name: string
  biological_sex: string
  insurance?: Partial<PatientInsurance>
  last_name: string
  payment_method?: PatientPaymentMethodPayload
  /**
   * @deprecated in favor of `payment_method`
   */
  payment_method_id?: string
  phone_number: string
  default_shipping_address: {
    street_1: string
    street_2?: string
    city: string
    state: string
    zipcode: string
  }
}

export enum AchConnectionStatus {
  INCOMPLETE = "INCOMPLETE",
  NOT_STARTED = "NOT_STARTED",
  COMPLETE = "COMPLETE",
}

export interface PhlebotomistFeatureProps {
  id: string
  phlebotomist_id: string
  name: string
  location_types: string
  email?: string
  url?: string
  phone?: string
  hours?: string
  approximate_fee?: number
  address_street?: string
  address_city?: string
  address_state?: string
  address_zip?: string
  distance?: number
}

export type PhlebotomistFeature = Feature<Point, PhlebotomistFeatureProps>

export type PhlebotomistFeatureCollection = FeatureCollection<
  Point,
  PhlebotomistFeatureProps
>

export enum OrderTestWarningTypes {
  SHIPPING_DELAY = "SHIPPING_DELAY",
  AGE_RESTRICTION = "AGE_RESTRICTION",
}

export enum OrderEventKey {
  ORDER_CREATED = "order_created",
  ORDER_CREATED_ON_OTHERS_BEHALF = "order_created_on_others_behalf",
  ORDER_CHEATSHEET_SENT = "send_order_cheatsheet",
  ORDER_PAID = "order_paid",
  ORDER_PAID_PRACTITIONER = "order_paid_by_practitioner",
  ORDER_COMPLETED = "order_completed",
  ORDER_PAUSED_BY_PATIENT = "order_paused_by_patient",
  ORDER_CANCELLED_BY_PATIENT = "order_cancelled_by_patient",
  RESULTS_SENT_TO_PATIENT = "results_sent_to_patient",
  ORDER_REQUISITION_FORM_AVAILABLE = "order_requisition_form_available",
  LAB_TEST_CHECK_IN_FORM_FILLED = "lab_test_check_in_form_filled",
  SPECIMEN_ISSUE = "specimen_issue",
  WELCOME_EMAIL_AND_INVOICE = "send_welcome_email_and_invoice",
  WELCOME_EMAIL_AND_CONSENT_FORM_EMAIL_SENT = "send_welcome_email_and_consent_form",
  SEND_PATIENT_PAYMENT_RECEIPT = "send_patient_receipt",
  KIT_SENT_TO_PATIENT = "kit_sent_to_patient",
}

export interface PostCheckoutIdentityVerificationSession {
  /**
   * This field is used with the Stripe SDK to begin the Verification Session Modal
   * flow with a call to `stripe.verifyIdentity(client_secret)`.
   */
  client_secret: string
  /**
   * This field is used for targeting the verification session when sending an update to
   * mark the session as processing once submitted.
   */
  verification_id: string
  /**
   * This field is used to ensure the proactive update of the session status in the
   * Rupa system does not override the status received by a webhook.
   */
  version: number
}

export type AnyOrderLineItem = OrderLineItem | PatientPortalOrderLineItem
export type AnyOrderedTests = OrderedTest | PatientPortalOrderedTest

// NOTE: Unions of objects with arrays don't work as you'd expect in TS. Any methods on those arrays
// like `.filter()` will throw a typeerror due to incompatible signatures. So to get around that
// we need to define a compound type that explictly defines the array with its element type.
//
// See: https://github.com/microsoft/TypeScript/issues/33591
export type AnyOrder = (Order | PatientPortalOrder) & {
  line_items: AnyOrderLineItem[]
  ordered_tests: AnyOrderedTests[]
}

export interface Biomarker {
  id: number
  short_name: string
  long_name: string
}

export interface BiomarkerGrouping {
  id: string
  name: string
  biomarkers: Biomarker[]
}

export interface HealthCategory {
  id: string
  name: string
  image: string
  position_in_catalog: number
}

/**
 * Represents a search suggestion received as part of a response from an auto-suggest API.
 */
export interface SearchSuggestion {
  type: string
  object_id: string
  label: string
  search_text: string
  score?: number
}

/**
 * Represents a response of {@link SearchSuggestion} from an auto-suggest API.
 */
export interface SearchSuggestionsResponse {
  results: SearchSuggestion[]
}

export interface EMRIntegration {
  emr_integration: string
  active: boolean
}

export enum PractitionerOptionType {
  DEFAULT = "default",
  VENDOR_PHYS_AUTH = "vendorPhysicianAuthorization",
}

export interface AnyPractitionerOption<T> {
  type: T
}

export interface DefaultPractitionerOption
  extends AnyPractitionerOption<PractitionerOptionType.DEFAULT> {
  practitioner: Practitioner
  blocked: boolean
}

export enum CheckoutWarningTypes {
  MULTIPLE_BLOOD_LABS = "MULTIPLE_BLOOD_LABS",
  ORDER_WITH_LTA_QUEST_TEST = "ORDER_WITH_LTA_QUEST_TEST",
  MIXED_AML_ORDER = "MIXED_AML_ORDER",
  MIXED_AML_ORDER_LABSHOP = "MIXED_AML_ORDER_LABSHOP",
  MIXED_EMPIRE_AND_LABCORP_ORDER = "MIXED_EMPIRE_AND_LABCORP_ORDER",
  MIXED_EMPIRE_AND_QUEST_ORDER = "MIXED_EMPIRE_AND_QUEST_ORDER",
  EMPIRE_DRAW_WARNING = "EMPIRE_DRAW_WARNING",
  DUPLICATE_BIOMARKERS = "DUPLICATE_BIOMARKERS",
  HEALTH_GORILLA_LABCORP_AND_MEDICARE = "HEALTH_GORILLA_LABCORP_AND_MEDICARE",
  FIRST_LABCORP_ORDER = "FIRST_LABCORP_ORDER",
  LABCORP_PHYSICIAN_SERVICES = "LABCORP_PHYSICIAN_SERVICES",
  AML_MIXED_DROPSHIP_IOK = "AML_MIXED_DROPSHIP_IOK",
  MIXED_DHA_LABCORP_ORDER = "MIXED_DHA_LABCORP_ORDER",
}
export enum CheckoutWarningSeverities {
  ERROR = "ERROR",
  WARNING = "WARNING",
  HIGHLIGHT = "HIGHLIGHT",
  INFO = "INFO",
}

export type VendorPhysAuthPractitionerOption =
  AnyPractitionerOption<PractitionerOptionType.VENDOR_PHYS_AUTH>

export const VENDOR_PHYS_AUTH_PRACTITIONER_OPTION: VendorPhysAuthPractitionerOption =
  {
    type: PractitionerOptionType.VENDOR_PHYS_AUTH,
  }

export type OneOfAnyPractitionerOption =
  | DefaultPractitionerOption
  | VendorPhysAuthPractitionerOption

export enum OrderEventType {
  EVENT = "Event",
  KIT = "Kit",
  RESULTS = "Results",
  EMAIL = "Email",
  RECEIPT = "Receipt",
  DOWNLOAD = "Download",
  WEBHOOK = "Webhook",
}

export enum OrderEventActor {
  PRACTITIONER = "PRACTITIONER",
  SERVICE = "SERVICE",
  ADMIN = "ADMIN",
}

export interface InOfficeKit {
  lab_company: string
  lab_test: string
  lab_test_quantity: string
}

export type InOfficeKitsFormData = {
  address_street_primary: string
  address_street_secondary: string
  address_city: string
  address_state: string
  address_zipcode: string
  in_office_kits: InOfficeKit[]
  lab_company: string
  lab_test: string
  lab_test_quantity: string
}

export type StripeConnectAccount = {
  id: string
  max_price: number
  status: "Active" | "Onboarding" | "Disabled"
  stripe_requirements: Record<string, unknown>
}

export type CustomFee = {
  id: string
  name: string
  price: number
  application_fee_percentage: number
  type?: string
}

export type CustomFeeLineItem = {
  id: string
  name: string
  price: number
  application_fee_percentage: number
  status: string
  date_balance_paid_out: string
  date_balance_transferred: string
  date_balance_refunded: string
}

export enum CustomFeeLineItemStatus {
  // Funds not sent to practitioner yet
  PENDING = "Pending",
  // Funds sent to practitioner, but not released
  PAID = "Paid",
  // Funds sent to practitioner and have been released
  COMPLETED = "Completed",
  // Funds have been refunded
  REFUNDED = "Refunded",
}

export type UserFilterItem = {
  id: string
  full_name: string
}

export type OrderFilters = {
  patients: [UserFilterItem]
  practitioners: [UserFilterItem]
  order_statuses: [string]
  storefront_ids: string[]
}

export interface PractitionerListItem {
  id: string
  user: User
  clinic: Clinic
  is_clinic_staff: boolean
  titled_full_name: string
  primary_practitioner_type?: PractitionerType
  lab_company_accounts?: LabCompanyAccount[]
  vendor_physician_authorization_enabled: boolean
  registered_lab_companies: string[]
  npi_number: string | null
}

export enum PhysicianAuthorizationApprovalStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
}

export enum OnboardingStep {
  SURVEY = "SURVEY",
  COMPLETE = "COMPLETE",
}

export enum ReferralSource {
  CLINIC_INVITATION = "Clinic Invitation",
}

export type Storefront = {
  id: string
  name?: string
  draft_name: string
  description?: string
  draft_description: string
  draft_description_richtext: EditorState
  logo?: string
  draft_logo: string
  video_link?: string
  draft_video_link: string
  draft_send_notification_emails: boolean
  send_notification_emails: boolean
  draft_send_to_ehr: boolean
  send_to_ehr: boolean
  draft_custom_fee: CustomFee | null
  custom_fee?: CustomFee | null
  draft_lab_tests: AnyLabTest[]
  lab_tests?: AnyLabTest[]
  draft_bundles: LabTestBundle[]
  bundles?: LabTestBundle[]
  status: string
  date_draft_updated: string
  date_published: string | null
  date_paused: string | null
  date_disabled: string | null
  use_practitioner_license: boolean
}

export type OrderingRightsStatus = {
  allowed: boolean
  ignore_ordering_issues: OrderingIssue[]
  warning_ordering_issues: OrderingIssue[]
  error_ordering_issues: OrderingIssue[]
}

export type OrderingIssue = {
  key: OrderingIssueKey
  type: OrderingIssueType
  source: string
  source_type: OrderingIssueSourceType
  level: OrderingIssueLevel
  message: string
}

export interface SupportedCountry {
  countryName: string
  countryShortCode: string
  regions: { name: string; shortCode: string }[]
}

export enum UserClinicAccessSharingStatus {
  NONE = "NONE",
  ALL = "ALL",
  SPECIFIC = "SPECIFIC",
  SPECIFIC_AND_FUTURE = "SPECIFIC_AND_FUTURE",
}
