import { useDispatch } from "react-redux"

import { RenameModal } from "app/components/modals/RenameModal"
import { BUNDLE_TYPES } from "app/constants"
import * as Actions from "app/store/actions"
import { PractitionerLabTestBundle } from "app/types"

export function RenameBundleModal({
  bundle,
  open,
  onClose,
}: {
  bundle: PractitionerLabTestBundle
  open: boolean
  onClose: () => void
}) {
  const dispatch = useDispatch()

  function handleSubmit(inputText) {
    dispatch(
      Actions.updateBundle(bundle.id, inputText, bundle.lab_tests, onClose)
    )
  }

  const bundleType =
    bundle.bundle_type === BUNDLE_TYPES.PANEL ? "Panel" : "Bundle"

  return (
    <RenameModal
      initialText={bundle.name}
      title={`Rename ${bundleType}`}
      label={`${bundleType} Name`}
      buttonText={`Save ${bundleType}`}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  )
}
