import { memo } from "react"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"

import {
  FilledInput,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  OutlinedInput,
  Select,
  SelectProps,
} from "@material-ui/core"

import { ariaLabel } from "app/components/forms/helpers"
import { InjectedProps, WrapperProps } from "formsy-react/dist/withFormsy"
import pick from "lodash/pick"

// Declare the fields we pass through to Select so we can pick them out.
// Add more as needed.
const SELECT_PROP_KEYS = [
  "children",
  "input",
  "variant",
  "native",
  "inputProps",
  "onChange",
  "required",
  "disabled",
] as const

export type SelectFormsyProps = Omit<WrapperProps<string>, "required"> &
  Pick<SelectProps, typeof SELECT_PROP_KEYS[number]> & {
    className?: string
    label?: React.ReactNode
  }

function SelectFormsy({
  name,
  label,
  value = "",
  className,
  setValue,
  errorMessage,
  ...props
}: SelectFormsyProps & InjectedProps<unknown>) {
  const selectProps = pick(props, SELECT_PROP_KEYS)
  const stringLabel = ariaLabel({ label, ...props })

  function input() {
    switch (selectProps.variant) {
      case "outlined":
        return <OutlinedInput labelWidth={stringLabel.length * 8} id={name} />
      case "filled":
        return <FilledInput id={name} />
      default:
        return <Input id={name} />
    }
  }

  function changeValue(
    event: React.ChangeEvent<{
      name?: string
      value: unknown
    }>,
    child: React.ReactNode
  ) {
    setValue(event.target.value)
    selectProps?.onChange?.(event, child)
  }

  return (
    <FormControl
      error={Boolean(errorMessage)}
      className={className}
      variant={selectProps?.variant}
    >
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <Select
        {...selectProps}
        name={name}
        value={value ?? ""}
        onChange={changeValue}
        // FUSE-MODIFICATION: fixed to allow custom input
        input={selectProps?.input || input()}
        inputProps={{ "aria-label": stringLabel, ...selectProps?.inputProps }}
      />
      {Boolean(errorMessage) && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default memo(withFormsy(SelectFormsy))
