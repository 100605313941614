import { useMemo, useState } from "react"

import { LAB_COMPANY_KEY } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import { colors } from "app/theme"
import interleave from "app/utils/interleave"
import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerIdentifier } from "types/biomarker"
import { BiomarkerGrouping } from "types/biomarker_grouping"

import { TYPE_KEYS } from "../constants"
import usePanels from "../hooks/use-panels"
import { PanelBuilderIdentifier } from "../types"
import PanelItem from "./PanelItem"
import PanelsEmptyState from "./PanelsEmptyState"
import PanelsNotSearchingState from "./PanelsNotSearchingState"

const useStyles = makeAppStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    borderRadius: 8,
    background: "white",
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
  },
  separator: {
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    height: 0,
    padding: 0,
    margin: 0,
    width: "100%",
  },
}))

export default function Panels({
  existingBundleName,
  existingBundleId,
  existingPanelLabCompanyKey,
  selectedItems,
  isDirty,
  onClose,
}: {
  existingBundleName?: string
  existingBundleId?: number
  existingPanelLabCompanyKey?: string
  selectedItems: PanelBuilderIdentifier[]
  isDirty: boolean
  onClose: () => void
}) {
  const classes = useStyles()

  const [isVibrantDisabled] = useFeatureFlag(FeatureFlag.VibrantDisabled)
  const [isSearching, setIsSearching] = useState<boolean>(!!existingBundleId)

  const selectedBiomarkerIds = useMemo(() => {
    return selectedItems
      .filter((item) => item.type === TYPE_KEYS.BIOMARKER)
      .map((item) => item.id)
  }, [selectedItems])

  const selectedBiomarkerGroupingIds = useMemo(() => {
    return selectedItems
      .filter((item) => item.type === TYPE_KEYS.BIOMARKER_GROUPING)
      .map((item) => item.id)
  }, [selectedItems])

  const biomarkersGroupings = useCachedCollection<BiomarkerGrouping>(
    selectedItems.filter((item) => item.type === TYPE_KEYS.BIOMARKER_GROUPING)
  )

  // Pull biomarkers from the biomarker groupings
  const combinedSelectedBiomarkerIdentifiers = useMemo(() => {
    const biomarkers: BiomarkerIdentifier[] = []

    for (const item of selectedItems) {
      if (item.type === TYPE_KEYS.BIOMARKER) {
        biomarkers.push(item)
      }
    }

    for (const grouping of biomarkersGroupings) {
      for (const biomarker of grouping.relationships.biomarkers.data) {
        biomarkers.push(biomarker as BiomarkerIdentifier)
      }
    }

    // Remove duplicates
    return [...new Map(biomarkers.map((m) => [m.id, m])).values()]
  }, [selectedItems, biomarkersGroupings])

  const combinedSelectedBiomarkerIds = combinedSelectedBiomarkerIdentifiers.map(
    (item) => item.id
  )
  const hasSelectedBiomarkers = Boolean(combinedSelectedBiomarkerIds.length)

  const { data: panels, isLoading } = usePanels(combinedSelectedBiomarkerIds)

  if (!isSearching) {
    return (
      <div className={classes.container}>
        <PanelsNotSearchingState
          hasSelectedBiomarkers={hasSelectedBiomarkers}
          setIsSearching={setIsSearching}
        />
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className={classes.container}>
        {interleave(
          [1, 2, 3, 4].map((idx) => (
            <PanelItem
              key={`panel-loading-${idx}`}
              panel={undefined}
              combinedSelectedBiomarkerIdentifiers={
                combinedSelectedBiomarkerIdentifiers
              }
              selectedBiomarkerIds={selectedBiomarkerIds}
              selectedBiomarkerGroupingIds={selectedBiomarkerGroupingIds}
            />
          )),
          (_, key) => (
            <div key={`separator-${key}`} className={classes.separator} />
          )
        )}
      </div>
    )
  }

  if (!panels || !Boolean(panels?.data?.length)) {
    const text = hasSelectedBiomarkers
      ? "No panels available with selected biomarkers."
      : "Search for biomarkers to begin. Panels will populate here!"
    return (
      <div className={classes.container}>
        <PanelsEmptyState text={text} />
      </div>
    )
  }

  const filteredPanels = panels.data.filter((panel) => {
    return !(
      isVibrantDisabled &&
      [
        LAB_COMPANY_KEY.VIBRANT_AMERICA,
        LAB_COMPANY_KEY.VIBRANT_WELLNESS,
      ].includes(panel?.lab_company?.key) &&
      panel?.lab_company?.key !== existingPanelLabCompanyKey
    )
  })

  return (
    <div className={classes.container}>
      {interleave(
        filteredPanels.map((panel, idx) => (
          <PanelItem
            key={`panel-${idx}`}
            panel={panel}
            combinedSelectedBiomarkerIdentifiers={
              combinedSelectedBiomarkerIdentifiers
            }
            selectedBiomarkerIds={selectedBiomarkerIds}
            selectedBiomarkerGroupingIds={selectedBiomarkerGroupingIds}
            existingBundleId={existingBundleId}
            existingBundleName={existingBundleName}
            existingPanelLabCompanyKey={existingPanelLabCompanyKey}
            isDirty={isDirty}
            onClose={onClose}
          />
        )),
        (_, key) => (
          <div key={`panel-sep-${key}`} className={classes.separator} />
        )
      )}
    </div>
  )
}
