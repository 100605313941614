import classNames from "classnames"

import { ClickAwayListener, IconButton, Theme, Zoom } from "@material-ui/core"

import { ReactComponent as EllipsesIcon } from "app/assets/icons/bundles/ellipses.svg"
import { ReactComponent as SharedBundleIcon } from "app/assets/icons/bundles/synced.svg"
import Tooltip from "app/components/Tooltip"
import TooltipNav from "app/components/design-system/TooltipNav"
import { NavItem } from "app/components/design-system/TooltipNav/constants"
import { primaryColor, white } from "app/theme"
import { PractitionerLabTestBundle } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import BundleFavoriteIcon from "./BundleFavoriteIcon"

const useStyles = makeAppStyles((theme: Theme) => ({
  iconsContainer: {
    display: "inline-flex",
    flexFlow: "row nowrap",
    gap: 0,
    alignItems: "center",
    marginLeft: 5,
    position: "relative",
    top: 2,
    width: "85%",
    [theme.breakpoints.up("lg")]: {
      width: "85%",
    },
  },
  iconContainer: {
    marginRight: 3,
  },
}))

interface Props {
  tooltipMenuIsOpen: boolean
  closeMenu: () => void
  bundle: PractitionerLabTestBundle
  handleTooltipMenuClick: (e: any) => void
  navItems: NavItem[]
  handleFavoriteClick: (e: any) => void

  // use "white" to change the icon colors if the background is not white
  variant?: "white" | "default"
}

const BundleCardIcons = ({
  tooltipMenuIsOpen,
  closeMenu,
  bundle,
  handleTooltipMenuClick,
  navItems,
  handleFavoriteClick,
  variant = "default",
}: Props) => {
  const classes = useStyles()

  const navItemsWithBundle = navItems?.map((item) => {
    return { ...item, metadata: { bundle } }
  })
  const iconFill = variant === "white" ? white : primaryColor

  return (
    <div className={classes.iconsContainer}>
      {bundle?.is_shared_bundle && (
        <SharedBundleIcon
          fill={iconFill}
          viewBox="0 0 32 52"
          width={20}
          height={20}
          className={classes.iconContainer}
        />
      )}

      <div className={classes.iconContainer}>
        <BundleFavoriteIcon
          bundle={bundle}
          onFavoriteClick={handleFavoriteClick}
          variant={variant}
        />
      </div>

      <ClickAwayListener onClickAway={closeMenu}>
        <span>
          <Tooltip
            open={tooltipMenuIsOpen}
            onClose={closeMenu}
            title={<TooltipNav children={navItemsWithBundle} />}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            TransitionComponent={Zoom}
            arrow
            interactive
          >
            <IconButton
              className={classNames("p-0 rounded-4")}
              onClick={handleTooltipMenuClick}
              aria-label="Bundle info"
            >
              <EllipsesIcon
                fill={iconFill}
                viewBox="0 0 15 15"
                width={20}
                height={20}
              />
            </IconButton>
          </Tooltip>
        </span>
      </ClickAwayListener>
    </div>
  )
}

export default BundleCardIcons
