import { IconButton } from "@material-ui/core"
import FavoriteIcon from "@material-ui/icons/Favorite"

import { BUNDLE_TYPES } from "app/constants"
import { colors, favoritedColor, white } from "app/theme"
import { PractitionerLabTestBundle } from "app/types"

interface Props {
  bundle: PractitionerLabTestBundle
  onFavoriteClick: (e: any) => void
  // use "white" to change the icon color if the background is not white
  variant?: "white" | "default"
}

const BundleFavoriteIcon: React.FC<Props> = ({
  bundle,
  onFavoriteClick,
  variant,
}) => {
  let iconColor = colors.blueGray[200]

  if (bundle.is_favorited) {
    if (variant === "white" && bundle?.bundle_type === BUNDLE_TYPES.PANEL) {
      iconColor = white
    } else {
      iconColor = favoritedColor
    }
  }

  return (
    <IconButton
      className="p-0 rounded-[4px]"
      onClick={onFavoriteClick}
      aria-label="Favorite test"
    >
      <FavoriteIcon fontSize="small" style={{ color: iconColor }} />
    </IconButton>
  )
}

export default BundleFavoriteIcon
