import { ReactComponent as PersonIcon } from "app/assets/icons/document-access/person.svg"

import Card from "../../Card"

const PatientPortalLabResultsAccessDocumentEmptyCard = () => (
  <Card className="mt-2 flex flex-col items-center">
    <div className="mt-5 mx-auto">
      <PersonIcon className="mx-auto table" />
    </div>
    <div className="mt-2 mb-2 text-center max-w-lg">
      Once you've had your first lab order through Rupa, you'll be able to
      manage who has access to your data here.
    </div>
  </Card>
)

export default PatientPortalLabResultsAccessDocumentEmptyCard
