import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { CircularProgress, Grid, Theme, useMediaQuery } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import useAppSelector from "app/hooks/useAppSelector"
import PhysicianAuthorizationSettings from "app/main/settings/components/PhysicianAuthorizationSettings"
import { physicianAuthorizationApplicationPageEvents } from "app/services/segment"
import { trackEventWithProperties } from "app/services/segment.typed"
import { updatePersonalSettings } from "app/store/actions"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import { Practitioner } from "app/types"

import SideNav from "./SideNav"
import { PersonalInformation } from "./components/PersonalInformation"
import PersonalPreferences from "./components/PersonalPreferences"
import Separator from "./components/Separator"

// denotes fields on the practitioner that are toggleable
type PractitionerSettings = Pick<
  Practitioner,
  | "vendor_physician_authorization_enabled"
  | "allow_in_office_kits"
  | "patient_results_release_automatically"
  | "default_physician_authorization"
>
export type PractitionerSetting = keyof PractitionerSettings

interface Props {
  openPhysServicesModalOnLoad?: boolean
}

const PersonalSettings: React.FC<Props> = ({
  openPhysServicesModalOnLoad = false,
}) => {
  const practitioner = useAppSelector(selectPractitioner)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },
            {
              label: "Personal Settings",
            },
          ]}
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title="Personal Settings"
              greyBackground={true}
              fullBorder={true}
            />
          )}
          {/* TODO: Subtract PageToolbar Height rather than using min-h-screen */}
          <div className="relative min-h-screen bg-slate-50 border border-slate-200 p-6">
            {practitioner ? (
              <PersonalSettingsInner
                practitioner={practitioner}
                openPhysServicesModalOnLoad={openPhysServicesModalOnLoad}
              />
            ) : (
              // TODO: better loading states for settings pages
              <CircularProgress
                className="absolute top-[40%] left-1/2"
                size={60}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </>
  )
}

interface InnerProps {
  practitioner: Practitioner
  openPhysServicesModalOnLoad: boolean
}

const PersonalSettingsInner: React.FC<InnerProps> = ({
  practitioner,
  openPhysServicesModalOnLoad,
}) => {
  const dispatch = useDispatch()
  useEffect(() => trackLoad(practitioner), [practitioner])

  const handlePreferenceChange = (key: PractitionerSetting) => {
    const previouslyEnabled = practitioner[key]
    let newPreferences: Partial<Practitioner> = { [key]: !previouslyEnabled }

    if (key === "vendor_physician_authorization_enabled" && practitioner[key]) {
      // When disabling physician services, turn off default and clear enabled method
      newPreferences = {
        ...newPreferences,
        default_physician_authorization: false,
        physician_services_enable_method: "",
      }
    }

    dispatch(updatePersonalSettings(newPreferences, true))
  }

  return (
    <>
      <PersonalInformation practitioner={practitioner} />
      <Separator />
      <PhysicianAuthorizationSettings
        practitioner={practitioner}
        handleToggle={handlePreferenceChange}
        openModalOnLoad={openPhysServicesModalOnLoad}
      />
      <Separator />
      <PersonalPreferences
        practitioner={practitioner}
        handleToggle={handlePreferenceChange}
      />
    </>
  )
}

const trackLoad = (practitioner: Practitioner) => {
  trackEventWithProperties(
    physicianAuthorizationApplicationPageEvents.VISIT_SETTINGS,
    {
      practitioner_id: practitioner.id,
      clinic_id: practitioner.clinic.id,
    }
  )
}

export default PersonalSettings
