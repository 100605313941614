import * as React from "react"
import { useEffect, useState } from "react"

import Dialog from "@material-ui/core/Dialog"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import TextField from "app/components/forms/TextFieldComponent"
import { ModalContent, ModalHeader } from "app/components/modals"
import { primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles((theme) => ({
  button: {
    marginRight: 16,
    marginBottom: 16,
  },
  buttonContainer: {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "white",
  },
  bodyText: {
    marginBottom: 6,
    marginTop: 6,
  },
  link: {
    color: primaryColor,
  },
}))

export function RenameModal({
  initialText,
  title,
  label,
  buttonText,
  open,
  onClose,
  onSubmit,
}: {
  initialText: string
  title: string
  label: string
  buttonText: string
  open: boolean
  onClose: () => void
  onSubmit: (inputText) => void
}) {
  const classes = useStyles()

  const [inputText, setInputText] = useState(initialText)

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value)
  }

  useEffect(() => {
    setInputText(initialText)
  }, [initialText])

  function handleSubmit() {
    onSubmit(inputText)
    onClose()
  }

  return (
    <Dialog maxWidth="xs" fullWidth onClose={onClose} open={open}>
      <ModalHeader title={title} onClose={onClose} size={"sm"} />
      <ModalContent>
        <BodyText className={classes.bodyText} weight="semibold" size="sm">
          {label}
        </BodyText>
        <TextField
          placeholder={`Enter ${label}`}
          error={!inputText}
          fullWidth
          margin="dense"
          onChange={handleTextChange}
          value={inputText}
          variant="outlined"
          InputProps={{
            className: "bg-white",
          }}
        />
      </ModalContent>
      <div className={classes.buttonContainer}>
        <DesignSystemButton
          onClick={() => {
            handleSubmit()
          }}
          className={classes.button}
          disabled={!inputText}
        >
          {buttonText}
        </DesignSystemButton>
      </div>
    </Dialog>
  )
}
