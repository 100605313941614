import { parsePhoneNumber } from "libphonenumber-js"

export const validatePhone = (phone: string | null | undefined) => {
  if (!phone) {
    return null
  }

  try {
    return parsePhoneNumber(phone, "US").isValid()
      ? null
      : "Please enter a valid US Phone number"
  } catch (e) {
    // If the parse function receives an invalid input it will throw an error.
    return "Please enter a valid US Phone number"
  }
}
