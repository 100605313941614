import { makeStyles } from "@material-ui/core/styles"

import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import Auth0LoginTab from "app/main/login/tabs/Auth0LoginTab"
import { colors } from "app/theme"

import JWTLoginTab from "../tabs/JWTLoginTab"
import { ROLES } from "../types"

const useStyles = makeStyles((theme) => ({
  signUpRow: {
    display: "flex",
    alignItems: "baseline",
    marginTop: 10,
    justifyContent: "center",
  },
  signUpFree: {
    marginLeft: 8,
  },
  otherLoginOptions: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: "20px 0",

    "& p": {
      margin: "0 8px",
    },

    "& div": {
      background: colors.blueGray[300],
      height: 1,
      flex: 1,
    },
  },
  container: {
    width: "100%",
  },
}))

export interface LoginProps {
  signUpLink: string
  toggleForgotPasswordModal: () => void
  role: string
  initialEmail: string | null
}

export default function LoginTab({
  signUpLink,
  toggleForgotPasswordModal,
  role,
  initialEmail,
}: LoginProps) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div>
        <JWTLoginTab
          role={role}
          toggleForgotPasswordModal={toggleForgotPasswordModal}
          initialEmail={initialEmail}
        />
      </div>

      <div className={classes.signUpRow}>
        <BodyText>Don't have an account?</BodyText>
        <Button color="text" href={signUpLink} className={classes.signUpFree}>
          Sign up free.
        </Button>
      </div>

      {role === ROLES.PRACTITIONER && (
        <div>
          <div className={classes.otherLoginOptions}>
            <div />
            <BodyText>Other Sign In Options</BodyText>
            <div />
          </div>

          <Auth0LoginTab />
        </div>
      )}
    </div>
  )
}
