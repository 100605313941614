import { useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

import { find, min } from "lodash"
import MaterialTable, { MTableBodyRow } from "material-table"

import { Link, Paper, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg"
import { ReactComponent as SelectionIcon } from "app/assets/icons/selection-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import TextField from "app/components/forms/TextFieldComponent"
import { StorefrontOrderApprovalStatus } from "app/main/ecommerce/constants"
import PatientOrdersOrderedTestList from "app/main/patient-orders/PatientOrdersOrderedTest/PatientOrdersOrderedTestList"
import theme, {
  colors,
  dashboardHighlight,
  navy,
  primaryColor,
  shadows,
} from "app/theme"
import { RootState, SimpleStorefront } from "app/types"

import { ORDER_STATUS } from "../../../constants"
import DashboardPatientFilter from "../../dashboard/components/DashboardPatientFilter"
import DashboardPractitionerFilter from "../../dashboard/components/DashboardPractitionerFilter"
import DashboardStatusFilter from "../../dashboard/components/DashboardStatusFilter"
import ApproveDenyOrderTableCell from "./ApproveDenyOrderTableCell"
import DetailPanelIcon from "./DetailPanelIcon"
import { TabOptions } from "./OrderListTabs"
import OrderTableEmptyFiller from "./OrderTableEmptyFiller"
import PatientNameTableCell from "./PatientNameTableCell"
import PractitionerNameTableCell from "./PractitionerNameTableCell"
import StatusTableCell from "./StatusTableCell"
import StorefrontNameTableCell from "./StorefrontNameTableCell"
import UpdatedAtTableCell from "./UpdatedAtTableCell"
import ViewOrderTableCell from "./ViewOrderTableCell"
import { ColumnData, RowData, getPageSize } from "./types"

const useStyles = makeStyles<Theme, { filterApplied?: boolean }>((theme) => ({
  autocomplete: {
    "& .MuiButtonBase-root": {
      // So autocomplete icon does not rotate when clicked
      transform: "rotate(0deg)",
    },
    "& button.MuiButtonBase-root": {
      visibility: (props) => (props.filterApplied ? "visible" : "hidden"),
    },
    minWidth: 200,
  },
  autocompleteEndAdornment: {
    position: "absolute",
    right: 9,
    pointerEvents: "none",
  },
  orderInfoWrapper: {
    marginTop: -1,
    backgroundColor: dashboardHighlight,
  },
  textField: {
    backgroundColor: "white",
    "& fieldset": {
      borderRadius: "6px",
      border: "1px solid #D3DCE3",
    },
  },
  placeholder: {
    "&::placeholder": {
      color: navy,
      opacity: 1,
    },
    color: (props) => (props.filterApplied ? primaryColor : "inherit"),
  },

  tablePaper: {
    "& tbody tr:not(:first-of-type):hover td:last-child": {
      backgroundImage:
        "linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0.42))",
      position: "sticky",
      right: 0,
    },
  },
  dropdownOptionText: {
    padding: "14px 10px",
  },
  dropdownOption: {
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  dropdownContent: {
    background: "white",
    borderRadius: 6,
    border: `1px solid ${primaryColor}`,
    boxShadow: shadows.lg,
    marginTop: 5,
    overflowY: "auto",
    "& .MuiAutocomplete-listbox": {
      padding: 0,
    },
    "& .MuiAutocomplete-option": {
      padding: 0,
    },
  },
  separator: {
    background: colors.blueGray[200],
    height: 1,
    width: "100%",
  },
  popupIconSpacer: {
    width: 16,
    height: 16,
  },
}))

interface Props {
  orders: any
  page: number
  onChangePage: (page: number) => void
  onChangeFilters: (attr: any, newValue: any) => void
  appliedFilters: any
  storefronts: SimpleStorefront[]
  handleApproveDenyOrder?: (
    approvalId: string,
    approveDenyStatus: StorefrontOrderApprovalStatus
  ) => void
}

export const OrderTable = ({
  orders,
  page,
  onChangePage,
  onChangeFilters,
  appliedFilters,
  storefronts,
  handleApproveDenyOrder,
}: Props) => {
  const classes = useStyles({ filterApplied: false })
  const tableRef = useRef(null)
  const practitioner = useSelector(
    ({ practitioner }: RootState) => practitioner
  )
  const [notificationsPresent, setNotificationsPresent] = useState(false)
  const multipleOrderingPractitioners =
    practitioner.clinic?.multiple_ordering_practitioners
  const isClinicStaff = practitioner.is_clinic_staff
  const hasPractitionerColumn = isClinicStaff || multipleOrderingPractitioners

  const numColumns = hasPractitionerColumn ? 5 : 4

  const handleOrderApproveBtnClick = (approvalId: string) => {
    if (handleApproveDenyOrder) {
      handleApproveDenyOrder(approvalId, StorefrontOrderApprovalStatus.APPROVED)
    }
  }

  const handleOrderDenyBtnClick = (approvalId: string) => {
    if (handleApproveDenyOrder) {
      handleApproveDenyOrder(approvalId, StorefrontOrderApprovalStatus.DENIED)
    }
  }

  useEffect(() => {
    for (const order of orders?.results) {
      if (isNotificationPresentOnOrder(order)) {
        setNotificationsPresent(true)
        break
      }
    }
  }, [orders])

  const isNotificationPresentOnOrder = useCallback(
    (order: RowData): boolean => {
      if (order?.ordered_tests.some((ot) => ot.is_ordered_test_result_unread)) {
        return true
      }

      return false
    },
    [orders]
  )

  const columns: [ColumnData] = [
    {
      field: "toggle",
      render: (order) =>
        !order?.isEmpty ? (
          <DetailPanelIcon
            order={order}
            showNotificationIndicator={isNotificationPresentOnOrder(order)}
            showNotificationIndicatorPlaceholder={notificationsPresent}
          />
        ) : (
          <></>
        ),
      filtering: false,
      cellStyle: {
        width: "16px",
        paddingRight: 4,
        paddingLeft: 8,
      },
      filterCellStyle: {
        width: "16px",
        paddingRight: 4,
        paddingLeft: 8,
      },
    },
  ]

  const patientFilterComponent = ({ columnDef, onFilterChanged }) => (
    <DashboardPatientFilter
      includeStorefront={true}
      onFilterChange={(value) => {
        onFilterChanged(columnDef.tableData.id, value)
        onChangeFilters("patient_id", value)
      }}
      value={appliedFilters.patient_id}
    />
  )

  columns.push({
    field: "patient.id",
    render: (order) =>
      !order?.isEmpty ? (
        <PatientNameTableCell order={order} />
      ) : (
        <div style={{ color: navy, whiteSpace: "nowrap" }}>
          Searching{" "}
          <span style={{ textTransform: "capitalize" }}>
            {appliedFilters.tab_status.split("_").join(" ")}
          </span>{" "}
          orders only.{" "}
          <Link
            style={{ fontWeight: 600, cursor: "pointer" }}
            onClick={() => onChangeFilters("tab_status", null)}
          >
            Search all orders.
          </Link>
        </div>
      ),
    cellStyle: {
      width: `calc(100% / ${numColumns})`,
      minWidth: `calc(100% / ${numColumns})`,
      paddingLeft: 4,
    },
    filterCellStyle: {
      width: `calc(100% / ${numColumns})`,
      paddingLeft: 4,
    },
    filterComponent: patientFilterComponent,
  })

  const column = {
    field: "storefront.id",
    render: (order) =>
      !order?.isEmpty ? <StorefrontNameTableCell order={order} /> : <></>,
    cellStyle: {
      minWidth: "300px",
    },
    filterComponent: makeAutocompleteFilter(
      // If a storefront does not have a name it is not published, and will break the filter
      storefronts.filter((storefront) => storefront.name),
      (option) => option.name,
      (option) => String(option.id),
      (value) =>
        find(
          (storefronts.length && storefronts) || [],
          (option) => String(option.id) === value
        ),
      "LabShop",
      (columnDef, value) => {
        // On unselect, need to nullify ids list in order to get all storefront orders again
        if (!value) {
          onChangeFilters("storefront_ids", null)
        } else {
          onChangeFilters("storefront_ids", value)
        }
      },
      appliedFilters.storefront_ids,
      false
    ),
  }

  columns.push(column)

  if (hasPractitionerColumn) {
    const practitionerFilterComponent = ({ columnDef, onFilterChanged }) => (
      <DashboardPractitionerFilter
        onFilterChange={(value) => {
          onFilterChanged(columnDef.tableData.id, value)
          onChangeFilters("practitioner_id", value)
        }}
        value={appliedFilters.practitioner_id}
      />
    )

    const column = {
      field: "practitioner.id",
      render: (order) =>
        !order?.isEmpty ? <PractitionerNameTableCell order={order} /> : <></>,
      cellStyle: {
        minWidth: "300px",
      },
      filterComponent: practitionerFilterComponent,
    }

    columns.push(column)
  }

  const statusFilterComponent = ({ columnDef, onFilterChanged }) => (
    <DashboardStatusFilter
      onFilterChange={(value) => {
        onFilterChanged(columnDef.tableData.id, value)
        onChangeFilters("status", value)
      }}
      value={appliedFilters.status}
    />
  )

  columns.push(
    {
      field: "status",
      render: (order) =>
        !order?.isEmpty ? <StatusTableCell order={order} /> : <></>,
      cellStyle: {
        minWidth: "210px",
      },
      filterComponent: statusFilterComponent,
    },
    {
      field: "updated_at",
      render: (order) =>
        order?.isEmpty ? (
          <></>
        ) : order.storefront_order_approval?.status ===
          StorefrontOrderApprovalStatus.PENDING ? (
          <ApproveDenyOrderTableCell
            orderApprovalId={order.storefront_order_approval.id}
            handleOrderApproveBtnClick={handleOrderApproveBtnClick}
            handleOrderDenyBtnClick={handleOrderDenyBtnClick}
          />
        ) : (
          <UpdatedAtTableCell order={order} />
        ),
      filterComponent: makeTitle("Updated On"),
    },
    {
      field: "",
      width: 170,
      render: (order) =>
        !order?.isEmpty ? <ViewOrderTableCell order={order} /> : <></>,
      filtering: false,
      cellStyle: {
        paddingRight: 32,
        backgroundImage: "none",
      },
      filterCellStyle: {
        paddingRight: 32,
      },
    }
  )

  // Rerender table when data changes
  useEffect(() => {
    if (tableRef.current) {
      ;(tableRef.current as any).onQueryChange(null)
    }
  }, [tableRef, orders.results])

  return (
    <div data-testid="order-list">
      <MaterialTable
        tableRef={tableRef}
        detailPanel={[
          {
            icon: () => null,
            render: (order) => {
              if (order.status === ORDER_STATUS.DRAFT) return null
              return (
                <div className={classes.orderInfoWrapper}>
                  <PatientOrdersOrderedTestList
                    order={order}
                    patient={order.patient}
                    dashboardView={true}
                    dashboardColumns={numColumns}
                    appliedFilters={appliedFilters}
                    isStorefrontDashboard={true}
                  />
                </div>
              )
            },
          },
        ]}
        onRowClick={(event, rowData, togglePanel) => {
          togglePanel?.()
        }}
        aria-label="List of Orders"
        data={(query) =>
          new Promise((resolve, reject) => {
            let results = orders?.results || []

            const showAdditionalSearchRow =
              appliedFilters.tab_status &&
              appliedFilters.tab_status !== TabOptions.ALL &&
              (appliedFilters.patient_id || appliedFilters.status)

            if (showAdditionalSearchRow) {
              results = [{ isEmpty: true }, ...results]
            }

            resolve({
              data: results,
              page: page,
              totalCount: orders?.count || 0,
            })
          })
        }
        onChangePage={onChangePage}
        options={{
          toolbar: false,
          filtering: true,
          initialPage: 0,
          pageSize: min([
            orders?.count + 1 || 0,
            getPageSize(practitioner?.clinic?.id),
          ]),
          pageSizeOptions: [],
          overflowY: "hidden",
          sorting: false,
          headerStyle: {
            display: "none",
          },
          detailPanelType: "single",
          detailPanelColumnStyle: { display: "none" },
          filterCellStyle: {
            color: theme?.palette?.text?.secondary,
            backgroundColor: colors.blueGray[50],
          },
          draggable: false,
          rowStyle: (rowData) => {
            return {
              backgroundColor: rowData.practitioner
                ? rowData.tableData.showDetailPanel
                  ? dashboardHighlight
                  : "white"
                : colors.blue[50],
              height: rowData.practitioner ? 82 : 45,
            }
          },
        }}
        columns={columns}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <OrderTableEmptyFiller
                appliedFilters={appliedFilters}
                multipleOrderingPractitioners={multipleOrderingPractitioners}
              />
            ),
          },
          pagination: {
            firstAriaLabel: "First page",
            previousAriaLabel: "Previous page",
            nextAriaLabel: "Next page",
            lastAriaLabel: "Last page",
          },
        }}
        components={{
          Container: (props) => (
            <Paper {...props} className={classes.tablePaper} elevation={0} />
          ),
          // Override row to make it so "pointer" cursor does not show for draft orders
          // If onRowClick is defined material-table will automatically make cursor a pointer
          Row: (props) => {
            const { onRowClick, ...newProps } = props

            return (
              <MTableBodyRow
                {...newProps}
                onRowClick={
                  props.data.status === ORDER_STATUS.DRAFT ||
                  !props.data.practitioner
                    ? undefined
                    : onRowClick
                }
              />
            )
          },
        }}
      ></MaterialTable>
    </div>
  )
}

function makeAutocompleteFilter(
  options,
  getOptionsLabel,
  normalizeValue,
  mapValueToOptions,
  ariaLabel,
  filterChanged,
  appliedFilter,
  selection
) {
  return function AutocompleteFilter({ columnDef, onFilterChanged }) {
    const value = !!appliedFilter
      ? mapValueToOptions(appliedFilter)
      : mapValueToOptions(columnDef.tableData.filterValue) || null

    const changedFilter = filterChanged

    function handleChange(event, newValue) {
      const normalizedValue = newValue && normalizeValue(newValue)
      onFilterChanged(columnDef.tableData.id, normalizedValue)
      changedFilter(columnDef, normalizedValue)
    }

    const classes = useStyles({ filterApplied: value })

    return (
      <>
        <Autocomplete
          popupIcon={<div className={classes.popupIconSpacer}></div>}
          className={classes.autocomplete}
          style={{ maxWidth: 300, fontSize: 14 }}
          value={value}
          onChange={handleChange}
          options={options || []}
          getOptionLabel={getOptionsLabel}
          renderOption={(option) => (
            <div className={classes.dropdownOption}>
              <BodyText
                className={classes.dropdownOptionText}
                weight="semibold"
              >
                {getOptionsLabel(option)}
              </BodyText>
              {option !== options.slice(-1) && (
                <div className={classes.separator}></div>
              )}
            </div>
          )}
          freeSolo={false}
          autoHighlight={true}
          aria-label={ariaLabel}
          PaperComponent={({ children }) => (
            <Paper className={classes.dropdownContent}>{children}</Paper>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              multiline
              placeholder={ariaLabel}
              variant="outlined"
              size="small"
              InputProps={{
                ...params.InputProps,
                classes: { input: classes.placeholder },
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    {selection ? (
                      <SelectionIcon
                        width={16}
                        height={16}
                        fill={primaryColor}
                        className={classes.autocompleteEndAdornment}
                      />
                    ) : (
                      <SearchIcon
                        width={16}
                        height={16}
                        fill={primaryColor}
                        className={classes.autocompleteEndAdornment}
                      />
                    )}
                  </>
                ),
              }}
              className={classes.textField}
              // If value is set make the input focused
              // Otherwise don't pass prop to avoid overriding default behavior
              {...(value ? { focused: value } : {})}
            />
          )}
        />
      </>
    )
  }
}

function makeTitle(title) {
  return function AutocompleteFilter({ columnDef, onFilterChanged }) {
    return <BodyText weight="regular">{title}</BodyText>
  }
}
