import { useEffect, useMemo, useRef, useState } from "react"

import { Link } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import { ReactComponent as AbnormalResultsIcon } from "app/assets/icons/rupa-blood-dashboards/abnormal-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import { ClinicSettings } from "app/main/settings/RupaBloodDashboards/constants"
import {
  PatientPortalClinic,
  PatientPortalPractitioner,
} from "app/patient-portal/types"
import { colors, navy } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { BodySystem } from "types/body-system"
import { Clinic } from "types/clinic"
import { DiscreteResult } from "types/discrete-result"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"
import { OrderedResult } from "types/ordered-result"
import { Practitioner } from "types/practitioner"

import BloodLabDashboardsBodySystemCards from "./BloodLabDashboardsBodySystemCards"
import { BloodLabDashboardsOutOfRangeCard } from "./BloodLabDashboardsOutOfRangeCard"

const useStyles = makeAppStyles(() => ({
  container: {
    width: "100%",
    scrollBehavior: "smooth",
  },
  heading: {
    fontSize: 19,
    fontWeight: 600,
    lineHeight: "135%",
    color: navy,
    paddingBottom: 5,
    width: "100%",
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    marginBottom: 30,
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  outOfRange: {
    marginBottom: 35,
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  sectionOutOfRangeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  divider: {
    width: "100%",
    height: 1,
    background: colors.blueGray[200],
    marginTop: 24,
  },
  disclaimerText: {
    color: colors.blueGray[500],
    marginTop: 25,
    marginBottom: 25,
  },
  disclaimerTextLink: {
    color: colors.blueGray[500],
    textDecoration: "underline !important",
    cursor: "pointer",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 14,
  },
  noResultsText: {
    color: colors.blueGray[400],
    textAlign: "center",
  },
}))

interface Props {
  searchValue: string
  orderedResult?: OrderedResult
  discreteResults?: DiscreteResult[]
  biomarkers: Biomarker[]
  bodySystems: BodySystem[]
  practitioner?: PatientPortalPractitioner | Practitioner
  clinic?: PatientPortalClinic | Clinic
  isLoading: boolean
  isPatientPreview: boolean
  getAbnormalDiscreteResults: () => DiscreteResult[]
  getMatchingOptimalRangeByBiomarkerId: (
    bioamrkerId?: string
  ) => LabCompanyBiomarkerRange | undefined
  getMatchingDiscreteResultByBiomarkerId: (
    bioamrkerId?: string
  ) => DiscreteResult | undefined
}

const BloodLabDashboardsPatientReport = ({
  searchValue,
  orderedResult,
  discreteResults,
  biomarkers,
  bodySystems,
  practitioner,
  clinic,
  isLoading,
  isPatientPreview,
  getAbnormalDiscreteResults,
  getMatchingOptimalRangeByBiomarkerId,
  getMatchingDiscreteResultByBiomarkerId,
}: Props) => {
  const classes = useStyles()

  const [filteredBiomarkers, setFilteredBiomarkers] = useState<string[] | null>(
    null
  )

  const ref = useRef<any>()

  const abnormalDiscreteResults = getAbnormalDiscreteResults()

  const optimalRangesSettingTurnedOn = useMemo(() => {
    return clinic?.attributes.clinic_settings.includes(
      ClinicSettings.USE_OPTIMAL_RANGES_FOR_BLOOD_REPORTS
    )
  }, [clinic])

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (searchValue === "") {
      setFilteredBiomarkers(biomarkers.map((biomarker) => biomarker.id))
    }

    setFilteredBiomarkers(
      biomarkers
        .filter(
          (biomarker) =>
            biomarker.attributes.short_name
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            biomarker.attributes.long_name
              .toLowerCase()
              .includes(searchValue.toLowerCase())
        )
        .map((biomarker) => biomarker.id)
    )
  }, [searchValue])

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <Skeleton animation="wave" height={80} width="25%" />

        <Skeleton animation="wave" height={60} width="100%" />
        <Skeleton animation="wave" height={60} width="100%" />

        <Skeleton animation="wave" height={80} width="25%" />

        <Skeleton animation="wave" height={50} width="25%" />

        <Skeleton animation="wave" height={50} width="20%" />

        <Skeleton animation="wave" height={50} width="20%" />
      </div>
    )
  }

  return (
    <div className={classes.container} ref={ref} id={"outOfRange"}>
      {searchValue === "" && Boolean(abnormalDiscreteResults.length) && (
        <div className={classes.outOfRange}>
          <div
            className={classes.heading}
            data-cy="abnormal-heading-patient-experience-blood-labs"
          >
            <AbnormalResultsIcon />
            Abnormal
          </div>
          <div className={classes.sectionOutOfRangeContainer}>
            {abnormalDiscreteResults.map((discreteResult) => (
              <BloodLabDashboardsOutOfRangeCard
                key={discreteResult.id}
                discreteResult={discreteResult}
                useOptimalRanges={optimalRangesSettingTurnedOn}
                getMatchingOptimalRangeByBiomarkerId={
                  getMatchingOptimalRangeByBiomarkerId
                }
                practitioner={practitioner}
                clinic={clinic}
                isPatientPreview={isPatientPreview}
              />
            ))}
          </div>
        </div>
      )}

      {Boolean(discreteResults?.length) &&
      (filteredBiomarkers === null || Boolean(filteredBiomarkers?.length)) ? (
        <div>
          <div className={classes.heading}>All Markers</div>
          <div className={classes.sectionContainer}>
            {bodySystems.map((bodySystem) => (
              <BloodLabDashboardsBodySystemCards
                bodySystem={bodySystem}
                filteredBiomarkers={filteredBiomarkers}
                useOptimalRanges={optimalRangesSettingTurnedOn}
                practitioner={practitioner}
                clinic={clinic}
                getMatchingDiscreteResultByBiomarkerId={
                  getMatchingDiscreteResultByBiomarkerId
                }
                getMatchingOptimalRangeByBiomarkerId={
                  getMatchingOptimalRangeByBiomarkerId
                }
                isPatientPreview={isPatientPreview}
              />
            ))}
          </div>
        </div>
      ) : (
        <BodyText weight={"semibold"} className={classes.noResultsText}>
          We couldn't find any markers that match your search. 🤔
        </BodyText>
      )}

      <div className={classes.divider}></div>

      <DisclaimerText
        orderedResultFile={orderedResult?.attributes.result_file || ""}
      />
    </div>
  )
}

const DisclaimerText = ({
  orderedResultFile,
}: {
  orderedResultFile?: string
}) => {
  const classes = useStyles()

  return (
    <BodyText size="xxs" className={classes.disclaimerText}>
      This dashboard is intended for informational purposes only and should not
      be used as a substitute for professional medical advice, diagnosis, or
      treatment. If you have any questions or concerns related to this dashboard
      or a possible medical condition, seek the advice of your healthcare
      provider. Never disregard the advice of your healthcare provider. Your{" "}
      <Link
        className={classes.disclaimerTextLink}
        onClick={() => orderedResultFile && window.open(orderedResultFile)}
      >
        original lab report
      </Link>{" "}
      (the “Report”) is the official documentation of your results; please
      always refer back to the Report. Rupa is not liable for any discrepancies
      between the Report and this visualization.{" "}
    </BodyText>
  )
}

export default BloodLabDashboardsPatientReport
