import React, { useEffect, useRef, useState } from "react"

import { Theme, useMediaQuery } from "@material-ui/core"

import { MIN_DISTANCE_FROM_TOP_FOR_STICKY_DIV } from "app/patient-portal/blood-lab-dashboard/constants"
import { PatientPortalPractitioner } from "app/patient-portal/types"
import { Practitioner } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { BloodReportResult } from "types/blood-report-result"
import { BodySystem } from "types/body-system"

import BloodLabDashboardsPatientSidebar from "../BloodLabDashboardsPatientPreview/sidebar/BloodLabDashboardsPatientSidebar"
import BloodLabDashboardsPatientSidebarMobile from "../BloodLabDashboardsPatientPreview/sidebar/BloodLabDashboardsPatientSidebarMobile"
import { BloodLabDashboardsSnapshotPatientReport } from "./BloodLabDashboardsSnapshotPatientReport"

const useStyles = makeAppStyles((theme: Theme) => ({
  container: {
    width: "100%",
    paddingTop: 48,
    display: "flex",
    gap: 57,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      paddingTop: 24,
    },
  },
  sidebar: {
    width: 223,
    minWidth: 223,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  report: {
    width: "100%",
  },
}))

const FIXED_POSITION_PIXEL_OFFSET_TOP = "14px"
const FIXED_POSITION_PIXEL_MARGIN_LEFT = "280px"
const RESET_POSITION_OFFSET_TOP = "0px"
const RESET_POSITION_MARGIN_LEFT = "0px"

interface Props {
  bloodReportResults?: BloodReportResult[]
  abnormalBloodReportResults: BloodReportResult[]
  bodySystems: BodySystem[]
  biomarkers: Biomarker[]
  isLoading: boolean
  practitioner?: PatientPortalPractitioner | Practitioner
  clinicName?: string
  orderedResultFile?: string
  snapshotCreationDate: string
  labCompanyName?: string
  getMatchingBloodReportResultByBiomarkerId: (
    biomarkerId?: string
  ) => BloodReportResult | undefined
  showHighLowDescriptions: boolean
}

export function BloodLabsDashboardsSnapshotPatientReportContainer({
  bloodReportResults,
  abnormalBloodReportResults,
  bodySystems,
  biomarkers,
  isLoading,
  practitioner,
  clinicName,
  orderedResultFile,
  snapshotCreationDate,
  labCompanyName,
  getMatchingBloodReportResultByBiomarkerId,
  showHighLowDescriptions,
}: Props) {
  const classes = useStyles()

  const [showMobileView, _setShowMobileView] = useState(false)
  const [searchValue, setSearchValue] = useState<string>("")

  const siderbarRef = useRef<any>()
  const mainRef = useRef<any>()

  // React state does not propagate to event listeners, so we need to use a ref
  const stateRef = useRef(showMobileView)
  const setShowMobileView = (data) => {
    stateRef.current = data
    _setShowMobileView(data)
  }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))

  useEffect(() => {
    setShowMobileView(isMobile)
  }, [isMobile])

  const handleScroll = (mobile) => {
    // Don't need to render special sidebar styling if mobile
    if (stateRef.current) {
      return
    }

    const targetTop = siderbarRef?.current?.getBoundingClientRect().top
    const mainTop = mainRef?.current?.getBoundingClientRect().top

    if (!targetTop || !mainTop) return

    if (targetTop + 1 < MIN_DISTANCE_FROM_TOP_FOR_STICKY_DIV) {
      siderbarRef.current.style.position = "fixed"
      siderbarRef.current.style.top = FIXED_POSITION_PIXEL_OFFSET_TOP
      mainRef.current.style.marginLeft = FIXED_POSITION_PIXEL_MARGIN_LEFT
    } else if (mainTop >= MIN_DISTANCE_FROM_TOP_FOR_STICKY_DIV) {
      siderbarRef.current.style.position = "relative"
      siderbarRef.current.style.top = RESET_POSITION_OFFSET_TOP
      mainRef.current.style.marginLeft = RESET_POSITION_MARGIN_LEFT
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, []) // Empty dependency array to run this effect only once

  return (
    <div className={classes.container}>
      <div className={classes.sidebar} ref={siderbarRef}>
        {isMobile ? (
          <BloodLabDashboardsPatientSidebarMobile
            setSearchValue={setSearchValue}
            bodySystems={bodySystems}
            isLoading={isLoading}
          />
        ) : (
          <BloodLabDashboardsPatientSidebar
            setSearchValue={setSearchValue}
            bodySystems={bodySystems}
            abnormalResultsPresent={Boolean(abnormalBloodReportResults.length)}
            isLoading={isLoading}
          />
        )}
      </div>
      <div className={classes.report} ref={mainRef}>
        <BloodLabDashboardsSnapshotPatientReport
          searchValue={searchValue}
          bloodReportResults={bloodReportResults}
          biomarkers={biomarkers}
          bodySystems={bodySystems}
          practitioner={practitioner}
          clinicName={clinicName}
          isLoading={isLoading}
          abnormalBloodReportResults={abnormalBloodReportResults}
          snapshotCreationDate={snapshotCreationDate}
          labCompanyName={labCompanyName}
          orderedResultFile={orderedResultFile}
          getMatchingBloodReportResultByBiomarkerId={
            getMatchingBloodReportResultByBiomarkerId
          }
          showHighLowDescriptions={showHighLowDescriptions}
        />
      </div>
    </div>
  )
}
