import { ReactComponent as ResultsVisualizationWithNotificationIcon } from "app/assets/icons/dashboard/results-visualization-with-notification.svg"
import { ReactComponent as ResultsVisualizationIcon } from "app/assets/icons/dashboard/results-visualization.svg"
import { useEmbeddableResultModal } from "app/components/hooks/use-embeddable-result-modal"
import useEventCallback from "app/hooks/use-event-callback"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { DashboardKit } from "types/dashboard/dashboard-kit"
import { DashboardOrder } from "types/dashboard/dashboard-order"
import { DashboardOrderedResult } from "types/dashboard/dashboard-ordered-result"

import ActionButton from "./ActionButton"

export interface OpenEmbeddableResultActionProps {
  kit: DashboardKit
  order: DashboardOrder
}

export default function OpenEmbeddableResultAction({
  kit,
  order,
}: OpenEmbeddableResultActionProps) {
  const emmbeddableUserResultId =
    kit.relationships.embeddable_user_result?.data?.id

  const latestOrderedResult = useCachedResource<DashboardOrderedResult>(
    kit.relationships.latest_ordered_result?.data
  )

  const { showEmbeddableResult } = useEmbeddableResultModal(
    kit.relationships.embeddable_user_result?.data?.id ?? null,
    order.id,
    kit.relationships.lab_company.data.id,
    kit.attributes.display_name,
    kit.attributes.downloadable_result_urls,
    "practitioner",
    latestOrderedResult?.id
  )

  const hasAnyUnreadNotification =
    latestOrderedResult?.attributes.has_unread_notification

  const onClick = useEventCallback(showEmbeddableResult)

  return (
    <ActionButton
      disabled={!emmbeddableUserResultId}
      onClick={onClick}
      tooltipTitle={"See Results"}
    >
      <>
        {hasAnyUnreadNotification ? (
          <ResultsVisualizationWithNotificationIcon
            data-cy="results-visualization-notification"
            fill="currentColor"
            viewBox="0 0 17 22"
          />
        ) : (
          <ResultsVisualizationIcon
            data-cy="results-visualization-cleared"
            fill="currentColor"
            viewBox="0 0 15 18"
          />
        )}
      </>
    </ActionButton>
  )
}
