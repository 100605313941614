import clsx from "clsx"

import { FormControl, FormHelperText } from "@material-ui/core"

import TextFieldComponent, {
  TextFieldComponentProps,
} from "app/components/forms/TextFieldComponent"

import useInputStyles from "./use-input-styles"

export type TextInputProps = Omit<TextFieldComponentProps, "type" | "variant">

const TextInput = ({
  InputProps,
  className,
  error,
  helperText,
  ...fieldProps
}: TextInputProps) => {
  const inputClasses = useInputStyles()
  return (
    <FormControl error={error} fullWidth>
      <TextFieldComponent
        InputProps={{
          classes: { ...InputProps?.classes, ...inputClasses },
          ...InputProps,
        }}
        className={clsx(className, "fs-exclude")}
        error={error}
        fullWidth
        type="text"
        variant="outlined"
        {...fieldProps}
      />
      {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default TextInput
