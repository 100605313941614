import * as React from "react"

import clsx from "clsx"

import { NavyTooltip } from "app/components/NavyTooltip"
import Button from "app/components/design-system/Button"

export type ActionButtonProps = {
  children: NonNullable<React.ReactNode>
  disabled?: boolean
  tooltipTitle: NonNullable<React.ReactNode>
  className?: string
} & React.ComponentProps<typeof Button>

export default React.forwardRef<HTMLButtonElement, ActionButtonProps>(
  function ActionButton(
    { children, disabled, tooltipTitle, className, ...buttonProps },
    ref
  ) {
    return (
      <NavyTooltip arrow placement="top" title={tooltipTitle}>
        <div className={clsx(className)}>
          <Button
            color="secondary"
            className="flex justify-center items-center h-9 w-9 p-[5px_8px] min-w-0 disabled:opacity-50"
            disabled={disabled}
            ref={ref}
            {...buttonProps}
          >
            {children}
          </Button>
        </div>
      </NavyTooltip>
    )
  }
)
