import KitShipmentTrackingDescription from "app/components/StatusComponents/KitShipmentTrackingDescription"
import { ORDERED_TEST_STATUS } from "app/constants.typed"
import { getRequisitionLabCompanyName } from "app/utils/order-status-utils"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-orders-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({
  orderedTest,
  requisitionAvailable,
  labCompany,
}: StatusConfigurationProps) => {
  switch (orderedTest.sample_collection_coordinator) {
    case "LAB":
      if (requisitionAvailable) {
        return "Requisition is Ready"
      } else {
        const name = getRequisitionLabCompanyName(labCompany.key)
        return `Awaiting requisition from ${name}`
      }
    case "CLINIC":
      if (requisitionAvailable) {
        return "Requisition is Ready"
      } else {
        // Requisition should always be available for clinic orders
        return "Requisition is Processing"
      }
    default:
      return "Kit Shipped to Patient"
  }
}

const SubTitle = ({ orderedTest, labCompany }: StatusConfigurationProps) => {
  switch (orderedTest.sample_collection_coordinator) {
    // PROD-2070: see comments in dashboard/ordered-with-lab-status-configuration.tsx
    case "PATIENT":
      return (
        <KitShipmentTrackingDescription
          estimated_arrival_date={orderedTest.estimated_arrival_date}
          shipment_tracking_link={orderedTest.shipment_tracking_link}
          lab_company_notifies_sample_at_lab={labCompany.notifies_sample_at_lab}
          lab_company_short_name={labCompany.short_name}
        />
      )
  }
}

const orderedWithLabStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { orderedTestStatus: ORDERED_TEST_STATUS.ORDERED_WITH_LAB },
    progressPercentage: PROGRESS_PERCENTAGES.ORDERED_WITH_LAB,
    title: Title,
    subTitle: SubTitle,
  }

export default orderedWithLabStatusConfiguration
