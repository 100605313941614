import { Fragment, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import axios from "axios"
import cx from "classnames"
import { usePrevious } from "react-use"
import useSWR from "swr"

import { Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { API } from "app/api"
import { ReactComponent as CalendarIcon } from "app/assets/icons/onboarding/calendar.svg"
import { ReactComponent as CouponIcon } from "app/assets/icons/onboarding/coupon.svg"
import { ReactComponent as EllipseIcon } from "app/assets/icons/onboarding/ellipse.svg"
import { ReactComponent as FAQIcon } from "app/assets/icons/onboarding/faq.svg"
import { ReactComponent as PeopleIcon } from "app/assets/icons/onboarding/people.svg"
import { ReactComponent as PhlebotomyIcon } from "app/assets/icons/onboarding/phlebotomy.svg"
import { ReactComponent as PlayIcon } from "app/assets/icons/onboarding/play.svg"
import { ReactComponent as SupportIcon } from "app/assets/icons/onboarding/support.svg"
import VideoThumbnail from "app/assets/icons/onboarding/video.png"
import PageToolbar from "app/components/PageToolbar"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import StartOrderModal from "app/components/modals/StartOrderModal"
import {
  PATIENT_EXPERIENCE_GUIDE_URL,
  PHYSICIAN_SERVICES_CLIENT_EXPERIENCE_GUIDE_URL,
  PHYSICIAN_SERVICES_INTENTIONS,
} from "app/constants"
import useVideoModal from "app/hooks/use-video-modal"
import useIntercomAutoLaunch from "app/hooks/useIntercomAutoLaunch"
import DashboardOnboarding from "app/main/dashboard/DashboardOnboarding"
import reducer from "app/main/dashboard/store/reducers"
import {
  trackClickedMessageUs,
  trackMarkedTaskComplete,
} from "app/services/segment"
import { showMessage } from "app/store/actions"
import withReducer from "app/store/withReducer"
import { navy, shadows } from "app/theme"
import { Practitioner } from "app/types"
import { getApiBaseUrl, getStartDraftTextFromIntention } from "app/utils"
import { getOrderTypeLabel } from "app/utils/order-utils"
import filter from "lodash/filter"
import isEmpty from "lodash/isEmpty"

import ExploreOrderingAccessBanner from "./ExploreOrderingAccessBanner"
import OnboardingProvider from "./OnboardingProvider"
import TaskContainer from "./TaskContainer"
import { useConfetti } from "./confetti/confetti-provider"
import useOnboardingSWR from "./hooks/use-onboarding-swr"
import OnboardingTaskItem from "./task-items/OnboardingTaskItem"
import { OnboardingTaskKey } from "./types"

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "20px",
  },
  taskSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    backgroundColor: "#ffffff",
    padding: "32px",
    gap: "20px",
    width: "860px",
    minWidth: "664px",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      width: "90%",
      padding: "20px",
    },
  },
  taskSectionHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    gap: "5px",
    width: "100%",
  },
  headerText: {
    fontWeight: 600,
    fontSize: "19px",
    lineHeight: "135%",
    color: navy,
  },
  subHeaderText: {
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "135%",
    color: navy,
  },
  progressBar: {
    borderRadius: "23px",
    display: "flex",
    flexDirection: "row",
  },
  progressBarEmpty: {
    marginTop: "5px",
    flexGrow: 1,
    maxWidth: "100%",
    height: "5px",
    background: "#E2E8F0",
    borderRadius: "inherit",
  },
  progressBarFilled: (percentageComplete) => ({
    marginTop: "5px",
    maxWidth: "100%",
    width: `${percentageComplete}%`,
    height: "5px",
    background: "linear-gradient(270deg, #3CADE9 0%, #56E4E2 100%)",
    borderRadius: "inherit",
    transition: "width 0.8s ease-in-out",
  }),
  resourcesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "180px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "unset",
    },
  },
  resourcesLink: {
    color: "#0075CD",
  },
  resourcesColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      width: "240px",
      marginTop: "20px",
      alignItems: "start",
      justifyContent: "start",
    },
  },
  link: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8px",
    },
  },
  linkIcon: {
    width: "22px",
    margin: "auto",
    marginRight: "6px",
    verticalAlign: "middle",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  videoContainer: {
    cursor: "pointer",
    width: "17vw",
    height: "10vw",
    minWidth: "180px",
    minHeight: "90px",
    maxHeight: "180px",
    maxWidth: "340px",
    margin: "auto 12px",
    marginLeft: 0,
    borderRadius: "8px",
    boxShadow: shadows.md,
    background: `linear-gradient(0deg, rgba(175, 195, 215, 0.52), rgba(175, 195, 215, 0.52)), url(${VideoThumbnail})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "inline",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      width: "100%",
      minHeight: "206px",
    },
  },
  ellipseIcon: {
    position: "relative",
    left: "calc(50% - 36px)",
    top: "calc(50% - 36px)",
  },
  playIcon: {
    position: "relative",
    borderRadius: "2.76138px",
    top: "calc(50% - 64px)",
    left: "calc(50% - 82px)",
  },
  pointer: {
    cursor: "pointer",
  },
  spaced: {
    marginTop: "3vh",
    marginRight: "auto",
    justifyContent: "unset",
    [theme.breakpoints.down("sm")]: {
      marginTop: "unset",
    },
  },
  spacedLink: {
    marginTop: theme.spacing(2),
  },
  getStartedContainer: {
    justifyContent: "space-between",
  },
}))

function Onboarding() {
  const dispatch = useDispatch()
  const videoModal = useVideoModal()

  // Automatically launch intercom if the "messages" query param is set
  useIntercomAutoLaunch()

  const [isStartOrderModalOpen, setIsStartOrderModalOpen] = useState(false)

  const { data: practitioner, mutate: mutatePractitioner } = useSWR(
    "/api/practitioner",
    async (url) => {
      const response = await axios.get(getApiBaseUrl() + url)
      return response.data as Practitioner
    }
  )

  // Onboarding Tasks Data //
  const {
    data: onboardingState,
    mutate: mutateOnboarding,
    isLoading: onboardingLoading,
  } = useOnboardingSWR()

  useEffect(() => {
    mutatePractitioner()
    mutateOnboarding()
  }, [])

  const tasks = useMemo(() => onboardingState?.tasks ?? [], [onboardingState])

  const { availableTasks, requiredTasks, customizationTasks } = useMemo(() => {
    const available = filter(tasks, { available: true })
    return {
      availableTasks: available,
      requiredTasks: filter(available, { section: "required" }),
      customizationTasks: filter(available, { section: "customization" }),
    }
  }, [tasks])

  const onTaskComplete = (taskKey: OnboardingTaskKey) => {
    mutateOnboarding({
      show_onboarding: true,
      ...onboardingState,
      tasks: tasks.map((task) =>
        task.key === taskKey ? { ...task, complete: true } : task
      ),
    })
  }

  const markTaskAsComplete = async (taskKey: OnboardingTaskKey) => {
    try {
      await API.Onboarding.markTaskComplete(taskKey)
      onTaskComplete(taskKey)
      trackMarkedTaskComplete(taskKey)
    } catch (e) {
      dispatch(
        showMessage({
          message:
            "Unable to mark task complete. Please try again. If this issue persists, please contact support via Intercom for assistance.",
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      )
    }
  }
  // End Onboarding Tasks Data //

  // Percentage Complete //
  const triggerConfetti = useConfetti()

  const percentageComplete = useMemo(() => {
    if (onboardingLoading) {
      return 0
    }
    // The progress bar only cares about the % complete of the required tasks
    const complete = requiredTasks.filter((task) => task.complete).length
    const total = requiredTasks.length
    return ((complete + 1) / (total + 1)) * 100
  }, [onboardingLoading, requiredTasks])

  const previousProgress = usePrevious(percentageComplete)

  useEffect(() => {
    if (previousProgress && percentageComplete === 100) {
      triggerConfetti()
    }
  }, [percentageComplete])
  // End Percentage Complete //

  const [openCard, setOpenCard] = useState<OnboardingTaskKey | undefined>()
  const previousOpenCard = usePrevious(openCard)

  useEffect(() => {
    if (!openCard && availableTasks.length) {
      const prevTask = availableTasks.find(
        (task) => task.key === previousOpenCard
      )

      if (prevTask && !prevTask.complete) {
        // We don't want to open the card if the previous task is not complete
        return
      }

      if (!prevTask || prevTask.section === "required") {
        const firstUncompletedTask = availableTasks.find(
          (task) => !task.complete && task.key !== previousOpenCard
        )
        setOpenCard(firstUncompletedTask?.key)
      } else if (
        customizationTasks[customizationTasks.length - 1].key !== prevTask.key
        // not the last customization task
      ) {
        const firstUncompletedTask = customizationTasks.find(
          (task) => !task.complete && task.key !== previousOpenCard
        )
        setOpenCard(firstUncompletedTask?.key)
      }
    }
  }, [availableTasks, customizationTasks, openCard])

  const intendingToUsePhysicianServices =
    practitioner?.physician_services_intention &&
    [
      PHYSICIAN_SERVICES_INTENTIONS.PHYSICIAN_SERVICES_AND_SELF,
      PHYSICIAN_SERVICES_INTENTIONS.PHYSICIAN_SERVICES,
    ].includes(practitioner.physician_services_intention)

  // UI Variables //
  const classes = useStyles(percentageComplete)
  const startDraftText = getStartDraftTextFromIntention(practitioner)
  const subTitleText = useMemo(() => {
    if (intendingToUsePhysicianServices) {
      return `Just a couple more steps before you can start a ${getOrderTypeLabel(
        true
      )}.`
    }

    return "Just a couple more steps before you can start an order."
  }, [practitioner?.physician_services_intention])
  // End UI Variables //

  return (
    <>
      <PageToolbar title="Getting Started">
        {startDraftText && (
          <Button
            size="medium"
            color="primary"
            onClick={() => setIsStartOrderModalOpen(true)}
            className="tour-onboarding-start-order"
          >
            {startDraftText}
          </Button>
        )}
      </PageToolbar>
      <OnboardingProvider openCard={openCard} setOpenCard={setOpenCard}>
        <div className={classes.pageContainer}>
          <div className={classes.taskSection}>
            <div className={classes.taskSectionHeader}>
              {percentageComplete === 100 ? (
                <>
                  <div className={classes.headerText}>
                    You’re ready to start ordering!
                  </div>
                  <div className={classes.progressBar} style={{ marginTop: 5 }}>
                    <div
                      className={classes.progressBarFilled}
                      style={{ width: `${percentageComplete}%` }}
                    />
                    <div className={classes.progressBarEmpty} />
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.headerText}>Welcome to Rupa! ✨</div>
                  <p className={classes.subHeaderText}>{subTitleText}</p>
                  <div className={classes.progressBar}>
                    <div
                      className={classes.progressBarFilled}
                      style={{ width: `${percentageComplete}%` }}
                    />
                    <div className={classes.progressBarEmpty} />
                  </div>
                </>
              )}
            </div>
            <TaskContainer header="Sign up" isTaskComplete />
            {requiredTasks.map((task) => (
              <Fragment key={task.key}>
                <OnboardingTaskItem
                  task={task}
                  markTaskAsComplete={markTaskAsComplete}
                  onTaskComplete={onTaskComplete}
                  practitioner={practitioner}
                  refreshPractitioner={mutatePractitioner}
                />
              </Fragment>
            ))}
            <ExploreOrderingAccessBanner
              practitioner={practitioner}
              refreshPractitioner={mutatePractitioner}
            />
          </div>

          {!isEmpty(customizationTasks) ? (
            <div className={classes.taskSection}>
              <div className={classes.taskSectionHeader}>
                <div className={classes.headerText}>
                  Finish customizing your account
                </div>
              </div>
              {customizationTasks.map((task) => (
                <Fragment key={task?.key}>
                  <OnboardingTaskItem
                    task={task}
                    markTaskAsComplete={markTaskAsComplete}
                    onTaskComplete={onTaskComplete}
                    practitioner={practitioner}
                    refreshPractitioner={mutatePractitioner}
                  />
                </Fragment>
              ))}
            </div>
          ) : null}

          <div className={classes.taskSection}>
            <div className={classes.taskSectionHeader}>
              <div className={classes.headerText}>Resources to Get Started</div>
            </div>

            <div
              className={cx([
                classes.resourcesContainer,
                classes.getStartedContainer,
              ])}
            >
              <div
                className={classes.videoContainer}
                onClick={() =>
                  videoModal.show({
                    src: "https://player.vimeo.com/video/734869168?h=8af393948e&badge=0&autopause=0&player_id=0&app_id=58479",
                    title: "An Overview of Rupa Health",
                  })
                }
              >
                <EllipseIcon className={classes.ellipseIcon} />
                <PlayIcon className={classes.playIcon} />
              </div>
              <div className={cx([classes.resourcesColumn, classes.spaced])}>
                <Link
                  className={cx([classes.link, classes.spacedLink])}
                  href="http://help.rupahealth.com/en/"
                  target="_blank"
                >
                  <FAQIcon className={classes.linkIcon} />{" "}
                  <BodyText weight="semibold" className={classes.resourcesLink}>
                    FAQs
                  </BodyText>
                </Link>
                <Link
                  className={cx([classes.link, classes.spacedLink])}
                  href={
                    intendingToUsePhysicianServices
                      ? PHYSICIAN_SERVICES_CLIENT_EXPERIENCE_GUIDE_URL
                      : PATIENT_EXPERIENCE_GUIDE_URL
                  }
                  target="_blank"
                >
                  <PeopleIcon className={classes.linkIcon} />{" "}
                  <BodyText weight="semibold" className={classes.resourcesLink}>
                    The Patient Experience
                  </BodyText>
                </Link>

                <Link
                  className={cx([classes.link, classes.spacedLink])}
                  href="https://www.rupahealth.com/phlebotomy"
                  target="_blank"
                >
                  <PhlebotomyIcon className={classes.linkIcon} />{" "}
                  <BodyText weight="semibold" className={classes.resourcesLink}>
                    Learn about Phlebotomy
                  </BodyText>
                </Link>
              </div>
              <div className={cx([classes.resourcesColumn, classes.spaced])}>
                <Link
                  className={cx([classes.link, classes.spacedLink])}
                  href="/promotions"
                >
                  <CouponIcon className={classes.linkIcon} />{" "}
                  <BodyText weight="semibold" className={classes.resourcesLink}>
                    First Order Discounts
                  </BodyText>
                </Link>
                <Link
                  className={cx([classes.link, classes.spacedLink])}
                  href="http://rupahealth.com/demo"
                  target="_blank"
                >
                  <CalendarIcon className={classes.linkIcon} />{" "}
                  <BodyText weight="semibold" className={classes.resourcesLink}>
                    Get a Demo
                  </BodyText>
                </Link>
                <Link
                  onClick={(e) => {
                    e.preventDefault()
                    window.Intercom("show")
                    trackClickedMessageUs()
                  }}
                  className={cx([classes.link, classes.spacedLink])}
                >
                  <SupportIcon className={classes.linkIcon} />{" "}
                  <BodyText
                    weight="semibold"
                    className={cx([classes.resourcesLink, classes.pointer])}
                  >
                    Send Us a Message
                  </BodyText>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </OnboardingProvider>
      <DashboardOnboarding refreshPractitioner={mutatePractitioner} />
      <StartOrderModal
        open={isStartOrderModalOpen}
        onClose={() => setIsStartOrderModalOpen(false)}
      />
    </>
  )
}

export default withReducer("onboarding", reducer)(Onboarding)
