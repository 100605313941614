import { Button, Typography } from "@material-ui/core"

import { ReactComponent as PersonGray } from "app/assets/icons/document-access/person-gray.svg"
import BodyText from "app/components/design-system/BodyText"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { UserClinicAccessSharingStatus } from "app/types"
import { Clinic } from "types/clinic"
import { UserClinicAccessConfiguration } from "types/user-clinic-access-configuration"

import Card from "../../Card"

const PatientPortalLabResultsAccessDocumentCard = ({
  userClinicAccessConfiguration,
}: {
  userClinicAccessConfiguration: UserClinicAccessConfiguration
}) => {
  const clinic = useCachedResource<Clinic>(
    userClinicAccessConfiguration.relationships.clinic.data
  )

  const getText = () => {
    switch (userClinicAccessConfiguration.attributes.sharing_status) {
      case UserClinicAccessSharingStatus.NONE:
        return "Only sharing documents from this clinic."
      case UserClinicAccessSharingStatus.SPECIFIC:
        return "Sharing specific documents."
      case UserClinicAccessSharingStatus.SPECIFIC_AND_FUTURE:
        return "Sharing specific documents, and future documents automatically."
      default:
        return "Sharing all documents."
    }
  }

  const onSelectDocuments = () => {
    // TODO: open modal to select documents
    console.log("select documents")
    console.log(userClinicAccessConfiguration.relationships.clinic.data.id)
  }

  return (
    <Card className="flex flex-col h-full">
      <div className="flex flex-row justify-between items-start">
        <div className="text-left">
          <BodyText weight="semibold">{clinic?.attributes.name}</BodyText>
          <BodyText>
            {clinic?.attributes.city}, {clinic?.attributes.state}
          </BodyText>
        </div>
        <div className="text-right">
          <Button size="medium" color="primary" onClick={onSelectDocuments}>
            Select Documents
          </Button>
        </div>
      </div>
      <div className="bg-blue-gray-100 p-[10px] rounded-[10px] flex items-center w-full">
        <PersonGray className="ml-1 min-w-[29px] min-h-[23px]" />
        <Typography className="ml-4">{getText()}</Typography>
      </div>
    </Card>
  )
}

export default PatientPortalLabResultsAccessDocumentCard
