export enum PatientPortalUserPaths {
  ROOT = "/patient_portal",
  ORDERS = "/patient_portal/accounts/:userId/orders",
  ORDER = "/patient_portal/accounts/:userId/orders/:orderId",
  SUPPORT = "/patient_portal/accounts/:userId/support",
  TRENDS = "/patient_portal/accounts/:userId/trends",
  DOCUMENTS = "/patient_portal/accounts/:userId/documents",
  ACCOUNTS = "/patient_portal/accounts",
  BLOOD_LABS = "/patient_portal/accounts/:userId/orders/:orderId/blood_lab_dashboard/:orderedResultId",
  BLOOD_LABS_SNAPSHOT = "/patient_portal/accounts/:userId/orders/:orderId/blood_lab_dashboard_snapshot/:snapshotId",
  LAB_RESULTS_ACCESS = "/patient_portal/accounts/:userId/lab_result_access",
}
