// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"

import DateInput, {
  DateInputProps,
} from "app/main/patient-checkout/fields/DateInput"
import { InjectedProps, WrapperProps } from "formsy-react/dist/withFormsy"
import pick from "lodash/pick"

import BodyText from "../design-system/BodyText"

// Declare the fields we pass through to DateInput so we can pick them out.
// Add more as needed.
const DATE_PICKER_PROP_KEYS = ["required", "fullWidth", "InputProps"] as const

export type DatePickerFormsyProps = WrapperProps<string | number | Date> &
  Pick<DateInputProps, typeof DATE_PICKER_PROP_KEYS[number]>

function DatePickerFormsy({
  value = "",
  name,
  setValue,
  errorMessage,
  ...props
}: DatePickerFormsyProps & InjectedProps<string | number | Date>) {
  const datePickerProps = pick(props, DATE_PICKER_PROP_KEYS)

  function changeValue(date: MaterialUiPickersDate | null) {
    setValue(date ?? "")
  }

  return (
    <div>
      <DateInput
        {...datePickerProps}
        name={name}
        value={value}
        onChange={changeValue}
        error={Boolean(errorMessage)}
      />
      {errorMessage && (
        <BodyText className="text-red-500 text-xs px-2 pt-1" weight="semibold">
          {errorMessage}
        </BodyText>
      )}
    </div>
  )
}

export default withFormsy(DatePickerFormsy)
