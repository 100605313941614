import { ReactComponent as BiomarkerIcon } from "app/assets/icons/biomarker.svg"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { primaryColor } from "app/theme"
import { Biomarker, BiomarkerIdentifier } from "types/biomarker"

import { getBiomarkerDisplayName } from "../utils"
import SelectedPill from "./SelectedPill"

export default function BiomarkerPill({
  biomarkerIdentifier,
  ...props
}: {
  biomarkerIdentifier: BiomarkerIdentifier
  onRemove: () => void
  hideRemoveButton?: boolean
}) {
  const biomarker = useCachedResource<Biomarker>(biomarkerIdentifier)

  if (!biomarker) {
    return null
  }

  return (
    <SelectedPill
      label={getBiomarkerDisplayName(biomarker)}
      fill={false}
      icon={<BiomarkerIcon fill={primaryColor} />}
      {...props}
    />
  )
}
