import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { Theme } from "@material-ui/core"

import BloodLabDashboardNavBar from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/BloodLabDashboardNavBar"
import BloodLabDashboardsPatientHero from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/BloodLabDashboardsPatientHero"
import useBundleResultsPdfPayload from "app/main/blood-lab-dashboards/BloodLabDashboardsPdfExport/use-bundle-results-pdf-payload"
import { BloodLabsDashboardsSnapshotPatientReportContainer } from "app/main/blood-lab-dashboards/BloodLabDashboardsSnapshotPatientPreview/BloodLabDashboardsSnapshotPatientReportContainer"
import useBiomarkerCustomDescriptions from "app/main/blood-lab-dashboards/hooks/use-biomarker-custom-descriptions"
import { useBloodLabDashboardsSnapshot } from "app/main/blood-lab-dashboards/hooks/use-blood-lab-dashboards-snapshot"
import Error404Page from "app/main/errors/404/Error404Page"
import Error500Page from "app/main/errors/500/Error500Page"
import PatientPortalBloodDashboardBackground from "app/patient-portal/PatientPortalBloodDashboardBackground"
import { sortBloodReportResultValues } from "app/patient-portal/blood-lab-dashboard/utils"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import makeAppStyles from "app/utils/makeAppStyles"
import { Clinic } from "types/clinic"
import { PatientSettings } from "types/patient"
import { Patient } from "types/patient"
import { Practitioner } from "types/practitioner"

const HEIGHT_OF_NAVBAR = 80

const useStyles = makeAppStyles((theme: Theme) => ({
  container: {
    width: "100%",
    maxWidth: 1500,
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingTop: HEIGHT_OF_NAVBAR + 57, // 57px is to offset the padding of the hero component
    paddingLeft: 42,
    paddingRight: 42,
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 22,
      paddingRight: 22,
    },
  },
}))

export const BloodLabDashboardSnapshot = ({
  isPatientPreview,
}: {
  isPatientPreview: boolean
}) => {
  const classes = useStyles()
  const { snapshotId } = useParams<{ snapshotId: string }>()

  const {
    bloodReportResults,
    allBiomarkers,
    isBloodReportResultsLoading,
    bloodReportResultsError,
    getMatchingBloodReportResultByBiomarkerId,
    snapshot,
    isSnapshotLoading,
    snapshotError,
    orderedResult,
    sortedBodySystems,
    missingBiomarkers,
    practitioner: practitionerFromHook,
    userResults,
    clinic: clinicFromHook,
    labCompany,
    getBloodReportResultsByLabTest,
    labTests,
    usesClinicOptimalRange,
  } = useBloodLabDashboardsSnapshot(snapshotId)

  const patient = useCachedResource<Patient>(
    snapshot?.relationships.patient.data
  )
  const practitionerFromPatient = useCachedResource<Practitioner>(
    patient?.relationships.practitioner.data
  )
  const clinicFromPractitioner = useCachedResource<Clinic>(
    practitionerFromPatient?.relationships.clinic.data
  )

  const practitioner = isPatientPreview
    ? practitionerFromPatient
    : practitionerFromHook

  const clinic = isPatientPreview ? clinicFromPractitioner : clinicFromHook

  const {
    biomarkerCustomDescriptionsMapping,
    isLoading: isDescriptionsLoading,
  } = useBiomarkerCustomDescriptions({
    isPatientPreview: isPatientPreview,
    biomarkerIds: allBiomarkers.map((biomarker) => biomarker.id),
    practitionerId: isPatientPreview ? undefined : practitioner?.id,
  })

  const isLoading =
    isBloodReportResultsLoading || isSnapshotLoading || isDescriptionsLoading

  const abnormalBloodReportResults = useMemo(() => {
    return bloodReportResults
      .filter(
        (bloodReportResult) =>
          bloodReportResult.attributes.out_of_standard_range
      )
      .sort((a, b) => sortBloodReportResultValues(a, b))
  }, [bloodReportResults])

  const showHighLowDescriptions = useMemo(() => {
    return patient?.attributes.patient_settings.includes(
      PatientSettings.INCLUDE_HIGH_LOW_DESCRIPTIONS_ON_BLOOD_REPORTS
    )
  }, [patient])

  const bloodReportResultsByLabTest = useMemo(() => {
    return labTests.map((labTest) => ({
      labTestName: labTest.attributes.name,
      bloodReportResults: getBloodReportResultsByLabTest(labTest),
    }))
  }, [labTests, getBloodReportResultsByLabTest])

  const pdfPayload = useBundleResultsPdfPayload({
    userResults,
    outOfRangeBloodReportResults: abnormalBloodReportResults,
    bloodReportResultsByLabTest,
    orderedResult,
    labCompany,
    patient,
    practitioner,
    clinic,
    snapshotId,
    biomarkerCustomDescriptionsMapping,
    isLoading,
    usesClinicOptimalRange: isPatientPreview
      ? usesClinicOptimalRange
      : undefined,
  })

  if (snapshotError || bloodReportResultsError) {
    const errorStatus =
      snapshotError?.response?.status ||
      bloodReportResultsError?.response?.status
    if (errorStatus === 404) {
      return <Error404Page />
    } else {
      return <Error500Page />
    }
  }

  return (
    <div className="fs-unmask">
      <BloodLabDashboardNavBar />
      <PatientPortalBloodDashboardBackground>
        <div className={classes.container}>
          <BloodLabDashboardsPatientHero
            orderedResult={orderedResult}
            patient={patient}
            missingBiomarkers={missingBiomarkers}
            clinicLogoUrl={clinic?.attributes.logo || ""}
            practitionerTitledName={
              practitioner?.attributes.titled_full_name || ""
            }
            isLoading={isLoading}
            isPatientPreview={isPatientPreview}
            pdfPayload={pdfPayload}
          />
          <BloodLabsDashboardsSnapshotPatientReportContainer
            bloodReportResults={bloodReportResults}
            abnormalBloodReportResults={abnormalBloodReportResults}
            bodySystems={sortedBodySystems}
            isLoading={isLoading}
            clinicName={clinic?.attributes.name}
            practitioner={practitioner}
            biomarkers={allBiomarkers}
            snapshotCreationDate={snapshot?.attributes.created_at ?? ""}
            labCompanyName={labCompany?.attributes.name}
            getMatchingBloodReportResultByBiomarkerId={
              getMatchingBloodReportResultByBiomarkerId
            }
            showHighLowDescriptions={showHighLowDescriptions || false}
            orderedResultFile={
              orderedResult?.attributes.result_file ?? undefined
            }
          />
        </div>
      </PatientPortalBloodDashboardBackground>
    </div>
  )
}
