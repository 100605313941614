import React, { useMemo, useState } from "react"

import clsx from "clsx"
import { isEmpty } from "lodash"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { faChartLine } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Theme, useMediaQuery } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import { FadeProps } from "@material-ui/core/Fade"
import Grow from "@material-ui/core/Grow"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { Skeleton } from "@material-ui/lab"

import { ReactComponent as BarsDarkIcon } from "app/assets/icons/rupa-blood-dashboards/bars.svg"
import { ReactComponent as QuestionCircleOutlinedIcon } from "app/assets/icons/rupa-blood-dashboards/question-circle-outlined.svg"
import { ReactComponent as QuestionSquareDark } from "app/assets/icons/rupa-blood-dashboards/question-square-dark.svg"
import SampleTypePill from "app/components/SampleTypePill"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import { Dialog } from "app/components/modals"
import BloodLabDashboardsBiomarkerStatusIndicator from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/statuses/BloodLabDashboardsBiomarkerStatusIndicator"
import NumericBiomarkerGraphic from "app/main/blood-lab-dashboards/NumericBiomarkerGraphic/NumericBiomarkerGraphic"
import {
  ResultsOverTimeDateGrouping,
  ResultsOverTimeResultData,
} from "app/main/patient-orders/trends/types/types"
import ResultsOverTimeChart from "app/main/results-over-time/ResultsOverTimeChart"
import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import ContentCard from "app/patient-portal/blood-lab-dashboard/modals/ContentCard"
import DiscreteResultDetailLegalDisclaimers from "app/patient-portal/blood-lab-dashboard/modals/DiscreteResultDetailLegalDisclaimers"
import HighLowDescriptionSymptomCard from "app/patient-portal/blood-lab-dashboard/modals/HighLowDescriptionSymptomCard"
import useAboutBloodLabsDashboardsModal from "app/patient-portal/blood-lab-dashboard/modals/use-about-blood-labs-dashboards-modal"
import { colors, navy, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { getValueForNumericBiomarkerGraphic } from "../../NumericBiomarkerGraphic/utils"
import useBiomarkerCustomDescriptions from "../../hooks/use-biomarker-custom-descriptions"
import { parseStringValueToFloat } from "../../utils"
import BiomarkerDescription from "./BiomarkerDescription"

interface BloodReportResultDetailModalProps {
  biomarkerId?: string
  biomarkerShortName?: string
  biomarkerLongName?: string
  biomarkerDescription?: string
  biomarkerShortDescription?: string
  biomarkerLowDescription?: string
  biomarkerHighDescription?: string
  biomarkerLowSymptoms?: string
  biomarkerHighSymptoms?: string
  optimalRangeMin?: string
  optimalRangeMax?: string
  standardRangeMin?: string
  standardRangeMax?: string
  value?: string
  alternateValue?: string
  isRangeValue: boolean
  unit?: string
  status: BiomarkerStatus
  clinicName: string
  resultsOverTime?: ResultsOverTimeResultData[]
  resultsGroupedBy?: ResultsOverTimeDateGrouping
  activeResultDate: string
  sampleTypes?: string[] | null
  labCompanyName: string
  onClose: () => void
  showHighLowDescriptions: boolean
}

const useStyles = makeAppStyles((theme: Theme) => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 12,
    border: "4px solid white",
    boxShadow: shadows["2xl"],
    width: "100%",
    maxWidth: 800,
  },
  closeButton: {
    color: navy,
    position: "relative",
    top: 1,
    right: 0,
    marginRight: -12,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    backgroundColor: "white",
    alignItems: "center",
    padding: "16px 24px",
  },
  titleText: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    marginRight: 24,
  },
  content: {
    padding: 22.5,
    backgroundColor: colors.trueGray[100],
    display: "flex",
    flexDirection: "column",
    gap: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 12,
    },
  },
  optimalRangeAboutBtn: {
    fontSize: 14,
    fontWeight: 400,
  },
  biomarkerValue: {
    fontSize: 22,
    fontWeight: 600,
    color: navy,
  },
  biomarkerUnits: {
    fontSize: 13,
    color: colors.blueGray[400],
    fontWeight: 600,
    marginLeft: 3,
  },
  redValueColor: {
    color: colors.red[900],
  },
  limeValueColor: {
    color: colors.lime[900],
  },
  greenValueColor: {
    color: colors.emerald[900],
  },
  redStatusColor: {
    color: colors.red[700],
  },
  limeStatusColor: {
    color: colors.lime[700],
  },
  greenStatusColor: {
    color: colors.emerald[700],
  },
}))

const GrowTransition = React.forwardRef<unknown, FadeProps>(function Transition(
  props,
  ref
) {
  return <Grow ref={ref} {...props} timeout={500} />
})

const BloodLabDashboardsBloodReportResultDetailModal = ({
  biomarkerId,
  biomarkerShortName,
  biomarkerLongName,
  biomarkerDescription,
  biomarkerShortDescription,
  biomarkerLowDescription,
  biomarkerHighDescription,
  biomarkerLowSymptoms,
  biomarkerHighSymptoms,
  optimalRangeMin,
  optimalRangeMax,
  standardRangeMin,
  standardRangeMax,
  value,
  alternateValue,
  isRangeValue,
  unit,
  clinicName,
  status,
  resultsOverTime,
  resultsGroupedBy,
  activeResultDate,
  labCompanyName,
  sampleTypes,
  onClose,
  showHighLowDescriptions,
}: BloodReportResultDetailModalProps) => {
  const classes = useStyles()

  const modal = useModal()
  const muiDialogControl = muiDialog(modal)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const [resultData, setResultData] = useState({
    optimalRangeMin,
    optimalRangeMax,
    standardRangeMin,
    standardRangeMax,
    value,
    alternateValue,
    isRangeValue,
    unit,
    status,
    activeResultDate,
    labCompanyName,
  })

  const { biomarkerCustomDescriptions, isLoading } =
    useBiomarkerCustomDescriptions({
      isPatientPreview: false,
      biomarkerIds: biomarkerId ? [biomarkerId] : [],
    })

  const aboutBloodDashboardsModal = useAboutBloodLabsDashboardsModal()

  const title =
    biomarkerLongName === biomarkerShortName
      ? biomarkerLongName
      : `${biomarkerLongName} (${biomarkerShortName})`

  const isOptimalRangePresent =
    Boolean(resultData.optimalRangeMax) || Boolean(resultData.optimalRangeMin)

  const handleTimepointClick = (resultId?: string) => {
    if (resultId && resultsOverTime?.length) {
      const newActiveResult = resultsOverTime.find((r) => r.id === resultId)
      if (newActiveResult) {
        setResultData({
          ...resultData,
          activeResultDate: newActiveResult.created_at,
          value: newActiveResult.value || "",
          isRangeValue: newActiveResult.is_range_value,
          alternateValue: newActiveResult.alternate_value,
          status: newActiveResult.status,
          labCompanyName: newActiveResult.lab_company_name,
          standardRangeMin: newActiveResult.standard_range_min,
          standardRangeMax: newActiveResult.standard_range_max,
        })
      }
    }
  }

  const biomarkerCustomDescription = useMemo(() => {
    if (
      biomarkerCustomDescriptions.length &&
      biomarkerId &&
      !isEmpty(biomarkerCustomDescriptions[0].attributes.description)
    ) {
      return biomarkerCustomDescriptions[0].attributes.description
    } else {
      return undefined
    }
  }, [biomarkerCustomDescriptions])

  const showNumericRangeBar = useMemo(() => {
    return (
      (resultData.standardRangeMin || resultData.standardRangeMax) &&
      !Number.isNaN(parseStringValueToFloat(resultData.value))
    )
  }, [resultData])

  const formatDate = (date: string) => {
    const dateObject = new Date(date)

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObject
    )

    return formattedDate
  }

  return (
    <Dialog
      {...muiDialogControl}
      disableEnforceFocus
      disableEscapeKeyDown
      fullWidth
      maxWidth="md"
      className={"fs-unmask"}
      classes={{
        paper: classes.dialogPaper,
      }}
      TransitionComponent={GrowTransition}
    >
      <TitleSection
        title={title}
        biomarkerStatus={resultData.status}
        value={resultData.value}
        unit={resultData.unit}
        alternateValue={resultData.alternateValue}
        sampleTypes={sampleTypes}
        onClose={onClose}
      />

      <MuiDialogContent className={classes.content}>
        {resultsOverTime && resultsOverTime.length > 1 && (
          <ContentCard
            title="Your Levels Over Time"
            icon={<FontAwesomeIcon className="text-base" icon={faChartLine} />}
          >
            <ResultsOverTimeChart
              onTimepointClick={handleTimepointClick}
              groupedBy={resultsGroupedBy || ResultsOverTimeDateGrouping.MONTH}
              resultData={resultsOverTime}
              activeDotLabel={activeResultDate}
              clinicName={clinicName}
            />
          </ContentCard>
        )}

        <ContentCard
          title={`What's ${biomarkerShortName}?`}
          icon={<QuestionSquareDark />}
        >
          {isLoading ? (
            <Skeleton animation="wave" height={30} width="80%" />
          ) : (
            <BiomarkerDescription
              biomarkerDescription={biomarkerDescription}
              biomarkerShortDescription={biomarkerShortDescription}
              biomarkerCustomDescription={biomarkerCustomDescription}
            />
          )}
        </ContentCard>

        {resultData.status &&
          resultData.value &&
          (resultData.standardRangeMax ||
            resultData.standardRangeMin ||
            resultData.optimalRangeMax ||
            resultData.optimalRangeMin) && (
            <ContentCard
              title={`Your Levels of ${biomarkerShortName} on ${formatDate(
                resultData.activeResultDate
              )}`}
              icon={<BarsDarkIcon />}
              actionButton={
                isOptimalRangePresent ? (
                  <DesignSystemButton
                    color="text"
                    onClick={() =>
                      aboutBloodDashboardsModal.show({
                        title: "About Optimal Ranges",
                        showAboutTheDashboardSection: false,
                        onClose: () => aboutBloodDashboardsModal.hide(),
                      })
                    }
                    endIcon={<QuestionCircleOutlinedIcon />}
                    loading={false}
                    className={classes.optimalRangeAboutBtn}
                  >
                    Optimal Range
                  </DesignSystemButton>
                ) : (
                  <></>
                )
              }
            >
              {isLoading ? (
                <Skeleton animation="wave" height={80} width="100%" />
              ) : (
                <>
                  {showNumericRangeBar && (
                    <div>
                      <NumericBiomarkerGraphic
                        normalMax={resultData.standardRangeMax || ""}
                        normalMin={resultData.standardRangeMin || ""}
                        optimalMax={resultData.optimalRangeMax || ""}
                        optimalMin={resultData.optimalRangeMin || ""}
                        value={getValueForNumericBiomarkerGraphic(
                          resultData.isRangeValue,
                          resultData.value
                        )}
                        singleLabel={isMobile}
                        unit={resultData.unit}
                        labCompanyName={resultData.labCompanyName}
                        status={status}
                      />
                    </div>
                  )}
                  {showHighLowDescriptions && (
                    <HighLowDescriptionSymptomCard
                      biomarkerStatus={resultData.status}
                      biomarkerCustomDescription={
                        biomarkerCustomDescriptions?.[0]
                      }
                      biomarkerShortName={biomarkerShortName}
                      biomarkerLowSymptoms={biomarkerLowSymptoms}
                      biomarkerHighSymptoms={biomarkerHighSymptoms}
                      biomarkerLowDescription={biomarkerLowDescription}
                      biomarkerHighDescription={biomarkerHighDescription}
                    />
                  )}
                </>
              )}
            </ContentCard>
          )}

        {!isLoading && (
          <DiscreteResultDetailLegalDisclaimers
            biomarkerCustomDescription={biomarkerCustomDescriptions?.[0]}
            clinicName={clinicName}
            isOptimalRangePresent={isOptimalRangePresent}
          />
        )}
      </MuiDialogContent>
    </Dialog>
  )
}

const TitleSection = ({
  title,
  biomarkerStatus,
  value,
  alternateValue,
  unit,
  sampleTypes,
  onClose,
}) => {
  const classes = useStyles()

  const valueStyle = {
    [BiomarkerStatus.HIGH]: classes.redValueColor,
    [BiomarkerStatus.LOW]: classes.redValueColor,
    [BiomarkerStatus.ABOVE_OPTIMAL]: classes.limeValueColor,
    [BiomarkerStatus.BELOW_OPTIMAL]: classes.limeValueColor,
    [BiomarkerStatus.NORMAL]: classes.greenValueColor,
    [BiomarkerStatus.OPTIMAL]: classes.greenValueColor,
    [BiomarkerStatus.ABNORMAL]: classes.redValueColor,
  }

  const statusStyle = {
    [BiomarkerStatus.HIGH]: classes.redStatusColor,
    [BiomarkerStatus.LOW]: classes.redStatusColor,
    [BiomarkerStatus.ABOVE_OPTIMAL]: classes.limeStatusColor,
    [BiomarkerStatus.BELOW_OPTIMAL]: classes.limeStatusColor,
    [BiomarkerStatus.NORMAL]: classes.greenStatusColor,
    [BiomarkerStatus.OPTIMAL]: classes.greenStatusColor,
    [BiomarkerStatus.ABNORMAL]: classes.redStatusColor,
  }

  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.titleContainer}>
      <div className={classes.titleText}>
        <div className="flex gap-1">
          <BodyText weight="bold" size="lg">
            {title}
          </BodyText>
          <div className="flex gap-0">
            {sampleTypes?.map((sampleType) => (
              <SampleTypePill
                name={sampleType}
                key={sampleType}
                className="ml-0 mr-1"
              />
            ))}
          </div>
        </div>

        <div>
          <BloodLabDashboardsBiomarkerStatusIndicator
            biomarkerStatus={biomarkerStatus}
          />{" "}
          <span
            className={clsx(
              classes.biomarkerValue,
              biomarkerStatus ? valueStyle[biomarkerStatus] : ""
            )}
          >
            {value ?? alternateValue}
          </span>
          {value && (
            <span
              className={clsx(
                classes.biomarkerUnits,
                biomarkerStatus ? statusStyle[biomarkerStatus] : ""
              )}
            >
              {unit}
            </span>
          )}
        </div>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}

const BloodLabDashboardsBloodReportResultDetailModalNiceModal =
  NiceModal.create<BloodReportResultDetailModalProps>(
    BloodLabDashboardsBloodReportResultDetailModal
  )

export default BloodLabDashboardsBloodReportResultDetailModalNiceModal
