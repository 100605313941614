import { useEffect, useMemo, useState } from "react"

import { Dialog, ModalContent, ModalHeader } from "app/components/modals"
import { arraysEqual } from "app/utils/equality-utils"
import makeAppStyles from "app/utils/makeAppStyles"

import BiomarkerSearch from "./BiomarkerSearch/BiomarkerSearch"
import Panels from "./Panels/Panels"
import SelectionPills from "./SelectionPills/SelectionPills"
import useExistingBiomarkers from "./hooks/use-existing-biomarkers"
import { PanelBuilderIdentifier } from "./types"
import usePanelBuilderModalCloseConfirmation from "./use-panel-builder-modal-close-confirmation"
import { trackSelectBiomarkerOrGrouping } from "./utils"

const useStyles = makeAppStyles((theme) => ({
  dialog: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      margin: 0,
      width: "100vw",
    },
  },
  headerContent: {
    maxWidth: 820,
  },
}))

export const PanelBuilderModal = ({
  open,
  onClose,
  existingBundleId,
  existingBundleName,
  existingBundleRequestedBiomarkerIds,
  existingBundleRequestedBiomarkerGroupingIds,
  existingPanelLabCompanyKey,
}: {
  open: boolean
  onClose: () => void
  existingBundleId?: number
  existingBundleName?: string
  existingBundleRequestedBiomarkerIds?: string[]
  existingBundleRequestedBiomarkerGroupingIds?: string[]
  existingPanelLabCompanyKey?: string
}) => {
  const classes = useStyles()
  const { isLoading: existingLoading, biomarkerIdentifiers: existing } =
    useExistingBiomarkers(
      existingBundleRequestedBiomarkerIds,
      existingBundleRequestedBiomarkerGroupingIds
    )
  const [selected, setSelected] = useState<PanelBuilderIdentifier[]>([])

  const isDirty = useMemo(() => {
    return !arraysEqual(
      existing.map((i) => i.id),
      selected.map((i) => i.id)
    )
  }, [existing, selected])

  function onSelect(identifier: PanelBuilderIdentifier) {
    trackSelectBiomarkerOrGrouping(identifier)
    setSelected((prev) => {
      // Don't add duplicates
      if (prev.find((b) => b.id === identifier.id)) return prev
      return [...prev, identifier]
    })
  }

  function onRemove(identifier: PanelBuilderIdentifier) {
    setSelected((prev) => prev.filter((b) => b.id !== identifier.id))
  }

  const closeConfirmationModal = usePanelBuilderModalCloseConfirmation()
  const handleClose = () => {
    if (isDirty) {
      closeConfirmationModal.show({
        onClose: () => closeConfirmationModal.remove(),
        handleConfirm: () => {
          closeConfirmationModal.remove()
          onClose()
        },
      })
    } else {
      onClose()
    }
  }

  useEffect(() => {
    if (existingLoading || selected.length) return
    // Add to selected biomarkers and biomarker groupings only if they are not already there
    setSelected(existing)
  }, [existingLoading])

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="lab-test-panel-builder-title"
        open={open}
        className={classes.dialog}
        classes={{ paper: classes.dialog }}
        maxWidth="lg"
      >
        <ModalHeader
          title={existingBundleName ?? "Create Blood Panel"}
          onClose={handleClose}
          size={"xl"}
          subtitle={
            <span className={classes.headerContent}>
              Choose multiple biomarkers, compare custom panels from our various
              labs, and save them as a custom blood panel for easy one-click
              ordering from your Favorites.
            </span>
          }
        />
        <ModalContent>
          <div className="min-h-[80vh]">
            <BiomarkerSearch
              autoFocus={true}
              handleSelectItem={onSelect}
              selectedIdentifiers={selected}
            />
            <SelectionPills selectedItems={selected} onRemove={onRemove} />
            <Panels
              existingBundleId={existingBundleId}
              existingBundleName={existingBundleName}
              selectedItems={selected}
              existingPanelLabCompanyKey={existingPanelLabCompanyKey}
              isDirty={isDirty}
              onClose={onClose}
            />
          </div>
        </ModalContent>
      </Dialog>
    </>
  )
}
