import { memo } from "react"
import { useDispatch } from "react-redux"

import clsx from "clsx"

import { Icon, IconButton, Snackbar, SnackbarContent } from "@material-ui/core"
import { amber, blue, green } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core/styles"

import useAppSelector from "app/hooks/useAppSelector"
import * as Actions from "app/store/actions"

export type FuseMessageVariant = "success" | "error" | "info" | "warning"

const useStyles = makeStyles((theme) => ({
  root: {},
  success: {
    backgroundColor: green[600],
    color: "#FFFFFF",
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark),
  },
  info: {
    backgroundColor: blue[600],
    color: "#FFFFFF",
  },
  warning: {
    backgroundColor: amber[600],
    color: "#FFFFFF",
  },
}))

const variantIcon: Record<FuseMessageVariant, string> = {
  success: "check_circle",
  warning: "warning",
  error: "error_outline",
  info: "info",
}

function FuseMessage(props) {
  const dispatch = useDispatch()
  const state = useAppSelector(({ fuse }) => fuse.message.state)
  const options = useAppSelector(({ fuse }) => fuse.message.options)

  const classes = useStyles()

  return (
    <Snackbar
      {...options}
      open={state}
      onClose={() => dispatch(Actions.hideMessage())}
      classes={{
        root: classes.root,
      }}
    >
      <SnackbarContent
        className={clsx(classes[options.variant])}
        message={
          <div className="flex items-center">
            {variantIcon[options.variant] && (
              <Icon className="mr-2" color="inherit">
                {variantIcon[options.variant]}
              </Icon>
            )}
            {/* Noticed occasionally an object was passed in for message but not sure the source. This protects from an unrecoverable error. */}
            {typeof options.message === "string" ? options.message : ""}
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(Actions.hideMessage())}
          >
            <Icon>close</Icon>
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

export default memo(FuseMessage)
