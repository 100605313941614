import { useSelector } from "react-redux"

import Cookies from "js-cookie"

import {
  IMPERSONATING_FIELD,
  IMPERSONATING_USER_FIELD,
} from "app/auth/services/simplejwt-auth-service"
import Banner from "app/main/dashboard/Banner"
import { colors } from "app/theme"
import { RootState, User } from "app/types"

import BodyText from "./design-system/BodyText"

export default function ImpersonatingBanner() {
  const impersonating = Cookies.get(IMPERSONATING_FIELD)
  if (!impersonating) {
    return null
  }

  return <ImpersonatingBannerInner />
}

function ImpersonatingBannerInner() {
  const { email: userEmail } = useSelector<RootState, User>(
    ({ profile }) => profile.user || {}
  )

  const impersonatingUser = Cookies.get(IMPERSONATING_USER_FIELD)
  let impersonatingText = "You"
  if (impersonatingUser !== "null") {
    impersonatingText += `, ${impersonatingUser},`
  }
  impersonatingText += " are impersonating"
  if (userEmail) {
    impersonatingText += ` ${userEmail}!`
  } else {
    impersonatingText += " this user!"
  }

  return (
    <Banner
      backgroundColor={colors.red[50]}
      borderColor={colors.red[400]}
      style={{ zIndex: 100 }}
    >
      <div>
        <BodyText weight="semibold" size="lg">
          WARNING:
        </BodyText>
        <BodyText weight="semibold" size="md">
          {impersonatingText}
        </BodyText>
        <BodyText size="md">
          Be very careful. All data changes are saved. Make sure to log out once
          you are done.
        </BodyText>
      </div>
    </Banner>
  )
}
