import { useEffect, useState } from "react"

import { Dialog, Typography, styled, useMediaQuery } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import { fade } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"

import AlertTriangleGrayIcon from "app/assets/icons/alert-triangle-gray.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import TextField from "app/components/forms/TextFieldComponent"
import { colors, navy, textPrimaryColor } from "app/theme"

import { SnippetTypes } from "../settings/SavedTextSnippets/constants"
import { InsertSnippetButton } from "./InsertSnippetButton"

const ActionsContainer = styled("div")({
  display: "flex",
  padding: "12px 20px",
  justifyContent: "flex-end",
})

const StyledDesignSystemButton = styled(DesignSystemButton)({
  marginRight: 12,
})

const TitleAndInfoContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  marginRight: 24,
})

const CloseButton = styled(IconButton)(({ isMobile }) => ({
  color: navy,
  position: isMobile ? "absolute" : "relative",
  top: 1,
  right: isMobile ? 12 : 0,
  marginRight: -12,
}))

const StyledMuiDialogTitle = styled("div")(({ isMobile, info }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: isMobile ? "wrap" : "nowrap",
  backgroundColor: "white",
  borderBottomWidth: 1,
  borderColor: colors.blueGray[200],
  alignItems: "center",
  padding: info ? "16px 24px" : "8px 24px",
}))

const ContentWrapper = styled("div")({
  backgroundColor: colors.blueGray[100],
  padding: 16,
})

export default function NotesModal({
  title,
  info,
  text,
  placeholder,
  warningText,
  open,
  onClose,
  onSave,
  showInsertSnippetButton = false,
}) {
  const [notes, setNotes] = useState(text)

  useEffect(() => {
    setNotes(text)
  }, [text])

  const handleAdd = () => {
    onSave(notes)
    onClose()
  }

  const insertSnippetText = (snippetText) => {
    const updatedNotes = notes ? `${notes} ${snippetText}` : snippetText
    setNotes(updatedNotes)
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="notes-modal-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <TitleSection title={title} info={info} onClose={onClose} />

      <ContentWrapper>
        {showInsertSnippetButton ? (
          <InsertSnippetButton
            onAdd={insertSnippetText}
            snippetType={SnippetTypes.NOTES_TO_PATIENT}
          />
        ) : null}

        <TextField
          value={notes || ""}
          fullWidth
          multiline
          onChange={(e) => setNotes(e.target.value)}
          placeholder={placeholder}
          variant="outlined"
          className="rounded fs-exclude bg-white"
          rows={15}
        />
        {warningText && (
          <div
            className="rounded flex items-start p-3 mt-4"
            style={{
              backgroundColor: fade(textPrimaryColor, 0.1),
              borderLeft: `solid 4px ${textPrimaryColor}`,
            }}
          >
            <img
              src={AlertTriangleGrayIcon}
              alt="Exclamation mark inside a triangle"
              className="mr-4 mt-1 w-9"
            />
            <Typography color="textPrimary" className="text-sm font-semibold">
              {warningText}
            </Typography>
          </div>
        )}
      </ContentWrapper>

      <ActionsContainer>
        <StyledDesignSystemButton color="noaction" onClick={onClose}>
          Cancel
        </StyledDesignSystemButton>
        <DesignSystemButton color="primary" onClick={handleAdd}>
          Save Notes
        </DesignSystemButton>
      </ActionsContainer>
    </Dialog>
  )
}

const TitleSection = ({ title, info, onClose }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  const closeButton = onClose && (
    <CloseButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      isMobile={isMobile}
    >
      <CloseIcon />
    </CloseButton>
  )

  return (
    <StyledMuiDialogTitle disableTypography isMobile={isMobile} info={info}>
      <TitleAndInfoContainer>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
        <BodyText size="base">{info}</BodyText>
      </TitleAndInfoContainer>
      {closeButton}
    </StyledMuiDialogTitle>
  )
}
