import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"

import { makeStyles } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import InfoIcon from "app/assets/icons/info-gray-circle.svg"
import Markdown from "app/components/Markdown"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import Button from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { FormTextField, SelectFormField } from "app/components/forms/fields"
import { Dialog, ModalActions, ModalContent } from "app/components/modals"
import ClinicInviteModal from "app/components/modals/ClinicInviteModal"
import { submitOnboardingSurvey } from "app/main/dashboard/store/actions"
import { Practitioner } from "app/types"

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      minWidth: 700,
    },
  },
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
  },
  infoIcon: {
    marginLeft: 8,
  },
  formLabel: {
    display: "inline-flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10,
  },
  selectFormField: ({ isMobile }: { isMobile: boolean }) => ({
    marginBottom: isMobile ? "5px" : "25px",
  }),
  displayText: {
    textAlign: "center",
  },
  dialogContent: ({ isMobile }: { isMobile: boolean }) => ({
    padding: isMobile ? "1 !important" : 22.5,
  }),
}))

export default function OnboardingSurveyModal({
  open,
  practitioner,
}: {
  open: boolean
  practitioner: Practitioner
}) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isClinicInviteModalOpen, setIsClinicInviteModalOpen] = useState(false)

  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))
  const classes = useStyles({ isMobile })

  const onSubmit = useCallback(
    (data) => {
      setIsSubmitting(true)
      dispatch(submitOnboardingSurvey(data, practitioner))

      // When the practitioner submits their onboarding survey, we automatically
      // display the ClinicInviteModal if their responses indicate that > 1
      // clinic staff or ordering practitioner work at their clinic.
      if (
        data.onboarding_survey_how_many_clinic_staff +
          data.onboarding_survey_how_many_ordering_practitioners >
        1
      ) {
        setIsClinicInviteModalOpen(true)
      }
    },
    [setIsSubmitting, practitioner]
  )

  return (
    <>
      <Dialog
        open={open}
        scroll="body"
        aria-labelledby="onboarding-survey-title"
        aria-describedby="onboarding-survey-description"
        className={classes.root}
        maxWidth="md"
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <MuiDialogContent className={classes.dialogContent}>
          <div className="header">
            <DisplayText
              size="xl"
              weight="semibold"
              id="onboarding-survey-title"
              className={classes.displayText}
            >
              Customize Your Rupa Portal 👩‍⚕️
            </DisplayText>
            <BodyText
              className={classes.displayText}
              id="onboarding-survey-description"
            >
              We’ll help you get started based on your responses.
            </BodyText>
          </div>
        </MuiDialogContent>
        <Formsy
          onValidSubmit={onSubmit}
          onValid={() => setIsValid(true)}
          onInvalid={() => setIsValid(false)}
          className="flex flex-col justify-center w-full"
        >
          <ModalContent backgroundColor="white" borderTop={"0"}>
            {practitioner.onboarding_survey_questions.map(
              ({ label, name, type, options, info }) => {
                if (type) {
                  return (
                    <FormTextField
                      name={name}
                      type={type}
                      label={label}
                      inputProps={{ min: 0 }}
                    />
                  )
                }

                if (options) {
                  return (
                    <SelectFormField
                      className={classes.selectFormField}
                      label={
                        <span className={classes.formLabel}>
                          {label}
                          {info && (
                            <Tooltip
                              arrow
                              placement="bottom"
                              title={<Markdown>{info}</Markdown>}
                              interactive
                            >
                              <img
                                src={InfoIcon}
                                className={classes.infoIcon}
                                alt=""
                              />
                            </Tooltip>
                          )}
                        </span>
                      }
                      name={name}
                      required
                      key={name}
                    >
                      {options.map(({ value, label }) => (
                        <option key={label} value={value}>
                          {label}
                        </option>
                      ))}
                    </SelectFormField>
                  )
                }

                return null
              }
            )}
          </ModalContent>
          <ModalActions>
            <Button
              loading={isSubmitting}
              className="w-full"
              type="submit"
              disabled={!isValid}
            >
              Get Started
            </Button>
          </ModalActions>
        </Formsy>
      </Dialog>
      <ClinicInviteModal
        isOpen={isClinicInviteModalOpen}
        onClose={() => setIsClinicInviteModalOpen(false)}
      />
    </>
  )
}
