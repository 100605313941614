import * as React from "react"

import { Link } from "@material-ui/core"

import OrderingRightsTooltip from "app/components/OrderingAccess/OrderingRightsTooltip"
import OverrideAccessTooltip from "app/components/OrderingAccess/OverrideAccessTooltip"
import PhysicianAuthorizationTooltip from "app/components/OrderingAccess/PhysicianAuthorizationTooltip"
import { CompanyPanel } from "app/main/patient-checkout/sections/types"
import {
  AnyLimitedLabTest,
  CheckoutDraftState,
  CheckoutWarningTypes,
  LabCompany,
  LabTest,
  labTestLocation,
  Order,
  OrderedTest,
  OrderLineItem,
  PatientPortalOrder,
  PatientPortalOrderedTest,
  PatientPortalOrderLineItem,
  PhysicianAuthorizationType,
  Practitioner,
  PractitionerListItem,
  SigningPractitioner,
} from "app/types"
import {
  getPractitionerToEvalOrderingRights,
  isClinicStaff,
} from "app/utils/practitioner-utils"
import get from "lodash/get"
import groupBy from "lodash/groupBy"
import isString from "lodash/isString"
import some from "lodash/some"

import {
  BLOCKED_OVERRIDE_LAB_COMPANIES,
  LAB_COMPANY_KEY,
  LAB_TEST_BLOOD_TYPES,
  LABCORP_COMPANY_KEYS,
  ORDER_LINE_ITEM_TYPE,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "../constants"

// Individual group keys
export const INDIVIDUAL_CARD_KEY_PREFIX = "individual_card"
export const COMBO_GROUP_CARD_KEY_PREFIX = "combo_group_card"
export const NON_LAB_TEST_KEY = "non_lab_test"

// Lab company group keys
export const BOSTON_HEART_PANEL = "Boston Heart"
export const VIBRANT_AMERICA_PANEL = "Vibrant America"
export const ACCESS_MEDICAL_PANEL = "Access Medical Labs"
export const BIOREFERENCE_PANEL = "BioReference"
export const DHA_PANEL = "DHA"
export const LABCORP_PANEL = "Labcorp by Professional Co-Op"
export const LABCORP_INSURANCE_PANEL = "Labcorp"
export const LABCORP_BY_AML_PANEL = "Labcorp"
export const QUEST_PANEL = "Quest"
export const QUEST_INSURANCE_PANEL = "Quest"
export const EMPIRE_CITY_LABS_PANEL = "Empire City Labs"

export const LAB_COMPANY_PANELS = [
  ACCESS_MEDICAL_PANEL,
  BIOREFERENCE_PANEL,
  BOSTON_HEART_PANEL,
  VIBRANT_AMERICA_PANEL,
  DHA_PANEL,
  LABCORP_PANEL,
  LABCORP_INSURANCE_PANEL,
  LABCORP_BY_AML_PANEL,
  QUEST_PANEL,
  QUEST_INSURANCE_PANEL,
  EMPIRE_CITY_LABS_PANEL,
]

export function getPanelTitle(groupKey: CompanyPanel["groupKey"]) {
  if (
    groupKey in
    [
      LABCORP_PANEL,
      LABCORP_INSURANCE_PANEL,
      LABCORP_BY_AML_PANEL,
      QUEST_PANEL,
      QUEST_INSURANCE_PANEL,
    ]
  ) {
    return "Blood panel"
  }
  return `${groupKey} Panel`
}

// Items that can be grouped. We need this union because our lab test grouping logic
// is reused in lots of places using different types:
// 1. OrderLineItem: Order checkout
// 2. PatientPortalOrderLineItem: Patient portal
// 3. OrderedTest: Patient orders list
// 4. LabTest: Bundle builder
type Item =
  | OrderLineItem
  | PatientPortalOrderLineItem
  | OrderedTest
  | AnyLimitedLabTest

const getLineItemFromItem = (item: Item) => {
  if ("type" in item) {
    return item
  }

  return null
}

const getLabTestFromItem = (item: Item) => {
  if ("lab_company" in item) {
    return item
  }

  if ("lab_test" in item) {
    return item.lab_test
  }

  if (item.type === ORDER_LINE_ITEM_TYPE.LAB_TEST) {
    return item.ordered_test.lab_test
  }

  return null
}

/**
 * Returns True if the item should be part of the DHA combined panel
 * @param item
 */
export function isInDHAPanel(item: Item) {
  const labTest = getLabTestFromItem(item)
  if (!labTest) {
    return false
  }

  const lab_company = labTest.lab_company
  const lab_test_type = labTest.lab_test_types[0]

  return (
    lab_company.key === LAB_COMPANY_KEY.DHA_LABORATORY &&
    lab_test_type &&
    LAB_TEST_BLOOD_TYPES.some((type) => type === lab_test_type.key)
  )
}

/**
 * Returns True if the item should be part of the Empire combined panel
 * @param item
 */
export function isInEmpirePanel(item: Item) {
  const labTest = getLabTestFromItem(item)
  if (!labTest) {
    return false
  }

  const lab_company = labTest.lab_company
  const lab_test_type = labTest.lab_test_types[0]

  return (
    lab_company.key === LAB_COMPANY_KEY.EMPIRE_CITY_LABS &&
    lab_test_type &&
    LAB_TEST_BLOOD_TYPES.some((type) => type === lab_test_type.key)
  )
}

/**
 * Returns True if the item should be part of the Labcorp combined panel
 * @param item
 */
export function isInLabcorpPanel(item: Item) {
  // For labcorp, we include the blood draw fee within the panel
  const lineItem = getLineItemFromItem(item)
  if (
    lineItem &&
    lineItem.type === ORDER_LINE_ITEM_TYPE.LABCORP_BLOOD_DRAW_FEE
  ) {
    return true
  }

  const labTest = getLabTestFromItem(item)
  if (!labTest) {
    return false
  }

  const lab_company = labTest.lab_company
  const lab_test_type = labTest.lab_test_types[0]

  return (
    lab_company.key === LAB_COMPANY_KEY.LABCORP &&
    lab_test_type &&
    LAB_TEST_BLOOD_TYPES.some((type) => type === lab_test_type.key)
  )
}

/**
 * Returns True if the item should be part of the Labcorp combined panel
 * @param item
 */
export function isInLabcorpByAMLPanel(item: Item) {
  // For labcorp, we include the blood draw fee within the panel
  const lineItem = getLineItemFromItem(item)
  if (
    lineItem &&
    lineItem.type === ORDER_LINE_ITEM_TYPE.LABCORP_BLOOD_DRAW_FEE
  ) {
    return true
  }

  const labTest = getLabTestFromItem(item)
  if (!labTest) {
    return false
  }

  const lab_company = labTest.lab_company
  const lab_test_type = labTest.lab_test_types[0]

  return (
    lab_company.key === LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS &&
    lab_test_type &&
    LAB_TEST_BLOOD_TYPES.some((type) => type === lab_test_type.key)
  )
}

export function isInLabcorpInsurancePanel(item: Item) {
  // For labcorp, we include the blood draw fee within the panel
  const lineItem = getLineItemFromItem(item)
  if (
    lineItem &&
    lineItem.type === ORDER_LINE_ITEM_TYPE.LABCORP_BLOOD_DRAW_FEE
  ) {
    return true
  }

  const labTest = getLabTestFromItem(item)
  if (!labTest) {
    return false
  }

  const lab_company = labTest.lab_company
  const lab_test_type = labTest.lab_test_types[0]

  return (
    lab_company.key === LAB_COMPANY_KEY.HEALTH_GORILLA_LABCORP &&
    lab_test_type &&
    LAB_TEST_BLOOD_TYPES.some((type) => type === lab_test_type.key)
  )
}

/**
 * Returns True if the item should be part of the Quest combined panel
 * @param item
 */
export function isInQuestPanel(item: Item) {
  // For quest, we include the blood draw fee within the panel
  const lineItem = getLineItemFromItem(item)
  if (lineItem && lineItem.type === ORDER_LINE_ITEM_TYPE.QUEST_BLOOD_DRAW_FEE) {
    return true
  }

  const labTest = getLabTestFromItem(item)
  if (!labTest) {
    return false
  }

  const lab_company = labTest.lab_company
  const lab_test_type = labTest.lab_test_types[0]

  return (
    (lab_company.key === LAB_COMPANY_KEY.LTA_QUEST ||
      lab_company.key === LAB_COMPANY_KEY.LABSFIRST_QUEST) &&
    lab_test_type &&
    LAB_TEST_BLOOD_TYPES.some((type) => type === lab_test_type.key)
  )
}

export function isInQuestInsurancePanel(item: Item) {
  // For quest, we include the blood draw fee within the panel
  const lineItem = getLineItemFromItem(item)
  if (lineItem && lineItem.type === ORDER_LINE_ITEM_TYPE.QUEST_BLOOD_DRAW_FEE) {
    return true
  }

  const labTest = getLabTestFromItem(item)
  if (!labTest) {
    return false
  }

  const lab_company = labTest.lab_company
  const lab_test_type = labTest.lab_test_types[0]

  return (
    lab_company.key === LAB_COMPANY_KEY.HEALTH_GORILLA_QUEST &&
    lab_test_type &&
    LAB_TEST_BLOOD_TYPES.some((type) => type === lab_test_type.key)
  )
}

/**
 * Returns True if the item should be part of the BostonHeart combined panel
 * @param item
 */
export function isInBostonHeartPanel(item: Item) {
  const labTest = getLabTestFromItem(item)
  if (!labTest) {
    return false
  }

  const lab_company = labTest.lab_company
  const lab_test_type = labTest.lab_test_types[0]

  return (
    lab_company.key === LAB_COMPANY_KEY.BOSTON_HEART &&
    lab_test_type &&
    LAB_TEST_BLOOD_TYPES.some((type) => type === lab_test_type.key) &&
    "has_instant_requisition" in labTest &&
    labTest.has_instant_requisition
  )
}

/**
 * Returns True if the item should be part of the Vibrant America combined panel
 * @param item
 */
export function isInVibrantAmericaHeartPanel(item: Item) {
  const labTest = getLabTestFromItem(item)
  if (!labTest) {
    return false
  }

  const lab_company = labTest.lab_company
  const lab_test_type = labTest.lab_test_types[0]

  return (
    lab_company.key === LAB_COMPANY_KEY.VIBRANT_AMERICA &&
    lab_test_type &&
    LAB_TEST_BLOOD_TYPES.some((type) => type === lab_test_type.key)
  )
}

/**
 * Returns True if the item should be part of the BioReference combined panel
 * @param item
 */
export function isInBioReferencePanel(item: Item) {
  const labTest = getLabTestFromItem(item)
  if (!labTest) {
    return false
  }

  const lab_company = labTest.lab_company
  const lab_test_type = labTest.lab_test_types[0]

  return (
    lab_company.key === LAB_COMPANY_KEY.BIOREFERENCE &&
    // All BioReference tests in Rupa are phleb tests, but we'll double check just in case.
    lab_test_type &&
    LAB_TEST_BLOOD_TYPES.some((type) => type === lab_test_type.key)
  )
}

/**
 * Returns True if the item should be part of the Access Medical Labs combined panel
 * @param item
 */
export function isInAccessMedicalLabsPanel(item: Item) {
  const labTest = getLabTestFromItem(item)
  if (!labTest) {
    return false
  }

  const lab_company = labTest.lab_company
  const lab_test_type = labTest.lab_test_types[0]

  return (
    lab_company.key === LAB_COMPANY_KEY.ACCESS_MEDICAL_LABS &&
    lab_test_type &&
    LAB_TEST_BLOOD_TYPES.some((type) => type === lab_test_type.key)
  )
}

function getKeyForLineItemLabTest(
  labTest: AnyLimitedLabTest,
  labTests: AnyLimitedLabTest[],
  orderedTests: (OrderedTest | PatientPortalOrderedTest)[] = [],
  isComboGroupsActive: boolean = false
) {
  // Object that keys on combo_group id and values are arrays of combo group option lab tests.
  // This will be used later on to attempt to find potential add-ons that are a part of a
  let comboGroupOptions = {}

  // If combo groups are active, retrieve combo group option lab tests
  if (isComboGroupsActive && orderedTests.length) {
    const comboGroupOrderedTests = orderedTests.filter(
      (ot) => "combo_group" in ot
    )

    comboGroupOrderedTests.forEach((ot) => {
      if ("combo_group" in ot && ot.combo_group && ot.combo_group_options) {
        comboGroupOptions[ot.combo_group] = ot.combo_group_options.map(
          (option) => option.lab_test
        )
      }
    })
  }

  if (isComboGroupsActive && labTest.combo_group) {
    return `${COMBO_GROUP_CARD_KEY_PREFIX}_${labTest.combo_group}`
  }

  if (labTest.add_ons.length) {
    return labTest.id
  }

  if (labTest.parents.length) {
    // Get first parent that's present in the cart
    // An add on should never be added to the cart without a parent present
    const parentIds = labTest.parents.map((parent) => {
      // For addons added from the addons section in the labTest modal, parents are present
      // as arrays eg: parents = [labts_4hf73b] instead of objects
      if (typeof parent === "string") {
        return parent
      } else {
        return parent.id
      }
    })
    const parentSelectedTest = labTests.find((labTest) =>
      parentIds.includes(labTest.id)
    )

    // If the parent test is not in the group of tests, then its for
    // an order made before we started grouping, or it is from a combo group.
    // So we treat it as an individual or look for the combo group it came from.
    if (!parentSelectedTest) {
      // Double check that the add-on parent is not a part of one of the combo groups.
      if (isComboGroupsActive) {
        // Iterate through each combo group and check if any of its options is the parent to the add-on
        for (const comboGroupId of Object.keys(comboGroupOptions)) {
          if (
            comboGroupOptions[comboGroupId].find((option) =>
              parentIds.includes(option.id)
            )
          ) {
            // Add-on is add-on to one of the lab tests in the combo group, add it.
            return `${COMBO_GROUP_CARD_KEY_PREFIX}_${comboGroupId}`
          }
        }
      }
      return INDIVIDUAL_CARD_KEY_PREFIX
    }

    // Return with a combo group prefix if the parent test is of a combo group (and not just an add-on)
    return isComboGroupsActive && parentSelectedTest.combo_group
      ? `${COMBO_GROUP_CARD_KEY_PREFIX}_${parentSelectedTest.combo_group}`
      : parentSelectedTest.id
  }

  return null
}

function getKeyToGroupItemBy(
  item: Item,
  labTests: AnyLimitedLabTest[],
  orderedTests: (OrderedTest | PatientPortalOrderedTest)[] = [],
  isComboGroupsActive: boolean = false
) {
  /*
  Groups lab tests into one of:
  - Combo Groups
  - Tests with addons
  - Boston Heart blood panels
  - Vibrant America blood panels
  - Access Medical blood panels
  - BioReference blood panels

  Group keys:
  - Tests with combo groups are grouped under the combo group card prefix key.
  - Tests with addons are grouped under the ID of the parent test.
  - Blood panels are grouped under the name of the lab company panel.
  - Tests not in one of those groups above are grouped together under the
  INDIVIDUAL_CARD_KEY.
  */
  const labTest = getLabTestFromItem(item)

  if (labTest) {
    const labTestGroupKey = getKeyForLineItemLabTest(
      labTest,
      labTests,
      orderedTests,
      isComboGroupsActive
    )

    if (labTestGroupKey) {
      return labTestGroupKey
    }
  }

  if (isInEmpirePanel(item)) {
    return EMPIRE_CITY_LABS_PANEL
  }

  if (isInDHAPanel(item)) {
    return DHA_PANEL
  }

  if (isInLabcorpInsurancePanel(item)) {
    return LABCORP_INSURANCE_PANEL
  }

  if (isInLabcorpPanel(item)) {
    return LABCORP_PANEL
  }

  if (isInLabcorpByAMLPanel(item)) {
    return LABCORP_BY_AML_PANEL
  }

  if (isInQuestInsurancePanel(item)) {
    return QUEST_INSURANCE_PANEL
  }

  if (isInQuestPanel(item)) {
    return QUEST_PANEL
  }

  if (isInBostonHeartPanel(item)) {
    return BOSTON_HEART_PANEL
  }

  if (isInVibrantAmericaHeartPanel(item)) {
    return VIBRANT_AMERICA_PANEL
  }

  if (isInAccessMedicalLabsPanel(item)) {
    return ACCESS_MEDICAL_PANEL
  }

  if (isInBioReferencePanel(item)) {
    return BIOREFERENCE_PANEL
  }

  // If the line item is a not a lab test line item and we reached this point, it means
  // the line item isn't included in any panels, and so is considered a non-lab test line
  // item.
  if (!labTest) {
    return NON_LAB_TEST_KEY
  }

  // Append labTest.id to make sure that each individual test is in its own group
  return `${INDIVIDUAL_CARD_KEY_PREFIX}_${item.id}`
}

export function groupLineItems<
  LineItem extends OrderLineItem | PatientPortalOrderLineItem
>(lineItems: LineItem[], isComboGroupsActive = false, removeDisabled = false) {
  if (removeDisabled) {
    lineItems = lineItems.filter((lineItem) => !lineItem.date_disabled)
  }
  const orderedTests = lineItems
    // Use flatMap so that the type properly filters out undefined (.filter() doesn't do that).
    .flatMap((lineItem) => lineItem.ordered_test || [])
  const labTests = orderedTests.map((orderedTest) => orderedTest.lab_test)

  const groups = groupBy(lineItems, (lineItem) =>
    getKeyToGroupItemBy(lineItem, labTests, orderedTests, isComboGroupsActive)
  )

  // Any non-lab test line items should be ignored (as they're typically shown outside the lab test groups).
  delete groups[NON_LAB_TEST_KEY]

  return groups
}

export function groupOrderedTests(orderedTests: OrderedTest[]) {
  const labTests = orderedTests.map((orderedTest) => orderedTest.lab_test)

  return groupBy(orderedTests, (orderedTest) => {
    // TODO: Pass in orderedTest when implementing patient checkout sidebar
    return getKeyToGroupItemBy(orderedTest, labTests, [], true)
  })
}

export function groupSelectedLabTests<T extends AnyLimitedLabTest>(
  selectedTests: T[]
): Record<string, T[]> {
  return groupBy(selectedTests, (labTest) =>
    getKeyToGroupItemBy(labTest, selectedTests)
  )
}

/**
 * Returns True if a labTest requires phlebotomy
 * @param labTest
 */
function requiresPhlebotomy(labTest: AnyLimitedLabTest) {
  return some(labTest.lab_test_types.map((type) => type.phlebotomy_required))
}

/**
 * Returns True if the labTests contains Vibrant America or Vibrant Wellness
 * @param labTests
 */
export function hasVibrantTests(labTests: LabTest[]) {
  return some(labTests, (labTest: LabTest) =>
    [
      LAB_COMPANY_KEY.VIBRANT_AMERICA,
      LAB_COMPANY_KEY.VIBRANT_WELLNESS,
    ].includes(labTest.lab_company.key)
  )
}

/**
 * Returns True if the labTests contains Quest Diagnostics
 * @param labTests
 */
export function hasQuestTests(labTests: AnyLimitedLabTest[]) {
  return some(labTests, (labTest: LabTest) =>
    [
      LAB_COMPANY_KEY.LTA_QUEST,
      LAB_COMPANY_KEY.LABSFIRST_QUEST,
      LAB_COMPANY_KEY.HEALTH_GORILLA_QUEST,
    ].includes(labTest.lab_company.key)
  )
}

/**
 * Returns True if the labTests contains Quest Diagnostics
 * @param labTests
 */
export function hasLTAQuestTests(labTests: LabTest[]) {
  return some(labTests, (labTest: LabTest) =>
    [LAB_COMPANY_KEY.LTA_QUEST].includes(labTest.lab_company.key)
  )
}

/**
 * Returns True if the labTests contains LabCorp tests
 * @param labTests
 */
export function hasLabcorpPhlebTests(labTests: AnyLimitedLabTest[]) {
  return some(
    labTests,
    (labTest: LabTest) =>
      LABCORP_COMPANY_KEYS.includes(labTest.lab_company.key) &&
      labTest.lab_company.key !== LAB_COMPANY_KEY.DHA_LABORATORY &&
      labTest.lab_test_types.some((type) => type.phlebotomy_required)
  )
}

/**
 * Returns True if the labTests contains DHA tests
 * @param labTests
 */
export function hasDHAPhlebTests(labTests: AnyLimitedLabTest[]) {
  return some(
    labTests,
    (labTest: LabTest) =>
      [LAB_COMPANY_KEY.DHA_LABORATORY].includes(labTest.lab_company.key) &&
      labTest.lab_test_types.some((type) => type.phlebotomy_required)
  )
}

/**
 * Returns True if the labTests contains AML tests
 * @param labTests
 */
function hasAMLTests(labTests: AnyLimitedLabTest[]) {
  return some(labTests, (labTest: LabTest) =>
    [LAB_COMPANY_KEY.ACCESS_MEDICAL_LABS].includes(labTest.lab_company.key)
  )
}

/**
 * Returns True if the labTests contains Labcorp by Access Medical Labs tests
 * @param labTests
 */
function hasLabcorpByAMLTests(labTests: AnyLimitedLabTest[]) {
  return some(labTests, (labTest: LabTest) =>
    [LAB_COMPANY_KEY.LABCORP_BY_ACCESS_MEDICAL_LABS].includes(
      labTest.lab_company.key
    )
  )
}

/**
 * Returns True if the labTests contains Empire City Labs tests that require phlebotomy
 * @param labTests
 */
function hasEmpirePhlebTests(labTests: AnyLimitedLabTest[]) {
  return some(labTests, (labTest: LabTest) => {
    return (
      [LAB_COMPANY_KEY.EMPIRE_CITY_LABS].includes(labTest.lab_company.key) &&
      requiresPhlebotomy(labTest)
    )
  })
}

/**
 * Returns True if the labTests contains any non-Empire tests that require phlebotomy
 * @param labTests
 */
function hasNonEmpirePhlebTests(labTests: AnyLimitedLabTest[]) {
  return labTests.some((labTest) => {
    return (
      requiresPhlebotomy(labTest) &&
      labTest.lab_company.key !== LAB_COMPANY_KEY.EMPIRE_CITY_LABS
    )
  })
}

/**
 * Returns True if the labTests contains both AML and Labcorp by Access Medical Labs tests
 * @param labTests
 */
export function hasAMLAndLabcorpByAMLTests(labTests: AnyLimitedLabTest[]) {
  return hasLabcorpByAMLTests(labTests) && hasAMLTests(labTests)
}

/**
 * Returns True if the labTests contains both AML and Labcorp by Access Medical Labs tests
 * @param labTests
 */
export function hasDHAAndLabcorpPhlebTests(labTests: AnyLimitedLabTest[]) {
  return hasDHAPhlebTests(labTests) && hasLabcorpPhlebTests(labTests)
}

/**
 * Returns either a CheckoutWarningType or undefined
 * @param labTests
 */
export function getEmpireMixedTestsError(
  labTests: AnyLimitedLabTest[]
): CheckoutWarningTypes | undefined {
  if (!hasEmpirePhlebTests(labTests)) return undefined

  if (hasLabcorpByAMLTests(labTests)) {
    return CheckoutWarningTypes.MIXED_EMPIRE_AND_LABCORP_ORDER
  }

  if (hasQuestTests(labTests)) {
    return CheckoutWarningTypes.MIXED_EMPIRE_AND_QUEST_ORDER
  }
}

/**
 * Returns True if we should show the Empire Blood Draw Warning
 *
 * Should only show if the order DOESNT have an empire shipping fee,
 * which is calculated on the backend. Ideally, the source of truth
 * for whether we show the warning, shipping fee, or neither
 * should be in one place (probly backend).
 * @param labTests
 */
export function showEmpireBloodDrawInfo(order: Order) {
  // Needs updating if Prac Pay ever allowed in NY
  if (order.is_practitioner_paying) {
    return false
  }

  // Don't show if the shipping state is definitely not NY
  if (order.shipping_state && order.shipping_state !== "NY") {
    return false
  }

  const labTests = order.ordered_tests.map((ot) => ot.lab_test)

  // Only show if all Phleb tests are Empire tests
  return hasEmpirePhlebTests(labTests) && !hasNonEmpirePhlebTests(labTests)
}

/**
 * Returns True if the labTests contains both IOK and dropship tests from AML
 * @param orderedTests
 */
export function hasAMLDropshipAndIOKTests(orderedTests: OrderedTest[]) {
  const amlTests = orderedTests.filter(
    (ot) => ot.lab_test.lab_company.key === LAB_COMPANY_KEY.ACCESS_MEDICAL_LABS
  )

  const hasAMLDropshipTests = amlTests.some((ot) => !ot.instant_requisition)
  const hasAMLIOKTests = amlTests.some((ot) => ot.instant_requisition)

  return hasAMLDropshipTests && hasAMLIOKTests
}

/**
 * Returns True if the labTests contains at least one BioReference phleb test
 * @param labTests
 */
export function hasBioReferencePhlebTests(labTests: LabTest[]) {
  return some(labTests, (labTest: LabTest) => {
    return (
      labTest.lab_company.key === LAB_COMPANY_KEY.BIOREFERENCE &&
      requiresPhlebotomy(labTest)
    )
  })
}

/**
 * Returns True if labTests contains tests from > 1 of the following labs:
 * BioReference, BostonHeart, Vibrant America, Access Medical
 * @param labTests
 */
export function hasMultipleBloodLabTests(
  labTests: AnyLimitedLabTest[]
): boolean {
  const groupedLabTests = groupSelectedLabTests(labTests)
  const groupedBloodLabTests = Object.entries(groupedLabTests).filter(
    ([key, value]) => LAB_COMPANY_PANELS.includes(key)
  )

  return groupedBloodLabTests.length > 1
}

export function orderHasInHousePhelbTests(order: Order | PatientPortalOrder) {
  return order.ordered_tests.some(
    (ot) => ot.sample_collection_coordinator === "LAB"
  )
}

/**
 * Returns duplicate biomarkers by lab company for paneled (blood lab) companies
 * @param labTests Array of lab tests
 * @returns An object where keys are lab companies and values are objects mapping tests to their duplicate biomarkers
 */
export function getTestsCausingDuplicateBiomarkers(
  labTests: AnyLimitedLabTest[]
): Record<string, Record<string, string[]>> {
  const groupedLabTests = groupBy(
    labTests,
    (labTest) => labTest.lab_company.key
  )
  const groupedBloodLabTests = Object.entries(groupedLabTests)

  const testsCausingDuplicatesByLabCompany: Record<
    string,
    Record<string, string[]>
  > = {}

  groupedBloodLabTests.forEach(([labCompanyName, tests]) => {
    const biomarkerToTests: Record<string, string[]> = {}
    const testToDuplicateBiomarkers: Record<string, Set<string>> = {}

    tests.forEach((test) => {
      // NOTE: The simplification of lab test endpoints (that introduced AnyLimitedLabTest) doesn't
      // include biomarkers. Not sure if that's a problem in the contexts in which this function is
      // used, but to be safe and keep TS happy, we just bail out if there's no biomarkers.
      // @ts-ignore
      if (!("biomarkers" in test)) {
        return
      }

      test.biomarkers?.forEach((biomarker) => {
        const biomarkerName = biomarker.long_name

        // Initialize array for this biomarker if it doesn't exist
        if (!biomarkerToTests[biomarkerName]) {
          biomarkerToTests[biomarkerName] = []
        }
        biomarkerToTests[biomarkerName].push(test.name)

        // If this biomarker appears in more than one test, it's a duplicate
        if (biomarkerToTests[biomarkerName].length > 1) {
          // Add this biomarker to all tests that include it
          biomarkerToTests[biomarkerName].forEach((testName) => {
            if (!testToDuplicateBiomarkers[testName]) {
              testToDuplicateBiomarkers[testName] = new Set()
            }
            testToDuplicateBiomarkers[testName].add(biomarkerName)
          })
        }
      })
    })

    // Only add to results if there are duplicate biomarkers
    if (Object.keys(testToDuplicateBiomarkers).length) {
      testsCausingDuplicatesByLabCompany[labCompanyName] = Object.fromEntries(
        Object.entries(testToDuplicateBiomarkers).map(
          ([test, duplicateBiomarkers]) => [
            test,
            Array.from(duplicateBiomarkers),
          ]
        )
      )
    }
  })

  return testsCausingDuplicatesByLabCompany
}

/**
 * Returns boolean tuple [hasOrderingRights, disallowedBecauseOfState]
 * depending on the Practitioner and Lab Test
 * @param practitioner
 * @param labTest
 */
const hasOrderingRights = (
  labTest: AnyLimitedLabTest,
  practitioner?: Practitioner | SigningPractitioner
): [hasOrderingRights: boolean, disallowedBecauseOfState: boolean] => {
  if (isClinicStaff(practitioner)) {
    return [true, false]
  }

  if (!practitioner?.primary_practitioner_type) {
    return [false, false]
  }

  if (!practitioner?.clinic) {
    return [false, false]
  }

  const orderingRight = labTest.lab_company.ordering_rights.find(
    (orderingRight) =>
      practitioner.primary_practitioner_type &&
      orderingRight.practitioner_types.includes(
        practitioner.primary_practitioner_type.id
      )
  )

  const stateDisallowed = orderingRight
    ? orderingRight.disallowed_states.includes(practitioner.clinic.state)
    : false

  return [Boolean(orderingRight) && !stateDisallowed, stateDisallowed]
}

export const hasOrderingRightsForLabCompany = (
  labCompany: LabCompany,
  practitioner?: Practitioner | SigningPractitioner | PractitionerListItem
): [hasOrderingRights: boolean, disallowedBecauseOfState: boolean] => {
  if (!practitioner?.primary_practitioner_type) {
    return [false, false]
  }

  if (!practitioner?.clinic) {
    return [false, false]
  }

  const hasLabCompanyOrderingRight = labCompany.ordering_rights.find(
    (orderingRight) =>
      practitioner.primary_practitioner_type &&
      orderingRight.practitioner_types.includes(
        practitioner.primary_practitioner_type.id
      )
  )

  const manualAccessGranted = Boolean(
    practitioner.registered_lab_companies?.find((rlc) => rlc === labCompany.id)
  )

  const stateDisallowed = hasLabCompanyOrderingRight
    ? hasLabCompanyOrderingRight.disallowed_states.includes(
        practitioner.clinic.state
      )
    : false

  const hasOrderingPermission =
    (Boolean(hasLabCompanyOrderingRight) && !stateDisallowed) ||
    manualAccessGranted

  return [hasOrderingPermission, stateDisallowed]
}

export const getUnavailableStateForLabCompany = (
  labCompany: LabCompany,
  practitioner?: Practitioner | SigningPractitioner | PractitionerListItem
): string => {
  const [, disallowedBecauseOfState] = hasOrderingRightsForLabCompany(
    labCompany,
    practitioner
  )

  const unavailableState =
    disallowedBecauseOfState && practitioner?.clinic?.state
      ? practitioner.clinic.state
      : ""

  return unavailableState
}

/**
 * Returns true if the Lab Test's Company is registered with the Practitioner.
 * @param practitioner
 * @param labTest
 */
const hasRegisteredLabCompany = (
  labTest: AnyLimitedLabTest,
  practitioner?: Practitioner | SigningPractitioner
) =>
  practitioner?.registered_lab_companies &&
  practitioner.registered_lab_companies.some(
    (labCompanyID) => labCompanyID === labTest.lab_company.id
  )

/**
 * Returns "" if labTest is available else returns the unavailable reason
 * @param labTest
 * @param showOverrideOption determines whether to show old tooltip message or the new override button
 * @param activePractitioner prac that is currently ordering lab test; added because sometimes there is a discrepancy between this and other practitioner passed in
 * @param practitioner
 * @param page
 * @param aParentIsInCart
 * @overrideTestAction CTA for button in new tooltip
 * @orderRequiresVendorPhysicianAuthorization order requires physician authorization
 */
export function getUnavailableReasonForLabTest(
  labTest: AnyLimitedLabTest,
  showOverrideOption: boolean,
  activePractitioner?: Practitioner,
  practitioner?: Practitioner,
  page = labTestLocation.CATALOG,
  aParentIsInCart = false,
  overrideTestAction?: (unavailableState?: string) => void,
  orderRequiresVendorPhysicianAuthorization = false,
  createBundleClick?: () => void
): React.ReactChild {
  // If an unauthorized practitioner
  if (!practitioner) {
    return ""
  }

  if (!labTest) {
    // Default to "Not Available" if no labTest is passed in
    return "Not Available"
  }

  // If this is invoked from the Bundle Editor, let a clinic staff access all test
  const isInLabShopsPage =
    page === labTestLocation.ECOMMERCE ||
    page === labTestLocation.ECOMMERCE_BUNDLES

  const isLabShopUsingPhysServices =
    isInLabShopsPage && orderRequiresVendorPhysicianAuthorization

  const isClinicStaffOrPhysAuthUser =
    isClinicStaff(practitioner) ||
    practitioner.vendor_physician_authorization_enabled

  const isAddOn = "parents" in labTest && Boolean(labTest.parents.length)

  let parentTestNames = ""
  if (isAddOn) {
    parentTestNames = labTest.parents.map((test) => test.name).join(", ")
  }

  let unavailableReason: React.ReactChild = ""

  // LabTests can be disabled for an entire lab company or just a single lab test within a lab company for LabShops.
  if (isInLabShopsPage) {
    if (!labTest.is_available_for_labshops) {
      return `${labTest.name} is not available for LabShops`
    } else if (!labTest.lab_company.is_available_for_labshops) {
      return `${labTest.lab_company.name} is not available for LabShops`
    }
  }

  if (isAddOn && page === labTestLocation.ECOMMERCE) {
    if (labTest.parents.length > 1) {
      return (
        <span>
          This test is an add-on. For the best client experience in your
          LabShop, please{" "}
          <Link style={{ cursor: "pointer" }} onClick={createBundleClick}>
            create a bundle
          </Link>{" "}
          with the one of the following parent tests: {parentTestNames}, plus
          the add-on.
        </span>
      )
    } else {
      return (
        <span>
          This test is an add-on. For the best client experience in your
          LabShop, please{" "}
          <Link style={{ cursor: "pointer" }} onClick={createBundleClick}>
            create a bundle
          </Link>{" "}
          with the parent test {parentTestNames}, plus the add-on.
        </span>
      )
    }
  }

  if (
    (page === labTestLocation.BUNDLES && isClinicStaffOrPhysAuthUser) ||
    (isLabShopUsingPhysServices && isClinicStaffOrPhysAuthUser)
  ) {
    return ""
  }

  // If this is invoked from the Comparisons page, let anyone access all tests.
  if (page === labTestLocation.COMPARISON) {
    return ""
  }

  // If this is the bundles settings page, let anyone access all active tests.
  if (page === labTestLocation.BUNDLES_SETTINGS && labTest.is_available) {
    return ""
  }

  // If this is invoked from the catalog.
  // test allows physician authorization, and the practitioner has it enabled, allow access.
  if (
    page === labTestLocation.CATALOG &&
    labTest.physician_authorization_approved &&
    activePractitioner?.vendor_physician_authorization_enabled
  ) {
    return ""
  }

  // If the order requires physician authorization and the lab test and is not phys auth approved, do not allow access.
  if (
    [labTestLocation.CHECKOUT, labTestLocation.BUNDLES].includes(page) &&
    !labTest.physician_authorization_approved &&
    activePractitioner?.vendor_physician_authorization_enabled &&
    orderRequiresVendorPhysicianAuthorization
  ) {
    return `${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} is not available for this test.`
  }

  // If labTest is available, not an add-on test, no signing_practitioner and practitioner
  // has physician_authorization ALWAYS_REQUIRED
  if (
    labTest.is_available &&
    !isAddOn &&
    !practitioner?.signing_practitioner &&
    practitioner?.physician_authorization ===
      PhysicianAuthorizationType.ALWAYS_REQUIRED
  ) {
    return ""
  }

  if (!labTest.is_available) {
    if (labTest.unavailable_reason) {
      unavailableReason = labTest.unavailable_reason
    } else {
      unavailableReason = "This test is no longer active"
    }
  } else if (isAddOn) {
    if (page === labTestLocation.CATALOG) {
      unavailableReason =
        labTest.parents.length > 1
          ? `This test is an add-on. You can start an order with one of the following tests: ${parentTestNames}`
          : `This test is an add-on. You can start an order with the following test: ${parentTestNames}`
    } else if (!aParentIsInCart) {
      const target = page === labTestLocation.CHECKOUT ? "cart" : "bundle"

      unavailableReason =
        labTest.parents.length > 1
          ? `This test is an add-on. It can only be added to the ${target} if one of the following tests is in your ${target}: ${parentTestNames}`
          : `This test is an add-on. It can only be added to the ${target} if the following test is in your ${target}: ${parentTestNames}`
    }
  } else if (
    // if the order requires phys auth and the lab test is phys auth approved, then allow it.
    activePractitioner?.vendor_physician_authorization_enabled &&
    orderRequiresVendorPhysicianAuthorization &&
    labTest.physician_authorization_approved
  ) {
    return ""
  } else {
    const signingPractitioner =
      getPractitionerToEvalOrderingRights(practitioner)

    const canOrderTest = (): [
      canOrder: boolean,
      unavailableState: string | undefined
    ] => {
      const [orderingRights, disallowedBecauseOfState] = hasOrderingRights(
        labTest,
        signingPractitioner
      )

      return [
        hasRegisteredLabCompany(labTest, signingPractitioner) || orderingRights,
        disallowedBecauseOfState && signingPractitioner?.clinic?.state
          ? signingPractitioner.clinic.state
          : "",
      ]
    }

    const [canOrder, unavailableState] = canOrderTest()

    if (!canOrder) {
      const name = getPractitionerTypeLabel(practitioner)

      const showOverrideTooltip =
        // Flag turned on
        showOverrideOption &&
        // Lab Company is not DSL or BRL
        !BLOCKED_OVERRIDE_LAB_COMPANIES.includes(labTest.lab_company.key) &&
        // Only allow prac to override for their account
        activePractitioner?.id === signingPractitioner.id &&
        // Prac has a primary_practitioner_type value
        activePractitioner?.primary_practitioner_type &&
        // Prac type is not in list of types built in to rupa app
        !activePractitioner?.primary_practitioner_type
          ?.must_be_included_in_lab_company_ordering_rights &&
        !_labCompanyHasBeenRedacted(labTest, activePractitioner)

      unavailableReason = (
        // Standard ordering rights tooltip
        <>
          {showOverrideTooltip ? (
            <OverrideAccessTooltip
              name={name}
              labTest={labTest}
              overrideTestAction={overrideTestAction}
              unavailableState={unavailableState}
            />
          ) : (
            <OrderingRightsTooltip
              name={name}
              labTest={labTest}
              unavailableState={unavailableState}
            />
          )}
          <PhysicianAuthorizationTooltip
            physicianAuthorizationEnabled={
              practitioner.vendor_physician_authorization_enabled
            }
            labTestVendorPhysAuthApproved={
              !!labTest.physician_authorization_approved
            }
            isPracDifferentFromActivePrac={
              activePractitioner
                ? practitioner.id !== activePractitioner.id
                : false
            }
          />
        </>
      )
    }
  }

  return unavailableReason
}

function getPractitionerTypeLabel(
  practitioner?: Practitioner | SigningPractitioner | PractitionerListItem
) {
  if (!practitioner) {
    return ""
  }
  if (isClinicStaff(practitioner)) {
    return "Clinic Staff"
  }
  if (practitioner.primary_practitioner_type) {
    return `${practitioner.primary_practitioner_type.name}`
  }

  return ""
}

/**
 * Check that the lab test's lab company has not had its override access redacted in admin.
 *
 * @param labTest Current lab test
 * @param practitioner Active practitioner
 *
 * @returns Lab company has been redacted
 */
function _labCompanyHasBeenRedacted(
  labTest: AnyLimitedLabTest,
  practitioner?: Practitioner
): boolean {
  if (practitioner === undefined) {
    return false
  }

  return (
    practitioner.redacted_ordering_rights_logs?.filter(
      (log) => log.lab_company.key === labTest.lab_company.key
    ).length > 0
  )
}

/**
 * Determines whether any of the tests on the order are blocked for the given practitioner.
 *
 * @param order the order
 * @param practitioner the practitioner to test
 * @param location the location within the app
 * @returns whether or not the practitioner is blocked from one or more tests
 */
export function isPractitionerBlockedFromTests(
  order: Order,
  practitioner: Practitioner,
  location: labTestLocation
) {
  return some(order.ordered_tests, ({ lab_test }) => {
    const unavailableReason = getUnavailableReasonForLabTest(
      lab_test,
      false,
      practitioner,
      practitioner,
      location,
      true
    )

    return !(typeof unavailableReason === "string" && !unavailableReason.length)
  })
}

/**
 * Whether an ordered test is blocked from ordering
 * @param orderedTestOrLabTest ordered test or lab test being checked
 * @param checkoutDraftValues values as a part of the checkout draft
 *
 * @returns blocked - whether the lab test is blocked from ordering, blockedText - the text explaining to the user that the test is blocked
 */
export function isOrderedTestOrLabTestBlocked(
  orderedTestOrLabTest: OrderedTest | LabTest,
  checkoutDraftValues: CheckoutDraftState["values"]
) {
  const labTest =
    "lab_test" in orderedTestOrLabTest
      ? orderedTestOrLabTest.lab_test
      : (orderedTestOrLabTest as LabTest)

  const signingPractitionerName = get(
    checkoutDraftValues.signing_practitioner,
    "user.full_name"
  )

  if (labTest && checkoutDraftValues.practitioner) {
    let signingPractitioner = checkoutDraftValues.practitioner
    if (checkoutDraftValues.signing_practitioner) {
      signingPractitioner = checkoutDraftValues.signing_practitioner
    }

    // For non-phys auth, use standard ordering rights.
    const unavailableReason = getUnavailableReasonForLabTest(
      labTest,
      false,
      checkoutDraftValues.practitioner,
      signingPractitioner,
      labTestLocation.CHECKOUT,
      true,
      () => {},
      checkoutDraftValues.requires_vendor_physician_authorization
    )
    if (Boolean(unavailableReason)) {
      // unavailableReason can be either a string or React element. If it is an element, just display a generic ordering rights error.
      if (isString(unavailableReason)) {
        return {
          blocked: true,
          blockedText: unavailableReason,
        }
      } else {
        return {
          blocked: true,
          blockedText: `${
            signingPractitionerName ?? "Signing Practitioner"
          } is not licensed to order this test.`,
        }
      }
    }
  }

  return { blocked: false, blockedText: "" }
}

/**
 * Whether an ordered test has an error
 * @param test ordered test or lab test being checked
 * @param values values as a part of the checkout draft
 *
 * @returns error - whether there is an error preventing ordering, errorText - text describing the error, warningOnly - whether the error is a warning only
 */
export function isOrderedTestOrLabTestOrderable(
  test: OrderedTest | LabTest,
  values: CheckoutDraftState["values"]
) {
  const { blocked, blockedText } = isOrderedTestOrLabTestBlocked(test, values)
  return { error: blocked, errorText: blockedText, warningOnly: false }
}

/**
 * Get a tooltip explaining why a practitioner isn't able to order from a given lab company.
 *
 * @param {boolean} hasOrderingAccess - whether or not the practitioner has ordering access for a lab company
 * @param {boolean} isOrderingAccessOverrideHoverEnabled - whether or not the "Order Access Override Hover" feature flag is enabled
 * @param {LabCompany} labCompany - the lab company that a practitioner may or may not have ordering access for
 * @param {Practitioner} practitioner -the practitioner that may or may not have ordering access for labCompany
 * @param {(unavailableState?: string) => void} overrideTestAction - the call to action in the resulting tooltip
 *
 * @returns {React.ReactChild} - a tooltip explaining why the Practitioner is not allowed to order from the labCompany
 */
export function getDisabledOrderingAccessReasonForLabCompany(
  hasOrderingAccess: boolean,
  isOrderingAccessOverrideHoverEnabled: boolean,
  labCompany: LabCompany,
  practitioner?: Practitioner | PractitionerListItem,
  overrideTestAction?: (unavailableState?: string) => void
): React.ReactChild {
  let disabledReason: React.ReactChild = ""
  const unavailableState = getUnavailableStateForLabCompany(
    labCompany,
    practitioner
  )

  if (!hasOrderingAccess) {
    const name = getPractitionerTypeLabel(practitioner)

    const showOverrideTooltip = isOrderingAccessOverrideTooltipDisplayed(
      isOrderingAccessOverrideHoverEnabled,
      labCompany,
      practitioner
    )

    disabledReason = showOverrideTooltip ? (
      <OverrideAccessTooltip
        name={name}
        labCompany={labCompany}
        overrideTestAction={overrideTestAction}
        unavailableState={unavailableState}
      />
    ) : (
      <OrderingRightsTooltip
        name={name}
        labCompany={labCompany}
        practitioner={practitioner}
        unavailableState={unavailableState}
      />
    )
  }

  return disabledReason
}

export function isOrderingAccessOverrideTooltipDisplayed(
  isOrderingAccessOverrideHoverFlagEnabled: boolean,
  labCompany: LabCompany,
  practitioner?: Practitioner | PractitionerListItem
) {
  const signingPractitioner = getPractitionerToEvalOrderingRights(practitioner)

  if (!practitioner) {
    return false
  }

  return (
    // `Order Access Override Hover` feature flag is enabled
    isOrderingAccessOverrideHoverFlagEnabled &&
    // Lab Company is not DSL or BRL
    !BLOCKED_OVERRIDE_LAB_COMPANIES.includes(labCompany.key) &&
    // Only allow Practitioner to override Ordering Access for their own account
    practitioner?.id === signingPractitioner?.id &&
    // Practitioner has a primary_practitioner_type value
    practitioner?.primary_practitioner_type &&
    // Practitioner's primary_practitioner_type is not in the list of Practitioner types included in Rupa
    !practitioner?.primary_practitioner_type
      .must_be_included_in_lab_company_ordering_rights
  )
}
