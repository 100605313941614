import BodyText from "app/components/design-system/BodyText"
import SampleTypeChip from "app/components/design-system/SampleTypeChip"
import { KIT_GROUP_TYPE, ORDERED_TEST_STATUS } from "app/constants.typed"
import PatientPortalFastingBanner from "app/patient-portal/order/components/PatientPortalFastingBanner"
import PatientPortalOrderKitListKitTestAction from "app/patient-portal/order/components/PatientPortalOrderKitListKitTestAction"
import PatientPortalOrderKitListKitTestPanelTooltip from "app/patient-portal/order/components/PatientPortalOrderKitListKitTestPanelTooltip"
import PatientPortalOrderKitListKitTestStatus from "app/patient-portal/order/components/PatientPortalOrderKitListKitTestStatus"
import {
  PatientPortalKit,
  PatientPortalLabCompany,
  PatientPortalLabTest,
  PatientPortalLabTestType,
  PatientPortalOrderedTest,
} from "app/patient-portal/types"
import useCachedResource from "app/swr/hooks/use-cached-resource"

import useOpenCheatsheet from "../hooks/use-open-cheatsheet"
import BloodDashboardsActionButton from "./BloodDashboardsActionButton"
import PatientPortalOrderedTestEmbeddableResultAction from "./PatientPortalOrderedTestEmbeddableResultAction"

const LabTestTypeListItem = ({ labTestTypeId }: { labTestTypeId: string }) => {
  const labTestType = useCachedResource<PatientPortalLabTestType>({
    type: "patient_portal_lab_test_type",
    id: labTestTypeId,
  })
  if (!labTestType) {
    return null
  }
  return <SampleTypeChip name={labTestType.attributes.name} />
}

const PatientPortalOrderKitListKitTestContent = ({
  kit,
  labCompany,
  labTest,
  orderedTest,
}: {
  kit: PatientPortalKit
  labCompany: PatientPortalLabCompany
  labTest: PatientPortalLabTest
  orderedTest: PatientPortalOrderedTest
}) => {
  const isPanel = kit.attributes.group_type === KIT_GROUP_TYPE.PANEL
  const openCheatsheet = useOpenCheatsheet(kit.attributes.cheatsheet_url)

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-flow-row sm:grid-flow-col sm:grid-cols-[25%_1fr_auto] items-stretch sm:items-center gap-8">
        <div className="flex flex-col gap-2">
          <BodyText weight="semibold" size="md">
            {isPanel
              ? `${labCompany.attributes.name} Panel`
              : labTest.attributes.name}
          </BodyText>
          <BodyText size="base">By {labCompany.attributes.name}</BodyText>

          {isPanel && (
            <PatientPortalOrderKitListKitTestPanelTooltip kit={kit} />
          )}
          <div className="flex flex-wrap gap-[3px]">
            {labTest.relationships.lab_test_types.data.map((labTestTypeRef) => (
              <LabTestTypeListItem
                key={labTestTypeRef.id}
                labTestTypeId={labTestTypeRef.id}
              />
            ))}
          </div>
        </div>

        <PatientPortalOrderKitListKitTestStatus
          labCompany={labCompany}
          orderedTest={orderedTest}
        />

        {orderedTest.relationships.embeddable_user_result?.data ? (
          <PatientPortalOrderedTestEmbeddableResultAction
            orderedTest={orderedTest}
            labCompany={labCompany}
            labTest={labTest}
          />
        ) : orderedTest.attributes.status === ORDERED_TEST_STATUS.COMPLETE &&
          kit.attributes.is_rupa_blood_dashboard_kit ? (
          <BloodDashboardsActionButton kit={kit} orderedTest={orderedTest} />
        ) : (
          <PatientPortalOrderKitListKitTestAction orderedTest={orderedTest} />
        )}
      </div>

      {orderedTest.attributes.status !== ORDERED_TEST_STATUS.AT_LAB &&
        orderedTest.attributes.status !== ORDERED_TEST_STATUS.COMPLETE &&
        orderedTest.attributes.status !==
          ORDERED_TEST_STATUS.ACTION_REQUIRED_SPECIMEN_ISSUE &&
        orderedTest.attributes.status !==
          ORDERED_TEST_STATUS.PROCESSING_SPECIMEN_ISSUE_ACTION && (
          <>
            <hr className="h-px bg-[#E2E8F0] w-full" />
            <div>
              <PatientPortalFastingBanner
                requiresFasting={kit.attributes.requires_fasting}
                fastingDurationHours={labTest.attributes.fasting_duration}
                withBackground={false}
                instructionsOnClick={openCheatsheet}
              />
            </div>
          </>
        )}
    </div>
  )
}

export default function PatientPortalOrderKitListKitTest({
  kit,
  orderedTestId,
}: {
  kit: PatientPortalKit
  orderedTestId: string
}) {
  const orderedTest = useCachedResource<PatientPortalOrderedTest>({
    type: "patient_portal_ordered_test",
    id: orderedTestId,
  })
  const labTest = useCachedResource<PatientPortalLabTest>(
    orderedTest?.relationships.lab_test.data
  )
  const labCompany = useCachedResource<PatientPortalLabCompany>(
    labTest?.relationships.lab_company.data
  )
  if (!orderedTest || !labTest || !labCompany) {
    return null
  }
  return (
    <PatientPortalOrderKitListKitTestContent
      kit={kit}
      labCompany={labCompany}
      labTest={labTest}
      orderedTest={orderedTest}
    />
  )
}
