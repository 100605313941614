import { Location } from "history"

import { AllowAnyPaths } from "app/Routes"
import { getBaseUrl } from "app/utils"

import { COMPARISON_TEST_ID_PARAM } from "./constants"

/**
 * Returns the Location to compare the lab tests with the given set of lab test IDs.
 *
 * @param labTestIds the set of lab test IDs to compare
 * @returns a Location for comparing the lab tests
 */
export function getComparisonLocation(labTestIds: string[] = []): Location {
  const params = new URLSearchParams()

  labTestIds.forEach((labTestId) =>
    params.append(COMPARISON_TEST_ID_PARAM, labTestId)
  )

  return {
    pathname: AllowAnyPaths.DISCOVER_LABS_SEARCH,
    search: `?${params.toString()}`,
  }
}

/**
 * Returns an absolute url to the page for comparing the given lab tests IDs.
 *
 * @param labTestIds the set of lab test IDs to compare
 * @returns an absolute url to the page for comparing the lab tests
 */
export function getAbsoluteComparisonUrl(labTestIds: string[] = []): string {
  const location = getComparisonLocation(labTestIds)
  return `${getBaseUrl()}${location.pathname}${location.search}`
}

/**
 * Used for pluralizing "day" for duration rows
 *
 * @param numDays the number of days
 * @returns either "day" or "days"
 */
export function pluralizeDays(numDays: number) {
  return numDays !== 1 ? "days" : "day"
}
