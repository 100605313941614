import { useMemo } from "react"

import { uniqBy } from "lodash"

import { InRangeOption } from "app/main/patient-orders/trends/types/types"
import { sortBloodReportResultValues } from "app/patient-portal/blood-lab-dashboard/utils"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Biomarker } from "types/biomarker"
import { BloodLabDashboardReportSnapshot } from "types/blood-lab-dashboard-report-snapshot"
import { BloodReportResult } from "types/blood-report-result"
import { BodySystem } from "types/body-system"
import { Clinic } from "types/clinic"
import { LabCompany } from "types/lab-company"
import { LabTest } from "types/lab-test"
import { Order } from "types/order"
import { OrderedResult } from "types/ordered-result"
import { OrderedTest } from "types/ordered-test"
import { Practitioner } from "types/practitioner"
import { UserResult } from "types/user-result"

export default function useBloodLabDashboardsSnapshotHelpers(
  snapshot: BloodLabDashboardReportSnapshot | undefined,
  bloodReportResults: BloodReportResult[],
  search?: string
) {
  const userResults = useCachedCollection<UserResult>(
    snapshot?.relationships.user_results.data
  )

  const filteredBloodReportResults = bloodReportResults.filter(
    (bloodReportResult) => {
      return (
        (bloodReportResult.attributes.biomarker_short_name ?? "")
          .toLowerCase()
          .includes(search?.toLowerCase() ?? "") ||
        (bloodReportResult.attributes.biomarker_long_name ?? "")
          .toLowerCase()
          .includes(search?.toLowerCase() ?? "")
      )
    }
  )

  const orderedResult = useCachedResource<OrderedResult>(
    snapshot?.relationships.ordered_result.data
  )

  const labCompany = useCachedResource<LabCompany>(
    orderedResult?.relationships.lab_company.data
      ? orderedResult?.relationships.lab_company.data
      : userResults?.[0]?.relationships?.lab_company?.data
  )

  const orderedTests = useCachedCollection<OrderedTest>(
    orderedResult?.relationships.ordered_tests.data
  )

  const labTests = useCachedCollection<LabTest>(
    orderedTests?.flatMap((orderedTest) => {
      return orderedTest.relationships.lab_test.data
    })
  )

  const allBiomarkers = useCachedCollection<Biomarker>(
    bloodReportResults?.map((bloodReportResult) => {
      return bloodReportResult.relationships.biomarker.data
    })
  )

  const biomarkers = useCachedCollection<Biomarker>(
    filteredBloodReportResults.map((bloodReportResult) => {
      return bloodReportResult.relationships.biomarker.data
    })
  )

  const missingBiomarkers = useCachedCollection<Biomarker>(
    labTests
      .flatMap((labTest) => {
        return labTest.relationships.biomarkers.data
      })
      .filter((biomarker) => {
        return !bloodReportResults.find((bloodReportResult) => {
          return (
            bloodReportResult.relationships.biomarker?.data.id === biomarker.id
          )
        })
      })
  )

  const uniqueBodySystemIdentifiers = uniqBy(
    biomarkers.flatMap(
      (biomarker) => biomarker?.relationships?.body_systems?.data
    ),
    "id"
  )

  const order = useCachedResource<Order>(
    orderedResult?.relationships?.order?.data
  )

  const practitioner = useCachedResource<Practitioner>(
    order?.relationships?.practitioner?.data
  )

  const clinic = useCachedResource<Clinic>(
    practitioner?.relationships?.clinic?.data
  )

  const bodySystems = useCachedCollection<BodySystem>(
    uniqueBodySystemIdentifiers
  )

  const sortedBodySystems = useMemo(() => {
    return [...bodySystems].sort(
      (a, b) => (a.attributes.sort_order || 0) - (b.attributes.sort_order || 0)
    )
  }, [bodySystems])

  const filterByInRangeOption = (
    bloodReportResults: BloodReportResult[],
    inRangeOption?: InRangeOption
  ) => {
    if (!inRangeOption || inRangeOption === InRangeOption.ALL) {
      return bloodReportResults
    }

    return bloodReportResults.filter((bloodReportResult) => {
      if (!bloodReportResult?.attributes?.value) {
        return false
      }
      if (inRangeOption === InRangeOption.IN_RANGE) {
        return (
          !bloodReportResult.attributes.out_of_optimal_range &&
          !bloodReportResult.attributes.out_of_standard_range
        )
      } else if (inRangeOption === InRangeOption.OUTSIDE_OPTIMAL_RANGE) {
        return (
          bloodReportResult.attributes.out_of_optimal_range ||
          bloodReportResult.attributes.out_of_standard_range
        )
      } else if (inRangeOption === InRangeOption.OUT_OF_RANGE) {
        return bloodReportResult.attributes.out_of_standard_range
      }
      return true
    })
  }

  const getMatchingBloodReportResultByBiomarkerId = (
    biomarkerId?: string
  ): BloodReportResult | undefined => {
    return filteredBloodReportResults.find((bloodReportResult) => {
      return bloodReportResult.relationships.biomarker.data?.id === biomarkerId
    })
  }

  const getBloodReportResultsByLabTest = (
    labTest: LabTest,
    filterBodySystemId?: string,
    inRangeOptionFilter?: InRangeOption
  ): BloodReportResult[] => {
    const bloodReportResults = labTest.relationships.biomarkers.data
      .map((biomarker) => {
        return getMatchingBloodReportResultByBiomarkerId(biomarker.id)
      })
      .filter((bloodReportResult) => {
        if (!bloodReportResult) {
          return false
        }

        if (filterBodySystemId && filterBodySystemId !== "all") {
          const filteredBodySystems = bodySystems.filter(
            (bodySystem) => filterBodySystemId === bodySystem.id
          )
          const biomarkerIds = filteredBodySystems.flatMap((bodySystem) =>
            bodySystem.relationships.biomarkers.data.map(
              (biomarker) => biomarker.id
            )
          )
          if (
            !biomarkerIds.includes(
              bloodReportResult.relationships.biomarker?.data?.id
            )
          ) {
            return false
          }
        }

        return bloodReportResult
      }) as BloodReportResult[]

    return filterByInRangeOption(
      bloodReportResults.sort((a, b) => sortBloodReportResultValues(a, b)),
      inRangeOptionFilter
    )
  }

  const getBloodReportResultsByBodySystem = (
    bodySystem: BodySystem,
    inRangeOptionFilter?: InRangeOption
  ): BloodReportResult[] => {
    const bloodReportResults = bodySystem.relationships.biomarkers.data
      .map((biomarker) => {
        return getMatchingBloodReportResultByBiomarkerId(biomarker.id)
      })
      .filter((bloodReportResult) => {
        if (!bloodReportResult) {
          return false
        }

        return bloodReportResult
      }) as BloodReportResult[]
    return filterByInRangeOption(
      bloodReportResults.sort((a, b) => sortBloodReportResultValues(a, b)),
      inRangeOptionFilter
    )
  }

  const usesClinicOptimalRange = bloodReportResults.some(
    (bloodReportResult) => bloodReportResult.attributes.is_clinic_optimal_range
  )

  return {
    clinic,
    snapshot,
    practitioner,
    userResults,
    filteredBloodReportResults,
    allBiomarkers,
    orderedResult,
    labTests,
    sortedBodySystems,
    missingBiomarkers,
    labCompany,
    usesClinicOptimalRange,
    getBloodReportResultsByLabTest,
    getBloodReportResultsByBodySystem,
    getMatchingBloodReportResultByBiomarkerId,
  }
}
