import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import clsx from "clsx"

import { Paper } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"

import { API } from "app/api"
import Loading from "app/components/Loading"
import { Dialog } from "app/components/modals"
import { ORDERING_RIGHTS_ACTIONS } from "app/constants"
import { getUnavailableReason } from "app/dataServices/orderingRights"
import useFeatureFlag from "app/hooks/use-feature-flag"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import * as Actions from "app/store/actions"
import { addFavoriteTest, removeFavoriteTest } from "app/store/actions"
import { colors } from "app/theme"
import {
  AnyLabTest,
  AnyLimitedLabTest,
  LabTestAddon,
  OrderingRightsStatus,
  Practitioner,
  SigningPractitioner,
  labTestLocation,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import AddOnSection from "./AddOnSection"
import BiomarkersSection from "./BiomarkersSection"
import InformationSection from "./InformationSection"
import TitleSection from "./TitleSection"

const useStyles = makeAppStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up("md")]: {
      minWidth: 700,
    },
  },
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
  },
  dialogContent: {
    padding: 12,
    backgroundColor: colors.coolGray[100],

    [theme.breakpoints.up("md")]: {
      width: 840,
      padding: 22.5,
    },
  },
  sectionsContainer: {
    "&:not(:first-child)": {
      marginTop: 22.5,
    },
  },
}))

function isLabTestAddon(anyLabTest: AnyLabTest): anyLabTest is LabTestAddon {
  const addOn = anyLabTest.add_ons[0]
  return typeof addOn === "string"
}

interface Props {
  // The test that should be displayed within the modal.
  labTest: AnyLimitedLabTest

  // The current practitioner. Can be undefined when the an anonymous user
  // accesses the catalog.
  practitioner?: Practitioner
  // Used to determine ordering rights
  signingPractitioner?: Practitioner | SigningPractitioner

  isOpen: boolean
  onClose: () => void

  // A map of selected lab tests by ID. Used to indicate which tests are
  // currently in the order or the bundle, depending on where it's rendered.
  // Can be undefined to allow the bundle editor within settings to not
  // provide selected lab tests.
  selectedLabTests?: { [labTestId: string]: AnyLabTest }

  // Handlers for adding/removing lab tests. These should effect the tests
  // within `selectedLabTests`.
  onAddLabTest?: (labTest: AnyLabTest, index?: number) => void
  onRemoveLabTest?: (labTest: AnyLabTest) => void

  // Where the modal was rendered. Used to alter the copy of the title
  // button (Start an order, Add to cart, etc) and the tooltip copy displayed
  // when the test can't be added.
  location: labTestLocation

  // Whether to render the title button.
  showTitleButton?: boolean

  // An internal prop used to determine whether an add-on has been opened
  // via its parent's modal. If true, we allow the add-on to be added. This
  // can be ignored for users of the component.
  _nestedDepth?: number
  createBundleClick?: () => void

  orderId?: string
  orderingRightsStatus?: OrderingRightsStatus
}

const LabTestModal = ({
  labTest: initialLabTest,
  practitioner,
  signingPractitioner,
  isOpen,
  onClose,
  // We set it to a private variable so we're able to detect whether
  // any tests were passed while maintaining the convenience of setting
  // a default.
  selectedLabTests: _selectedLabTests,
  onAddLabTest,
  onRemoveLabTest,
  location,
  showTitleButton: _showTitleButton = true,
  _nestedDepth = 0,
  createBundleClick,
  orderId,
  orderingRightsStatus,
}: Props) => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const [labTest, setLabTest] = useState<AnyLabTest | null>(
    !initialLabTest.is_partial_serialization
      ? (initialLabTest as AnyLabTest)
      : null
  )
  const [loading, setLoading] = useState(false)
  const labTestInTitle = labTest ?? initialLabTest

  // Show tooltip with button to override lab company restriction
  const [showOverrideOption] = useFeatureFlag(
    FeatureFlag.OrderAccessOverrideHover
  )

  useEffect(() => {
    if (!isOpen) {
      return
    }

    if (labTest && labTest.id === initialLabTest?.id) {
      return
    }

    setLoading(true)

    let action = ORDERING_RIGHTS_ACTIONS.ADD_TO_CART
    if (location === labTestLocation.BUNDLES) {
      action = ORDERING_RIGHTS_ACTIONS.ADD_TO_BUNDLE
    } else if (location === labTestLocation.CATALOG) {
      action = ORDERING_RIGHTS_ACTIONS.START_ORDER_WITH
    }
    const queryString = `?action=${action}${
      orderId ? `&order_id=${orderId}` : ""
    }`

    API.LabTest.get(initialLabTest.id, queryString).then((response) => {
      setLabTest(response.data as AnyLabTest)
      setLoading(false)
    })
  }, [initialLabTest, isOpen])

  const selectedLabTests = _selectedLabTests || {}
  const isInCart = labTest && selectedLabTests[labTest.id]

  let showTitleButton = _showTitleButton
  // If the test within the modal has already been selected (meaning its in
  // the order or the bundle), then we want to disable the "Add to Cart/Bundle"
  // button. We only need to do this if the button hasn't already been explicitly
  // disabled.
  if (showTitleButton && selectedLabTests && isInCart) {
    showTitleButton = false
  }

  const handleClickFavorite = useCallback(
    (labTestToFavorite: AnyLimitedLabTest) => {
      if (!practitioner) {
        return
      }

      if (practitioner.favorite_lab_test_id_set?.has(labTestToFavorite.id)) {
        dispatch(removeFavoriteTest(practitioner, labTestToFavorite))
      } else {
        dispatch(addFavoriteTest(practitioner, labTestToFavorite))
      }
    },
    [practitioner, dispatch, removeFavoriteTest, addFavoriteTest]
  )

  const handleAddAddOnLabTest = useCallback(
    async (addOnLabTest: AnyLabTest) => {
      if (!onAddLabTest) {
        return
      }

      // If the parent isn't already in the cart, add it before adding the child;
      if (!isInCart && labTest) {
        // wait for the parent to be added so the backend doesn't throw an ADD_ON_MISSING_PARENT error
        // when we try to add the add-on
        await onAddLabTest(labTest)
      }

      onAddLabTest(addOnLabTest)
    },
    [isInCart, onAddLabTest, labTest]
  )

  const handleClickTitleButton = useCallback(() => {
    if (!onAddLabTest || !labTest) {
      return
    }

    onAddLabTest(labTest)
    onClose()
  }, [labTest, onAddLabTest])

  /**
   * Set redux state for lab test override.
   *
   * This updated state will open the override modal and provide
   * the modal with the data it needs to submit an API call to
   * register the lab company override.
   *
   * @param includeClinicState if we should specify the specific clinic state
   */
  const overrideAccessAction = (includeClinicState: boolean) => {
    if (!labTest) {
      return
    }
    dispatch(
      Actions.setLabTestForOverride(
        labTest.lab_company,
        includeClinicState ? practitioner?.clinic?.state : undefined
      )
    )
    setHideTooltip(true)
  }

  const buttonUnavailableReason = getUnavailableReason({
    labTest: labTestInTitle,
    signingPractitioner,
    orderingPractitioner: practitioner,
    overrideAccessAction,
    showOverrideOption,
  })

  const infoOrderingIssue = useMemo(() => {
    const issues = orderingRightsStatus?.error_ordering_issues
    if (issues?.length) return issues[0]
  }, [orderingRightsStatus])

  // Having an exhaustive condition here is to properly typeguard all the types that AddonSection
  // depends on. That way it doesn't have to support possible undefined props.
  const showAddons =
    !!labTest?.add_ons?.length &&
    labTest.add_ons.length > 0 &&
    !isLabTestAddon(labTest) &&
    onAddLabTest &&
    onRemoveLabTest &&
    practitioner

  const [addOnOpenInModal, setAddOnOpenInModal] = useState<LabTestAddon | null>(
    null
  )

  const [hideTooltip, setHideTooltip] = useState(false)

  const handleHiddenTooltip = () => {
    if (hideTooltip) {
      setHideTooltip(false)
    }
  }

  if (!labTest && !loading) {
    return null
  }

  let dialogContent: any = null
  if (loading) {
    dialogContent = (
      <>
        <TitleSection
          labTest={labTestInTitle}
          unavailableReason={buttonUnavailableReason}
          onClose={onClose}
          isFavorited={
            practitioner?.favorite_lab_test_id_set?.has(initialLabTest?.id) ||
            false
          }
          shouldShowTitleButton={showTitleButton}
          shouldShowFavoriteButton={Boolean(practitioner)}
          location={location}
          onClickTitleButton={handleClickTitleButton}
          onClickFavorite={handleClickFavorite}
          hideTooltip={hideTooltip}
          handleHiddenTooltip={handleHiddenTooltip}
          practitioner={practitioner}
        />
        <MuiDialogContent className={styles.dialogContent}>
          <Loading ariaLabel="Loading Lab Test Details" />
        </MuiDialogContent>
      </>
    )
  }

  if (labTest && !loading) {
    dialogContent = (
      <>
        <TitleSection
          labTest={labTestInTitle}
          unavailableReason={buttonUnavailableReason}
          onClose={onClose}
          isFavorited={
            practitioner?.favorite_lab_test_id_set?.has(labTest.id) || false
          }
          shouldShowTitleButton={showTitleButton}
          shouldShowFavoriteButton={Boolean(practitioner)}
          location={location}
          onClickTitleButton={handleClickTitleButton}
          onClickFavorite={handleClickFavorite}
          hideTooltip={hideTooltip}
          handleHiddenTooltip={handleHiddenTooltip}
          practitioner={practitioner}
        />
        <MuiDialogContent className={styles.dialogContent}>
          <InformationSection
            details={labTest.details}
            rupaUrl={labTest.rupa_url}
            url={labTest.url}
            isAuthenticated={Boolean(practitioner)}
            vendorPhysAuthApproved={labTest.physician_authorization_approved}
            labCompanyName={labTest.lab_company.name}
            labCompanyDetails={labTest.lab_company.details}
            labCompanyKey={labTest.lab_company.key}
            msrpPrice={labTest.msrp_price}
            rupaPrice={labTest.rupa_price}
            labTestTypes={labTest.lab_test_types.map((type) => type.name)}
            shippingDaysMin={labTest.shipping_days_min}
            shippingDaysMax={labTest.shipping_days_max}
            activeShippingAlert={labTest.active_shipping_alert}
            estimatedDaysForResults={labTest.estimated_days_for_results}
            cheatsheetUrl={labTest.cheatsheet_url}
            sampleReportUrl={labTest.sample_report_url}
            stateOrderingRestrictions={labTest.state_ordering_restrictions}
            isPhlebotomyRequired={labTest.lab_test_types.some(
              (type) => type.phlebotomy_required
            )}
            orderingIssue={infoOrderingIssue}
          />
          {labTest.biomarkers.length !== 0 && (
            <Paper className={styles.sectionsContainer}>
              <BiomarkersSection
                biomarkers={labTest.biomarkers}
                location={location}
              />
            </Paper>
          )}
          {showAddons && (
            <Paper className={styles.sectionsContainer}>
              <AddOnSection
                labTest={labTest}
                onAdd={handleAddAddOnLabTest}
                onRemove={onRemoveLabTest}
                selectedLabTests={selectedLabTests}
                practitioner={practitioner}
                onToggleFavorite={handleClickFavorite}
                setAddOnOpenInModal={setAddOnOpenInModal}
                location={location}
                createBundleClick={createBundleClick}
              />
            </Paper>
          )}
        </MuiDialogContent>
      </>
    )
  }

  return (
    <>
      {addOnOpenInModal && showAddons && (
        <LabTestModal
          labTest={addOnOpenInModal}
          isOpen={isOpen}
          selectedLabTests={selectedLabTests}
          practitioner={practitioner}
          onAddLabTest={handleAddAddOnLabTest}
          onRemoveLabTest={onRemoveLabTest}
          onClose={() => setAddOnOpenInModal(null)}
          _nestedDepth={_nestedDepth + 1}
          location={location}
          showTitleButton={_showTitleButton}
        />
      )}
      <Dialog
        onClose={onClose}
        aria-labelledby="lab-test-modal-title"
        open={isOpen}
        className={clsx(styles.dialog, "fs-unmask")}
        maxWidth="md"
        classes={{
          paper: styles.dialogPaper,
        }}
      >
        {dialogContent}
      </Dialog>
    </>
  )
}

export default LabTestModal
