import { useEffect, useState } from "react"

import {
  DndContext,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  PointerSensor,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { Divider, Theme } from "@material-ui/core"
import FavoriteIcon from "@material-ui/icons/Favorite"

import BundleIconWhite from "app/assets/icons/bundle-white.svg"
import { ReactComponent as DragIcon } from "app/assets/images/storefront/drag-handle.svg"
import { CreateBundleOrPanelButton } from "app/components/CreateBundleOrPanelButton"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import GenericChoiceModal from "app/components/modals/GenericChoiceModal"
import useFeatureFlag from "app/hooks/use-feature-flag"
import BundleCard from "app/main/checkout/BundleCard"
import useBundleFavorites from "app/main/checkout/hooks/use-bundle-favorites"
import useClinicSharedBundles from "app/main/checkout/hooks/use-clinic-shared-bundles"
import useLaunchPanelBuilderDeepLink from "app/main/panel-builder/use-launch-panel-builder-deep-link"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import {
  trackBundleCreateClick,
  trackBundleDrag,
  trackBundleRemoveFromClinicConfirmationClick,
} from "app/services/segment"
import { favoritedColor } from "app/theme"
import {
  Practitioner,
  PractitionerLabTestBundle,
  labTestLocation,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import DroppableBundleSection from "./DroppableBundleSection"

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: 24,
    marginTop: 24,
  },
  bundlesSectionContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: 24,
  },
  bundlesSectionHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column nowrap",
      gap: 8,
    },
  },
  bundlesSectionTitle: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: 4,
    paddingRight: 10,
  },
  createBundleBtn: {
    width: "max-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  favoriteIcon: {
    color: favoritedColor,
    height: 14,
    width: 14,
  },
  dragIcon: {
    width: 18,
  },
})
const useStyles = makeAppStyles(styles)

interface Props {
  practitioner: Practitioner
  isInfoCard: boolean
  bundles: PractitionerLabTestBundle[]
  openBundleModal: (bundle?: PractitionerLabTestBundle) => void
  usePhysicianAuthorizationForOrderingAccess: boolean
  location: labTestLocation
  orderedBundleIds?: number[]
  onAdd?: () => void
  onRemove?: () => void
}

const SavedAndSharedBundles = (props: Props) => {
  const classes = useStyles()
  const [panelBuilderEnabled] = useFeatureFlag(FeatureFlag.PanelBuilder)

  useLaunchPanelBuilderDeepLink()

  const { shareWithClinic, unshareWithClinic } = useClinicSharedBundles()
  const { unfavoriteBundle } = useBundleFavorites()

  const [activeBundle, setActiveBundle] =
    useState<PractitionerLabTestBundle | null>(null)
  const [personalBundles, setPersonalBundles] = useState<
    PractitionerLabTestBundle[]
  >([])
  const [sharedBundles, setSharedBundles] = useState<
    PractitionerLabTestBundle[]
  >([])
  const [hasBundleBeenRemovedFromShared, setHasBundleBeenRemovedFromShared] =
    useState(false)
  const [
    hasBundleBeenRemovedFromPersonal,
    setHasBundleBeenRemovedFromPersonal,
  ] = useState(false)

  const [showRemoveModal, setShowRemoveModal] = useState(false)

  useEffect(() => {
    setPersonalBundles(
      props.bundles.filter((bundle) => !bundle.is_shared_bundle)
    )
    setSharedBundles(props.bundles.filter((bundle) => bundle.is_shared_bundle))
  }, [props.bundles])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  )

  const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: "0.5",
        },
      },
    }),
  }

  const handleDragStart = (event) => {
    const { active } = event

    setActiveBundle(
      props.bundles.find((bundle) => bundle.id === active.id) || null
    )
  }

  const movePersonalBundle = (personalBundle: PractitionerLabTestBundle) => {
    setPersonalBundles(
      personalBundles.filter((bundle) => bundle.id !== personalBundle.id)
    )
    setSharedBundles([personalBundle, ...sharedBundles])
  }

  const moveSharedBundle = (sharedBundle: PractitionerLabTestBundle) => {
    setSharedBundles(
      sharedBundles.filter((bundle) => bundle.id !== sharedBundle.id)
    )
    setPersonalBundles([sharedBundle, ...personalBundles])
  }

  const handleDragOver = (event) => {
    const { active, over } = event

    const sharedBundle = sharedBundles.find((bundle) => bundle.id === active.id)
    const personalBundle = personalBundles.find(
      (bundle) => bundle.id === active.id
    )

    if (over?.id === "sharedBundles" && personalBundle) {
      movePersonalBundle(personalBundle)
      setHasBundleBeenRemovedFromShared(false)
      setHasBundleBeenRemovedFromPersonal(true)
    } else if (over?.id === "personalBundles" && sharedBundle) {
      moveSharedBundle(sharedBundle)
      setHasBundleBeenRemovedFromShared(true)
      setHasBundleBeenRemovedFromPersonal(false)
    }
  }

  const handleDragEnd = (event) => {
    if (hasBundleBeenRemovedFromShared) {
      trackBundleDrag(
        props.practitioner.id,
        props.practitioner?.clinic?.id,
        "Shared",
        "Personal"
      )
      setShowRemoveModal(true)
    } else if (
      activeBundle &&
      !activeBundle.is_shared_bundle &&
      hasBundleBeenRemovedFromPersonal
    ) {
      trackBundleDrag(
        props.practitioner.id,
        props.practitioner?.clinic?.id,
        "Personal",
        "Shared"
      )
      shareWithClinic(activeBundle)
    }

    setHasBundleBeenRemovedFromShared(false)
    setHasBundleBeenRemovedFromPersonal(false)
  }

  const createABundle = () => {
    props.openBundleModal()
    trackBundleCreateClick(
      props.practitioner.id,
      props.practitioner?.clinic?.id
    )
  }

  const removeFromClinic = () => {
    if (activeBundle) {
      unshareWithClinic(activeBundle)
    }
  }

  const handleUnfavoriteBundle = async () => {
    if (activeBundle) {
      undoMoveBundleFromShared()
      await unfavoriteBundle(activeBundle)
    }
  }

  const undoMoveBundleFromShared = () => {
    if (activeBundle) {
      movePersonalBundle(activeBundle)
    }
  }

  const handleSecondaryModalClick = async () => {
    if (activeBundle?.is_favorited) {
      await handleUnfavoriteBundle()
    } else {
      undoMoveBundleFromShared()
    }

    setShowRemoveModal(false)
  }

  return (
    <>
      <Divider style={{ marginTop: 24 }} />
      <div className={classes.container}>
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          <div className={classes.bundlesSectionContainer}>
            <div className={classes.bundlesSectionHeader}>
              <div className={classes.bundlesSectionTitle}>
                <BodyText size="lg" weight="semibold">
                  Your Saved Bundles
                </BodyText>
                <BodyText size="base" weight="regular">
                  These bundles are visible only to you. Click the{" "}
                  <FavoriteIcon className={classes.favoriteIcon} /> button to
                  favorite it for use in the Cart. Use the{" "}
                  <DragIcon className={classes.dragIcon} /> handle to drag and
                  drop bundles into clinic section
                </BodyText>
              </div>
              <div>
                {panelBuilderEnabled ? (
                  <CreateBundleOrPanelButton
                    color={"primary"}
                    onCreateBundle={createABundle}
                  />
                ) : (
                  <DesignSystemButton
                    color="primary"
                    startIcon={BundleIconWhite}
                    className={classes.createBundleBtn}
                    onClick={createABundle}
                  >
                    Create a Bundle
                  </DesignSystemButton>
                )}
              </div>
            </div>
            <div className={classes.bundlesSectionContainer}>
              <DroppableBundleSection
                bundles={personalBundles}
                droppableId={"personalBundles"}
                practitioner={props.practitioner}
                isInfoCard={props.isInfoCard}
                openModal={props.openBundleModal}
                usePhysicianAuthorizationForOrderingAccess={
                  props.usePhysicianAuthorizationForOrderingAccess
                }
                location={props.location}
                orderedBundleIds={props.orderedBundleIds}
                onAdd={props.onAdd}
                onRemove={props.onRemove}
              />
            </div>
          </div>
          <div className={classes.bundlesSectionContainer}>
            <div className={classes.bundlesSectionHeader}>
              <div className={classes.bundlesSectionTitle}>
                <BodyText size="lg" weight="semibold">
                  Shared {props.practitioner?.clinic?.name} Bundles
                </BodyText>
                {sharedBundles.length ? (
                  <BodyText size="base" weight="regular">
                    These bundles are shared with everyone in your clinic. When
                    someone adds to your Shared Clinic Bundles, click the{" "}
                    <FavoriteIcon className={classes.favoriteIcon} /> button to
                    favorite it for use in the Cart.
                  </BodyText>
                ) : (
                  <BodyText size="base" weight="regular">
                    Create a group of lab tests for easy 1-click ordering and
                    share with everyone in your clinic.
                  </BodyText>
                )}
              </div>
            </div>
            <div className={classes.bundlesSectionContainer}>
              <DroppableBundleSection
                bundles={sharedBundles}
                droppableId={"sharedBundles"}
                practitioner={props.practitioner}
                isInfoCard={props.isInfoCard}
                openModal={props.openBundleModal}
                usePhysicianAuthorizationForOrderingAccess={
                  props.usePhysicianAuthorizationForOrderingAccess
                }
                location={props.location}
                orderedBundleIds={props.orderedBundleIds}
                onAdd={props.onAdd}
                onRemove={props.onRemove}
              />
            </div>
          </div>
          <DragOverlay dropAnimation={dropAnimation}>
            {activeBundle && (
              <BundleCard
                practitioner={props.practitioner}
                isInfoCard={props.isInfoCard}
                bundle={activeBundle}
                openModal={() => props.openBundleModal(activeBundle)}
                location={props.location}
                orderedBundleIds={props.orderedBundleIds}
                onAdd={props.onAdd}
                onRemove={props.onRemove}
                disableTooltip={true}
              />
            )}
          </DragOverlay>
        </DndContext>
        <GenericChoiceModal
          open={showRemoveModal}
          onClose={handleSecondaryModalClick}
          data={{
            title: "Remove From Clinic?",
            text: (
              <>
                <BodyText>
                  Are you sure you’d like to remove this bundle from your shared
                  clinic bundles? Your team members will lose access.
                </BodyText>
                {activeBundle?.is_favorited && (
                  <BodyText style={{ marginTop: 4 }}>
                    To not show the bundle in your cart, just click the
                    unfavorite button!
                  </BodyText>
                )}
              </>
            ),
            primaryButtonText: "Remove from Clinic",
            secondaryButtonText: activeBundle?.is_favorited
              ? "Unfavorite"
              : "Cancel",
          }}
          onPrimaryClick={async () => {
            trackBundleRemoveFromClinicConfirmationClick(
              props.practitioner.id,
              props.practitioner?.clinic?.id,
              activeBundle?.id
            )
            removeFromClinic()
            setShowRemoveModal(false)
          }}
          primaryActionButtonColor="destructiveSecondary"
          // TODO: Need to handle unfavoriting a shared bundle from a clinic in future PR
          onSecondaryClick={handleSecondaryModalClick}
          secondaryActionButtonColor="noaction"
        />
      </div>
    </>
  )
}

export default SavedAndSharedBundles
