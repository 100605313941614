import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { Redirect, Route, Switch } from "react-router-dom"

import { Container } from "@material-ui/core"

import { AllowAnyPaths } from "app/Routes/paths"
import { isUserPractitioner } from "app/auth/util"
import { CreateBundleOrPanelButton } from "app/components/CreateBundleOrPanelButton"
import LabTests from "app/components/LabTests/LabTests"
import PageToolbar from "app/components/PageToolbar"
import RupaButton from "app/components/design-system/Button"
import BundleModal from "app/components/modals/BundleModal"
import StartOrderModal from "app/components/modals/StartOrderModal"
import useAppSelector from "app/hooks/useAppSelector"
import { markTestToAddToNextOrder } from "app/main/checkout/store/actions"
import checkoutReducer from "app/main/checkout/store/reducers"
import ComparisonProvider from "app/main/comparison/ComparisonProvider"
import { ComparisonCallToActionTypes } from "app/main/comparison/types"
import { getPatients } from "app/main/dashboard/store/actions"
import dashboardReducer from "app/main/dashboard/store/reducers"
import { trackEventWithProperties } from "app/services/segment.typed"
import {
  addFavoriteTest,
  getTeamMembers,
  removeFavoriteTest,
} from "app/store/actions"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import withReducer from "app/store/withReducer"
import { colors } from "app/theme"
import { AnyLabTest, LabTestListItem, labTestLocation } from "app/types"
import { getStartDraftText } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

import { ComboGroupEvents } from "../combo-groups/constants"
import CompareTestsButton from "../comparison/components/CompareTestsButton"
import CatalogBanner from "./CatalogBanner"
import { useCatalogWelcomeModal } from "./welcome-modal/CatalogWelcomeModal"

const useDiscoverLabsStyles = makeAppStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(10),
    height: "100%",
  },
  iframeContainer: {
    marginTop: theme.spacing(4),
  },
  unauthenticatedBreadcrumbsContainer: {
    background: "white",
    borderBottom: `1px solid ${colors.coolGray[200]}`,
    top: 0,
    zIndex: theme.zIndex.mobileStepper,
    [theme.breakpoints.up("md")]: {
      position: "sticky",
    },
  },
  unauthenticatedBreadcrumbs: {
    maxWidth: 1550,
    marginLeft: "auto",
    marginRight: "auto",
  },
}))

function DiscoverLabs() {
  const classes = useDiscoverLabsStyles()
  const dispatch = useDispatch()
  const user = useAppSelector(({ auth }) => auth.user)
  const practitioner = useAppSelector(selectPractitioner)
  const practitionerBundles = useAppSelector(
    ({ practitionerBundles }) => practitionerBundles.results
  )
  useCatalogWelcomeModal()
  const startDraftText = getStartDraftText(practitioner)
  const [isStartOrderModalOpen, setIsStartOrderModalOpen] = useState(false)
  const [bundleModalIsOpen, setBundleModalIsOpen] = useState(false)

  const isAuthenticated = useMemo(() => {
    return isUserPractitioner(user)
  }, [user])

  useEffect(() => {
    const load = async () => {
      if (isAuthenticated) {
        // fetch for signing practitioner filter
        dispatch(getTeamMembers())
        // fetch upfront for 'Start an Order'
        // could move this to when user actually clicks button
        // would want to add in loading and failure states
        dispatch(getPatients())
      }
    }

    load()
  }, [dispatch, isAuthenticated])

  const onAddLabTest = (labTest: LabTestListItem | AnyLabTest) => {
    dispatch(markTestToAddToNextOrder(labTest))
    setIsStartOrderModalOpen(true)

    if (labTest.combo_group) {
      trackEventWithProperties(
        ComboGroupEvents.COMBO_GROUP_LAB_TEST_START_ORDER_CLICKED,
        {
          comboGroupLabTestId: labTest.id,
          practitionerId: practitioner?.id,
          comboGroupId: labTest.combo_group,
        }
      )
    }
  }

  const onClickStartOrder = () => {
    setIsStartOrderModalOpen(true)
  }

  function handleToggleFavorite(lab_test, isFavorite) {
    if (isFavorite) {
      dispatch(removeFavoriteTest(practitioner, lab_test))
    } else {
      dispatch(addFavoriteTest(practitioner, lab_test))
    }
  }

  function closeBundleModal() {
    setBundleModalIsOpen(false)
  }

  return (
    <ComparisonProvider
      callToAction={ComparisonCallToActionTypes.START_ORDER}
      onCallToAction={onAddLabTest}
    >
      {isAuthenticated && (
        <PageToolbar title="Lab Test Catalog">
          <CompareTestsButton />
          <CreateBundleOrPanelButton
            color={"secondary"}
            onCreateBundle={() => setBundleModalIsOpen(true)}
            defaultBorderColor
          />
          {startDraftText && (
            <RupaButton
              size="small"
              color="primary"
              onClick={onClickStartOrder}
            >
              {startDraftText}
            </RupaButton>
          )}
        </PageToolbar>
      )}
      {!isAuthenticated && <CatalogBanner />}
      <Container className={classes.container} maxWidth="xl">
        <Switch>
          <Route path={AllowAnyPaths.DISCOVER_LABS_SEARCH}>
            <LabTests
              autoFocusSearch
              location={labTestLocation.CATALOG}
              onAddLabTest={onAddLabTest}
              practitioner={practitioner}
              signingPractitioner={practitioner}
              syncWithUrl
            />
          </Route>
          <Route path={AllowAnyPaths.DISCOVER_LABS}>
            <Redirect to={AllowAnyPaths.DISCOVER_LABS_SEARCH} />
          </Route>
        </Switch>
      </Container>
      {isAuthenticated && (
        <StartOrderModal
          open={isStartOrderModalOpen}
          onClose={() => setIsStartOrderModalOpen(false)}
          shouldHideCreatePatientButton
        />
      )}
      {isAuthenticated && practitioner && (
        <BundleModal
          open={bundleModalIsOpen}
          practitioner={practitioner}
          practitionerBundles={practitionerBundles}
          onClose={closeBundleModal}
          handleToggleLabTestFavorite={handleToggleFavorite}
          usePhysicianAuthorizationForOrderingAccess={false}
          location={labTestLocation.CATALOG}
        />
      )}
    </ComparisonProvider>
  )
}

export default withReducer(
  "orders",
  checkoutReducer
)(withReducer("dashboard", dashboardReducer)(DiscoverLabs))
