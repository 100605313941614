import { useMemo } from "react"

import { Button, IconButton, TableCell, TableRow } from "@material-ui/core"
import FavoriteIcon from "@material-ui/icons/Favorite"

import lockIcon from "app/assets/images/lock.svg"
import SampleTypePill from "app/components/SampleTypePill"
import { LABCORP_COMPANY_KEYS, QUEST_COMPANY_KEYS } from "app/constants"
import { ComboGroupConfigurationPayload } from "app/main/combo-groups/constants"
import { colors, favoritedColor } from "app/theme"
import {
  AnyLimitedLabTest,
  Practitioner,
  SigningPractitioner,
  labTestLocation,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import BodyText from "../design-system/BodyText"
import LabTestButtons from "./LabTestButtons"
import PendingPlaceholder from "./PendingPlaceholder"
import { SelectedLabTests } from "./types"

const useStyles = makeAppStyles<{
  isFavorited: boolean
  isPending: boolean
  disabledStyle: boolean
}>({
  container: {
    backgroundColor: "white",
    height: 80,

    "&:not(:last-child)": {
      borderBottom: `1px solid ${colors.blueGray[200]}`,
    },

    "& .favoriteIcon": {
      opacity: (props) => (!props.isPending ? 1 : 0),
    },
  },
  favoriteTableCell: {
    padding: 0,
  },
  favoriteIcon: {
    opacity: (props) => (props.isPending ? 0 : 1),
    color: (props) =>
      props.isFavorited ? favoritedColor : colors.blueGray[200],
    transition: "0.08s opacity",
  },
  favoriteIconButton: {
    padding: 1,
    marginLeft: 7,
    marginRight: -10,
    borderRadius: 4,
    width: 24,
  },
  tableCellBody: {
    borderBottom: "none",
    opacity: (props) => (props.disabledStyle ? 0.4 : 1),
  },
  tableCellRoot: {
    padding: "18px 16px",
  },
  labTestNameContainer: {
    display: "flex",
    flexFlow: "column",
    width: "100%",
  },
  labTestTypeContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  phlebotomyRequired: {
    marginTop: 4,
  },
  signUpText: {
    marginLeft: 4,
  },
  pendingPlaceholder: {
    background: colors.blueGray[300],
    borderRadius: 40,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    flexShrink: 0,

    "&:not(:first-child)": {
      marginLeft: 12,
    },
  },
})

interface BaseProps {
  labTest?: AnyLimitedLabTest
  practitioner?: Practitioner
  signingPractitioner?: Practitioner | SigningPractitioner
  isPending: boolean
  location: labTestLocation
  selectedLabTests?: SelectedLabTests
  onAddLabTest?: (labTest: AnyLimitedLabTest) => void
  onRemoveLabTest?: (labTest: AnyLimitedLabTest) => void
  createBundleClick?: () => void
  vendorPhysicianServicesActivated?: boolean
  onAddComboGroup?: (
    labTest: AnyLimitedLabTest,
    payload: ComboGroupConfigurationPayload
  ) => void
}

type PendingProps = BaseProps & {
  labTest?: never
  setLabTestInModal?: never
  onToggleFavorite?: never
  isPending: true
}

type LoadedProps = BaseProps & {
  labTest: AnyLimitedLabTest
  isPending: boolean
  setLabTestInModal: (labTest: AnyLimitedLabTest) => void
  onToggleFavorite: (labTest: AnyLimitedLabTest) => void
}

type Props = PendingProps | LoadedProps

function LabTestListDesktopRow(props: Props) {
  // NOTE: We can't destructure the props in the function definition as it breaks
  // typeguarding on the discrimated union. So we destructure below so we can use
  // them when don't need to discriminate and retain `props` for when we do.
  const { practitioner, labTest, isPending } = props

  const isFavorited = Boolean(
    labTest && practitioner?.favorite_lab_test_id_set?.has(labTest.id)
  )
  const disabledStyle = useMemo(() => {
    if (props.location !== labTestLocation.CATALOG) {
      // The disabledStyle is only applied in the catalog
      return false
    }
    return labTest?.ordering_rights_status?.allowed === false
  }, [props.location, labTest?.ordering_rights_status?.allowed])

  const styles = useStyles({ isFavorited, isPending, disabledStyle })

  const phlebotomyRequired =
    labTest && labTest.lab_test_types.some((type) => type.phlebotomy_required)

  const tableCellClasses = {
    body: styles.tableCellBody,
    root: styles.tableCellRoot,
  }

  const getPhlebotomyText = () => {
    if (!labTest) return ""
    if (LABCORP_COMPANY_KEYS.includes(labTest.lab_company.key)) {
      return "Phlebotomy via Labcorp"
    }
    if (QUEST_COMPANY_KEYS.includes(labTest.lab_company.key)) {
      return "Phlebotomy via Quest"
    }
    if (phlebotomyRequired) {
      return "Phlebotomy required"
    }
    return ""
  }

  return (
    <TableRow className={styles.container}>
      {practitioner && (
        <TableCell
          classes={{ body: styles.tableCellBody }}
          className={styles.favoriteTableCell}
        >
          {!props.isPending && (
            <IconButton
              className={styles.favoriteIconButton}
              onClick={() => props.onToggleFavorite(props.labTest)}
              aria-label="Favorite test"
            >
              <FavoriteIcon
                fontSize="small"
                className={`${styles.favoriteIcon} favoriteIcon`}
              />
            </IconButton>
          )}
        </TableCell>
      )}
      <TableCell classes={tableCellClasses}>
        <div className={styles.labTestNameContainer}>
          {!props.isPending ? (
            <>
              <BodyText weight="bold">{props.labTest.name}</BodyText>
              <BodyText weight="regular" size="sm">
                {props.labTest.lab_company.name}
              </BodyText>
            </>
          ) : (
            <>
              <PendingPlaceholder color={colors.blueGray[400]} width={148} />
              <PendingPlaceholder width={100} />
            </>
          )}
        </div>
      </TableCell>
      <TableCell classes={tableCellClasses}>
        <div className={styles.labTestTypeContainer}>
          {!props.isPending ? (
            props.labTest.lab_test_types.map((type) => {
              return (
                <SampleTypePill
                  name={type.name.toLowerCase()}
                  key={type.name}
                  disabled={disabledStyle}
                  style={{
                    marginLeft: 0,
                    marginRight: 2,
                  }}
                />
              )
            })
          ) : (
            <>
              <PendingPlaceholder />
              <PendingPlaceholder />
            </>
          )}
        </div>
        {phlebotomyRequired && !isPending && (
          <BodyText className={styles.phlebotomyRequired} size="sm">
            {getPhlebotomyText()}
          </BodyText>
        )}
      </TableCell>
      <TableCell classes={tableCellClasses}>
        {!props.isPending ? (
          QUEST_COMPANY_KEYS.includes(props.labTest.lab_company.key) ? (
            <BodyText weight="semibold">Varies by panel</BodyText>
          ) : practitioner ? (
            <BodyText weight="semibold">${props.labTest.rupa_price}</BodyText>
          ) : (
            <Button href="https://www.rupahealth.com/sign-up">
              <img src={lockIcon} alt="lock" width={17} height={17} />
              <BodyText weight="semibold" className={styles.signUpText}>
                Sign up to view
              </BodyText>
            </Button>
          )
        ) : (
          <PendingPlaceholder />
        )}
      </TableCell>
      <TableCell classes={tableCellClasses} className="opacity-100">
        {!props.isPending && (
          <LabTestButtons
            labTest={props.labTest}
            setLabTestInModal={props.setLabTestInModal}
            selectedLabTests={props.selectedLabTests}
            onAddLabTest={props.onAddLabTest}
            onRemoveLabTest={props.onRemoveLabTest}
            location={props.location}
            practitioner={practitioner}
            signingPractitioner={props.signingPractitioner}
            createBundleClick={props.createBundleClick}
            vendorPhysicianServicesActivated={
              props.vendorPhysicianServicesActivated
            }
            onAddComboGroup={props.onAddComboGroup}
          />
        )}
      </TableCell>
    </TableRow>
  )
}

export default LabTestListDesktopRow
