import { useDraggable } from "@dnd-kit/core"
import { Theme } from "@material-ui/core"

import DragHandleIcon from "app/assets/images/storefront/drag-handle.svg"
import BundleCard from "app/main/checkout/BundleCard"
import {
  Practitioner,
  PractitionerLabTestBundle,
  labTestLocation,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

const styles = (theme: Theme) => ({
  bundleDragButton: {
    position: "absolute" as any,
    top: 14,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 999,
    "&:hover": {
      cursor: "grab",
    },
    "&:active": {
      cursor: "grabbing",
    },
  },
})
const useStyles = makeAppStyles(styles)

interface Props {
  bundle: PractitionerLabTestBundle
  practitioner: Practitioner
  isInfoCard: boolean
  openModal: (bundle: PractitionerLabTestBundle) => void
  usePhysicianAuthorizationForOrderingAccess: boolean
  location: labTestLocation
  orderedBundleIds?: number[]
  onAdd?: () => void
  onRemove?: () => void
}

const DraggableBundle = (props: Props) => {
  const classes = useStyles()
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.bundle.id,
    disabled:
      props.bundle.shared_by !== props.practitioner.id &&
      props.bundle.is_shared_bundle,
  })

  return (
    <div ref={setNodeRef} style={{ position: "relative" }}>
      {props.bundle.shared_by === props.practitioner.id && (
        <div
          {...attributes}
          {...listeners}
          className={classes.bundleDragButton}
        >
          <img src={DragHandleIcon} alt="drag handle" />
        </div>
      )}
      <BundleCard
        practitioner={props.practitioner}
        isInfoCard={props.isInfoCard}
        bundle={props.bundle}
        openModal={() => props.openModal(props.bundle)}
        location={props.location}
        orderedBundleIds={props.orderedBundleIds}
        onAdd={props.onAdd}
        onRemove={props.onRemove}
        disableTooltip={true}
      />
    </div>
  )
}

export default DraggableBundle
