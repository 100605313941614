import { PatientOrderEvent } from "app/main/patient-orders/constants"

/**
 * Tracks when a patient portal authentication fails.
 *
 * @param token the token
 * @param detail the detail
 * @param code the code
 */
export function trackPatientPortalAuthenticationFailed(
  token: string | null,
  detail: string,
  code: string
) {
  trackEventWithProperties("patient_portal_authentication_failed", {
    token,
    detail,
    code,
  })
}

/**
 * Tracks when a patient portal page is visited.
 *
 * @param token the token
 * @param pathname the pathname
 */
export function trackPatientPortalVisit(
  token: string | null,
  pathname: string
) {
  trackEventWithProperties("patient_portal_visit", {
    token,
    url: window.location.host + pathname,
  })
}

/**
 * Tracks actions made by the user in the patient portal.
 *
 * @param token the token
 * @param actionType the type of action
 */
export function trackPatientPortalAction(
  token: string | null,
  actionType:
    | "download_results"
    | "download_receipt"
    | "download_superbill"
    | "download_requisition"
) {
  trackEventWithProperties("patient_portal_action", {
    token,
    actionType,
  })
}

/**
 * Tracks actions taken by a practitioner with orders in the dashboard.
 *
 * @param actionType the type of action
 * @param practitioner_id the practitioner id
 */
export function trackPractitionerDashboardAction(
  actionType: PatientOrderEvent,
  practitioner_id: string
) {
  trackEventWithProperties(actionType, {
    practitioner_id: practitioner_id,
  })
}

export function trackInteractiveReportView(
  user_type: "patient" | "practitioner",
  order_id?: string,
  lab_company_id?: string
) {
  trackEventWithProperties("interactive_report_view", {
    order_id,
    lab_company_id,
    user_type,
  })
}
/*
 * These methods send events to segment, which are then forwarded to MixPanel.
 */
export function trackEventWithProperties(name: string, properties: object) {
  window.analytics.track({
    event: name,
    properties,
  })
}
