import { StyledFormControlLabel } from "../components/StyledFormControlLabel"
import { StyledSwitch } from "../components/StyledSwitch"

export interface PreliminaryResultNotificationsProps {
  value: boolean | undefined
  onToggle: () => void
}

const PreliminaryResultNotificationsToggle = ({
  value,
  onToggle,
}: PreliminaryResultNotificationsProps) => {
  return (
    <div>
      <p className="font-semibold text-lg">Preliminary Result Notifications</p>
      <StyledFormControlLabel
        control={
          <div>
            <StyledSwitch
              onChange={onToggle}
              checked={value}
              name="notify_for_result_statuses"
            />
          </div>
        }
        label={
          <div>
            <p className="font-semibold">Email Me</p>
            <p>
              Get notified as results come in, even if all biomarkers aren't
              complete. You'll still always get an email when all results come
              in.
            </p>
          </div>
        }
      />
    </div>
  )
}

export default PreliminaryResultNotificationsToggle
