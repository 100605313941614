import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { isEmpty } from "lodash"

import { makeStyles } from "@material-ui/core"
import Container from "@material-ui/core/Container"

import { API } from "app/api"
import { USER_SHOWN_EMAIL_VERIFIED_MESSAGE } from "app/auth/store/actions/user.actions"
import PageToolbar from "app/components/PageToolbar"
import Button from "app/components/design-system/Button"
import NewCouponSlideOutModal from "app/components/modals/NewCouponSlideOutModal"
import StartOrderModal from "app/components/modals/StartOrderModal"
import { PHYSICIAN_SERVICES_ENABLE_METHODS, SURVEY_STATUS } from "app/constants"
import DashboardOrdersTable from "app/dashboard/components/DashboardOrdersTable"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import useIntercomAutoLaunch from "app/hooks/useIntercomAutoLaunch"
import DashboardOnboarding from "app/main/dashboard/DashboardOnboarding"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { showMessage } from "app/store/actions/fuse"
import withReducer from "app/store/withReducer"
import { AuthUser, Coupon, RootState, User } from "app/types"
import { getStartDraftText } from "app/utils"
import { Cookie, getCookie } from "app/utils/cookie-util"

import Alerts from "./Alerts"
import DashboardCards from "./DashboardCards"
import NoNPIBanner from "./NoNPIBanner"
import PhysicianServicesTermsBanner from "./PhysicianServicesTermsBanner"
import VerifyEmailBanner from "./VerifyEmailBanner"
import * as Actions from "./store/actions"
import reducer from "./store/reducers"

const useStyles = makeStyles({
  contentContainer: {
    marginBottom: 84,
  },
  noNPIBanner: {
    marginTop: 19,
  },
})

function Dashboard() {
  const dispatch = useDispatch()

  const [taskBasedOnboarding] = useFeatureFlag(FeatureFlag.TaskBasedOnboarding)
  const [physicianServicesBackfillPhase1Enabled] = useFeatureFlag(
    FeatureFlag.PhysicianServicesBackfillPhase1
  )
  const [physicianServicesBackfillPhase2Enabled] = useFeatureFlag(
    FeatureFlag.PhysicianServicesBackfillPhase2
  )

  const { shouldShowEmailVerifiedMessage } = useSelector<RootState, AuthUser>(
    ({ auth }) => auth.user
  )
  const { force_password_change: forcePasswordChange } = useSelector<
    RootState,
    User
  >(({ profile }) => profile.user || {})
  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const [isStartOrderModalOpen, setIsStartOrderModalOpen] = useState(false)

  const { contentContainer, noNPIBanner } = useStyles()

  const [coupon, setCoupon] = useState<Coupon | undefined>()
  const [showSnackbar, setShowSnackbar] = useState(false)

  const startDraftText = getStartDraftText(practitioner)

  useEffect(() => {
    const promoCouponCodeCookie = getCookie("promotional_coupon_code")

    const createCouponUser = async (cookie: Cookie) => {
      const code = cookie.value
      try {
        await API.Coupon.createCouponUser(code)

        const response = await API.Coupon.getList({ show_prev_used: false })

        // First coupon will be the one we just created
        setCoupon(response.data.results.find((c) => c.code === code))
      } catch (error) {
        const response = await API.Coupon.getList({ show_prev_used: false })

        if (response.data.results.length) {
          setCoupon(response.data.results.find((c) => c.code === code))
        }
      }
    }

    const surveyAwaitingOrInProgress = [
      SURVEY_STATUS.AWAITING_USER_TRIGGER,
      SURVEY_STATUS.STARTED_AND_INCOMPLETE,
    ].includes(practitioner?.onboarding_survey_status)

    if (
      practitioner?.user &&
      promoCouponCodeCookie &&
      !surveyAwaitingOrInProgress
    ) {
      createCouponUser(promoCouponCodeCookie)
    }
  }, [practitioner])

  useEffect(() => {
    if (practitioner?.user && coupon) {
      setShowSnackbar(true)
    }
  }, [practitioner, coupon])

  useEffect(() => {
    if (shouldShowEmailVerifiedMessage) {
      dispatch(
        showMessage({
          message: "Your email address has been verified.",
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            // Put this on the left so that it doesn't overlap the 'Start new Order' button for the tour
            horizontal: "left",
          },
        })
      )
      dispatch({
        type: USER_SHOWN_EMAIL_VERIFIED_MESSAGE,
      })
    }
  }, [shouldShowEmailVerifiedMessage])

  // Automatically launch intercom if the "messages" query param is set
  useIntercomAutoLaunch()

  useEffect(() => {
    dispatch(Actions.getPatients())
  }, [dispatch])

  const handleStartNewOrder = () => setIsStartOrderModalOpen(true)

  const [isUpdatePasswordOpen, setIsUpdatePasswordOpen] = useState(false)
  useEffect(() => {
    setIsUpdatePasswordOpen(forcePasswordChange || false)
  }, [forcePasswordChange])

  return (
    <>
      <PageToolbar
        title={
          practitioner.clinic
            ? `${practitioner.clinic.name} Dashboard`
            : "Dashboard"
        }
      >
        {startDraftText && (
          <Button
            size="medium"
            color="primary"
            onClick={handleStartNewOrder}
            className="tour-onboarding-start-order"
          >
            {startDraftText}
          </Button>
        )}
      </PageToolbar>
      <Container className={contentContainer} maxWidth="xl">
        {practitioner && !isEmpty(practitioner) && !taskBasedOnboarding && (
          <DashboardCards practitioner={practitioner} />
        )}
        {physicianServicesBackfillPhase1Enabled &&
        !physicianServicesBackfillPhase2Enabled &&
        practitioner.vendor_physician_authorization_enabled &&
        practitioner.physician_services_enable_method !==
          PHYSICIAN_SERVICES_ENABLE_METHODS.OPT_IN ? (
          <PhysicianServicesTermsBanner
            practitionerHasPhysicianServicesLabshop={
              practitioner.has_published_physician_services_labshop
            }
          />
        ) : null}
        <div className={noNPIBanner}>
          <NoNPIBanner />
        </div>
        <VerifyEmailBanner />
        <Alerts />
        {!isEmpty(practitioner) && (
          <DashboardOrdersTable practitioner={practitioner} />
        )}
      </Container>
      {!isUpdatePasswordOpen && <DashboardOnboarding />}
      <StartOrderModal
        open={isStartOrderModalOpen}
        onClose={() => setIsStartOrderModalOpen(false)}
      />
      <NewCouponSlideOutModal
        open={!isUpdatePasswordOpen && showSnackbar}
        coupon={coupon}
        startNewOrder={handleStartNewOrder}
        practitioner={practitioner}
        closeSlideout={() => setShowSnackbar(false)}
      />
    </>
  )
}

export default withReducer("dashboard", reducer)(Dashboard)
