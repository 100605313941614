import { useDispatch } from "react-redux"

import { updatePractitionerState } from "app/store/actions"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { Practitioner } from "app/types"
import { handleApiError } from "app/utils"
import { Clinic } from "types/clinic"

interface Props {
  practitioner: Practitioner | undefined
  onSuccess?: (clinic: Clinic | undefined) => void
}

export default function useToggleInOfficeKits({
  practitioner,
  onSuccess,
}: Props) {
  const dispatch = useDispatch()
  const {
    data: clinic,
    mutate,
    isLoading,
  } = useResourceSWR<Clinic>(
    practitioner ? `/clinics/${practitioner?.clinic.id}/` : null
  )
  const value = !!clinic?.attributes.clinic_features.includes(
    "allow_in_office_kits"
  )
  const disabledReason = practitioner?.clinic?.in_office_kits_disabled_reason
  const disabled = !practitioner || !clinic || isLoading || !!disabledReason

  async function toggleInOfficeKits() {
    if (!clinic || isLoading || !practitioner) return

    let features = clinic.attributes.clinic_features

    if (value) {
      features = features.filter((f) => f !== "allow_in_office_kits")
    } else {
      features.push("allow_in_office_kits")
    }

    try {
      await mutate(
        async () => {
          const { data } = await resourceRequest<ResourceResponse<Clinic>>({
            method: "PATCH",
            url: `/clinics/${clinic.id}/`,
            data: {
              data: {
                type: "clinic",
                id: clinic.id,
                attributes: {
                  clinic_features: features,
                },
              },
            },
          })

          return data
        },
        {
          revalidate: true,
        }
      ).then((clinicRes) => {
        if (clinicRes) {
          const newPractitioner = {
            ...practitioner,
            clinic: {
              ...practitioner.clinic,
              clinic_features: clinicRes.attributes.clinic_features,
            },
          }
          dispatch(updatePractitionerState(newPractitioner))
        }
        onSuccess?.(clinicRes)
      })
    } catch (error) {
      handleApiError(error)
    }
  }

  return { value, disabled, disabledReason, toggleInOfficeKits }
}
