import clsx from "clsx"

import { ReactComponent as RightCarrotIcon } from "app/assets/icons/labshops/carrot-right.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import BloodLabDashboardsBiomarkerStatusIndicator from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/statuses/BloodLabDashboardsBiomarkerStatusIndicator"
import BloodLabDashboardsBodySystemStatusBar from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/statuses/BloodLabDashboardsBodySystemStatusBar"
import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import useDiscreteResultDetailModal from "app/patient-portal/blood-lab-dashboard/modals/use-discrete-result-detail-modal"
import { getBiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/utils"
import {
  PatientPortalClinic,
  PatientPortalPractitioner,
} from "app/patient-portal/types"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { ResourceIdentifier } from "app/swr/types"
import { colors, navy, primaryColor, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { Clinic } from "types/clinic"
import { DiscreteResult } from "types/discrete-result"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"
import { Practitioner } from "types/practitioner"

import useBloodLabDashboardsDiscreteResultDetailModal from "../hooks/use-blood-lab-dashboards-discrete-result-modal"

const useStyles = makeAppStyles(() => ({
  container: {
    width: "100%",
    background: "white",
    display: "flex",
    flexDirection: "column",
    gap: 25,
    borderRadius: 10,
    border: `1px solid ${colors.blueGray[200]}`,
    boxShadow: shadows.sm,
    padding: "14px 14px 16px",
    position: "relative",
    overflow: "hidden",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      border: "1px solid #60A5FA",
      boxShadow:
        "0px 0.99042px 1.98083px 0px rgba(0, 0, 0, 0.06), 0px 0.99042px 2.97125px 0px rgba(0, 0, 0, 0.10)",
      transform: "scale(1.03, 1.03)",
      transition: "all 0.2s ease-in-out",
    },
    justifyContent: "space-between",
  },
  biomarkerStatus: {
    textTransform: "uppercase",
  },
  biomarkerStatusHighLow: {
    color: colors.red[600],
  },
  biomarkerStatusHighLowOptimal: {
    color: colors.yellow[600],
  },
  noRangeBioamarkerStatus: {
    color: navy,
  },
  biomarkerStatusOptimal: {
    color: colors.emerald[600],
  },
  biomarkerValue: {
    fontSize: 22,
    fontWeight: 600,
    color: navy,
  },
  biomarkerUnits: {
    fontSize: 13,
    color: colors.blueGray[400],
    fontWeight: 600,
  },
  detailsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  detailsBtn: {
    height: "fit-content",
  },
  wordWrap: {
    wordWrap: "break-word",
  },
  redValueColor: {
    color: colors.red[900],
  },
  yellowValueColor: {
    color: colors.yellow[900],
  },
  greenValueColor: {
    color: colors.emerald[900],
  },
  redStatusColor: {
    color: colors.red[700],
  },
  yellowStatusColor: {
    color: colors.yellow[700],
  },
  greenStatusColor: {
    color: colors.emerald[700],
  },
}))

interface Props {
  biomarkerIdentifier: ResourceIdentifier
  useOptimalRanges?: boolean
  getMatchingDiscreteResultByBiomarkerId: (
    biomarkerId?: string
  ) => DiscreteResult | undefined
  getMatchingOptimalRangeByBiomarkerId: (
    biomarkerId?: string
  ) => LabCompanyBiomarkerRange | undefined
  practitioner?: PatientPortalPractitioner | Practitioner
  clinic?: PatientPortalClinic | Clinic
  isPatientPreview: boolean
}

export const BloodLabDashboardsBodySystemCard = ({
  biomarkerIdentifier,
  useOptimalRanges,
  getMatchingDiscreteResultByBiomarkerId,
  getMatchingOptimalRangeByBiomarkerId,
  practitioner,
  clinic,
  isPatientPreview,
}: Props) => {
  const classes = useStyles()

  const biomarker = useCachedResource<Biomarker>(biomarkerIdentifier)
  const discreteResult = getMatchingDiscreteResultByBiomarkerId(biomarker?.id)
  const optimalRange = getMatchingOptimalRangeByBiomarkerId(biomarker?.id)

  const patientPortalModal = useDiscreteResultDetailModal()
  const bloodLabDashboardsModal =
    useBloodLabDashboardsDiscreteResultDetailModal()

  const discreteResultDetailModal = isPatientPreview
    ? bloodLabDashboardsModal
    : patientPortalModal

  const statusStyleMap = {
    below_optimal: classes.biomarkerStatusHighLowOptimal,
    above_optimal: classes.biomarkerStatusHighLowOptimal,
    optimal: classes.biomarkerStatusOptimal,
    high: classes.biomarkerStatusHighLow,
    low: classes.biomarkerStatusHighLow,
    normal: classes.biomarkerStatusOptimal,
  }

  const biomarkerStatus = getBiomarkerStatus(
    discreteResult,
    optimalRange,
    useOptimalRanges
  )

  const valueStyle = {
    [BiomarkerStatus.HIGH]: classes.redValueColor,
    [BiomarkerStatus.LOW]: classes.redValueColor,
    [BiomarkerStatus.ABOVE_OPTIMAL]: classes.yellowValueColor,
    [BiomarkerStatus.BELOW_OPTIMAL]: classes.yellowValueColor,
    [BiomarkerStatus.NORMAL]: classes.greenValueColor,
    [BiomarkerStatus.OPTIMAL]: classes.greenValueColor,
  }

  const statusStyle = {
    [BiomarkerStatus.HIGH]: classes.redStatusColor,
    [BiomarkerStatus.LOW]: classes.redStatusColor,
    [BiomarkerStatus.ABOVE_OPTIMAL]: classes.yellowStatusColor,
    [BiomarkerStatus.BELOW_OPTIMAL]: classes.yellowStatusColor,
    [BiomarkerStatus.NORMAL]: classes.greenStatusColor,
    [BiomarkerStatus.OPTIMAL]: classes.greenStatusColor,
  }

  if (!biomarker) {
    return null
  }

  return (
    <div
      className={classes.container}
      onClick={() =>
        discreteResultDetailModal.show({
          discreteResult,
          optimalRange,
          biomarkerStatus,
          practitionerId: practitioner?.id,
          clinicName: clinic?.attributes.name || "Practitioner's Clinic",
          useOptimalRanges,
          onClose: () => discreteResultDetailModal.hide(),
        })
      }
    >
      <BodyText size="md" weight="bold" className={classes.wordWrap}>
        {biomarker?.attributes.short_name}
      </BodyText>

      <div className={classes.detailsContainer}>
        <div className={classes.detailsText}>
          <BodyText
            size="sm"
            weight="bold"
            className={clsx(
              classes.biomarkerStatus,
              biomarkerStatus
                ? statusStyleMap[biomarkerStatus]
                : classes.noRangeBioamarkerStatus
            )}
          >
            {biomarkerStatus
              ? biomarkerStatus.replace("_", " ")
              : biomarkerStatus}
          </BodyText>
          <div>
            <BloodLabDashboardsBiomarkerStatusIndicator
              biomarkerStatus={biomarkerStatus}
            />{" "}
            <span
              className={clsx(
                classes.biomarkerValue,
                biomarkerStatus ? valueStyle[biomarkerStatus] : ""
              )}
            >
              {discreteResult?.attributes.value}
            </span>
            <span
              className={clsx(
                classes.biomarkerUnits,
                biomarkerStatus ? statusStyle[biomarkerStatus] : ""
              )}
            >
              {discreteResult?.attributes.units}
            </span>
          </div>
        </div>
        <DesignSystemButton
          color="text"
          loading={false}
          variant="text"
          endIcon={
            <RightCarrotIcon
              fill={primaryColor}
              width={8}
              height={13}
              viewBox="0 0 8 15"
            />
          }
          className={classes.detailsBtn}
        >
          Details
        </DesignSystemButton>
      </div>
      <BloodLabDashboardsBodySystemStatusBar
        standardRangeLow={
          discreteResult?.attributes.normal_min
            ? discreteResult?.attributes.normal_min
            : useOptimalRanges
            ? optimalRange?.attributes.standard_range_min || ""
            : ""
        }
        optimalRangeLow={
          useOptimalRanges ? optimalRange?.attributes.optimal_range_min : ""
        }
        optimalRangeHigh={
          useOptimalRanges ? optimalRange?.attributes.optimal_range_max : ""
        }
        standardRangeHigh={
          discreteResult?.attributes.normal_max
            ? discreteResult?.attributes.normal_max
            : useOptimalRanges
            ? optimalRange?.attributes.standard_range_max || ""
            : ""
        }
        biomarkerStatus={biomarkerStatus}
      />
    </div>
  )
}
