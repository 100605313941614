import { ChangeEvent, useMemo } from "react"
import { useDispatch } from "react-redux"

import MenuItem from "@material-ui/core/MenuItem"

import TextField from "app/components/forms/TextFieldComponent"
import * as Actions from "app/main/checkout/store/actions"

import { ORDER_PRICING_TYPE } from "../../constants"

export default function InsuranceFields({
  order,
  insuranceOption,
  medicareOption,
}) {
  const dispatch = useDispatch()

  const getValue = useMemo(() => {
    if (order.use_insurance && order.patient_has_medicare) {
      return ORDER_PRICING_TYPE.INSURANCE_MEDICARE
    } else if (order.use_insurance) {
      return ORDER_PRICING_TYPE.INSURANCE_NON_MEDICARE
    }

    return ORDER_PRICING_TYPE.CASH
  }, [order.use_insurance, order.patient_has_medicare])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    dispatch(
      Actions.setUseInsurance(
        [
          ORDER_PRICING_TYPE.INSURANCE_NON_MEDICARE,
          ORDER_PRICING_TYPE.INSURANCE_MEDICARE,
        ].includes(newValue),
        newValue === ORDER_PRICING_TYPE.INSURANCE_MEDICARE
      )
    )
  }

  return (
    <div className="mt-8">
      <TextField
        className="fs-exclude"
        fullWidth
        select
        label="Insurance Option"
        value={getValue}
        onChange={onChange}
        variant="outlined"
      >
        <MenuItem value={ORDER_PRICING_TYPE.CASH}>Cash Pay</MenuItem>
        {insuranceOption && (
          <MenuItem value={ORDER_PRICING_TYPE.INSURANCE_NON_MEDICARE}>
            {insuranceOption}
          </MenuItem>
        )}
        {medicareOption && (
          <MenuItem value={ORDER_PRICING_TYPE.INSURANCE_MEDICARE}>
            {medicareOption}
          </MenuItem>
        )}
      </TextField>
    </div>
  )
}
