import { useMemo } from "react"

import AutoSuggestAction from "app/components/search/AutoSuggest/AutoSuggestAction"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Biomarker, BiomarkerIdentifier } from "types/biomarker"
import {
  BiomarkerGrouping,
  BiomarkerGroupingIdentifier,
} from "types/biomarker_grouping"

import { PanelBuilderIdentifier } from "../types"
import { getBiomarkerDisplayName } from "../utils"

export default function BiomarkerAutoSuggestAction({
  biomarkerIdentifier,
  handleSelectBiomarker,
  highlightedItem,
  moveCursorToItem,
  selectedBiomarkersIdentifiers,
  selectedBiomarkerGroupingsIdentifiers,
}: {
  biomarkerIdentifier: BiomarkerIdentifier
  handleSelectBiomarker: (suggestion: PanelBuilderIdentifier) => void
  highlightedItem: PanelBuilderIdentifier | undefined
  moveCursorToItem: (suggestion: PanelBuilderIdentifier) => void
  selectedBiomarkersIdentifiers: BiomarkerIdentifier[]
  selectedBiomarkerGroupingsIdentifiers: BiomarkerGroupingIdentifier[]
}) {
  const biomarker = useCachedResource<Biomarker>(biomarkerIdentifier)
  const biomarkerGroupings = useCachedCollection<BiomarkerGrouping>(
    selectedBiomarkerGroupingsIdentifiers
  )

  const groupingMessage = useMemo(() => {
    const groupingsIncluding: string[] = []
    for (const biomarkerGrouping of biomarkerGroupings) {
      if (
        biomarkerGrouping.relationships.biomarkers.data.some(
          (obj) => obj.id === biomarkerIdentifier.id
        )
      ) {
        groupingsIncluding.push(biomarkerGrouping.attributes.name)
      }
    }
    if (groupingsIncluding.length === 0) {
      return undefined
    }
    return `Added with ${groupingsIncluding.join(", ")}`
  }, [biomarkerGroupings, biomarkerIdentifier.id])

  const addedMessage = useMemo(() => {
    if (
      selectedBiomarkersIdentifiers.some(
        (obj) => obj.id === biomarkerIdentifier.id
      )
    ) {
      return "Added"
    } else if (groupingMessage) {
      return groupingMessage
    } else return undefined
  }, [biomarkerIdentifier, groupingMessage, selectedBiomarkersIdentifiers])

  if (!biomarker) {
    return null
  }

  return (
    <AutoSuggestAction
      key={`${"Biomarkers"}_${biomarker.id}`}
      label={getBiomarkerDisplayName(biomarker)}
      highlighted={highlightedItem === biomarkerIdentifier}
      icon={"biomarker"}
      onHover={() => {
        moveCursorToItem(biomarkerIdentifier)
      }}
      onSelect={() => {
        handleSelectBiomarker(biomarkerIdentifier)
      }}
      addedMessage={addedMessage}
    />
  )
}
