import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { every, isEmpty, sortBy } from "lodash"
import { useForm } from "react-hook-form"
import { Prompt } from "react-router-dom"
import { useMount } from "react-use"
import { Descendant } from "slate"
import { z } from "zod"

import { zodResolver } from "@hookform/resolvers/zod"
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"

import { API } from "app/api"
import AddWhiteIcon from "app/assets/icons/add-white.svg"
import ClockIcon from "app/assets/icons/clock-emoji.png"
import BoxLayersIcon from "app/assets/images/box-layers.svg"
import BoxesIcon from "app/assets/images/boxes-collected-rounded.svg"
import PencilIcon from "app/assets/images/pencil-rounded.svg"
import StorefrontPreview from "app/assets/images/storefront/preview-lab-shop.svg"
import SectionIcon from "app/assets/images/storefront/section-icon.svg"
import authService from "app/auth/services/simplejwt-auth-service"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"
import {
  CUSTOM_FEE_TYPE,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import { StorefrontStatus } from "app/constants.typed"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import {
  StorefrontLabTestBundleType,
  StorefrontLabTestType,
  StorefrontSectionType,
  StorefrontType,
  useResource,
  useResourceSelector,
} from "app/hooks/use-resource"
import useAppSelector from "app/hooks/useAppSelector"
import { useRefreshFeaturePromotions } from "app/providers/FeaturePromotionProvider"
import { trackLabShopEvent } from "app/services/segment"
import { showMessage } from "app/store/actions/fuse"
import { Theme, cancelRed, colors } from "app/theme"
import {
  AnyLimitedLabTest,
  DefaultPractitionerOption,
  PractitionerLabTestBundle,
  PractitionerOptionType,
  StripeConnectAccount,
} from "app/types"

import EcommerceLabTests from "./EcommerceLabTests"
import CustomizeProductModal from "./components/CustomizeProductModal"
import EcommerceSections from "./components/EcommerceSections"
import EcommerceToolbar from "./components/EcommerceToolbar"
import RemoveStorefrontSectionModal from "./components/RemoveStorefrontSectionModal"
import { SectionData } from "./components/dnd-components/SortableSection"
import {
  LabShopEvent,
  MAX_NUMBER_OF_PRODUCTS,
  MAX_NUMBER_OF_PRODUCTS_FOR_ALERT,
} from "./constants"
import { CreateEcommerceFormSchema } from "./form/CreateEcommerceFormSchema"
import EcommerceForm from "./form/EcommerceForm"

interface Props {
  storefrontId: string
  handleStorefrontUpdate: (storefront) => void
  handleStorefrontDelete: () => void
  handleIsDirty: (isDirty: boolean) => void
}

const styles = (theme: Theme) => ({
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  container: {
    padding: "27px 32px",
  },
  headerContainer: {
    display: "flex",
    "flex-direction": "row",
    width: "100%",
    alignItems: "center",
  },
  iconContainer: {
    marginRight: 10,
    minWidth: 50,
  },
  divider: {
    marginTop: 45,
    marginBottom: 35,
  },
  emptyLabTests: {
    display: "flex",
    "flex-direction": "column",
    background: "white",
    justifyContent: "center",
    alignItems: "center",
    padding: "32px 64px",
    borderRadius: 8,
    marginTop: 35,
  },
  labTestsContainer: {
    display: "flex",
    "flex-direction": "row",
    gap: 16,
    "flex-wrap": "wrap",
    width: "100%",
    marginTop: 35,
  },
  labTestCard: {
    width: "calc(25% - 12px)",
    "box-sizing": "border-box",
    [theme.breakpoints.down(2000)]: {
      width: "calc(33% - 12px)",
    },
    [theme.breakpoints.down(1600)]: {
      width: "calc(50% - 8px)",
    },
    [theme.breakpoints.down(1000)]: {
      width: "100%",
    },
  },
  disabledButtonOverride: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 8,
    },
  },
  fixedToolbar: {
    position: "sticky" as any,
    width: "100%",
    top: 0,
    zIndex: 999,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  deleteStorefrontBtn: {},
  productButtons: {
    "flex-direction": "row",
  },
})

const useStyles = makeStyles(styles)

export default function EcommerceStorefrontWrapper({
  storefrontId,
  handleStorefrontUpdate,
  handleStorefrontDelete,
  handleIsDirty,
}: Props) {
  const dispatch = useDispatch()
  const classes = useStyles(styles)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const refreshFeaturePromotions = useRefreshFeaturePromotions()

  const physicianServicesOptInModal = usePhysicianServicesOptInModal()

  const [practitionerLicenseFlagEnabled] = useFeatureFlag(
    FeatureFlag.LabshopsUsePractitionerLicense
  )
  const [hidePhysServicesPricing] = useFeatureFlag(
    FeatureFlag.HidePhysicianServicesPricing
  )

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const [stripeConnectAccount, setStripeConnectAccount] =
    useState<StripeConnectAccount | null>(null)
  useMount(async () => {
    const response = await API.StripeConnectAccount.getList()

    if (response.data.objects.length) {
      setStripeConnectAccount(response.data.objects[0])
    }
  })

  const [isDirty, setIsDirty] = useState(false)
  const [isLabTestModalOpen, setIsLabTestModalOpen] = useState<boolean>(false)
  const [selectedOrderingPhysician, setSelectedOrderingPhysician] = useState<
    DefaultPractitionerOption | undefined
  >(undefined)
  const [isCustomizeProductModalOpen, setIsCustomizeProductModalOpen] =
    useState(false)
  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    null
  )
  const [selectedSectionId, setSelectedSectionId] = useState<string | null>(
    null
  )
  const [removeSectionModalIsOpen, setRemoveSectionModalIsOpen] =
    useState(false)

  useEffect(() => {
    handleIsDirty(isDirty)
  }, [isDirty])

  const include = [
    "draft_storefront_sections.storefront_products.lab_test.lab_test_types",
    "draft_storefront_sections.storefront_products.lab_test.lab_company",
    "draft_storefront_sections.storefront_products.bundle.lab_tests.lab_test_types",
    "draft_storefront_sections.storefront_products.bundle.lab_tests.lab_company",
    "draft_storefront_sections.storefront_products.custom_fee",
  ]

  /**
   * Interfaces between legacy and simplejwt auth to get the proper access token.
   *
   * @returns {string | undefined} - The access token to use for the request
   */
  async function accessToken() {
    return await authService.getAccessToken()
  }

  const {
    ready,
    data: storefront,
    updateCache,
  } = useResource<StorefrontType>(`storefronts/${storefrontId}`, {
    accessToken,
    include,
    refresh: true,
  })

  // Only used if useStorefrontProducts feature flag is set to `true`
  const sections = useResourceSelector((entities) => {
    if (!storefront?.relationships.draft_storefront_sections) {
      return []
    }

    return storefront.relationships.draft_storefront_sections.data.map(
      (ref) => entities.storefront_section?.[ref.id]
    )
  }) as StorefrontSectionType[]

  const products = useResourceSelector((entities) => {
    const productRelationships = sections?.flatMap(
      (s) => s?.relationships.storefront_products.data
    )
    if (!productRelationships.length) {
      return []
    }

    return productRelationships.reduce((result: any[], ref) => {
      if (ref) {
        result.push(entities?.storefront_product?.[ref.id])
      }
      return result
    }, [])
  })

  const customFees = useResourceSelector((entities) => {
    const customFeeRelationships = products?.map(
      (p) => p?.relationships.custom_fee.data
    )
    if (!customFeeRelationships.length) {
      return []
    }

    return customFeeRelationships.reduce((result: any[], ref) => {
      if (ref) {
        result.push(entities?.custom_fee?.[ref.id])
      }
      return result
    }, [])
  })

  const onAddSection = async () => {
    const payload = {
      data: {
        type: "storefront",
      },
    }
    const response = await API.Storefront.createSection(
      storefrontId,
      payload,
      include
    )
    if (response.data) {
      updateCache(response.data)
      setIsDirty(true)

      trackLabShopEvent(LabShopEvent.ADD_LABSHOP_SECTION, {
        storefrontId: storefront?.id,
      })
      refreshFeaturePromotions()
    }
  }

  const onDeleteSection = async () => {
    const response = await API.Storefront.deleteSection(
      storefront?.id,
      selectedSectionId,
      include
    )
    if (response.data) {
      updateCache(response.data)
      setIsDirty(true)

      trackLabShopEvent(LabShopEvent.REMOVE_LABSHOP_SECTION, {
        storefrontId: storefront?.id,
      })

      setRemoveSectionModalIsOpen(false)
      setSelectedSectionId(null)
      refreshFeaturePromotions()
    }
  }

  const handleSectionsOrderUpdate = async (sectionsOrder: any[]) => {
    const payload = {
      data: {
        type: "storefront",
        id: storefrontId,
        relationships: {
          draft_storefront_sections: {
            data: sectionsOrder,
          },
        },
      },
    }

    const response = await API.Storefront.updateStorefront(
      storefrontId,
      payload,
      include
    )

    if (response.data) {
      updateCache(response.data)
      setIsDirty(true)
      refreshFeaturePromotions()
    }
  }

  const handleSectionUpdate = async (sectionData: SectionData[]) => {
    let response
    for (const data of sectionData) {
      const payload = {
        data: {
          type: "storefront",
          id: storefrontId,
          attributes: {
            name: data.name,
            description: data.description,
          },
        },
      }

      if (data.products) {
        payload.data["relationships"] = {
          storefront_products: {
            data: data.products,
          },
        }
      }

      response = await API.Storefront.updateSection(
        storefront?.id,
        data.id,
        include,
        payload
      )
    }

    if (response.data) {
      updateCache(response.data)
      setIsDirty(true)
      refreshFeaturePromotions()
    }
  }

  const onAddLabTest = async (labTest: AnyLimitedLabTest) => {
    let response

    const requestData = {
      data: {
        type: "storefront",
        relationships: {
          lab_test: {
            data: {
              type: "lab_test",
              id: labTest.id,
            },
          },
        },
      },
    }

    response = await API.Storefront.createProduct(
      storefront?.id,
      requestData,
      include
    )

    updateCache(response.data)
    setIsDirty(true)

    trackLabShopEvent(LabShopEvent.ADD_LABSHOP_PRODUCT, {
      storefrontId: storefront?.id,
      labTestId: labTest.id,
    })
    refreshFeaturePromotions()
  }

  const onRemoveLabTest = async (
    labTest: AnyLimitedLabTest | StorefrontLabTestType | undefined
  ) => {
    if (labTest) {
      let response

      const product = products.find(
        (p) => p?.relationships.lab_test.data?.id === labTest.id
      )

      if (product) {
        response = await API.Storefront.deleteProduct(
          storefront?.id,
          product.id,
          include
        )
      } else {
        dispatch(
          showMessage({
            message: "Unable to remove Lab Test from your LabShop",
            variant: "error",
            anchorOrigin: {
              vertical: "bottom", //top bottom
              horizontal: "left", //left center right
            },
          })
        )
        return
      }

      updateCache(response.data)
      setIsDirty(true)

      trackLabShopEvent(LabShopEvent.REMOVE_LABSHOP_PRODUCT, {
        storefrontId: storefront?.id,
        labTestId: labTest.id,
      })
      refreshFeaturePromotions()
    }
  }

  const onAddBundle = async (bundle: PractitionerLabTestBundle) => {
    let response

    const requestData = {
      data: {
        type: "storefront",
        relationships: {
          bundle: {
            data: {
              type: "lab_test_bundle",
              id: bundle.id,
            },
          },
        },
      },
    }

    response = await API.Storefront.createProduct(
      storefront?.id,
      requestData,
      include
    )

    updateCache(response.data)
    setIsDirty(true)

    trackLabShopEvent(LabShopEvent.ADD_LABSHOP_PRODUCT, {
      storefrontId: storefront?.id,
      bundleId: bundle.id,
    })
    refreshFeaturePromotions()
  }

  const onRemoveBundle = async (
    bundle: PractitionerLabTestBundle | StorefrontLabTestBundleType | undefined
  ) => {
    if (bundle) {
      let response

      const product = products.find(
        (p) => p?.relationships.bundle.data?.id === bundle.id.toString()
      )

      if (product) {
        response = await API.Storefront.deleteProduct(
          storefront?.id,
          product.id,
          include
        )
      } else {
        dispatch(
          showMessage({
            message: "Unable to remove Bundle from your LabShop",
            variant: "error",
            anchorOrigin: {
              vertical: "bottom", //top bottom
              horizontal: "left", //left center right
            },
          })
        )
        return
      }

      updateCache(response.data)
      setIsDirty(true)

      trackLabShopEvent(LabShopEvent.REMOVE_LABSHOP_PRODUCT, {
        storefrontId: storefront?.id,
        bundleId: bundle.id,
      })
      refreshFeaturePromotions()
    }
  }

  const saveDraftAndPublish = async (formValues) => {
    if (formValues) {
      const requestData = {
        data: {
          type: "storefront",
          id: storefront?.id,
          attributes: {
            draft_name: formValues.draft_name,
            draft_description: formValues.draft_description,
            draft_description_richtext: formValues.draft_description_richtext,
            draft_video_link: formValues.draft_video_link,
            draft_send_notification_emails:
              formValues.draft_send_notification_emails,
            draft_send_to_ehr: formValues.draft_send_to_ehr,
          },
          relationships: {
            draft_ordering_practitioner: {
              data: {
                type: "practitioner",
                id: selectedOrderingPhysician?.practitioner.id,
              },
            },
          },
        },
      }

      await API.Storefront.updateStorefront(
        storefront?.id,
        requestData,
        include
      )

      const response = await API.Storefront.publish(storefront?.id, include)

      updateCache(response.data)

      setIsDirty(false)
      // Don't need to include "includes" data
      handleStorefrontUpdate(response.data.data)
      dispatch(
        showMessage({
          message: "LabShop successfully published!",
          variant: "success",
          anchorOrigin: {
            vertical: "bottom", //top bottom
            horizontal: "left", //left center right
          },
          autoHideDuration: 5000,
        })
      )
      resetFields({
        draft_name: formValues.draft_name,
        draft_description: formValues.draft_description,
        draft_description_richtext: formValues.draft_description_richtext,
        draft_video_link: formValues.draft_video_link,
        draft_send_notification_emails:
          formValues.draft_send_notification_emails,
        draft_send_to_ehr: formValues.draft_send_to_ehr,
      })

      trackLabShopEvent(LabShopEvent.PUBLISH_LABSHOP, {
        storefrontId: storefront?.id,
      })
      refreshFeaturePromotions()
    }
  }

  const handleRemoveLogo = async () => {
    methods.setValue("draft_logo", null)

    const requestData = {
      data: {
        type: "storefront",
        id: storefront?.id,
        attributes: {
          draft_logo: null,
        },
      },
    }

    const response = await API.Storefront.updateStorefront(
      storefront?.id,
      requestData,
      include
    )

    updateCache(response.data)
    setIsDirty(true)
  }

  const handleChangeDraftDescriptionRichtext = (value: Descendant[]) => {
    methods.setValue("draft_description_richtext", value)
    setIsDirty(true)
  }

  const handleAddLogo = async () => {
    // Already uploaded to draft state, just need to mark draft as dirty so it can be published
    setIsDirty(true)
  }

  const handleSelectedOrderingPhysician = (
    selection: DefaultPractitionerOption | undefined
  ) => {
    if (selection) {
      setSelectedOrderingPhysician(selection)
      setIsDirty(true)
      refreshFeaturePromotions()
    }
  }

  useEffect(() => {
    if (isDirty) {
      // autosave the practitioner selection so that the lab tests have the correct ordering rights
      handleSaveDraft()
    }
  }, [selectedOrderingPhysician])

  useEffect(() => {
    // null out the selected ordering physician if the storefront changes
    setSelectedOrderingPhysician(undefined)
  }, [storefrontId])

  const [orderingPractitionerOptions, setOrderingPractitionerOptions] =
    useState<DefaultPractitionerOption[]>([])

  useEffect(() => {
    if (!isEmpty(practitioner)) {
      const getOptions = async () => {
        const practitioners = await (
          await API.Clinic.getPractitioners(practitioner.clinic.id)
        ).data

        if (isEmpty(practitioners)) {
          setOrderingPractitionerOptions([])
        }

        const practitionersSorted = sortBy(
          practitioners.map((practitioner) => ({
            type: PractitionerOptionType.DEFAULT,
            practitioner,
            blocked: false,
          })),
          (option) => (option.blocked ? 1 : 0)
        )

        setOrderingPractitionerOptions(practitionersSorted)

        if (storefront?.relationships.draft_ordering_practitioner.data) {
          setSelectedOrderingPhysician(
            practitionersSorted[
              practitionersSorted.findIndex(
                (p) =>
                  p.practitioner.id ===
                  storefront.relationships.draft_ordering_practitioner.data.id
              )
            ]
          )
        }
      }

      getOptions()
    }
  }, [practitioner])

  useEffect(() => {
    if (storefront?.relationships.draft_ordering_practitioner.data) {
      setSelectedOrderingPhysician(
        orderingPractitionerOptions[
          orderingPractitionerOptions.findIndex(
            (p) =>
              p.practitioner.id ===
              storefront.relationships.draft_ordering_practitioner.data.id
          )
        ]
      )
    }
  }, [storefront])

  const EcommerceFormSchema = CreateEcommerceFormSchema()

  const methods = useForm<z.infer<typeof EcommerceFormSchema>>({
    defaultValues: {
      draft_name: storefront?.attributes.draft_name,
      draft_description: storefront?.attributes.draft_description,
      draft_description_richtext:
        storefront?.attributes.draft_description_richtext,
      draft_logo: storefront?.attributes.draft_logo,
      draft_video_link: storefront?.attributes.draft_video_link,
      draft_send_notification_emails:
        storefront?.attributes.draft_send_notification_emails,
      draft_send_to_ehr: storefront?.attributes.draft_send_to_ehr,
      draft_custom_fee_id: storefront?.relationships.draft_custom_fee?.data?.id,
      draft_ordering_practitioner_id: orderingPractitionerOptions.length
        ? orderingPractitionerOptions[0].practitioner.id
        : "",
    },
    resolver: zodResolver(EcommerceFormSchema),
    mode: "onChange",
  })

  const fieldsEdited = methods.formState.isDirty
  const validationValues = methods.watch([
    "draft_name",
    "draft_description",
    "draft_description_richtext",
    "draft_logo",
  ])

  const isVideoLinkValid = (url: string | null): boolean => {
    const regex =
      /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?-].*)?$/gi // eslint-disable-line no-useless-escape
    if (url && !url.match(regex)) {
      return false
    }

    return true
  }

  const resetFields = (updates?: any) => {
    methods.reset({
      draft_name: updates?.draft_name
        ? updates?.draft_name
        : storefront?.attributes.draft_name,
      draft_description: updates?.draft_description
        ? updates?.draft_description
        : storefront?.attributes.draft_description,
      draft_description_richtext: updates?.draft_description_richtext
        ? updates?.draft_description_richtext
        : storefront?.attributes.draft_description_richtext,
      draft_logo: storefront?.attributes.draft_logo,
      draft_video_link:
        updates && updates.hasOwnProperty("draft_video_link")
          ? updates?.draft_video_link
          : storefront?.attributes.draft_video_link,
      draft_send_notification_emails:
        updates?.draft_send_notification_emails !== undefined
          ? updates?.draft_send_notification_emails
          : storefront?.attributes.draft_send_notification_emails,
      draft_send_to_ehr:
        updates?.draft_send_to_ehr !== undefined
          ? updates?.draft_send_to_ehr
          : storefront?.attributes.draft_send_to_ehr,
      draft_custom_fee_id: storefront?.relationships.draft_custom_fee?.data?.id,
      draft_ordering_practitioner_id: "",
    })
    setIsDirty(false)
    refreshFeaturePromotions()
  }

  useEffect(() => {
    resetFields()
  }, [storefrontId])

  const handleSubmitPauseButton = async () => {
    if (
      storefront?.attributes.status !== StorefrontStatus.Disabled &&
      storefront?.attributes.status !== StorefrontStatus.Paused &&
      !isDirty
    ) {
      // pause store
      const requestData = {
        data: {
          type: "storefront",
          id: storefront?.id,
          attributes: {
            status: StorefrontStatus.Paused,
            date_paused: new Date().toISOString(),
          },
        },
      }

      const response = await API.Storefront.updateStorefront(
        storefront?.id,
        requestData,
        include
      )

      updateCache(response.data)
      // Don't need to include "includes" data
      handleStorefrontUpdate(response.data.data)
      dispatch(
        showMessage({
          message: "LabShop successfully paused!",
          variant: "success",
          anchorOrigin: {
            vertical: "bottom", //top bottom
            horizontal: "left", //left center right
          },
          autoHideDuration: 5000,
        })
      )
      resetFields()
      setIsDirty(false)

      trackLabShopEvent(LabShopEvent.PAUSE_LABSHOP, {
        storefrontId: storefront?.id,
      })
    } else {
      saveDraftAndPublish(methods.getValues())
    }
  }

  const handleSaveDraft = async () => {
    const formValues = methods.getValues()
    const requestData = {
      data: {
        type: "storefront",
        id: storefront?.id,
        attributes: {
          draft_name: formValues.draft_name,
          draft_description: formValues.draft_description,
          draft_description_richtext: formValues.draft_description_richtext,
          draft_video_link: formValues.draft_video_link,
          draft_send_notification_emails:
            formValues.draft_send_notification_emails,
          draft_send_to_ehr: formValues.draft_send_to_ehr,
        },
        relationships: {
          draft_ordering_practitioner: {
            data: {
              type: "practitioner",
              id: selectedOrderingPhysician?.practitioner.id,
            },
          },
        },
      },
    }

    const response = await API.Storefront.updateStorefront(
      storefront?.id,
      requestData,
      include
    )

    updateCache(response.data)

    // Don't need to include "includes" data
    handleStorefrontUpdate(response.data.data)

    dispatch(
      showMessage({
        message: "Successfully saved LabShop as draft!",
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        autoHideDuration: 5000,
      })
    )
    setIsDirty(false)
    resetFields({
      draft_name: formValues.draft_name,
      draft_description: formValues.draft_description,
      draft_description_richtext: formValues.draft_description_richtext,
      draft_video_link: formValues.draft_video_link,
      draft_send_notification_emails: formValues.draft_send_notification_emails,
      draft_send_to_ehr: formValues.draft_send_to_ehr,
    })

    trackLabShopEvent(LabShopEvent.SAVE_LABSHOP_DRAFT, {
      storefrontId: storefront?.id,
    })
  }

  // The functions below here are the functions that will be used moving forward for custom fees.
  // TODO: Deprecate old custom fee functions when this is fully rolled out
  const handleOpenProductCustomizeModal = (productId: string) => {
    setSelectedProductId(productId)
    setIsCustomizeProductModalOpen(true)
    trackLabShopEvent(LabShopEvent.OPEN_CUSTOMIZE_PRODUCT, {
      storefrontId: storefront?.id,
      productId: productId,
    })
  }

  const handleCloseProductCustomizeModal = () => {
    setIsCustomizeProductModalOpen(false)
    setSelectedProductId(null)
  }

  const handleAddProductCustomFee = async (name: string, price: number) => {
    let response
    // Use existing custom fee name and prices
    if (stripeConnectAccount) {
      response = await API.CustomFee.post(
        stripeConnectAccount.id,
        name,
        price,
        CUSTOM_FEE_TYPE.LABSHOP_PRODUCT
      )
    } else {
      dispatch(
        showMessage({
          message:
            "You must set up your Stripe Connect Account before creating a Custom Fee",
          variant: "error",
          anchorOrigin: {
            vertical: "bottom", //top bottom
            horizontal: "left", //left center right
          },
        })
      )
      return
    }

    const requestData = {
      data: {
        type: "storefront",
        id: storefrontId,
        relationships: {
          custom_fee: {
            data: {
              type: "custom_fee",
              id: response.data.id,
            },
          },
        },
      },
    }

    const storefrontResponse = await API.Storefront.updateProduct(
      storefront?.id,
      selectedProductId,
      include,
      requestData
    )

    updateCache(storefrontResponse.data)
    setIsDirty(true)
  }

  const handleUpdateProduct = async (productAttributes) => {
    let relationships
    // Completely nullify custom fee relationship if it was completely removed
    // If it was removed and replaced with another custom fee, then handleAddProductCustomFee will overwrite it
    if (
      productAttributes.removeCustomFee &&
      productAttributes.customFeeName === "" &&
      productAttributes.customFeePrice === 0
    ) {
      relationships = {
        custom_fee: {
          data: null,
        },
      }
    }

    if (productAttributes.customFeeId) {
      const customFeeResponse = await API.CustomFee.put(
        productAttributes.customFeeId,
        productAttributes.customFeeName,
        productAttributes.customFeePrice
      )

      if (!customFeeResponse.data) {
        dispatch(
          showMessage({
            message: "There was a problem updating your Custom Fee",
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          })
        )
        return
      }
    } else if (
      productAttributes.customFeeName !== "" &&
      productAttributes.customFeePrice > 0
    ) {
      await handleAddProductCustomFee(
        productAttributes.customFeeName,
        productAttributes.customFeePrice
      )
    }

    const requestData = {
      data: {
        type: "storefront",
        id: storefrontId,
        attributes: {
          notes: productAttributes.customNotes,
        },
      },
    }

    if (relationships) {
      requestData["data"]["relationships"] = relationships
    }

    const storefrontResponse = await API.Storefront.updateProduct(
      storefront?.id,
      selectedProductId,
      include,
      requestData
    )

    if (!storefrontResponse.data) {
      dispatch(
        showMessage({
          message: "There was a problem updating your LabShop product",
          variant: "error",
          anchorOrigin: {
            vertical: "bottom", //top bottom
            horizontal: "left", //left center right
          },
        })
      )
      return
    }

    updateCache(storefrontResponse.data)
    setIsDirty(true)

    setIsCustomizeProductModalOpen(false)
  }

  const onOpenRemoveSectionmodal = (sectionId: string) => {
    setSelectedSectionId(sectionId)
    setRemoveSectionModalIsOpen(true)
  }

  useEffect(() => {
    if (fieldsEdited) {
      setIsDirty(true)
    }
  }, [fieldsEdited])

  useEffect(() => {
    if (ready) {
      if (storefront?.relationships.draft_ordering_practitioner.data?.id) {
        const prac =
          orderingPractitionerOptions[
            orderingPractitionerOptions.findIndex(
              (p) =>
                p.practitioner.id ===
                storefront?.relationships.draft_ordering_practitioner.data?.id
            )
          ]
        setSelectedOrderingPhysician(prac)
      }

      resetFields()
    }
  }, [ready])

  const showEmptyLabTestBundleState = !Boolean(
    sections.filter((section) =>
      Boolean(section.relationships.storefront_products.data.length)
    ).length
  )

  const isUsingPractitionerLicense = Boolean(
    practitionerLicenseFlagEnabled &&
      storefront?.attributes.use_practitioner_license
  )

  return (
    <div className="fs-unmask">
      <Prompt
        when={isDirty}
        message="Are you sure you want to leave? Changes will not be saved."
      />

      {ready ? (
        <>
          <Grid
            item
            xs={12}
            md={12}
            className={classes.fixedToolbar}
            style={{ position: "-webkit-sticky" }}
          >
            <EcommerceToolbar
              storefront={storefront}
              products={products}
              selectedOrderingPhysician={selectedOrderingPhysician}
              isDirty={isDirty}
              isFormValid={
                every(validationValues, (value) => value !== "") &&
                isVideoLinkValid(methods.getValues("draft_video_link"))
              }
              handleSubmitPauseButton={handleSubmitPauseButton}
              handleSaveDraft={handleSaveDraft}
              usePractitionerLicense={isUsingPractitionerLicense}
              currentPractitionerIsClinicStaff={practitioner.is_clinic_staff}
            />
          </Grid>
          <div className={classes.container}>
            <Grid container>
              <Grid item xs={12} md={7} style={{ paddingRight: 20 }}>
                <div className={classes.headerContainer}>
                  <div className={classes.iconContainer}>
                    <img src={PencilIcon} alt="pencil icon" />
                  </div>
                  <div>
                    <BodyText size="lg" weight="semibold">
                      Customize Your LabShop
                    </BodyText>
                  </div>
                </div>
                <EcommerceForm
                  storefront={storefront}
                  orderingPractitionerOptions={orderingPractitionerOptions}
                  methods={methods}
                  onRemoveLogo={handleRemoveLogo}
                  onAddLogo={handleAddLogo}
                  onChangeDraftDescriptionRichtext={
                    handleChangeDraftDescriptionRichtext
                  }
                  selectedOrderingPhysician={selectedOrderingPhysician}
                  onSelectedOrderingPhysician={(selection) =>
                    handleSelectedOrderingPhysician(selection)
                  }
                  usePractitionerLicense={isUsingPractitionerLicense}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                style={!isMobile ? { paddingLeft: 20 } : {}}
              >
                <img
                  src={StorefrontPreview}
                  alt="storefront preview"
                  style={{ marginTop: 75 }}
                />
              </Grid>
            </Grid>

            <Divider className={classes.divider} />
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div className={classes.headerContainer}>
                    <div className={classes.iconContainer}>
                      <img src={BoxesIcon} alt="boxes icon" />
                    </div>
                    <div>
                      <BodyText
                        size="lg"
                        weight="semibold"
                        style={{ marginBottom: 4 }}
                      >
                        Select Lab Tests
                      </BodyText>
                      <BodyText size="sm">
                        This is the list of tests you want to offer in your
                        LabShop
                      </BodyText>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  alignContent="flex-end"
                  className={classes.productButtons}
                >
                  <Box
                    display="flex"
                    justifyContent={isMobile ? "stretch" : "flex-end"}
                    gridGap={10}
                  >
                    {(selectedOrderingPhysician?.practitioner
                      .vendor_physician_authorization_enabled ||
                      isUsingPractitionerLicense) &&
                      Boolean(products.length) && (
                        <DesignSystemButton
                          onClick={onAddSection}
                          color="secondary"
                          startIcon={
                            <img
                              src={SectionIcon}
                              alt="section icon"
                              style={{ position: "relative", top: -2 }}
                            />
                          }
                          className={classes.disabledButtonOverride}
                        >
                          Add Section
                        </DesignSystemButton>
                      )}
                    <Tooltip
                      title={
                        storefront?.relationships?.draft_lab_tests?.data &&
                        storefront?.relationships?.draft_bundles?.data &&
                        storefront?.relationships?.draft_lab_tests?.data
                          ?.length +
                          storefront?.relationships?.draft_bundles?.data
                            ?.length ===
                          MAX_NUMBER_OF_PRODUCTS ? (
                          `You cannot add more than ${MAX_NUMBER_OF_PRODUCTS} lab tests and bundles`
                        ) : !selectedOrderingPhysician?.practitioner
                            .vendor_physician_authorization_enabled &&
                          !isUsingPractitionerLicense ? (
                          <>
                            {selectedOrderingPhysician?.practitioner.user
                              .full_name
                              ? selectedOrderingPhysician?.practitioner.user
                                  .full_name
                              : "Practitioner"}{" "}
                            must have {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} to
                            set up your store.{" "}
                            {!practitioner.vendor_physician_authorization_enabled &&
                              !practitioner.is_clinic_staff && (
                                <div
                                  className={
                                    "font-semibold text-primary cursor-pointer"
                                  }
                                  onClick={() => {
                                    physicianServicesOptInModal.show({
                                      defaultPhysAuthOn: false,
                                      returnToDescriptor: "Labshop",
                                      onClose: () => {
                                        physicianServicesOptInModal.remove()
                                      },
                                    })
                                  }}
                                >
                                  Add {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}.
                                </div>
                              )}
                          </>
                        ) : (
                          ""
                        )
                      }
                      interactive
                      placement="top"
                      arrow
                    >
                      <DesignSystemButton
                        onClick={() => setIsLabTestModalOpen(true)}
                        color="primary"
                        startIcon={AddWhiteIcon}
                        disabled={
                          (storefront?.relationships?.draft_lab_tests?.data &&
                            storefront?.relationships?.draft_bundles?.data &&
                            storefront?.relationships?.draft_lab_tests?.data
                              ?.length +
                              storefront?.relationships?.draft_bundles?.data
                                ?.length ===
                              MAX_NUMBER_OF_PRODUCTS) ||
                          (!selectedOrderingPhysician?.practitioner
                            .vendor_physician_authorization_enabled &&
                            !isUsingPractitionerLicense)
                        }
                        className={classes.disabledButtonOverride}
                      >
                        Add Tests
                      </DesignSystemButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>

              {products?.length > MAX_NUMBER_OF_PRODUCTS_FOR_ALERT && (
                <div className="flex flex-row border border-gray-300 rounded-md overflow-hidden mt-8 bg-white">
                  <div className="bg-amber-50 text-lg py-4 px-3 self-stretch flex items-center justify-center">
                    <img src={ClockIcon} className="w-5 h-5" alt="alert" />
                  </div>
                  <p className="text-body text-sm py-4 px-2 ">
                    To avoid slow down loading times for your LabShop, we
                    recommend making more than one LabShop if you have more than{" "}
                    {MAX_NUMBER_OF_PRODUCTS_FOR_ALERT} products.
                  </p>
                </div>
              )}

              {showEmptyLabTestBundleState ? (
                <div className={classes.emptyLabTests}>
                  <img src={BoxLayersIcon} alt="layers icon" />
                  <BodyText
                    size="sm"
                    style={{ marginTop: 10, color: colors.blueGray[500] }}
                  >
                    Add tests or custom bundles to your store.
                  </BodyText>
                </div>
              ) : (
                <EcommerceSections
                  sections={sections}
                  openProductCustomizeModal={handleOpenProductCustomizeModal}
                  removeLabTest={onRemoveLabTest}
                  removeBundle={onRemoveBundle}
                  openRemoveSectionModal={onOpenRemoveSectionmodal}
                  updateSections={handleSectionUpdate}
                  updateSectionsOrder={handleSectionsOrderUpdate}
                  hidePhysServicesPricing={
                    hidePhysServicesPricing || isUsingPractitionerLicense
                  }
                />
              )}
            </Grid>
            <EcommerceLabTests
              onAddLabTest={onAddLabTest}
              onRemoveLabTest={onRemoveLabTest}
              onAddBundle={onAddBundle}
              onRemoveBundle={onRemoveBundle}
              selectedLabTestIds={
                products
                  .map((product) => product?.relationships.lab_test?.data?.id)
                  .filter((labTestId) => labTestId !== undefined) as string[]
              }
              selectedBundleIds={
                products
                  .map((product) =>
                    product?.relationships.bundle?.data
                      ? parseInt(product.relationships.bundle.data.id)
                      : undefined
                  )
                  .filter((bundleId) => bundleId !== undefined) as number[]
              }
              isModalOpen={isLabTestModalOpen}
              closeModal={() => setIsLabTestModalOpen(false)}
              orderingPractitioner={selectedOrderingPhysician?.practitioner}
              usePractitionerLicense={isUsingPractitionerLicense}
              storefrontId={storefrontId}
            />
            <Divider className={classes.divider} />
            <div className={classes.deleteStorefrontBtn}>
              <DesignSystemButton
                color="destructiveText"
                startIcon={
                  <DeleteIcon
                    fontSize="small"
                    style={{
                      color: cancelRed,
                      position: "relative",
                      top: "2px",
                    }}
                  />
                }
                onClick={handleStorefrontDelete}
              >
                Delete LabShop
              </DesignSystemButton>
            </div>
          </div>
        </>
      ) : (
        <div className={classes.spinnerContainer}>
          <CircularProgress ingaria-label="Loading" size={60} />
        </div>
      )}
      <CustomizeProductModal
        open={isCustomizeProductModalOpen}
        onCloseModal={() => handleCloseProductCustomizeModal()}
        product={products.find((p) => p?.id === selectedProductId)}
        existingCustomFees={customFees}
        updateProduct={handleUpdateProduct}
        hidePhysServicesPricing={
          hidePhysServicesPricing || isUsingPractitionerLicense
        }
      />
      <RemoveStorefrontSectionModal
        open={removeSectionModalIsOpen}
        onCloseModal={() => setRemoveSectionModalIsOpen(false)}
        confirmDelete={onDeleteSection}
      />
    </div>
  )
}
