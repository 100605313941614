import { AxiosError } from "axios"

import { InRangeOption } from "app/main/patient-orders/trends/types/types"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { ResourceCollection } from "app/swr/types"
import { ResourceErrorResponse } from "app/swr/types"
import { Biomarker } from "types/biomarker"
import { BloodLabDashboardReportSnapshot } from "types/blood-lab-dashboard-report-snapshot"
import { BloodReportResult } from "types/blood-report-result"
import { BodySystem } from "types/body-system"
import { Clinic } from "types/clinic"
import { LabCompany } from "types/lab-company"
import { LabTest } from "types/lab-test"
import { OrderedResult } from "types/ordered-result"
import { Practitioner } from "types/practitioner"
import { UserResult } from "types/user-result"

import useBloodLabDashboardsSnapshotHelpers from "./use-blood-lab-dashboard-snapshot-helpers"

export type BloodLabDashboardSnapshotHookReturn = {
  userResults?: UserResult[]
  allBiomarkers: Biomarker[]
  orderedResult?: OrderedResult
  sortedBodySystems: BodySystem[]
  missingBiomarkers: Biomarker[]
  isSnapshotLoading: boolean
  isBloodReportResultsLoading: boolean
  isBloodReportResultsValidating: boolean
  isSnapshotValidating: boolean
  snapshotError: any
  bloodReportResultsError?: AxiosError<ResourceErrorResponse>
  labCompany?: LabCompany
  usesClinicOptimalRange: boolean
  bloodReportResults: BloodReportResult[]
  snapshot?: BloodLabDashboardReportSnapshot
  getBloodReportResultsByBodySystem: (
    bodySystem: BodySystem,
    inRangeOptionFilter?: InRangeOption
  ) => BloodReportResult[]
  mutateBloodReportResultsAndSnapshot: () => void
  getMatchingBloodReportResultByBiomarkerId: (
    biomarkerId?: string
  ) => BloodReportResult | undefined
  practitioner?: Practitioner
  clinic?: Clinic
  labTests: LabTest[]
  getBloodReportResultsByLabTest: (
    labTest: LabTest,
    filterBodySystemId?: string,
    inRangeOptionFilter?: InRangeOption
  ) => BloodReportResult[]
}

export function useBloodLabDashboardsSnapshot(
  snapshotId: string,
  search?: string
): BloodLabDashboardSnapshotHookReturn {
  const {
    data: snapshot,
    error: snapshotError,
    isLoading: isSnapshotLoading,
    isValidating: isSnapshotValidating,
    mutate: mutateSnapshot,
  } = useResourceSWR<BloodLabDashboardReportSnapshot>(
    `/blood_lab_dashboard_report_snapshots/${snapshotId}/`,
    {
      include: [
        "patient.practitioner.clinic",
        "user_results.lab_company",
        "patient",
        "ordered_result.kit.ordered_tests.lab_test.biomarkers.body_systems",
        "ordered_result.order.practitioner",
        "ordered_result.order.practitioner.clinic",
        "ordered_result.lab_company",
        "ordered_result.ordered_tests.lab_test.biomarkers",
        "result_values.biomarker",
      ],
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
    }
  )

  const {
    data: bloodReportResultIdentifiers,
    isLoading: isBloodReportResultsLoading,
    isValidating: isBloodReportResultsValidating,
    error: bloodReportResultsError,
    mutate: mutateBloodReportResults,
  } = useCollectionSWR<ResourceCollection<BloodReportResult>>(
    `/blood_lab_dashboard_report_snapshots/${snapshotId}/results/`,
    {},
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
    }
  )

  const mutateBloodReportResultsAndSnapshot = () => {
    mutateBloodReportResults()
    mutateSnapshot(undefined, { revalidate: true, populateCache: false })
  }
  const bloodReportResults = useCachedCollection<BloodReportResult>(
    bloodReportResultIdentifiers
  )

  const {
    userResults,
    filteredBloodReportResults,
    allBiomarkers,
    orderedResult,
    labTests,
    practitioner,
    clinic,
    sortedBodySystems,
    missingBiomarkers,
    labCompany,
    usesClinicOptimalRange,
    getBloodReportResultsByLabTest,
    getBloodReportResultsByBodySystem,
    getMatchingBloodReportResultByBiomarkerId,
  } = useBloodLabDashboardsSnapshotHelpers(snapshot, bloodReportResults, search)

  return {
    snapshot,
    userResults,
    bloodReportResults: filteredBloodReportResults,
    allBiomarkers,
    orderedResult,
    practitioner,
    clinic,
    labTests,
    sortedBodySystems,
    missingBiomarkers,
    isSnapshotLoading,
    isBloodReportResultsLoading,
    isBloodReportResultsValidating,
    isSnapshotValidating,
    snapshotError,
    bloodReportResultsError,
    labCompany,
    usesClinicOptimalRange,
    mutateBloodReportResultsAndSnapshot,
    getBloodReportResultsByLabTest,
    getBloodReportResultsByBodySystem,
    getMatchingBloodReportResultByBiomarkerId,
  }
}
