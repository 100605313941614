import { useState } from "react"

import Tooltip from "app/components/Tooltip"
import GenericConfirmationModal from "app/components/modals/GenericConfirmationModal"
import useAppSelector from "app/hooks/useAppSelector"
import { IOKOnboardingModal } from "app/main/in-office-kits/IOKOnboardingModal"
import { StyledSwitch } from "app/main/settings/components/StyledSwitch"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import { Clinic } from "types/clinic"

import useToggleInOfficeKits from "./hooks/use-toggle-in-office-kits"

interface Props {
  name: string
  onSuccess?: (clinic: Clinic | undefined) => void
}

const InOfficeKitsToggle: React.FC<Props> = ({ name, onSuccess }) => {
  const practitioner = useAppSelector(selectPractitioner)
  const [showIOKModal, setShowIOKModal] = useState(false)
  const [disableIOKModalOpen, setDisableIOKModalOpen] = useState(false)

  const { value, disabled, disabledReason, toggleInOfficeKits } =
    useToggleInOfficeKits({ practitioner, onSuccess })

  const onClick = () => {
    if (disabled) return
    // If previously off, open the onboarding modal.
    if (!value) {
      setShowIOKModal(true)
    } else {
      // If previously on, open the confirmation modal.
      setDisableIOKModalOpen(true)
    }
  }

  return (
    <>
      {showIOKModal && (
        <IOKOnboardingModal
          showModal={showIOKModal}
          onExit={() => setShowIOKModal(false)}
          onComplete={() => {
            toggleInOfficeKits().then(() => setShowIOKModal(false))
          }}
        />
      )}
      <GenericConfirmationModal
        open={disableIOKModalOpen}
        data={{
          title: "Turn off In-Office Supplies?",
          text: "Turning off In-Office Supplies will disable it for your entire clinic.",
          buttonText: "Disable In-Office Supplies",
        }}
        onClose={() => setDisableIOKModalOpen(false)}
        actionFn={() => {
          toggleInOfficeKits().then(() => setDisableIOKModalOpen(false))
        }}
        actionButtonColor="destructiveSecondary"
      />
      <Tooltip title={disabledReason ?? ""} placement="top" arrow>
        <div>
          <StyledSwitch
            checked={value}
            value={value}
            onChange={onClick}
            name={name}
            disabled={disabled}
          />
        </div>
      </Tooltip>
    </>
  )
}

export default InOfficeKitsToggle
