import { Button, Tooltip, TooltipProvider } from "@rupahealth/design"

import { ReactComponent as ResultsVisualizationIcon } from "app/assets/icons/dashboard/results-visualization.svg"
import { useEmbeddableResultModal } from "app/components/hooks/use-embeddable-result-modal"
import useEventCallback from "app/hooks/use-event-callback"
import {
  PatientPortalLabCompany,
  PatientPortalLabTest,
  PatientPortalOrderedTest,
} from "app/patient-portal/types"

export interface PatientPortalOrderedTestEmbeddableResultActionProps {
  orderedTest: PatientPortalOrderedTest
  labCompany: PatientPortalLabCompany
  labTest: PatientPortalLabTest
}

export default function PatientPortalOrderedTestEmbeddableResultAction({
  orderedTest,
  labCompany,
  labTest,
}: PatientPortalOrderedTestEmbeddableResultActionProps) {
  const emmbeddableUserResultId =
    orderedTest.relationships.embeddable_user_result?.data.id
  const resultsReleasedToPatient =
    orderedTest.attributes.results_released_to_patient

  const { showEmbeddableResult } = useEmbeddableResultModal(
    emmbeddableUserResultId,
    orderedTest.relationships.order.data.id,
    labCompany.id,
    labTest.attributes.name,
    orderedTest.attributes.lab_results
      ? [orderedTest.attributes.lab_results]
      : [],
    "patient"
  )

  const onClick = useEventCallback(showEmbeddableResult)

  const buttonNode = (
    <Button
      disabled={!emmbeddableUserResultId || !resultsReleasedToPatient}
      onClick={onClick}
      className="flex flex-row items-center gap-2"
    >
      <ResultsVisualizationIcon
        data-cy="results-visualization-cleared"
        fill="currentColor"
        viewBox="0 0 15 18"
      />
      See Results
    </Button>
  )

  if (orderedTest.attributes.results_released_to_patient) {
    return buttonNode
  }

  return (
    <TooltipProvider>
      <Tooltip
        content={
          resultsReleasedToPatient
            ? emmbeddableUserResultId
              ? "See Results"
              : "Results unavailable."
            : "Results have not been released by your practitioner."
        }
      >
        {buttonNode}
      </Tooltip>
    </TooltipProvider>
  )
}
