import { useCallback, useMemo } from "react"

import { CircularProgress, Button as MuiButton } from "@material-ui/core"

import { ReactComponent as CalendarBlue } from "app/assets/images/calendar-blue.svg"
import { ReactComponent as PrintBlue } from "app/assets/images/print-blue.svg"
import { NavyTooltip } from "app/components/NavyTooltip"
import {
  LAB_COMPANY_KEY,
  ORDER_STATUS,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import useOpenRequisition from "app/dashboard/hooks/use-open-requisition"
import { isOrderedTestForCompany } from "app/dataServices/orderDataService"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { trackPractitionerDashboardAction } from "app/services/segment.typed"
import { colors, shadows } from "app/theme"
import { OrderedTest, Patient, PatientOrdersOrder } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"
import { getOrderTypeLabel } from "app/utils/order-utils"

import { PatientOrderEvent } from "../constants"
import PatientOrdersOrderedTestDownloadActions from "./PatientOrdersOrderedTestDownloadActions"
import PatientOrdersOrderedTestEmbeddableResultAction from "./PatientOrdersOrderedTestEmbeddableResultAction"
import PatientOrdersOrderedTestMarkReviewedAction from "./PatientOrdersOrderedTestMarkReviewedAction"
import PatientOrdersOrderedTestResultsAction from "./PatientOrdersOrderedTestResultsAction"
import { getClinicalConsultAvailability } from "./clinicalConsultAvailability"

export const useStyles = makeAppStyles((theme) => ({
  orderActionsWrapper: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 0,

    [theme.breakpoints.up("md")]: {
      marginLeft: 8,
      justifyContent: "flex-end",
    },
  },
  actionButton: {
    height: 36,
    width: 36,
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    padding: "5px 8px",
    minWidth: 0,
    marginRight: 8,
    backgroundColor: "white",
    boxShadow: shadows.sm,
    "&:disabled": {
      opacity: 0.5,
    },

    [theme.breakpoints.up("md")]: {
      marginLeft: 8,
      marginRight: "auto",
    },
  },
  icon: {
    width: "100%",
    height: "100%",
  },
}))

function useConsultUrl(order: PatientOrdersOrder, orderedTest: OrderedTest) {
  const consultUrl = useMemo(() => {
    if (order.status === ORDER_STATUS.DRAFT) {
      return
    }

    const practitioner = order.signing_practitioner
    const labCompany = orderedTest.lab_test.lab_company
    const labCompanyAccount =
      practitioner &&
      practitioner.lab_company_accounts.find((companyAccount) =>
        companyAccount.lab_companies
          .map((company) => company.id)
          .includes(labCompany.id)
      )

    const query = new URLSearchParams({
      "practitioner-first-name": practitioner && practitioner.user.first_name,
      "practitioner-last-name": practitioner && practitioner.user.last_name,
      "lab-company-name": labCompany.name,
      "lab-company-id": labCompany.id,
      "accession-id": orderedTest.accession_id,
      "practitioner-npi": practitioner && practitioner.npi_number,
      "practitioner-email": practitioner && practitioner.user.email,
      "request-not-from-signing-practitioner":
        practitioner && (practitioner.id !== order.practitioner.id).toString(),
    })

    if (labCompanyAccount && labCompanyAccount.account_id) {
      query.append("practitioner-account-number", labCompanyAccount.account_id)
    }

    if (practitioner && practitioner.clinic.phone_number) {
      query.append("practitioner-phone", practitioner.clinic.phone_number)
    }

    return `https://www.rupahealth.com/clinical-consultation?${query.toString()}`
  }, [order, orderedTest.lab_test.lab_company])

  return consultUrl
}

function legacyClinicConsultDisabledReason(
  order: PatientOrdersOrder,
  orderedTest: OrderedTest
) {
  if (!orderedTest.results) {
    return (
      <>
        Schedule a Clinical Consult <br />
        (Available When Results Are In)
      </>
    )
  } else if (
    isOrderedTestForCompany(orderedTest, LAB_COMPANY_KEY.BIOREFERENCE)
  ) {
    return "Clinical consults are not available for BioReference tests"
  }

  return ""
}

export default function PatientOrdersOrderedTestOrderActions({
  order,
  orderedTest,
  patient,
  markOrderedTestResultReviewed,
  acknowledgeUnreadResult,
  appliedFilters,
}: {
  order: PatientOrdersOrder
  orderedTest: OrderedTest
  patient: Patient
  markOrderedTestResultReviewed: Function
  acknowledgeUnreadResult: Function
  appliedFilters?: any
}) {
  const { lab_test, requisition, specimen_issue } = orderedTest
  const styles = useStyles()

  const [embeddableResultsEnabled] = useFeatureFlag(
    FeatureFlag.EmbeddableResults
  )

  const [isEarlyClinicalConsultsEnabled] = useFeatureFlag(
    FeatureFlag.IsEarlyClinicalConsultsEnabled
  )

  const {
    disabled: requisitionDisabled,
    loading: requisitionLoading,
    openRequisition,
  } = useOpenRequisition({
    sampleCollectionCoordinator: orderedTest.sample_collection_coordinator,
    order,
    patient,
    labCompany: lab_test.lab_company,
    orderedTestId: orderedTest.id,
    requisitionUrl: requisition,
    recollectionRequisitionUrl: specimen_issue?.recollection_requisition,
  })

  const consultUrl = useConsultUrl(order, orderedTest)

  const openConsult = useCallback(() => {
    trackPractitionerDashboardAction(
      PatientOrderEvent.SCHEDULE_CONSULT,
      order.practitioner.id
    )
    window.open(consultUrl, "_blank")
  }, [consultUrl, order.practitioner.id])

  const viewRequisition = async () => {
    await openRequisition()
    trackPractitionerDashboardAction(
      PatientOrderEvent.DOWNLOAD_REQUISTION,
      order.practitioner.id
    )
  }

  const { clinicConsultDisabled, clinicConsultDisabledReason } = useMemo(() => {
    if (
      order.requires_physician_authorization ||
      order.requires_vendor_physician_authorization
    ) {
      return {
        clinicConsultDisabled: true,
        clinicConsultDisabledReason: `Clinical consults are not available for ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} ${getOrderTypeLabel(
          true
        )}s`,
      }
    }
    if (isEarlyClinicalConsultsEnabled) {
      const clinicalConsultAvailabilityDetails =
        getClinicalConsultAvailability(orderedTest)
      return {
        clinicConsultDisabled:
          !clinicalConsultAvailabilityDetails.isAvailable(),
        clinicConsultDisabledReason: (
          <>
            Schedule a Clinical Consult <br />
            {clinicalConsultAvailabilityDetails.unavailableReason}
          </>
        ),
      }
    } else {
      const clinicConsultDisabledReason = legacyClinicConsultDisabledReason(
        order,
        orderedTest
      )
      return {
        clinicConsultDisabled: clinicConsultDisabledReason !== "",
        clinicConsultDisabledReason: clinicConsultDisabledReason,
      }
    }
  }, [orderedTest, order])

  const clinicConsultTooltip = clinicConsultDisabled
    ? clinicConsultDisabledReason
    : "Schedule a Clinical Consult"

  return (
    <div className={styles.orderActionsWrapper}>
      {orderedTest.results ? (
        <PatientOrdersOrderedTestMarkReviewedAction
          order={order}
          orderedTest={orderedTest}
          markOrderedTestResultReviewed={markOrderedTestResultReviewed}
        />
      ) : (
        <NavyTooltip
          arrow
          placement="top"
          title={
            requisitionDisabled
              ? "No Digital Requisition Available"
              : "View Requisition"
          }
        >
          <div>
            <MuiButton
              onClick={viewRequisition}
              disabled={requisitionDisabled}
              key="print-button"
              className={styles.actionButton}
            >
              {requisitionLoading ? (
                <CircularProgress
                  size={15}
                  aria-label="Loading the requisition form"
                />
              ) : (
                <PrintBlue className={styles.icon} />
              )}
            </MuiButton>
          </div>
        </NavyTooltip>
      )}
      <NavyTooltip arrow placement="top" title={clinicConsultTooltip}>
        <div>
          <MuiButton
            onClick={openConsult}
            disabled={clinicConsultDisabled}
            key="calendar-button"
            className={styles.actionButton}
          >
            <CalendarBlue
              title="consult-calendar-icon"
              className={styles.icon}
            />
          </MuiButton>
        </div>
      </NavyTooltip>

      <PatientOrdersOrderedTestResultsAction
        order={order}
        orderedTest={orderedTest}
        patient={patient}
      />

      {embeddableResultsEnabled && orderedTest.embeddable_user_result_id ? (
        <PatientOrdersOrderedTestEmbeddableResultAction
          order={order}
          orderedTest={orderedTest}
          labCompany={lab_test.lab_company}
          labTest={lab_test}
        />
      ) : (
        <PatientOrdersOrderedTestDownloadActions
          order={order}
          orderedTest={orderedTest}
          acknowledgeUnreadResult={acknowledgeUnreadResult}
          appliedFilters={appliedFilters}
        />
      )}
    </div>
  )
}
