import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { isEmpty } from "lodash"

import { Dialog, Grid, makeStyles } from "@material-ui/core"

import BundleBlue from "app/assets/icons/bundle-blue.svg"
import CircleArrowRight from "app/assets/icons/circle-arrow-right.svg"
import LabTests from "app/components/LabTests/LabTests"
import PageToolbar from "app/components/PageToolbar"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import BundleModal from "app/components/modals/BundleModal"
import { ORDERING_RIGHTS_ACTIONS } from "app/constants"
import { useResourceSelector } from "app/hooks/use-resource"
import useAppSelector from "app/hooks/useAppSelector"
import { fetchPractitionerBundles } from "app/store/actions"
import {
  addFavoriteTest,
  removeFavoriteTest,
} from "app/store/actions/practitioner.actions"
import { colors, Theme } from "app/theme"
import {
  AnyLimitedLabTest,
  labTestLocation,
  Practitioner,
  PractitionerLabTestBundle,
} from "app/types"

import BundleCard from "../checkout/BundleCard"

const styles = (theme: Theme) => ({
  container: {
    padding: 22.5,
    background: colors.blueGray[100],
  },
  bundlesContainer: {
    background: "white",
    padding: "15px 14px",
    borderRadius: 8,
    "box-shadow":
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    marginBottom: 20,
  },
  bundlesHeaderContainer: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
  },
  bundlesContent: {
    marginTop: 19,
  },
})
const useStyles = makeStyles(styles)

interface Props {
  onAddLabTest: (labTest: AnyLimitedLabTest) => void
  onRemoveLabTest: (labTest: AnyLimitedLabTest) => void
  onAddBundle: (bundle: PractitionerLabTestBundle) => void
  onRemoveBundle: (bundle: PractitionerLabTestBundle) => void
  selectedLabTestIds: string[]
  selectedBundleIds: number[]
  isModalOpen: boolean
  closeModal: () => void
  orderingPractitioner?: Practitioner
  usePractitionerLicense: boolean
  storefrontId: string
}

const EcommerceLabTests = ({
  onAddLabTest,
  onRemoveLabTest,
  onAddBundle,
  onRemoveBundle,
  selectedLabTestIds,
  selectedBundleIds,
  isModalOpen,
  closeModal,
  orderingPractitioner,
  usePractitionerLicense,
  storefrontId,
}: Props) => {
  const [isBundleModalOpen, setIsBundleModalOpen] = useState(false)
  const [bundleInModal, setBundleInModal] =
    useState<PractitionerLabTestBundle>()
  const classes = useStyles(styles)
  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const practitionerBundles = useAppSelector(
    ({ practitionerBundles }) => practitionerBundles.results
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (storefrontId) {
      dispatch(
        fetchPractitionerBundles(
          undefined,
          ORDERING_RIGHTS_ACTIONS.ADD_TO_LABSHOP,
          storefrontId
        )
      )
    }
  }, [storefrontId, isModalOpen])

  const labTests = useResourceSelector((entities) => {
    if (!selectedLabTestIds) {
      return []
    }

    return selectedLabTestIds.map((labTestId) => {
      return entities?.lab_test?.[labTestId]
    })
  })

  const openBundleModal = (bundle?: PractitionerLabTestBundle) => {
    setBundleInModal(bundle)
    setIsBundleModalOpen(true)
  }

  const closeBundleModal = () => {
    setIsBundleModalOpen(false)
    setBundleInModal(undefined)
  }

  const handleCreateBundleClick = () => {
    setIsBundleModalOpen(true)
  }

  const handleToggleFavorite = (lab_test, isFavorite) => {
    if (isFavorite) {
      dispatch(removeFavoriteTest(practitioner, lab_test))
    } else {
      dispatch(addFavoriteTest(practitioner, lab_test))
    }
  }

  return (
    <>
      <Dialog
        onClose={closeModal}
        aria-labelledby="lab-test-modal-title"
        open={isModalOpen}
        fullWidth={true}
        maxWidth={false}
      >
        <div>
          <PageToolbar
            title="Add Lab Tests to Your Store"
            fullBorder={true}
            children={
              <DesignSystemButton
                onClick={closeModal}
                color="text"
                endIcon={CircleArrowRight}
                size="medium"
              >
                Return to Store Setup
              </DesignSystemButton>
            }
          />
        </div>
        <div className={classes.container}>
          <div className={classes.bundlesContainer}>
            <div className={classes.bundlesHeaderContainer}>
              <div>
                <BodyText size="lg" weight="semibold">
                  Store Panels & Bundles
                </BodyText>
                <BodyText size="sm">
                  Create blood panels, or any combination of tests.
                </BodyText>
              </div>
              <div>
                <DesignSystemButton
                  onClick={() => setIsBundleModalOpen(true)}
                  color="secondary"
                  startIcon={BundleBlue}
                >
                  Create a Panel or Bundle
                </DesignSystemButton>
              </div>
            </div>
            {Boolean(practitionerBundles.length) && (
              <Grid container spacing={2} className={classes.bundlesContent}>
                {practitionerBundles.map((bundle) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      // set the key as a combination of the bundle id and lab tests it includes so that this
                      // component updates when the lab tests belonging to a bundle are updated
                      key={`${bundle.id}-${bundle.lab_tests
                        ?.map((lab_test) => lab_test.id)
                        .join("-")}`}
                      className="labTestCardGridItem"
                    >
                      <BundleCard
                        practitioner={
                          orderingPractitioner
                            ? orderingPractitioner
                            : practitioner
                        }
                        bundle={bundle}
                        openModal={() => openBundleModal(bundle)}
                        onAdd={onAddBundle}
                        onRemove={onRemoveBundle}
                        orderedBundleIds={selectedBundleIds}
                        location={labTestLocation.ECOMMERCE_BUNDLES}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            )}
          </div>

          <LabTests
            location={labTestLocation.ECOMMERCE}
            practitioner={practitioner}
            signingPractitioner={
              orderingPractitioner ? orderingPractitioner : practitioner
            }
            onAddLabTest={onAddLabTest}
            onRemoveLabTest={onRemoveLabTest}
            selectedLabTests={
              labTests
                ? labTests.reduce((selectedLabTests, labTest) => {
                    if (!labTest?.id) {
                      return selectedLabTests
                    }
                    return {
                      ...selectedLabTests,
                      [labTest?.id]: labTest?.attributes,
                    }
                  }, {})
                : {}
            }
            createBundleClick={handleCreateBundleClick}
            vendorPhysicianServicesActivated={!usePractitionerLicense}
            storefrontId={storefrontId}
          />
        </div>
        {!isEmpty(practitioner) && (
          <BundleModal
            open={isBundleModalOpen}
            practitioner={practitioner}
            practitionerBundles={[]}
            onClose={closeBundleModal}
            handleToggleLabTestFavorite={handleToggleFavorite}
            bundle={bundleInModal}
            usePhysicianAuthorizationForOrderingAccess={!usePractitionerLicense}
            ecommerceOrderingPractitioner={orderingPractitioner ?? practitioner}
            location={labTestLocation.ECOMMERCE_BUNDLES}
            storefrontId={storefrontId}
          />
        )}
      </Dialog>
    </>
  )
}

export default EcommerceLabTests
