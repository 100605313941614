import { useModal } from "@ebay/nice-modal-react"

import useEventCallback from "app/hooks/use-event-callback"
import useFeatureFlag from "app/hooks/use-feature-flag"
import BloodLabDashboardsModal from "app/main/blood-lab-dashboards/BloodLabDashboardsModal/BloodLabDashboardsModal"
import { BloodLabDashboardsSnapshotModal } from "app/main/blood-lab-dashboards/BloodLabDashboardsSnapshotModal/BloodLabDashboardsSnapshotModal"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import { Kit } from "types/kit"
import { OrderedResultDocument } from "types/patient-with-documents"
import { UserResult } from "types/user-result"

// TODO: Refactor so other modals use this hook PROD-487
export default function useBloodReportModal(
  orderedResult: OrderedResultDocument,
  kit: Kit | undefined
) {
  const [isBloodLabDashboardSnapshotOn] = useFeatureFlag(
    FeatureFlag.RupaBloodReportSnapshots
  )
  const bloodReportModalLegacy = useModal(BloodLabDashboardsModal)
  const bloodReportModal = useModal(BloodLabDashboardsSnapshotModal)
  const snapshot =
    orderedResult.relationships.blood_lab_dashboard_report_snapshots.data?.[0]

  const onModalOpen = useEventCallback(async () => {
    if (isBloodLabDashboardSnapshotOn && snapshot) {
      bloodReportModal.show({
        onClose: () => {
          bloodReportModal.remove()
        },
        snapshotId: snapshot.id,
        kitSentToPatient: Boolean(kit?.attributes.date_results_sent_to_patient),
      })
    } else {
      bloodReportModalLegacy.show({
        onClose: () => {
          bloodReportModalLegacy.remove()
        },
        orderedResultId: orderedResult.id,
        kitSentToPatient: Boolean(kit?.attributes.date_results_sent_to_patient),
      })
    }
  })
  return { onModalOpen }
}

// TODO: rename and delete `V2` from method name
// cleanup v1 ticket - https://linear.app/rupa-health/issue/PROD-2148/[document-access]-remove-feature-flags
export function useBloodReportModalV2(
  userResult?: UserResult,
  date_results_sent_to_patient?: string
) {
  const bloodReportModal = useModal(BloodLabDashboardsSnapshotModal)
  const snapshot =
    userResult?.relationships?.blood_lab_dashboard_report_snapshots?.data?.[0]

  const onModalOpen = useEventCallback(async () => {
    if (snapshot) {
      bloodReportModal.show({
        onClose: () => {
          bloodReportModal.remove()
        },
        snapshotId: snapshot.id,
        kitSentToPatient: Boolean(date_results_sent_to_patient),
      })
    }
  })
  return { onModalOpen }
}
