import { useEffect } from "react"

import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid } from "@material-ui/core"

import rupaHealthLogo from "app/assets/images/logos/rupa-logo-mark.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import usePhysicianServicesOptInModal from "app/components/modals/PhysicianServicesOptInModal/use-physician-services-opt-in-modal"
import useConfirmationModal from "app/components/modals/generic/hooks/use-confirmation-modal"
import {
  PHYSICIAN_AUTHORIZATION_GUIDE_URL,
  PHYSICIAN_SERVICES_ENABLE_METHODS,
  VENDOR_PHYSICIAN_AUTHORIZATION_LABEL,
} from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { Practitioner } from "app/types"
import { getOrderTypeLabel } from "app/utils/order-utils"

import { PractitionerSetting } from "../PersonalSettings"
import { StyledFormControlLabel } from "./StyledFormControlLabel"
import { StyledSwitch } from "./StyledSwitch"

interface Props {
  practitioner: Practitioner
  handleToggle: (key: PractitionerSetting) => void
  openModalOnLoad: boolean
}

const PhysicianAuthorizationSettings: React.FC<Props> = ({
  practitioner,
  handleToggle,
  openModalOnLoad,
}) => {
  const physicianServicesOptInModal = usePhysicianServicesOptInModal()
  const confirmationModal = useConfirmationModal()

  const [physicianServicesBackfillPhase1Enabled] = useFeatureFlag(
    FeatureFlag.PhysicianServicesBackfillPhase1
  )

  const handlePhysicianServicesToggle = () => {
    if (!practitioner.vendor_physician_authorization_enabled) {
      physicianServicesOptInModal.show({
        onClose: () => {
          physicianServicesOptInModal.remove()
        },
      })
    } else {
      confirmationModal.show({
        title: "Are you sure?",
        message: practitioner.has_published_physician_services_labshop
          ? `Removing ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} will turn off all active Labshops, and cancel any unpaid orders. Are you sure you’d like to do this?`
          : `Removing ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} will cancel any unpaid orders. Are you sure you'd like to do this?`,
        backButtonTitle: "Go back",
        confirmButtonTitle: `Turn Off ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}`,
        confirmButtonColor: "destructiveSecondary",
        onClose: confirmationModal.remove,
        loading: false,
        handleConfirm: () => {
          confirmationModal.remove()
          handleToggle("vendor_physician_authorization_enabled")
        },
      })
    }
  }

  const blockedMessage: string | undefined =
    // if they already have it turned on somehow, we'll let them turn it off
    practitioner.vendor_physician_authorization_enabled
      ? undefined
      : practitioner.physician_services_blocked
      ? `Unable to turn on ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}. Please message our team to continue.`
      : practitioner.is_clinic_staff
      ? `Each practitioner must enable ${VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} within their own accounts.`
      : undefined

  const informationText = (
    <BodyText>
      By adding {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} to your account, your
      clients can gain access to testing on Rupa. A Signing Physician will
      review the results for a critical value.
      <a
        href={PHYSICIAN_AUTHORIZATION_GUIDE_URL}
        target="_blank"
        rel="noreferrer"
        className="text-primary font-semibold sm:ml-1"
      >
        Learn more <FontAwesomeIcon icon={faArrowUpRight} />
      </a>
    </BodyText>
  )

  useEffect(() => {
    const updatedTerms =
      physicianServicesBackfillPhase1Enabled &&
      practitioner.vendor_physician_authorization_enabled &&
      practitioner.physician_services_enable_method !==
        PHYSICIAN_SERVICES_ENABLE_METHODS.OPT_IN
    if (
      openModalOnLoad &&
      (!practitioner.vendor_physician_authorization_enabled || updatedTerms)
    ) {
      physicianServicesOptInModal.show({
        updatedTerms: updatedTerms,
        practitionerHasPhysicianServicesLabshop:
          practitioner.has_published_physician_services_labshop,
        onClose: () => {
          physicianServicesOptInModal.remove()
        },
      })
    }
  }, [openModalOnLoad, practitioner, physicianServicesBackfillPhase1Enabled])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="flex items-center">
          <BodyText weight="semibold" size="lg">
            <img
              className="mr-2 -mb-1.5 w-[25px] h-auto"
              src={rupaHealthLogo}
              alt="Rupa Logo"
            />
            {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
          </BodyText>
        </Grid>
      </Grid>

      <div>
        <StyledFormControlLabel
          control={
            <Tooltip
              title={blockedMessage ?? ""}
              disableHoverListener={!blockedMessage}
              placement="bottom"
              arrow
            >
              <div>
                <StyledSwitch
                  name="vendor_physician_authorization_enabled"
                  checked={practitioner.vendor_physician_authorization_enabled}
                  onChange={handlePhysicianServicesToggle}
                  disabled={!!blockedMessage}
                />
              </div>
            </Tooltip>
          }
          label={
            <>
              <BodyText weight="semibold">
                {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}
              </BodyText>
              {informationText}
            </>
          }
        />
        {practitioner.vendor_physician_authorization_enabled &&
          !blockedMessage && (
            <StyledFormControlLabel
              control={
                <StyledSwitch
                  name="default_physician_authorization"
                  checked={practitioner.default_physician_authorization}
                  onChange={() =>
                    handleToggle("default_physician_authorization")
                  }
                />
              }
              label={
                <>
                  <BodyText weight="semibold">
                    Use the {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} Signing
                    Physician as the default for new{" "}
                    {getOrderTypeLabel(true).toLowerCase()}s
                  </BodyText>
                  <BodyText>
                    Save time by automatically using{" "}
                    {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL} for new{" "}
                    {getOrderTypeLabel(true).toLowerCase()}s. You can still
                    choose whether to use a different signing practitioner every
                    time you start a {getOrderTypeLabel(true).toLowerCase()}.
                  </BodyText>
                </>
              }
            />
          )}
      </div>
    </>
  )
}

export default PhysicianAuthorizationSettings
