import { useState } from "react"

import { Link } from "react-router-dom"

import { UserPaths } from "app/Routes"
import ActionRequiredImage from "app/assets/images/action-required.jpg"
import OrderSuccessFallbackImage from "app/assets/images/order-success.svg"
import DesignSystemButton from "app/components/design-system/Button"
import ActionRequiredModal from "app/components/modals/ActionRequiredModal"
import SignatureAndLicenseModalButton, {
  signatureAndLicenseButtonTitle,
} from "app/components/modals/SignatureAndLicenseModal/SignatureAndLicenseModalButton"
import { Order, Practitioner } from "app/types"
import { formatDate } from "app/utils"
import { getOrderPatientLabel, getOrderTypeLabel } from "app/utils/order-utils"

import { CERBO_OAUTH_ID, ORDER_NOTIFICATION_METHOD } from "../../constants"

interface Props {
  order: Order
  currentPractitioner: Practitioner | undefined
  open: boolean
  onClose: () => void
}

const OrderConfirmationModal: React.FC<Props> = ({
  currentPractitioner,
  onClose,
  open,
  order,
}) => {
  const practitioner = order?.practitioner
  const [helloSignVerificationComplete, setHelloSignVerificationComplete] =
    useState(false)

  const patientName = order?.patient?.full_name
  const onCloseSignatureAndLicenseModal = (fullVerificationComplete) => {
    setHelloSignVerificationComplete(fullVerificationComplete)
  }

  // To prevent showing the request for signatures to older users who have signed through the old system, we hardcode
  // in a date for now based on the date of first order.
  const excludedFromSigning =
    practitioner?.first_order_date &&
    Date.parse(practitioner.first_order_date) <
      Date.parse("2020-04-20T08:00:00Z")

  const signingPractitioner = order.signing_practitioner

  // If verification was completed via Hellosign, we no longer need to display
  // the "Action Required" content and "Sign Now" button.
  const esignatureProvided = helloSignVerificationComplete

  const signatureNeeded =
    !order.requires_vendor_physician_authorization &&
    !signingPractitioner?.has_full_verification_info &&
    !excludedFromSigning &&
    !esignatureProvided

  const verificationNeeded = !practitioner?.user?.is_verified

  // Show sign now button if the logged in practitioner is the signing practitioner
  const needsSigningPractitionerSignature =
    signatureNeeded &&
    signingPractitioner &&
    signingPractitioner.id === currentPractitioner?.id &&
    !esignatureProvided

  const hasRupaNotes = !!order.notes_to_rupa
  const textMessageCopy =
    order.notification_method === ORDER_NOTIFICATION_METHOD.EMAIL_AND_TEXT
      ? " and text message"
      : ""

  let description

  if (needsSigningPractitionerSignature) {
    description = `We need your e-signature and license on file before we can send this order to ${patientName}.`
  } else if (signatureNeeded) {
    description = `${signingPractitioner?.titled_full_name} needs to log in to Rupa and sign their documents. We just sent an email with instructions to ${signingPractitioner?.user?.email}. Please have ${signingPractitioner?.titled_full_name} check their email and sign their documents.`
  } else if (verificationNeeded) {
    description = `${practitioner?.titled_full_name} needs to verify their email. We just sent an email with instructions to ${practitioner?.user?.email}. Please have ${practitioner?.titled_full_name} check their email and verify their account.`
  } else if (hasRupaNotes) {
    description = `A member of our team will review your Notes to Rupa, and we’ll send an email${textMessageCopy} to ${patientName} with next steps shortly.`
  } else if (order.date_scheduled) {
    description = `${patientName} will receive an email${textMessageCopy} with the next steps on ${formatDate(
      order.date_scheduled
    )}.`
  } else if (order.requires_vendor_physician_authorization) {
    description = `You have just taken the next step to help ${
      order?.patient?.first_name
        ? order?.patient?.first_name
        : `your ${getOrderPatientLabel(true).toLowerCase()}`
    } uncover the root cause, in collaboration with a physician.`
    if (order.is_practitioner_paying) {
      description += ` This order has been sent to the signing physician and may take up to a few business days to process.`
    } else {
      description += ` Once paid, this order will be sent to the signing physician. Orders may take up to a few business days to process.`
    }
  } else {
    description = `We’ve sent an email${textMessageCopy} to ${patientName} with next steps.`
  }

  const actionRequired = signatureNeeded || verificationNeeded

  const orderImageWrapper = actionRequired
    ? ActionRequiredImage
    : order.confirmation_image
    ? order.confirmation_image.image
    : OrderSuccessFallbackImage

  const canSignForOrder = signingPractitioner?.id === currentPractitioner?.id
  const esignButton = (
    // Hellosign process
    <SignatureAndLicenseModalButton
      location="order_confirmation_modal"
      variant="primary"
      disabled={!canSignForOrder}
      onClose={onCloseSignatureAndLicenseModal}
      autoClose
    >
      {signatureAndLicenseButtonTitle(practitioner)}
    </SignatureAndLicenseModalButton>
  )

  const image = orderImageWrapper
  const submitSuccessTitle = order.date_scheduled
    ? `${getOrderTypeLabel(
        order.requires_vendor_physician_authorization
      )} scheduled for ${patientName}!`
    : `${getOrderTypeLabel(
        order.requires_vendor_physician_authorization
      )} sent to ${patientName}!`
  const actionRequiredTitle = "Just One More Step!"
  const title = actionRequired ? actionRequiredTitle : submitSuccessTitle

  // Button to return to either the dashboard or the return url (for oauth connection)
  const returnButton = order.order_intent?.return_url ? (
    <DesignSystemButton color="secondary" href={order.order_intent.return_url}>
      {order.order_intent.oauth_app_id === CERBO_OAUTH_ID
        ? `Finalize Cerbo Order`
        : `Return to ${order.order_intent.oauth_app_name}`}
    </DesignSystemButton>
  ) : (
    <Link
      to={UserPaths.DASHBOARD}
      style={{ textDecoration: "none", width: "100%" }}
    >
      <DesignSystemButton color="secondary" onClick={onClose}>
        Return to Dashboard
      </DesignSystemButton>
    </Link>
  )

  const button = needsSigningPractitionerSignature ? esignButton : returnButton

  return (
    <>
      <ActionRequiredModal
        // Force a re-mount of the modal when the image is updated. This ensures we always properly preload the image.
        key={image}
        open={open}
        onClose={onClose}
        image={image}
        imageAlt="Order Confirmed"
        button={button}
        title={title}
        subtitle={description}
      />
    </>
  )
}

export default OrderConfirmationModal
