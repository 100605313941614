import { useDroppable } from "@dnd-kit/core"
import { Grid, Theme } from "@material-ui/core"

import BundlesIcon from "app/assets/icons/bundles/bundles-fill.svg"
import { ReactComponent as SharedBundleIcon } from "app/assets/icons/bundles/synced.svg"
import { colors } from "app/theme"
import {
  Practitioner,
  PractitionerLabTestBundle,
  labTestLocation,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import DraggableBundle from "./DraggableBundle"
import EmptyBundleSection from "./EmptyBundleSection"

const styles = (theme: Theme) => ({
  bundleSectionCard: {
    width: "100%",
    background: "#fff",
    padding: 24,
    borderRadius: 8,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    display: "flex",
    flexFlow: "column nowrap",
    gap: 16,
    height: "100%",
  },
})
const useStyles = makeAppStyles(styles)

interface Props {
  droppableId: string
  practitioner: Practitioner
  isInfoCard: boolean
  bundles: PractitionerLabTestBundle[]
  openModal: (bundle: PractitionerLabTestBundle) => void
  usePhysicianAuthorizationForOrderingAccess: boolean
  location: labTestLocation
  orderedBundleIds?: number[]
  onAdd?: () => void
  onRemove?: () => void
}

const DroppableBundleSection = (props: Props) => {
  const classes = useStyles()
  const { setNodeRef } = useDroppable({
    id: props.droppableId,
  })

  if (props.bundles.length === 0) {
    return (
      <div ref={setNodeRef} className={classes.bundleSectionCard}>
        <EmptyBundleSection
          icon={
            props.droppableId === "personalBundles" ? (
              <img
                src={BundlesIcon}
                alt="bundle icon"
                width={45}
                style={{ marginBottom: 20 }}
              />
            ) : (
              <SharedBundleIcon
                fill={colors.blueGray[500]}
                viewBox="0 0 32 52"
                width={30}
                style={{ marginBottom: 20 }}
              />
            )
          }
          title={
            props.droppableId === "personalBundles"
              ? "Your Saved Bundles"
              : "Clinic Bundles"
          }
          description={
            props.droppableId === "personalBundles"
              ? "Create a private group of lab tests for easy 1-click ordering."
              : "Create a group of lab tests for easy 1-click ordering and share with everyone in your clinic"
          }
        />
      </div>
    )
  }

  return (
    <div ref={setNodeRef} className={classes.bundleSectionCard}>
      <Grid container spacing={3}>
        {props.bundles.map((bundle) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            // set the key as a combination of the bundle id and lab tests it includes so that this
            // component updates when the lab tests belonging to a bundle are updated
            key={`${bundle.id}-${bundle.lab_tests
              ?.map((lab_test) => lab_test.id)
              .join("-")}`}
            className="labTestCardGridItem"
          >
            <DraggableBundle
              bundle={bundle}
              practitioner={props.practitioner}
              isInfoCard={props.isInfoCard}
              openModal={props.openModal}
              usePhysicianAuthorizationForOrderingAccess={
                props.usePhysicianAuthorizationForOrderingAccess
              }
              location={props.location}
              orderedBundleIds={props.orderedBundleIds}
              onAdd={props.onAdd}
              onRemove={props.onRemove}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default DroppableBundleSection
