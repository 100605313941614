import React, { useMemo, useState } from "react"

import { orderBy, sumBy } from "lodash"

import { Grid } from "@material-ui/core"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import AddWhiteIcon from "app/assets/icons/add-white.svg"
import BundleIcon from "app/assets/icons/bundle-grey.svg"
import InfoIcon from "app/assets/icons/info-gray-circle.svg"
import Panel from "app/components/Panel"
import BodyText from "app/components/design-system/BodyText"
import { default as DesignSystemButton } from "app/components/design-system/Button"
import { Dialog } from "app/components/modals"
import LabTestModal from "app/components/modals/LabTestModal"
import { BUNDLE_TYPES } from "app/constants"
import LabTestCard from "app/main/checkout/LabTestCard"
import {
  LabTest,
  labTestLocation,
  LabTestShareBundle,
  Practitioner,
} from "app/types"

interface Props {
  open: boolean
  bundle: LabTestShareBundle
  practitioner: Practitioner | undefined
  onClose: () => void
  handleToggleFavorite: (labTest: LabTest, isFavorite: boolean) => void
  handleAddShareBundle: () => void
}

export const SharedBundleModal: React.FC<Props> = ({
  open,
  practitioner,
  onClose,
  handleToggleFavorite,
  bundle,
  handleAddShareBundle,
}) => {
  const [labTestInModal, setLabTestInModal] = useState<LabTest>()

  // Sort bundle lab tests by lab company name and then by lab test name(within a lab company)
  const bundleLabTestsSorted = useMemo(
    () => orderBy(bundle.lab_tests, ["lab_company.short_name", "name"]),
    [bundle]
  )

  const selectedLabTestsMap = bundle.lab_tests.reduce(
    (acc, lt) => ({ ...acc, [lt.id]: lt }),
    {} as { [labTestId: string]: LabTest }
  )

  const total = useMemo(() => {
    return sumBy(bundle.lab_tests, (lt) => parseFloat(lt.rupa_price))
  }, [bundle])

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        className="fs-unmask"
        classes={{
          paper: "m-3 rounded-lg",
        }}
        maxWidth="md"
      >
        <MuiDialogTitle
          className="flex flex-col sm:flex-row items-center gap-2 justify-between bg-white border-b border-slate-200"
          disableTypography
        >
          <div className="font-semibold text-xl19 mr-auto">{bundle.name}</div>
          <div className="flex flex-wrap gap-1.5 w-full sm:flex-nowrap sm:mr-6 sm:w-auto sm:ml-auto">
            {onClose && (
              <IconButton
                aria-label="close"
                onClick={onClose}
                className="text-body absolute top-2.5 right-0 text-2xl"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
          </div>
        </MuiDialogTitle>
        <MuiDialogContent className="py-3 sm:py-5 bg-slate-100">
          <div className="flex flex-col sm:flex-row items-stretch w-full relative">
            <div className="flex-1 relative w-auto sm:w-[700px]">
              <div
                className="rounded-lg p-5 border-gray-300 bg-white mb-4"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 8px",
                }}
              >
                <BodyText size="sm" className="mb-3">
                  Details
                </BodyText>
                <div className="flex">
                  <img
                    src={InfoIcon}
                    alt="info-icon"
                    className="mt-1 mr-1.5 w-[14.6px] h-[14.6px]"
                  />
                  {bundle.description}
                </div>
              </div>
              <Panel className="mb-8">
                <div className="flex items-end pt-4 pb-3 mx-7 rounded-t-lg">
                  <img
                    className="mt-[3px] mr-[3px] w-[17.42px]"
                    src={BundleIcon}
                    alt="bundle-icon"
                  />
                  <BodyText weight="semibold" className="ml-2">
                    ${total.toFixed(2)} Bundle
                  </BodyText>
                </div>

                <Grid container className="py-3 px-6 mt-0 " spacing={2}>
                  {bundleLabTestsSorted.map((lab_test) => {
                    let isFavorite = false
                    if (practitioner?.favorite_lab_test_id_set) {
                      isFavorite = practitioner.favorite_lab_test_id_set.has(
                        lab_test.id
                      )
                    }

                    return (
                      <Grid item xs={12} sm={6} lg={4} key={lab_test.id}>
                        <LabTestCard
                          modalPage={labTestLocation.BUNDLES}
                          labTest={lab_test}
                          practitioner={practitioner}
                          selectedLabTests={selectedLabTestsMap}
                          isFavorite={isFavorite}
                          onToggleFavorite={() =>
                            handleToggleFavorite(lab_test, isFavorite)
                          }
                          openModal={() => setLabTestInModal(lab_test)}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Panel>
            </div>
          </div>
        </MuiDialogContent>
        <MuiDialogActions className="m-0 py-2 px-5">
          <DesignSystemButton
            color="primary"
            startIcon={AddWhiteIcon}
            onClick={handleAddShareBundle}
          >
            {bundle.bundle_type === BUNDLE_TYPES.PANEL
              ? "Add to my Panels"
              : "Add to my Bundles"}
          </DesignSystemButton>
        </MuiDialogActions>
      </Dialog>
      {practitioner && labTestInModal && (
        <LabTestModal
          labTest={labTestInModal}
          isOpen={!!labTestInModal}
          practitioner={practitioner}
          signingPractitioner={practitioner}
          onClose={() => setLabTestInModal(undefined)}
          selectedLabTests={selectedLabTestsMap}
          location={labTestLocation.BUNDLES}
        />
      )}
    </>
  )
}
