import { LinearProgress, LinearProgressProps } from "@material-ui/core"
import { cn } from "@rupahealth/design"

import { progressBarGradient1, progressBarGradient2 } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const useStyles = makeAppStyles<{ progressPercentage?: number }>(() => ({
  linearProgress: {
    "& .MuiLinearProgress-bar": {
      borderRadius: 5,
      backgroundImage: `linear-gradient(to left, ${progressBarGradient1}, ${progressBarGradient2})`,
      backgroundPosition: "right",
      // Background size is used to show the full range of colors no matter what % of the way the progress bar
      // Background size is not required for values >= 75 %
      backgroundSize: (props) =>
        props.progressPercentage && props.progressPercentage < 75
          ? `${props.progressPercentage}%`
          : "auto",
    },
  },
}))

interface Props extends Omit<LinearProgressProps, "value"> {
  progressPercentage: number
}
const RupaLinearProgress: React.FC<Props> = ({
  progressPercentage,
  className,
  ...linearProgressProps
}) => {
  const styles = useStyles({ progressPercentage })
  return (
    <LinearProgress
      className={cn(
        "h-[6px] rounded-[5px] my-2 bg-gray-200 min-w-[256px]",
        styles.linearProgress,
        className
      )}
      value={progressPercentage}
      variant="determinate"
      {...linearProgressProps}
    />
  )
}

export default RupaLinearProgress
