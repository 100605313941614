import { useCallback, useEffect, useMemo, useState } from "react"

import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { Dialog, DialogContent, Skeleton } from "@rupahealth/design"

import { API } from "app/api"
import { ReactComponent as SearchIcon } from "app/assets/icons/search-icon.svg"
import { ReactComponent as TestTubesIconLarge } from "app/assets/icons/test-tubes-large.svg"
import { ReactComponent as TestTubesIconSmall } from "app/assets/icons/test-tubes-small.svg"
import OverflowTooltip from "app/components/OverflowTooltip"
import RupaLoadingButton from "app/components/RupaLoadingButton"
import SampleTypePill from "app/components/SampleTypePill"
import Tooltip from "app/components/Tooltip"
import TextField from "app/components/forms/TextFieldComponent"
import { ORDERING_RIGHTS_ACTIONS } from "app/constants"
import ordersReducer from "app/main/checkout/store/reducers"
import dashboardReducer from "app/main/dashboard/store/reducers"
import withReducer from "app/store/withReducer"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useResourceSWR from "app/swr/hooks/use-resource-swr"
import { AnyLabTest } from "app/types"
import { handleApiError } from "app/utils"
import sortBy from "lodash/sortBy"
import { LabTestType } from "types/lab-test-type"

import { InOfficeKitLabTest, InOfficeKitProduct } from "../types"
import { getUnavailableReasonForActivation } from "../utils"
import { InventoryItem } from "./inventory-types"

interface Props {
  inventoryItem: InventoryItem
  onLabTestSelect: (labTest: AnyLabTest) => void
}
const ActivateKitModal: React.FC<Props> = ({
  inventoryItem,
  onLabTestSelect,
}) => {
  const modal = useModal()
  const [searchTerm, setSearchTerm] = useState("")

  // Clear search term when modal is closed
  useEffect(() => {
    !modal.visible && setSearchTerm("")
  }, [modal.visible])

  const { data, isLoading } = useResourceSWR<InOfficeKitProduct>(
    `/in_office_kit_product/${inventoryItem.in_office_kit_product_id}`,
    { include: ["lab_tests.lab_test_types"] }
  )
  const labTests = useCachedCollection<InOfficeKitLabTest>(
    data?.relationships.lab_tests.data
  )
  const sortedLabTests = useMemo(() => {
    return sortBy(labTests, (labTest) => labTest.attributes.name.toLowerCase())
  }, [labTests])
  const filteredLabTests = useMemo(() => {
    return sortedLabTests.filter((item) =>
      item.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }, [sortedLabTests, searchTerm])

  return (
    <Dialog open={modal.visible} onOpenChange={(open) => !open && modal.hide()}>
      <DialogContent className="bg-slate-100 p-0 h-[90vh] overflow-scroll max-w-[90vw] lg:max-w-2xl">
        <div className="py-6 px-7 md:py-12 md:px-14 space-y-4 overflow-x-hidden">
          <div className="flex flex-col">
            <div className="text-center text-body-500 space-y-[3px]">
              <span className="inline-block bg-white p-5 rounded-xl mb-[13px]">
                <TestTubesIconLarge className="fill-sky-600 block m-auto" />
              </span>
              <p className="text-xl19 font-semibold">
                Select a Test to Get Started
              </p>
              <p className="text-base15">
                For the kit: {inventoryItem.in_office_kit_product_name}
              </p>
            </div>
          </div>

          <div className="flex flex-col border-t border-t-slate-200">
            <TextField
              className="bg-white rounded-lg shadow-sm my-4 border border-slate-200"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Filter Tests"
              variant="outlined"
              size="small"
              InputProps={{
                className: "gap-2 rounded-lg",
                startAdornment: (
                  <SearchIcon
                    width={16}
                    height={16}
                    className="pointer-events-none"
                  />
                ),
              }}
            />
            <div className="flex flex-col gap-[10px]">
              {isLoading
                ? [1, 2, 3].map((i) => (
                    <LabTestLoadingItem key={`loading-lab-test-${i}`} />
                  ))
                : filteredLabTests.map((item) => (
                    <IOKLabTestListItem
                      key={item.id}
                      labTest={item}
                      onSelect={onLabTestSelect}
                    />
                  ))}
            </div>
          </div>
          <p className="text-base15 text-center">
            You can select more tests or add-ons once in the cart!
          </p>
        </div>
      </DialogContent>
    </Dialog>
  )
}

interface ListItemProps {
  labTest: InOfficeKitLabTest
  onSelect: (labTest: AnyLabTest) => void
}
const IOKLabTestListItem: React.FC<ListItemProps> = ({ labTest, onSelect }) => {
  const labTestTypes = useCachedCollection<LabTestType>(
    labTest.relationships.lab_test_types.data
  )

  // It is possible that other errors will be raised in the cart, e.g. if labTest?.has_instant_requisition is false
  const unavailableReason = getUnavailableReasonForActivation(labTest)
  const [loading, setLoading] = useState(false)

  const onSelectLabTest = useCallback(async () => {
    setLoading(true)

    // When a lab test is selected, we load the full thing before dispatching it
    // because the dashboard redux store expects AnyLabTest, but the collection
    // used here is comprised of InOfficeKitLabTest, a slimmed down version of
    // AnyLabTest.
    API.LabTest.get(
      labTest.id,
      `?action=${ORDERING_RIGHTS_ACTIONS.START_ORDER_WITH}`
    )
      .then(({ data }) => onSelect(data as AnyLabTest))
      .catch((e) => handleApiError(e))
      .finally(() => setLoading(false))
  }, [labTest, onSelect])

  return (
    <div className="flex flex-nowrap items-center justify-between gap-2 p-2 rounded-lg bg-white shadow-sm border border-slate-200 overflow-hidden">
      <span className="bg-slate-100 p-[10px] rounded-md">
        <TestTubesIconSmall className="fill-slate-500 block m-auto" />
      </span>
      <div className="flex flex-col mr-auto min-w-0">
        <OverflowTooltip
          text={labTest.attributes.name}
          className="text-body-500 text-base15 font-semibold"
        />
        <div className="flex flex-wrap items-center gap-[2px]">
          {labTestTypes.map((type) => (
            <SampleTypePill
              key={type.id}
              name={type.attributes.name.toLowerCase()}
              className="m-0"
            />
          ))}
        </div>
      </div>
      <Tooltip title={unavailableReason} placement="top" arrow>
        <span>
          <RupaLoadingButton
            variant="primary"
            onClick={onSelectLabTest}
            loading={loading}
            disabled={Boolean(unavailableReason)}
            className="font-title text-[15px] shadow-sm ml-auto w-36"
          >
            <span className="inline-flex items-center pt-1">Select Test</span>
          </RupaLoadingButton>
        </span>
      </Tooltip>
    </div>
  )
}

const LabTestLoadingItem: React.FC = () => {
  return (
    <div className="flex flex-nowrap items-center justify-between gap-2 p-2 rounded-lg bg-white shadow-sm border border-slate-200">
      <span className="bg-slate-100 p-[10px] rounded-md">
        <TestTubesIconSmall className="fill-slate-500 block m-auto" />
      </span>
      <div className="flex flex-col w-full gap-1">
        <Skeleton className="h-3 w-3/4 rounded" />
        <Skeleton className="h-3 w-1/4" />
      </div>
    </div>
  )
}

// We need to use the dashboard store because StartOrderModal uses it for loading patients.
// We need to use the orders store to ensure it is loaded when we dispatch markTestToAddToNextOrder
// Ideally, we wouldn't need to use either of these here, which would require:
// - StartOrderModal be agnostic to where in the app it's being used
// - markTestToAddToNextOrder use global state instead of a specific redux store
const ActivateKitNiceModal = NiceModal.create<Props>(
  withReducer(
    "orders",
    ordersReducer
  )(
    withReducer(
      "dashboard",
      dashboardReducer
    )((props) => <ActivateKitModal {...props} />)
  )
)

export function useActivateKitModal() {
  return useModal(ActivateKitNiceModal)
}
