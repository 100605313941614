import { useMemo } from "react"

import { styled } from "@material-ui/core"

import BodyText from "app/components/design-system/BodyText"
import { DEFAULT_LABTEST_COLOR, labTestColors } from "app/constants"
import { colors } from "app/theme"

const textColorsMap = {
  pink: colors.rose[700],
  yellow: colors.yellow[700],
  tangerine: colors.orange[700],
  purple: colors.violet[600],
  sky: colors.lightBlue[700],
  cyan: colors.cyan[900],
  disabled: colors.gray[700],
}
const textColorForColorGroup = (colorGroup) => textColorsMap[colorGroup]

const backgroundColorsMap = {
  pink: colors.rose[100],
  yellow: colors.yellow[100],
  tangerine: colors.orange[100],
  purple: colors.violet[100],
  sky: colors.lightBlue[50],
  cyan: colors.lightBlue[50],
  disabled: colors.gray[200],
}
const backgroundColorForColorGroup = (colorGroup) =>
  backgroundColorsMap[colorGroup]

const colorGroupForName = (name: string | undefined) => {
  name = name?.toLowerCase() ?? ""

  if (name in labTestColors) {
    return labTestColors[name]
  }
  return DEFAULT_LABTEST_COLOR
}

const Container = styled("div")({
  padding: "2px 10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: 6,
  borderRadius: 49,
  marginTop: 3,
  marginBottom: 3,
})
const StyledBodyText = styled(BodyText)({
  display: "block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textTransform: "uppercase",
})

const SampleTypePill = ({
  className = "",
  name,
  style = {},
  textStyle = {},
  title = "",
  shortenName = false,
  disabled = false,
}) => {
  const { backgroundColor, textColor } = useMemo(() => {
    const colorGroup = disabled ? "disabled" : colorGroupForName(name)
    return {
      textColor: textColorForColorGroup(colorGroup),
      backgroundColor: backgroundColorForColorGroup(colorGroup),
    }
  }, [name, disabled])

  return (
    <Container
      key={name}
      className={className}
      style={{
        backgroundColor,
        ...style,
      }}
    >
      <StyledBodyText
        weight="bold"
        size="xxs"
        title={title}
        style={{
          color: textColor,
          ...textStyle,
        }}
      >
        {shortenName ? name.slice(0, 2) : name}
      </StyledBodyText>
    </Container>
  )
}

export {
  backgroundColorForColorGroup,
  colorGroupForName,
  SampleTypePill as default,
  textColorForColorGroup,
}
