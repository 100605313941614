import {
  isInAccessMedicalLabsPanel,
  isInBostonHeartPanel,
} from "app/dataServices/labTestDataService"
import { Order } from "app/types"

export function getInstantRequisitionTestNames(order: Order) {
  const instantRequisitionTestNamesSet = new Set<string>()
  const dropShipTestNamesSet = new Set<string>()

  order.ordered_tests.forEach((orderedTest) => {
    if (orderedTest.instant_requisition) {
      if (isInBostonHeartPanel(orderedTest.lab_test)) {
        instantRequisitionTestNamesSet.add("Boston Heart Blood Kit")
      } else if (isInAccessMedicalLabsPanel(orderedTest.lab_test)) {
        instantRequisitionTestNamesSet.add("Access Medical Blood Kit")
      } else {
        instantRequisitionTestNamesSet.add(orderedTest.lab_test.name)
      }
    } else {
      dropShipTestNamesSet.add(orderedTest.lab_test.name)
    }
  })

  const instantRequisitionTestNames = Array.from(instantRequisitionTestNamesSet)
  instantRequisitionTestNames.sort()

  const dropShipTestNames = Array.from(dropShipTestNamesSet)
  dropShipTestNames.sort()

  return { instantRequisitionTestNames, dropShipTestNames }
}
