import { snakeCase } from "lodash"

import NiceModal from "@ebay/nice-modal-react"
import { faXmark, faCircleArrowDown } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Tooltip, TooltipProvider } from "@rupahealth/design"

import { Dialog } from "app/components/modals"

export interface InteractiveReportModalProps {
  onClose: () => void
  src: string | undefined
  reportName: string | undefined // typically the lab test or kit name
  downloadableResultUrls: string[]
}

export const InteractiveReportModal =
  NiceModal.create<InteractiveReportModalProps>(
    ({ onClose, src, reportName, downloadableResultUrls }) => {
      const DownloadButton = (
        <Button
          size="sm"
          className="flex flex-row gap-2"
          disabled={!downloadableResultUrls.length}
          onClick={() =>
            downloadableResultUrls.forEach((url, index) => {
              window.open(
                url,
                `${snakeCase(
                  reportName?.toLowerCase() ?? "report"
                )}_result_${index}`,
                "_blank"
              )
            })
          }
        >
          <FontAwesomeIcon icon={faCircleArrowDown} />
          {`Download PDF${downloadableResultUrls.length > 1 ? "s" : ""}`}
        </Button>
      )

      return (
        <Dialog
          onClose={onClose}
          open
          classes={{ paper: "h-full" }}
          aria-labelledby="interactive-report-modal"
          fullWidth
          maxWidth="xl"
        >
          <div className="flex flex-row justify-between items-center px-4 py-2 text-base font-semibold">
            {!reportName
              ? "Interactive Report"
              : reportName.endsWith("Report")
              ? reportName
              : `${reportName} Report`}
            <div className="flex flex-row gap-4 items-center">
              {downloadableResultUrls.length ? (
                DownloadButton
              ) : (
                <TooltipProvider>
                  <Tooltip content={"PDF is unavailable"}>
                    {DownloadButton}
                  </Tooltip>
                </TooltipProvider>
              )}

              <FontAwesomeIcon
                className="h-4 font-semibold"
                onClick={onClose}
                icon={faXmark}
              />
            </div>
          </div>
          <div className="w-full h-full flex justify-center items-center bg-slate-100">
            <iframe
              className="w-full h-full"
              src={src}
              title="Interactive Report"
            />
          </div>
        </Dialog>
      )
    }
  )
