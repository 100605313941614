import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Link } from "react-router-dom"

import { faArrowUpRight, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDebounce } from "@fuse/hooks"
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  useMediaQuery,
  withStyles,
} from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"

import { UserPaths } from "app/Routes"
import AddNotesBlueIcon from "app/assets/icons/add-notes-blue.svg"
import AddNotesIcon from "app/assets/icons/add-notes.svg"
import NotesIcon from "app/assets/icons/notes.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import TextField from "app/components/forms/TextFieldComponent"
import { SnippetTypes } from "app/main/settings/SavedTextSnippets/constants"
import * as Actions from "app/store/actions"
import { colors, navy, primaryColor, shadows, titleFontFamily } from "app/theme"

const StyledDesignSystemButton = styled(DesignSystemButton)({
  marginBottom: 16,
  alignItems: "center",
  float: "right",
  "&:disabled": {
    pointerEvents: "auto",
  },
})

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    border: `1px solid ${colors.blueGray[300]}`,
    borderRadius: 6,
    shadow: shadows.lg,
    marginTop: 5,
    marginLeft: -50,
    width: 240,
  },
  "& .MuiMenu-list": {
    padding: 0,
  },
})

const SearchField = styled(TextField)({
  marginLeft: 10,
  "& .MuiOutlinedInput-root": {
    borderRadius: 6,
    width: 218,
  },
})

const StyledMenuItem = styled(MenuItem)({
  paddingLeft: 10,
  paddingRight: 10,
  backgroundColor: "white",
})

const StyledListItemText = styled(ListItemText)({
  marginLeft: 5,
  color: navy,
  fontSize: 15,
  fontFamily: titleFontFamily,
  "& .MuiListItemText-primary": {
    fontWeight: 600,
  },
})

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 0,
})

const IconImage = styled("img")({
  width: 16,
})

const CenteredBodyText = styled(BodyText)({
  textAlign: "center",
})

const SnippetsListWrapper = styled("div")({
  maxHeight: 160,
  overflowY: "auto",
})

const StyledLink = styled(Link)({
  color: primaryColor,
})

/**
 * The component which contains the button to insert snippet
 * @param onAdd - We need to pass the snippet text to the function and it will be appended to the notes
 */
export function InsertSnippetButton({ onAdd, snippetType }) {
  const dispatch = useDispatch()

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  const [anchorEl, setAnchorEl] = useState(null)

  const [searchText, setSearchText] = useState("")
  const [debouncedSearchText, setDebouncedSearchText] = useState("")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value)
    handleUpdateDebounceSearchText(e.target.value)
  }

  const addSnippetTextToNotes = (text) => {
    onAdd(text)

    // Close dropdown
    handleClose()
  }

  const snippets = useSelector(({ snippet }) => snippet)

  useEffect(() => {
    dispatch(Actions.getSnippets("", snippetType))
  }, [dispatch])

  // Debounce search text so that it only gives us latest value. If searchText has not been updated within last 300ms.
  // The goal is to only have the API call fire when user stops typing, So that we aren't hitting our API rapidly.
  const handleUpdateDebounceSearchText = useDebounce((newSearchText) => {
    setDebouncedSearchText(newSearchText)
  }, 300)

  // API call when the search text changes
  useEffect(() => {
    dispatch(Actions.getSnippets(debouncedSearchText, snippetType))
  }, [debouncedSearchText, dispatch])

  // Reset search text when user clicks outside
  useEffect(() => {
    if (!anchorEl && searchText) {
      setSearchText("")
      dispatch(Actions.getSnippets("", snippetType))
    }
  }, [anchorEl, searchText, dispatch])

  return (
    <>
      <CustomTooltip
        placement="bottom-end"
        interactive
        title={
          !snippets?.length ? (
            <>
              <CenteredBodyText weight="semibold" size="xs">
                No snippets have been created
              </CenteredBodyText>
              <CenteredBodyText size="xs">
                Create reusable saved bits of text to your clients
                <br />
                in{" "}
                <StyledLink to={UserPaths.SNIPPETS} target="_blank">
                  Settings
                </StyledLink>
                .
              </CenteredBodyText>
            </>
          ) : (
            ""
          )
        }
      >
        <div>
          <StyledDesignSystemButton
            color="text"
            startIcon={
              anchorEl || !snippets?.length ? AddNotesIcon : AddNotesBlueIcon
            }
            onClick={handleClick}
            isMobile={isMobile}
            disabled={anchorEl || !snippets?.length ? true : false}
          >
            Insert Snippet
          </StyledDesignSystemButton>
        </div>
      </CustomTooltip>

      <StyledMenu
        id="customized-menu"
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <SearchField
          onChange={handleSearchTextChange}
          variant="outlined"
          margin="dense"
          placeholder="Search Snippets"
          value={searchText}
        />

        <SnippetsListWrapper>
          {snippets?.length ? (
            <div>
              {snippets.map((snippet) => (
                <StyledMenuItem
                  disableGutters={true}
                  onClick={() =>
                    addSnippetTextToNotes(
                      snippetType === SnippetTypes.NOTES_TO_PATIENT
                        ? snippet.text
                        : snippet.rich_text
                    )
                  }
                  key={snippet.id}
                >
                  <StyledListItemIcon>
                    <IconImage src={NotesIcon} alt="snippet-icon" />
                  </StyledListItemIcon>
                  <StyledListItemText primary={snippet.title} />
                </StyledMenuItem>
              ))}
              <div className="px-3 pb-3">
                <div className="w-full h-[1px] bg-slate-300"></div>
                <div className="mt-3 flex gap-1 items-center">
                  <FontAwesomeIcon
                    className="text-sm text-primary"
                    icon={faPlus}
                  />
                  <StyledLink
                    to={UserPaths.SNIPPETS}
                    className="font-semibold"
                    target="_blank"
                  >
                    Create New Snippet
                  </StyledLink>
                  <FontAwesomeIcon
                    className="text-sm text-primary"
                    icon={faArrowUpRight}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </SnippetsListWrapper>
      </StyledMenu>
    </>
  )
}

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: navy,
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(14),
    padding: 16,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    borderRadius: 8,
    marginTop: 30,
  },
  arrow: {
    color: "white",
  },
}))(Tooltip)
