import { useRef, useState } from "react"

import { ClickAwayListener, Theme, useMediaQuery } from "@material-ui/core"

import { ReactComponent as QuestionCircleIcon } from "app/assets/icons/rupa-blood-dashboards/question-circle.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { sortDiscreteValues } from "app/patient-portal/blood-lab-dashboard/utils"
import {
  PatientPortalClinic,
  PatientPortalPractitioner,
} from "app/patient-portal/types"
import makeAppStyles from "app/utils/makeAppStyles"
import { BodySystem } from "types/body-system"
import { Clinic } from "types/clinic"
import { DiscreteResult } from "types/discrete-result"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"
import { Practitioner } from "types/practitioner"

import { BloodLabDashboardsBodySystemCard } from "./BloodLabDashboardsBodySystemCard"

const useStyles = makeAppStyles((theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    gap: 10,
    flexDirection: "column",
    marginBottom: 45,
  },
  heading: {
    display: "flex",
    gap: 4,
    alignItems: "center",
  },
  cardsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    gridColumnGap: 16,
    gridRowGap: 16,
  },
}))

interface Props {
  bodySystem: BodySystem
  filteredBiomarkers: string[] | null
  useOptimalRanges?: boolean
  getMatchingDiscreteResultByBiomarkerId: (
    biomarkerId?: string
  ) => DiscreteResult | undefined
  getMatchingOptimalRangeByBiomarkerId: (
    biomarkerId?: string
  ) => LabCompanyBiomarkerRange | undefined
  practitioner?: PatientPortalPractitioner | Practitioner
  clinic?: PatientPortalClinic | Clinic
  isPatientPreview: boolean
}

const BloodLabDashboardsBodySystemCards = ({
  bodySystem,
  filteredBiomarkers,
  useOptimalRanges,
  practitioner,
  clinic,
  isPatientPreview,
  getMatchingDiscreteResultByBiomarkerId,
  getMatchingOptimalRangeByBiomarkerId,
}: Props) => {
  const classes = useStyles()

  const ref = useRef<any>()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const [mobileTooltipIsOpen, setMobileTooltipIsOpen] = useState(false)

  const filteredBiomarkerIdentifiers =
    bodySystem.relationships.biomarkers.data.filter((identifier) =>
      filteredBiomarkers === null
        ? true
        : filteredBiomarkers.includes(identifier.id)
    )

  const discreteResults = filteredBiomarkerIdentifiers
    .map((identifier) => {
      return getMatchingDiscreteResultByBiomarkerId(identifier.id)!
    })
    .sort((a, b) =>
      sortDiscreteValues(
        a,
        b,
        getMatchingOptimalRangeByBiomarkerId(
          a.relationships.biomarker.data?.id
        ),
        getMatchingOptimalRangeByBiomarkerId(b.relationships.biomarker.data?.id)
      )
    )
    // Make sure there are no undefined values
    .filter((discreteResult) => discreteResult)

  return Boolean(discreteResults.length) ? (
    <div
      className={classes.container}
      ref={ref}
      id={bodySystem.id}
      key={bodySystem.id}
    >
      <div
        className={classes.heading}
        onClick={() => isMobile && setMobileTooltipIsOpen(!mobileTooltipIsOpen)}
      >
        <BodyText size="md" weight="semibold">
          {bodySystem.attributes.name}
        </BodyText>
        {isMobile ? (
          <ClickAwayListener onClickAway={() => setMobileTooltipIsOpen(false)}>
            <Tooltip
              arrow
              placement="top"
              title={bodySystem.attributes.description}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              open={mobileTooltipIsOpen}
              onClose={() => setMobileTooltipIsOpen(false)}
            >
              <div>
                <QuestionCircleIcon />
              </div>
            </Tooltip>
          </ClickAwayListener>
        ) : (
          <Tooltip
            arrow
            placement="right"
            title={bodySystem.attributes.description}
          >
            <div>
              <QuestionCircleIcon />
            </div>
          </Tooltip>
        )}
      </div>
      <div className={classes.cardsContainer}>
        {discreteResults.map((discreteResult) => (
          <BloodLabDashboardsBodySystemCard
            key={discreteResult.id}
            biomarkerIdentifier={discreteResult.relationships.biomarker.data}
            useOptimalRanges={useOptimalRanges}
            getMatchingDiscreteResultByBiomarkerId={
              getMatchingDiscreteResultByBiomarkerId
            }
            getMatchingOptimalRangeByBiomarkerId={
              getMatchingOptimalRangeByBiomarkerId
            }
            practitioner={practitioner}
            clinic={clinic}
            isPatientPreview={isPatientPreview}
          />
        ))}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default BloodLabDashboardsBodySystemCards
