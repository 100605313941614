/**
 * Builds patient portal url for patients or patient orders.
 *
 * @param patientId if provided, will build url for patient orders
 * @param orderId if provided with patientId, will build order for single patient order
 *
 * @returns generated url
 */
export const buildPatientPortalUrl = (
  patientId?: string,
  orderId?: string,
  orderedResultId?: string,
  snapshotId?: string
) => {
  const baseUrl = "/patient_portal/patients"
  let returnUrl = baseUrl

  if (patientId) {
    returnUrl = `${returnUrl}/${patientId}/orders`

    if (orderId) {
      returnUrl = `${returnUrl}/${orderId}`

      if (orderedResultId) {
        returnUrl = `${returnUrl}/blood_lab_dashboard/${orderedResultId}`
      } else if (snapshotId) {
        returnUrl = `${returnUrl}/blood_lab_dashboard_snapshot/${snapshotId}`
      }
    }
  }

  return returnUrl
}

/**
 * Builds patient portal user url for patients or patient orders.
 *
 * @param userId if provided, will build url for patient orders
 * @param orderId if provided with patientId, will build order for single patient order
 *
 * @returns generated url
 */
export const buildPatientPortalUserUrl = (
  userId?: string,
  orderId?: string,
  orderedResultId?: string,
  snapshotId?: string
) => {
  const baseUrl = "/patient_portal/accounts"
  let returnUrl = baseUrl

  if (userId) {
    returnUrl = `${returnUrl}/${userId}/orders`

    if (orderId) {
      returnUrl = `${returnUrl}/${orderId}`

      if (orderedResultId) {
        returnUrl = `${returnUrl}/blood_lab_dashboard/${orderedResultId}`
      } else if (snapshotId) {
        returnUrl = `${returnUrl}/blood_lab_dashboard_snapshot/${snapshotId}`
      }
    }
  }

  return returnUrl
}

/**
 * Builds patient portal support url
 *
 * @param patientId
 *
 * @returns generated url
 */
export const buildPatientPortalSupportUrl = (patientId: string) => {
  return `/patient_portal/patients/${patientId}/support`
}

/**
 * Builds patient portal support url
 *
 * @param userId
 *
 * @returns generated url
 */
export const buildPatientPortalUserSupportUrl = (userId: string) => {
  return `/patient_portal/accounts/${userId}/support`
}

/**
 * Builds patient portal trends url
 *
 * @param userId
 *
 * @returns generated url
 */
export const buildPatientPortalUserTrendsUrl = (userId: string) => {
  return `/patient_portal/accounts/${userId}/trends`
}

/**
 * Builds patient portal documents url
 *
 * @param userId
 *
 * @returns generated url
 */
export const buildPatientPortalUserDocumentsUrl = (userId: string) => {
  return `/patient_portal/accounts/${userId}/documents`
}

export const buildPatientPortalUserLabResultsAccessUrl = (
  patientId: string
) => {
  return `/patient_portal/accounts/${patientId}/lab_result_access`
}

export const buildLoginUrl = (
  role?: "patient" | "practitioner",
  email?: string,
  next?: string
) => {
  const baseUrl = "/login"
  const params: string[] = []

  if (role) {
    params.push(`role=${encodeURIComponent(role)}`)
  }
  if (email) {
    params.push(`email=${encodeURIComponent(email)}`)
  }
  if (next) {
    params.push(`next=${encodeURIComponent(next)}`)
  }

  if (params.length === 0) {
    return baseUrl
  } else {
    return `${baseUrl}?${params.join("&")}`
  }
}
