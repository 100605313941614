import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection } from "app/swr/types"
import { UserClinicAccessConfiguration } from "types/user-clinic-access-configuration"

export default function useUserClinicAccessConfigurations(userId: string) {
  const { data, error, isLoading, isValidating } = useCollectionSWR<
    ResourceCollection<UserClinicAccessConfiguration>
  >(
    `/user_clinic_access_configurations/`,
    {
      include: ["clinic"],
      params: {
        "filter[user.id]": userId,
      },
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  )

  const userClinicAccessConfigurations =
    useCachedCollection<UserClinicAccessConfiguration>(data)

  return {
    userClinicAccessConfigurations,
    error,
    isLoading,
    isValidating,
  }
}
