import React from "react"

import { makeStyles, styled } from "@material-ui/core"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"
import { colors, navy } from "app/theme"

const styles = () => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
  },
})

const useStyles = makeStyles(styles)

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "stretch",
  width: "100%",
  position: "relative",

  [theme.breakpoints.up("md")]: {
    flexFlow: "row",
  },

  // As the checkout stretches the entire width of the screen, we need
  // an extra breakpoint for the lab test cards.
  "& .labTestCardGridItem": {
    "@media (min-width: 2000px)": {
      flexBasis: "20%",
      width: "20%",
    },
  },
}))

const StyledContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: !theme.breakpoints.down("sm") ? 12 : 22.5,
  backgroundColor: colors.coolGray[50],
}))

const StyledMuiDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: !theme.breakpoints.down("sm") ? "wrap" : "nowrap",
  backgroundColor: "white",
  borderBottomWidth: 1,
  borderColor: colors.blueGray[200],
  alignItems: "center",
  padding: "8px 24px",
}))

const ActionsContainer = styled(MuiDialogActions)({
  margin: 0,
  padding: "8px 20px",
})

const TitleAndInfoContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  marginRight: 24,
})

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: navy,
  position: !theme.breakpoints.down("sm") ? "absolute" : "relative",
  top: 1,
  right: !theme.breakpoints.down("sm") ? 12 : 0,
  marginRight: -12,
}))

interface Props {
  open: boolean
  title?: string
  message?: string
  backButtonTitle?: string
  confirmButtonTitle?: string
  confirmButtonColor?: string
  onClose: () => void
  loading?: boolean
  handleConfirm: () => void
  showBackButton?: boolean
}
/**
 * Confirmation Modal
 * @param open - The modal will be open if this is true
 * @param title - The title of the modal
 * @param message - The message shown in the modal
 * @param backButtonTitle - The back button title
 * @param confirmButtonTitle - The confirm button title
 * @param confirmButtonColor - destructive | primary | secondary - The color of the confirm button
 * @param onClose - The action which needs to be done when the modal closes
 * @param loading - The loader state
 * @param handleConfirm - The action which needs to be done on confirm
 */
const ConfirmationModal: React.FC<Props> = ({
  open,
  title = "Are You Sure?",
  message = "",
  backButtonTitle = "Go back",
  confirmButtonTitle = "Delete",
  confirmButtonColor = "destructivePrimary",
  onClose,
  loading,
  handleConfirm,
  showBackButton = true,
}) => {
  const classes = useStyles(styles)

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="lab-test-bundle-title"
        open={open}
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
        maxWidth="xs"
      >
        <TitleSection title={title} onClose={onClose} />
        <StyledContent>
          <Container>
            <BodyText>{message}</BodyText>
          </Container>
        </StyledContent>
        <ActionsContainer>
          {showBackButton && (
            <DesignSystemButton color="noaction" onClick={onClose}>
              {backButtonTitle}
            </DesignSystemButton>
          )}
          <DesignSystemButton
            color={confirmButtonColor}
            loading={loading}
            onClick={handleConfirm}
          >
            {confirmButtonTitle}
          </DesignSystemButton>
        </ActionsContainer>
      </Dialog>
    </>
  )
}

const TitleSection = ({ title, onClose }) => {
  const closeButton = onClose && (
    <CloseButton aria-label="close" onClick={onClose} key="close-button">
      <CloseIcon />
    </CloseButton>
  )

  return (
    <StyledMuiDialogTitle disableTypography>
      <TitleAndInfoContainer>
        <DisplayText weight="semibold" size="lg">
          {title}
        </DisplayText>
      </TitleAndInfoContainer>
      {closeButton}
    </StyledMuiDialogTitle>
  )
}

export default ConfirmationModal
