import React, { useRef, useState } from "react"

import { ClickAwayListener, Theme, useMediaQuery } from "@material-ui/core"

import { ReactComponent as QuestionCircleIcon } from "app/assets/icons/rupa-blood-dashboards/question-circle.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import { sortBloodReportResultValues } from "app/patient-portal/blood-lab-dashboard/utils"
import { PatientPortalPractitioner } from "app/patient-portal/types"
import { Practitioner } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"
import { BloodReportResult } from "types/blood-report-result"
import { BodySystem } from "types/body-system"

import BloodLabDashboardsSnapshotBodySystemCard from "./BloodLabDashboardsSnapshotBodySystemCard"

const useStyles = makeAppStyles((theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    gap: 10,
    flexDirection: "column",
    marginBottom: 45,
  },
  heading: {
    display: "flex",
    gap: 4,
    alignItems: "center",
  },
  cardsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    gridColumnGap: 16,
    gridRowGap: 16,
  },
}))

interface Props {
  bodySystem?: BodySystem
  filteredBiomarkers: string[] | null
  getMatchingBloodReportResultByBiomarkerId: (
    biomarkerId?: string
  ) => BloodReportResult | undefined
  practitioner?: PatientPortalPractitioner | Practitioner
  clinicName?: string
  snapshotCreationDate: string
  labCompanyName?: string
  showHighLowDescriptions: boolean
}

const BloodLabDashboardsSnapshotBodySystemCards = ({
  bodySystem,
  filteredBiomarkers,
  clinicName,
  snapshotCreationDate,
  labCompanyName,
  getMatchingBloodReportResultByBiomarkerId,
  showHighLowDescriptions,
}: Props) => {
  const classes = useStyles()

  const ref = useRef<any>()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const [mobileTooltipIsOpen, setMobileTooltipIsOpen] = useState(false)

  const bloodReportResults = (filteredBiomarkers ?? [])
    .map((identifier) => {
      return getMatchingBloodReportResultByBiomarkerId(identifier)!
    })
    .sort((a, b) => sortBloodReportResultValues(a, b))
    // Make sure there are no undefined values
    .filter((discreteResult) => discreteResult)

  return Boolean(bloodReportResults.length) ? (
    <div
      className={classes.container}
      ref={ref}
      id={bodySystem?.id ?? ""}
      key={bodySystem?.id ?? ""}
    >
      <div
        className={classes.heading}
        onClick={() => isMobile && setMobileTooltipIsOpen(!mobileTooltipIsOpen)}
      >
        <BodyText size="md" weight="semibold">
          {bodySystem?.attributes.name ?? "Other"}
        </BodyText>
        {isMobile ? (
          bodySystem ? (
            <ClickAwayListener
              onClickAway={() => setMobileTooltipIsOpen(false)}
            >
              <Tooltip
                arrow
                placement="top"
                title={bodySystem?.attributes.description}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                open={mobileTooltipIsOpen}
                onClose={() => setMobileTooltipIsOpen(false)}
              >
                <div>
                  <QuestionCircleIcon />
                </div>
              </Tooltip>
            </ClickAwayListener>
          ) : null
        ) : bodySystem ? (
          <Tooltip
            arrow
            placement="right"
            title={bodySystem.attributes.description}
          >
            <div>
              <QuestionCircleIcon />
            </div>
          </Tooltip>
        ) : null}
      </div>
      <div className={classes.cardsContainer}>
        {bloodReportResults.map((bloodReportResult) => (
          <BloodLabDashboardsSnapshotBodySystemCard
            key={bloodReportResult.id}
            biomarkerIdentifier={bloodReportResult.relationships.biomarker.data}
            bloodReportResult={bloodReportResult}
            clinicName={clinicName}
            snapshotCreationDate={snapshotCreationDate}
            labCompanyName={labCompanyName}
            showHighLowDescriptions={showHighLowDescriptions}
          />
        ))}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default BloodLabDashboardsSnapshotBodySystemCards
