import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { FuseSplashScreen } from "@fuse"
import _ from "lodash"
import { useParams } from "react-router-dom"

import { Theme, useMediaQuery } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

import { UserPaths } from "app/Routes"
import BundlesBanner from "app/assets/icons/bundles/bundles-collection.svg"
import UpRightArrow from "app/assets/icons/up-right-arrow.svg"
import CogIconGray from "app/assets/images/cog-icon-gray.svg"
import PageToolbar from "app/components/PageToolbar"
import BodyText from "app/components/design-system/BodyText"
import BundleModal from "app/components/modals/BundleModal"
import ConfirmationModal from "app/components/modals/ConfirmationModal"
import { SharedBundleModal } from "app/components/modals/SharedBundleModal"
import useAppSelector from "app/hooks/useAppSelector"
import useFacebookSDK from "app/integrations/hooks/use-facebook-sdk"
import reducer from "app/main/checkout/store/reducers"
import Banner from "app/main/dashboard/Banner"
import { trackBundleVisitCustomSettings } from "app/services/segment"
import * as OtherActions from "app/store/actions"
import {
  addFavoriteTest,
  loadShareBundle,
  removeFavoriteTest,
} from "app/store/actions"
import withReducer from "app/store/withReducer"
import { colors, rupaTeal } from "app/theme"
import { PractitionerLabTestBundle, labTestLocation } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import SavedAndSharedBundles from "./Bundles/SavedAndSharedBundles"
import SideNav from "./SideNav"

const useBundlesStyles = makeAppStyles({
  wrapper: {
    background: colors.coolGray[50],
    border: `1px solid ${colors.coolGray[200]}`,
    minHeight: "100vh",
    padding: 24,
  },
  panel: {
    marginTop: 24,
  },
  designSystemButton: {
    marginTop: ({ isMobile }: { isMobile: boolean }) =>
      isMobile ? 10 : "auto",
    alignItems: "center",
    float: ({ isMobile }: { isMobile: boolean }) =>
      isMobile ? "left" : "right",
  },
  emptySection: {
    display: "flex",
    background: "white",
    justifyContent: "center",
    alignItems: "center",
    border: `2px dashed ${colors.blueGray[200]}`,
    padding: "32px 64px",
    marginTop: 24,
    borderRadius: 8,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 6,
  },
  linkText: {
    fontWeight: 600,
    marginTop: 3,
    display: "inline-block",
  },
  previewImage: {
    width: "50%",
    maxWidth: 700,
  },
  banner: {
    marginTop: 24,
    borderWidth: 0,
    padding: "0px",
  },
  bannerContent: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    background:
      "radial-gradient(100% 200% at -50% 110%, rgba(74, 168, 188, 0.48) 0%, rgba(197, 245, 255, 0) 100%)",
    borderRadius: 8,
  },
  bannerText: {
    margin: "auto 30px",
    padding: "12px 0",
  },
  upRightArrowIcon: {
    width: 10,
    marginLeft: 5,
    marginRight: 10,
  },
})

function Bundles() {
  useFacebookSDK()
  const dispatch = useDispatch()
  const { shareBundleId } = useParams<{ shareBundleId: string }>()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const classes = useBundlesStyles({ isMobile })

  useEffect(() => {
    if (shareBundleId) {
      setShareBundleLoading(true)
      dispatch(
        loadShareBundle(shareBundleId, () => {
          setShareBundleModalIsOpen(true)
          setShareBundleLoading(false)
        })
      )
    }
  }, [shareBundleId])

  const shareBundle = useAppSelector(({ shareBundle }) => shareBundle)

  const practitioner = useAppSelector(({ practitioner }) => practitioner)
  const practitionerBundles = useAppSelector(
    ({ practitionerBundles }) => practitionerBundles.results
  )

  useEffect(() => {
    if (practitioner.id) {
      trackBundleVisitCustomSettings(practitioner.id, practitioner.clinic?.id)
    }
  }, [practitioner.id])

  useEffect(() => {
    const bundleToRefresh = practitionerBundles.find(
      (bundle) => bundle.id === bundleInModal?.id
    )
    if (bundleInModal && bundleToRefresh) {
      setBundleInModal(bundleToRefresh)
    }
  }, [practitionerBundles])

  const [shareBundleLoading, setShareBundleLoading] = useState(false)

  const [bundleModalIsOpen, setBundleModalIsOpen] = useState(false)
  const [bundleInModal, setBundleInModal] =
    useState<PractitionerLabTestBundle>()

  const [bundleConfirmationModalIsOpen, setBundleConfirmationModalIsOpen] =
    useState(false)

  const [bundleIsSubmitting, setBundleIsSubmitting] = useState(false)
  const [shareBundleIsAdding, setShareBundleIsAdding] = useState(false)
  const [shareBundleModalIsOpen, setShareBundleModalIsOpen] = useState(false)

  function handleToggleFavorite(lab_test_id, isFavorite) {
    if (isFavorite) {
      dispatch(removeFavoriteTest(practitioner, lab_test_id))
    } else {
      dispatch(addFavoriteTest(practitioner, lab_test_id))
    }
  }

  // If no bundle is passed, it will open in the create state
  function openBundleModal(bundle?: PractitionerLabTestBundle) {
    setBundleInModal(bundle)
    setBundleModalIsOpen(true)
  }

  function closeBundleModal() {
    setBundleModalIsOpen(false)
    setBundleInModal(undefined)
  }

  function handleDeleteBundle() {
    if (bundleInModal) {
      setBundleIsSubmitting(true)
      dispatch(OtherActions.deleteBundle(bundleInModal.id, closeBundleModal))
    }
  }

  function handleAddShareBundle() {
    setShareBundleIsAdding(true)
    if (!shareBundleIsAdding && shareBundleId) {
      dispatch(
        OtherActions.addShareBundle(shareBundleId, (newBundle) => {
          setShareBundleIsAdding(false)
          setShareBundleModalIsOpen(false)
        })
      )
    }
  }

  const showShareBundleModal =
    shareBundleModalIsOpen &&
    !_.isEmpty(shareBundleId) &&
    !_.isEmpty(shareBundle) &&
    !_.isEmpty(practitioner)

  // Show loader until the shared bundle is loaded
  if (shareBundleLoading) {
    return <FuseSplashScreen />
  }

  return (
    <>
      {isMobile && (
        <PageToolbar
          breadcrumbs={[
            {
              to: UserPaths.SETTINGS,
              icon: CogIconGray,
              label: "Settings",
            },

            {
              label: "Custom Test Bundles",
            },
          ]}
        />
      )}
      <Grid container>
        {!isMobile && (
          <Grid item xs={12} md={3}>
            <SideNav />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {!isMobile && (
            <PageToolbar
              title="Custom Test Bundles"
              greyBackground={true}
              fullBorder={true}
            />
          )}
          <div className={classes.wrapper}>
            <Banner
              backgroundColor="#FFFFFF"
              borderColor={rupaTeal}
              className={classes.banner}
              style={{ marginTop: 0 }}
            >
              <a
                className={classes.bannerContent}
                href="https://www.rupahealth.com/bundle-library"
                target="_blank"
                rel="noreferrer"
              >
                <div className={classes.bannerText}>
                  <BodyText>
                    Bundles let you save a group of lab tests for easy 1-click
                    ordering, right from your Favorites. These can be custom
                    blood panels or a mix of tests from different lab companies.
                  </BodyText>
                  <BodyText className={classes.linkText} color="primary">
                    Browse example bundles from our collection.
                    <img
                      className={classes.upRightArrowIcon}
                      src={UpRightArrow}
                      alt="up-right-arrow"
                    />
                  </BodyText>
                </div>
                {!isMobile && (
                  <img
                    className={classes.previewImage}
                    src={BundlesBanner}
                    alt=""
                  />
                )}
              </a>
            </Banner>

            <SavedAndSharedBundles
              practitioner={practitioner}
              isInfoCard
              bundles={practitionerBundles}
              openBundleModal={openBundleModal}
              usePhysicianAuthorizationForOrderingAccess={
                practitioner.vendor_physician_authorization_enabled
              }
              location={labTestLocation.BUNDLES_SETTINGS}
            />
          </div>
        </Grid>
      </Grid>
      {showShareBundleModal && shareBundle && (
        <SharedBundleModal
          open
          bundle={shareBundle}
          practitioner={practitioner}
          onClose={() => setShareBundleModalIsOpen(false)}
          handleAddShareBundle={handleAddShareBundle}
          handleToggleFavorite={handleToggleFavorite}
        />
      )}
      {!_.isEmpty(practitioner) && (
        <BundleModal
          open={bundleModalIsOpen}
          practitioner={practitioner}
          practitionerBundles={practitionerBundles}
          onClose={closeBundleModal}
          handleToggleLabTestFavorite={handleToggleFavorite}
          bundle={bundleInModal}
          usePhysicianAuthorizationForOrderingAccess={
            practitioner.vendor_physician_authorization_enabled
          }
          location={labTestLocation.BUNDLES}
        />
      )}
      {!_.isEmpty(practitioner) && bundleInModal && (
        <ConfirmationModal
          open={bundleConfirmationModalIsOpen}
          message="This will delete this bundle from your account. This action cannot be reversed."
          backButtonTitle="Actually, Let’s Keep It"
          confirmButtonTitle="Delete Bundle"
          onClose={() => setBundleConfirmationModalIsOpen(false)}
          loading={bundleIsSubmitting}
          handleConfirm={handleDeleteBundle}
        />
      )}
    </>
  )
}

export default withReducer("orders", reducer)(Bundles)
