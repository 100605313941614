import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { isAxiosError } from "axios"
import { Redirect, useHistory, useParams } from "react-router-dom"

import { Typography } from "@material-ui/core"
import * as Sentry from "@sentry/react"

import { UserPaths } from "app/Routes"
import infoCircleOutlined from "app/assets/images/info-circle-outlined.svg"
import LabTests from "app/components/LabTests/LabTests"
import Loading from "app/components/Loading"
import BloodDrawModal from "app/components/modals/BloodDrawModal"
import BundleModal from "app/components/modals/BundleModal"
import useComboGroupConfigurationModal from "app/components/modals/ComboGroupModal/hooks/use-combo-group-configuration-modal"
import LabTestModal from "app/components/modals/LabTestModal"
import OverrideAccessModal from "app/components/modals/OverrideAccessModal"
import { PRACTITIONER_SETTINGS } from "app/constants"
import { OrderStatusUnion } from "app/constants.typed"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import { CheckoutPageToolbar } from "app/main/checkout/CheckoutPageToolbar"
import CHECKOUT_STATE from "app/main/checkout/CheckoutState"
import OrderConfirmationModal from "app/main/checkout/OrderConfirmationModal"
import usePhysicianServicesConfirmationModal from "app/main/checkout/hooks/use-physician-services-confirmation-modal"
import useRefreshOrderingRights from "app/main/checkout/hooks/use-refresh-ordering-rights"
import {
  ComboGroupAddToCartEventType,
  ComboGroupEvents,
} from "app/main/combo-groups/constants"
import ComparisonProvider from "app/main/comparison/ComparisonProvider"
import { ComparisonCallToActionTypes } from "app/main/comparison/types"
import NoNPIBanner from "app/main/dashboard/NoNPIBanner"
import { useRefreshFeaturePromotions } from "app/providers/FeaturePromotionProvider"
import {
  trackAddBundleToCart,
  trackCartVisit,
  trackSaveAsDraft,
  trackSendToPatient,
} from "app/services/segment"
import { trackEventWithProperties } from "app/services/segment.typed"
import userPreferenceService from "app/services/userPreferenceService"
import { fetchPractitionerBundles } from "app/store/actions/practitionerBundles.actions"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import withReducer from "app/store/withReducer"
import { colors } from "app/theme"
import {
  AnyLabTest,
  AnyLimitedLabTest,
  PractitionerLabTestBundle,
  labTestLocation,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import { ORDERING_RIGHTS_ACTIONS, ORDER_STATUS } from "../../constants"
import { hasInstantRequisitions } from "../../models/order"
import {
  addFavoriteTest,
  getPractitioner,
  removeFavoriteTest,
} from "../../store/actions"
import { handleApiError } from "../../utils"
import Favorites from "./Favorites"
import { OrderEditConfirmationModal } from "./OrderEditConfirmationModal"
import OrderSidebar from "./OrderSidebar"
import InstantRequisitionModal from "./instantRequisitions/InstantRequisitionModal"
import CheckoutDraftProvider from "./providers/CheckoutDraftProvider"
import * as Actions from "./store/actions"
import reducer from "./store/reducers"

const useStyles = makeAppStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "column",
    alignItems: "stretch",
    width: "100%",
    position: "relative",

    [theme.breakpoints.up("md")]: {
      height: "100vh",
      flexFlow: "row",
    },

    // As the checkout stretches the entire width of the screen, we need
    // an extra breakpoint for the lab test cards.
    "& .labTestCardGridItem": {
      "@media (min-width: 2000px)": {
        flexBasis: "20%",
        width: "20%",
      },
    },
  },
  mainContentContainer: {
    flex: 1,
    display: "flex",
    flexFlow: "column",
  },
  testsContainer: {
    flex: 1,
    padding: 24,
    position: "relative",
    overflowY: "auto",
    height: "100%",
  },

  separator: {
    height: 1,
    backgroundColor: colors.coolGray[300],
    marginLeft: 24,
    marginRight: 24,
  },
}))

function Checkout() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { orderId, checkoutState } = useParams<{
    orderId?: string
    checkoutState?: string
  }>()

  // Show tooltip with button to override lab company restriction
  const [showOverrideOption] = useFeatureFlag(
    FeatureFlag.OrderAccessOverrideHover
  )

  const [showPhysicianServicesConfirmationModal] = useFeatureFlag(
    FeatureFlag.ShowPhysicianServicesConfirmationModal
  )

  const styles = useStyles()

  const edit = checkoutState === CHECKOUT_STATE.EDIT

  const refreshFeaturePromotions = useRefreshFeaturePromotions()

  const practitioner = useSelector(selectPractitioner)
  const practitionerBundles = useAppSelector(
    ({ practitionerBundles }) => practitionerBundles.results
  )
  const order = useAppSelector(({ orders }) => orders.orders.order)
  const patient = useAppSelector(({ orders }) => orders.orders.patient)
  // State of override modal
  const labTestOverride = useAppSelector(
    ({ overrideLabTest }) => overrideLabTest
  )

  const testMarkedToAdd = useAppSelector(
    ({ orders }) => orders.orders.marked_for_next_order
  )

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [instantRequisitionModalOpen, setInstantRequisitionModalOpen] =
    useState(false)
  const [orderIsSubmitting, setOrderIsSubmitting] = useState(false)
  const [orderValidationErrors, setOrderValidationErrors] = useState({})

  const [bundleModalIsOpen, setBundleModalIsOpen] = useState(false)
  const [bundleInModal, setBundleInModal] =
    useState<PractitionerLabTestBundle>()

  const [labTestModalIsOpen, setLabTestModalIsOpen] = useState(false)
  const [labTestOpenInModal, setLabTestOpenInModal] = useState<AnyLabTest>()

  const [showBloodDrawModalOnSubmit, setShowBloodDrawModalOnSubmit] =
    useState(false)
  const [bloodDrawModalIsOpen, setBloodDrawModalIsOpen] = useState(false)

  const openModalWithLabTest = useCallback((labTest: AnyLabTest) => {
    setLabTestOpenInModal(labTest)
    setLabTestModalIsOpen(true)
  }, [])

  const hybridBloodDrawModalEnabled =
    userPreferenceService.bloodDrawModalEnabled()
  const [bloodDrawModalEnabled, setBloodDrawModalEnabled] = useState(
    hybridBloodDrawModalEnabled
  )

  const physicianServicesConfirmationModal =
    usePhysicianServicesConfirmationModal()

  const signingPractitioner =
    order.signing_practitioner ||
    practitioner?.signing_practitioner ||
    practitioner

  const scrollContainerRef = useRef(null)

  const comboGroupConfigurationModal = useComboGroupConfigurationModal()

  const {
    useRefreshOrderingRightsBlocking,
    useRefreshOrderingRightsBackground,
  } = useRefreshOrderingRights({ order })

  const fetchBundles = () => {
    dispatch(
      fetchPractitionerBundles(order?.id, ORDERING_RIGHTS_ACTIONS.ADD_TO_CART)
    )
  }

  // refetch practitioner bundles to get the correct ordering rights
  useRefreshOrderingRightsBackground(() => {
    fetchBundles()
  })

  useRefreshOrderingRightsBlocking(() => {
    fetchBundles()
  })

  // Track cart visit
  useEffect(() => {
    trackCartVisit(practitioner?.id, practitioner?.clinic?.id, orderId)
  }, [practitioner, orderId])

  useEffect(() => {
    const bundleToRefresh = practitionerBundles.find(
      (bundle) => bundle.id === bundleInModal?.id
    )
    if (bundleInModal && bundleToRefresh) {
      setBundleInModal(bundleToRefresh)
    }
  }, [practitionerBundles])

  useEffect(() => {
    async function loadOrder() {
      /* Wait for the order to return before moving on. This ensures that the GET_ORDER
       * action has been sent before the UPDATE_ORDER action that will happen via
       * `addTest`. Without this it's possible for the GET_ORDER to come back _after_
       * UPDATE_ORDER, causing it to overwrite the new pricing fields. */
      await dispatch(Actions.getOrder(orderId))

      if (testMarkedToAdd) {
        const { lab_test, additionalData } = testMarkedToAdd
        handleAdd(lab_test, additionalData)

        // We are safe to unmark, even if it is a combo group test that was marked to add. Once we show the combo group config
        // modal, we have successfully carried over the requested lab test. If the user cancels the modal, we can assume that
        // they did not want the combo group as a part of their order.
        dispatch(Actions.unmarkTestToAddToNextOrder(testMarkedToAdd.lab_test))
      }
    }

    loadOrder()

    return () => {
      dispatch(Actions.clearOrder())
    }
  }, [dispatch, orderId])

  useEffect(() => {
    if (order.patient) {
      const patientId = order.patient.id ? order.patient.id : order.patient
      dispatch(Actions.getPatient(patientId))
    }
  }, [dispatch, order.patient])

  // Update the practitioner to ensure we have the latest values for has_signature and first_order_date
  // Also update practitioner when shipping state or phys services changes so that the favorite lab tests have the correct ordering rights
  useEffect(() => {
    if (order !== undefined) {
      dispatch(
        getPractitioner({
          shipping_state: order?.shipping_state,
          using_physician_services:
            order?.requires_vendor_physician_authorization,
        })
      )
    }
  }, [
    dispatch,
    order?.shipping_state,
    order?.requires_vendor_physician_authorization,
  ])

  // Update setShowBloodDrawModalOnSubmit when the order flag changes
  useEffect(() => {
    if (bloodDrawModalEnabled && order?.flags?.HYBRID_SCARLET_PHLEB) {
      setShowBloodDrawModalOnSubmit(true)
    } else if (showBloodDrawModalOnSubmit) {
      setShowBloodDrawModalOnSubmit(false)
    }
  }, [order.flags, bloodDrawModalEnabled])

  function handleToggleFavorite(lab_test: AnyLabTest, isFavorite: boolean) {
    if (isFavorite) {
      dispatch(removeFavoriteTest(practitioner, lab_test))
    } else {
      dispatch(addFavoriteTest(practitioner, lab_test))
    }
  }

  function handleAdd(lab_test: AnyLimitedLabTest, additionalData = {}) {
    if (lab_test.combo_group) {
      comboGroupConfigurationModal.show({
        updating: false,
        initialTargetId: lab_test.id,
        comboGroupId: lab_test.combo_group,
        initialOptionIds: [],
        initialAddOnIds: [],
        onAddToCart: async (payload) => {
          await handleAddComboGroup(lab_test, { ...payload, ...additionalData })
          comboGroupConfigurationModal.hide()
        },
      })
    } else {
      return dispatch(Actions.addTest(lab_test, additionalData))
    }
  }

  function handleRemove(labTest: AnyLimitedLabTest) {
    const orderedTest = order.ordered_tests_by_lab_test_id[labTest.id]

    // If the orderedTest hasn't been created yet on the server, then ignore
    // any attempt to remove it.
    if (orderedTest["optimistic"]) {
      return
    }

    // Check if it has addons in the cart, if so, remove them.
    const addOnsInCart = orderedTest.lab_test.add_ons
      .map((addOn) => order.ordered_tests_by_lab_test_id[addOn.id])
      .filter((t) => t)

    if (addOnsInCart.length) {
      dispatch(Actions.removePanel(order.id, [...addOnsInCart, orderedTest]))
    } else {
      dispatch(Actions.removeTest(orderedTest))
    }
  }

  function handleComparisonCallToAction(labTest) {
    const isInCart = labTest.id in order.lab_tests_by_id
    if (isInCart) {
      handleRemove(labTest)
    } else {
      handleAdd(labTest)
    }
  }

  function handleAddBundle(bundle) {
    trackAddBundleToCart(practitioner?.id, practitioner?.clinic?.id, bundle.id)

    dispatch(Actions.addBundle(order, bundle))
  }

  function handleRemoveBundle(bundle) {
    dispatch(Actions.removeBundle(order, bundle))
  }

  async function handleSubmission() {
    setBloodDrawModalIsOpen(false)

    if (hasInstantRequisitions(order)) {
      setInstantRequisitionModalOpen(true)
    } else {
      return submitOrder()
    }
  }

  async function handleOrderSubmission() {
    function submit() {
      if (showBloodDrawModalOnSubmit) {
        setBloodDrawModalIsOpen(true)
      } else {
        handleSubmission()
      }
    }

    if (
      showPhysicianServicesConfirmationModal &&
      order.requires_vendor_physician_authorization &&
      practitioner &&
      !practitioner?.practitioner_settings.includes(
        PRACTITIONER_SETTINGS.DONT_SHOW_PHYSICIAN_SERVICES_ORDER_CONFIRMATION_MODAL
      )
    ) {
      physicianServicesConfirmationModal.show({
        practitioner: practitioner,
        onConfirm: () => {
          submit()
          physicianServicesConfirmationModal.remove()
        },
        onClose: () => {
          physicianServicesConfirmationModal.remove()
        },
      })
    } else {
      submit()
    }
  }

  async function submitOrder() {
    setOrderIsSubmitting(true)

    trackSendToPatient(
      practitioner?.id,
      practitioner?.clinic?.id,
      orderId,
      order.is_practitioner_paying
    )

    setOrderValidationErrors({})
    setInstantRequisitionModalOpen(false)

    // If hideBloodDrawModal is checked while submitting the order, store the preference
    // so it does not display again in the future
    if (!bloodDrawModalEnabled) {
      userPreferenceService.setBloodDrawModalEnabled("false")
    }

    try {
      let parameters

      if (order.date_scheduled) {
        parameters = {
          submit_schedule_order: true,
        }
      } else if (edit) {
        parameters = {
          submit_edits: true,
        }
      } else {
        parameters = {
          submit: true,
        }
      }

      await dispatch(
        Actions.patchOrder(parameters, {
          exclude: "*",
          include: ["confirmation_image"],
        })
      )

      afterSubmitTransition()
    } catch (e) {
      if (
        isAxiosError(e) &&
        e.response &&
        e.response.data &&
        e.response.data.errors
      ) {
        setOrderValidationErrors(e.response.data.errors)
      } else {
        dispatch(handleApiError(e))
      }
    }

    setOrderIsSubmitting(false)
  }

  function afterSubmitTransition() {
    if (hasInstantRequisitions(order)) {
      history.push(`/patient/${order.patient.id}/pendingpayment/${order.id}`)
    }
    setConfirmationModalOpen(true)
    refreshFeaturePromotions()
  }

  function handleSaveDraftOrder() {
    trackSaveAsDraft(
      order.practitioner?.id,
      order.practitioner?.clinic?.id,
      order.id
    )
    history.push(UserPaths.ROOT)
  }

  async function handleDeleteOrder() {
    await dispatch(Actions.deleteOrder(order))
    history.push(UserPaths.ROOT)
  }

  // If no bundle is passed, it will open in the create state
  function openBundleModal(bundle?: PractitionerLabTestBundle) {
    setBundleInModal(bundle)
    setBundleModalIsOpen(true)
  }

  function closeBundleModal() {
    setBundleModalIsOpen(false)
    setBundleInModal(undefined)
  }

  function handleCloseOrderConfirmationModal() {
    try {
      if (!!window.parent) {
        window.parent.postMessage("rupaOrderConfirmed", "*")
      }
      if (!!window.opener) {
        window.opener.postMessage("rupaOrderConfirmed", "*")
      }
    } catch (e) {
      Sentry.captureException(e)
    }
    setConfirmationModalOpen(false)
  }

  async function handleAddComboGroup(labTest, payload) {
    await dispatch(Actions.addTest(labTest, payload))
    trackEventWithProperties(
      ComboGroupEvents.COMBO_GROUP_ADD_TO_CART_FROM_MODAL_CLICKED,
      {
        comboGroupLabTestId: labTest.id,
        practitionerId: practitioner?.id,
        comboGroupId: labTest.combo_group,
        orderId: order.id,
        type: ComboGroupAddToCartEventType.CREATE,
      }
    )
  }

  if (!orderId) {
    return <Redirect to={UserPaths.ERRORS_404} />
  }

  // Don't allow edits to orders that aren't in the 'Sent to patient' status
  if (
    edit &&
    order &&
    order.status !== ORDER_STATUS.PENDING_PAYMENT &&
    order.status !== ORDER_STATUS.SCHEDULED
  ) {
    return <Redirect to={UserPaths.ERRORS_404} />
  }

  // Check that order.patient.user exists. This is used to check that
  // the getOrder call has completed and that we have a full Order object
  // with all nested objects available.
  if (!order?.patient?.user || !patient?.user) {
    return <Loading ariaLabel="Loading order" />
  }

  return (
    <CheckoutDraftProvider>
      <ComparisonProvider
        callToAction={ComparisonCallToActionTypes.ADD_TO_CART}
        onCallToAction={handleComparisonCallToAction}
        orderId={order?.id}
      >
        <div className={styles.container}>
          <div className={styles.mainContentContainer}>
            <CheckoutPageToolbar patient={patient} orderId={order.id} />
            <div className={styles.testsContainer} ref={scrollContainerRef}>
              <NoNPIBanner />
              {!(
                [ORDER_STATUS.DRAFT, ORDER_STATUS.DEMO] as OrderStatusUnion[]
              ).includes(order.status) &&
                !edit && (
                  <div className="absolute top-0 bottom-0 left-0 right-0 z-99 opacity-75 bg-white" />
                )}
              {edit && (
                <div className="flex items-center pb-6">
                  <img src={infoCircleOutlined} alt="" className="mr-1" />
                  <Typography className="font-semibold text-sm text-gray-700">
                    Updates are automatically saved and are reflected on{" "}
                    {patient && patient.first_name}'s invoice.
                  </Typography>
                </div>
              )}
              <Favorites
                signingPractitioner={signingPractitioner}
                practitioner={practitioner}
                practitionerBundles={practitionerBundles}
                selectedLabTests={order.lab_tests_by_id}
                selectedBundleIds={order.lab_test_bundles.map(
                  (bundle) => bundle.id
                )}
                handleAdd={handleAdd}
                handleRemove={handleRemove}
                handleToggleFavorite={handleToggleFavorite}
                openModalWithLabTest={openModalWithLabTest}
                handleAddBundle={handleAddBundle}
                handleRemoveBundle={handleRemoveBundle}
                openBundleModal={openBundleModal}
                showBundles={true}
                onAddComboGroup={handleAddComboGroup}
                order={order}
              />
              <LabTests
                practitioner={practitioner}
                signingPractitioner={signingPractitioner}
                location={labTestLocation.CHECKOUT}
                onAddLabTest={handleAdd}
                onRemoveLabTest={handleRemove}
                selectedLabTests={order.lab_tests_by_id}
                vendorPhysicianServicesActivated={
                  order.requires_vendor_physician_authorization
                }
                syncWithUrl
                order={order}
                onAddComboGroup={handleAddComboGroup}
              />
            </div>
          </div>
          <OrderSidebar
            order={order}
            orderIsSubmitting={orderIsSubmitting}
            orderValidationErrors={orderValidationErrors}
            // onRemoveTest={handleRemove}
            onSubmit={handleOrderSubmission}
            onSave={handleSaveDraftOrder}
            onDelete={handleDeleteOrder}
            openModalWithLabTest={openModalWithLabTest}
            edit={edit}
          />
        </div>
        {edit ? (
          <OrderEditConfirmationModal
            order={order}
            open={confirmationModalOpen}
            onClose={() => setConfirmationModalOpen(false)}
          />
        ) : (
          order.practitioner && (
            <OrderConfirmationModal
              currentPractitioner={practitioner}
              order={order}
              open={confirmationModalOpen}
              onClose={handleCloseOrderConfirmationModal}
            />
          )
        )}
        <InstantRequisitionModal
          order={order}
          open={instantRequisitionModalOpen}
          onClose={() => setInstantRequisitionModalOpen(false)}
          onConfirm={() => submitOrder()}
        />
        <BloodDrawModal
          open={bloodDrawModalIsOpen}
          patient={patient}
          bloodDrawModalEnabled={bloodDrawModalEnabled}
          checkboxToggleHandler={() =>
            setBloodDrawModalEnabled(!bloodDrawModalEnabled)
          }
          onSubmit={handleSubmission}
          onClose={() => setBloodDrawModalIsOpen(false)}
        />
        {order && labTestOpenInModal && !bundleModalIsOpen && (
          <LabTestModal
            labTest={labTestOpenInModal}
            practitioner={practitioner}
            signingPractitioner={signingPractitioner}
            isOpen={labTestModalIsOpen}
            onClose={() => setLabTestModalIsOpen(false)}
            location={labTestLocation.CHECKOUT}
            selectedLabTests={order.lab_tests_by_id}
            onAddLabTest={handleAdd}
            onRemoveLabTest={handleRemove}
            orderId={order.id}
          />
        )}
        {!!order && !!practitioner && (
          <BundleModal
            open={bundleModalIsOpen}
            practitioner={practitioner}
            practitionerBundles={practitionerBundles}
            onClose={closeBundleModal}
            handleToggleLabTestFavorite={handleToggleFavorite}
            bundle={bundleInModal}
            order={order}
            usePhysicianAuthorizationForOrderingAccess={
              order.requires_vendor_physician_authorization
            }
            location={labTestLocation.CHECKOUT}
          />
        )}

        {showOverrideOption && Boolean(labTestOverride.labCompany) && (
          <OverrideAccessModal
            open={!!labTestOverride.labCompany}
            title={`Confirm Ordering Access for ${labTestOverride.labCompany?.name}`}
            licenseType={practitioner?.primary_practitioner_type?.name}
            state={labTestOverride.unavailableState}
            labCompany={labTestOverride.labCompany}
            practitionerId={practitioner?.id}
            orderId={orderId}
          />
        )}
      </ComparisonProvider>
    </CheckoutDraftProvider>
  )
}

export default withReducer("orders", reducer)(Checkout)
