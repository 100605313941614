import { Resource, ResourceRelationshipSingle } from "app/swr/types"

export enum PatientDocumentType {
  FOOD_PLAN = "FOOD_PLAN",
  ORDER_USER_RESULT = "ORDER_USER_RESULT",
  RESULTS_INTERPRETATION = "RESULTS_INTERPRETATION",
  IMPORTED_USER_RESULT = "IMPORTED_USER_RESULT",
}

export interface PaginationMeta {
  pagination: {
    count: number
    page: number
    pageSize: number
  }
}

export interface Document extends Resource {
  id: string
  meta: {
    can_read: boolean
    can_write: boolean
    can_delete: boolean
    is_owner: boolean
  }
  attributes: {
    resource_type: string
    origin_description: string
    title: string
  }
  relationships: {
    food_plan?: ResourceRelationshipSingle
    user_result?: ResourceRelationshipSingle
    results_interpretation?: ResourceRelationshipSingle
    user?: ResourceRelationshipSingle
  }
}
