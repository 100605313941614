import { memo } from "react"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"

import TextFieldComponent, {
  TextFieldComponentProps,
} from "app/components/forms/TextFieldComponent"
import { InjectedProps, WrapperProps } from "formsy-react/dist/withFormsy"
import pick from "lodash/pick"

// Declare the fields we pass through to TextFieldComponent so we can pick them out.
// Add more as needed.
const TEXT_FIELD_PROP_KEYS = [
  "children",
  "clickToEdit",
  "variant",
  "inputProps",
  "onChange",
  "required",
  "disabled",
  "helperText",
  "label",
  "type",
  "InputProps",
  "fullWidth",
  "placeholder",
  "onFocus",
  "multiline",
  "rowsMax",
  "rows",
  "className",
] as const

export type TextFieldFormsyProps = Omit<WrapperProps<string>, "required"> &
  Pick<TextFieldComponentProps, typeof TEXT_FIELD_PROP_KEYS[number]>

function TextFieldFormsy({
  name,
  value = "",
  setValue,
  errorMessage,
  ...props
}: TextFieldFormsyProps & InjectedProps<string>) {
  const textFieldProps = pick(props, TEXT_FIELD_PROP_KEYS)

  function changeValue(event) {
    setValue(event.currentTarget.value)
    textFieldProps?.onChange?.(event)
  }

  return (
    <TextFieldComponent
      {...textFieldProps}
      name={name}
      onChange={changeValue}
      value={value ?? ""}
      error={Boolean(errorMessage)}
      helperText={errorMessage || textFieldProps?.helperText}
    />
  )
}

export default memo(withFormsy(TextFieldFormsy))
