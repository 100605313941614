import { useRef, useState } from "react"
import { useDispatch } from "react-redux"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"

import { makeStyles, Popover, useMediaQuery } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import { cn } from "@rupahealth/design"

import { ReactComponent as CancelIcon } from "app/assets/images/cancel.svg"
import DesignSystemButton from "app/components/design-system/Button"
import { Theme } from "app/theme"

import {
  RadioGroupFormsy,
  TextFieldFormsy,
} from "../../../@fuse/components/formsy"
import usePatientOrderStyles from "./hooks/usePatientOrderStyles"
import { cancelOrder } from "./store/actions"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    "box-shadow": "0px 3px 8px rgba(0, 0, 0, 0.1)",
    "border-radius": "8px",
  },
}))

export function CancelOrderButton({ orderId }) {
  const classes = useStyles({})
  const sharedClasses = usePatientOrderStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div className={sharedClasses.buttonContainer}>
      <DesignSystemButton
        color="secondary"
        shadow="default"
        startIcon={<CancelIcon width={15} />}
        onClick={(event) => handlePopoverOpen(event)}
        className={cn(
          sharedClasses.button,
          "p-3 border border-slate-400",
          isTablet ? "items-center" : "items-start"
        )}
      >
        Cancel
      </DesignSystemButton>

      <Popover
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
      >
        <CancelForm orderId={orderId} />
      </Popover>
    </div>
  )
}

function CancelForm({ orderId }) {
  const dispatch = useDispatch()
  const formRef = useRef<Formsy>(null)

  const OTHER_VALUE = "Other"

  function handleSubmit(values) {
    const cancellationReason =
      values.reason === OTHER_VALUE ? values.other : values.reason
    dispatch(cancelOrder(orderId, cancellationReason))
  }

  function onTextFocus() {
    formRef.current?.updateInputsWithValue({ reason: OTHER_VALUE })
  }

  return (
    <Formsy
      onValidSubmit={handleSubmit}
      ref={formRef}
      className="flex flex-col justify-center w-full"
    >
      <RadioGroupFormsy name="reason" required>
        <FormControlLabel
          value="Demo order"
          control={<Radio size="small" color="primary" />}
          label="This was a demo order."
        />
        <FormControlLabel
          value="Patient no longer wants test."
          control={<Radio size="small" color="primary" />}
          label="Patient no longer wants test."
        />
        <FormControlLabel
          value="Incorrectly placed order"
          control={<Radio size="small" color="primary" />}
          label="I incorrectly placed this order."
        />
        <FormControlLabel
          value={OTHER_VALUE}
          control={<Radio size="small" color="primary" />}
          label="Other"
        />
      </RadioGroupFormsy>

      <TextFieldFormsy
        name="other"
        type="text"
        variant="outlined"
        onFocus={onTextFocus}
        className="p-1 w-72"
        multiline={true}
        rowsMax={5}
        InputProps={{
          style: { fontSize: 14, padding: 12 },
        }}
      />

      <div className="flex flex-row-reverse mt-1.5">
        <Button type="submit" color="primary">
          Submit
        </Button>
      </div>
    </Formsy>
  )
}
