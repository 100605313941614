import React, { useState } from "react"

import { ClipboardModal } from "app/components/modals/ClipboardModal/ClipboardModal"

const SECTIONS = [
  {
    title: "How it Works",
    buttonText: "Next",
    items: [
      "You’ll be able to order bulk inventory in a new In-Office Kit Supplies Tab",
      "When you’re ready to use your kits on patients, you must activate the kit on Rupa by placing an order (same way as drop ship!)",
      <div>
        When placing the order, select “In Office Delivery Method” in
        Checkout.&nbsp;
        <a
          className="text-primary"
          href="https://www.rupahealth.com/videos/order-supplies-place-in-office-kit-orders"
          target="_blank"
          rel="noreferrer"
        >
          View a tutorial here!
        </a>
      </div>,
      "Once the order is placed, your kit is activated and when needed, we’ll give you an auto-filled Requisition to print & ship back.",
    ],
  },
  {
    title: "Key things to know",
    buttonText: "I Understand!",
    items: [
      "All kits must be activated on Rupa! We cannot process results if the order is not placed on Rupa.",
      "There is no cost to order supplies on Rupa",
      "In-Office Kits cannot be used with Physician Services",
      "You must have access or select a Signing Practitioner with access to the Lab to order Supplies",
    ],
  },
]

interface Props {
  showModal: boolean
  onExit: () => void
  onComplete: () => void
}

export const IOKOnboardingModal: React.FC<Props> = ({
  showModal,
  onExit,
  onComplete,
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const currentSection = SECTIONS[currentStep]
  const advanceStep = () => {
    if (currentStep < SECTIONS.length - 1) {
      setCurrentStep(currentStep + 1)
    } else {
      onComplete()
    }
  }

  if (!showModal) {
    return null
  }
  return (
    <ClipboardModal
      modalTitle="Stock In-Office Kit Supplies Through Rupa"
      clipboardText={currentSection.title}
      items={currentSection.items}
      showModal={showModal}
      onExit={onExit}
      onContinue={advanceStep}
      buttonText={currentSection.buttonText}
      ariaLabel="in-office-kits-onboarding-modal"
      showCloseButton
    />
  )
}
