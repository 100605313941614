import { useState } from "react"

import {
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core"
import { cn } from "@rupahealth/design"

import BodyText from "../design-system/BodyText"
import { ariaLabel } from "./helpers"

export type TextFieldComponentProps = MuiTextFieldProps & {
  clickToEdit?: boolean
}

const TextFieldComponent: React.FC<TextFieldComponentProps> = ({
  clickToEdit,
  ...props
}) => {
  if (clickToEdit) {
    return <ClickToEditTextField {...props} />
  }
  return <TextFieldBase {...props} />
}

const ClickToEditTextField = (props: MuiTextFieldProps) => {
  const [editing, setEditing] = useState(false)
  const onClick = () => {
    setEditing((currentlyEditing) => {
      // When not editing, can always switch mode to edit
      if (!currentlyEditing) return true
      // When editing, can only save when there is not a validation error
      return !!props.error
    })
  }
  // const classes = useStyles({ active: !readOnly })

  return (
    <TextFieldComponent
      {...props}
      // Focus input when in edit mode
      inputRef={(input) => (input && editing ? input.focus() : null)}
      InputProps={{
        ...props.InputProps,
        readOnly: !editing,
        endAdornment: (
          <InputAdornment position="end">
            <button
              className={cn(
                "rounded-[3px]",
                editing ? "bg-primary py-1 px-2" : "bg-transparent p-0"
              )}
              disabled={editing && !props.value}
              onClick={onClick}
            >
              <BodyText
                className={cn(editing ? "text-white" : "text-primary")}
                weight="semibold"
                size="sm"
              >
                {editing ? "Save" : "Edit"}
              </BodyText>
            </button>
          </InputAdornment>
        ),
      }}
    />
  )
}

const TextFieldBase = ({ ...props }: MuiTextFieldProps) => {
  return (
    <MuiTextField
      {...props}
      inputProps={{
        ...props.inputProps,
        "aria-label": props.inputProps?.["aria-label"] ?? ariaLabel(props),
      }}
    />
  )
}

export default TextFieldComponent
