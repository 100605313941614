import { memo } from "react"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import { withFormsy } from "formsy-react"

import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core"

import { InjectedProps, WrapperProps } from "formsy-react/dist/withFormsy"
import pick from "lodash/pick"

// Declare the fields we pass through to Checkbox so we can pick them out.
// Add more as needed.
const CHECKBOX_PROP_KEYS = ["id", "onChange", "required", "color"] as const

export type CheckboxFormsyProps = Omit<WrapperProps<boolean>, "required"> &
  Pick<CheckboxProps, typeof CHECKBOX_PROP_KEYS[number]> & {
    label?: React.ReactNode
    className?: string
  }

function CheckboxFormsy({
  value,
  label,
  setValue,
  errorMessage,
  className,
  ...props
}: CheckboxFormsyProps & InjectedProps<boolean>) {
  const checkboxProps = pick(props, CHECKBOX_PROP_KEYS)

  function changeValue(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    setValue(checked)
    checkboxProps.onChange?.(event, checked)
  }

  return (
    <FormControl error={Boolean(errorMessage)} className={className}>
      <FormControlLabel
        name={props.name}
        control={
          <Checkbox {...checkboxProps} checked={value} onChange={changeValue} />
        }
        label={label}
      />
      {Boolean(errorMessage) && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default memo(withFormsy(CheckboxFormsy))
