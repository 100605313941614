import React from "react"

import { SelectFormsyProps } from "@fuse/components/formsy/SelectFormsy"
import { TextFieldFormsyProps } from "@fuse/components/formsy/TextFieldFormsy"
import { OutlinedInput, Typography, withStyles } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { cn } from "@rupahealth/design"

import Tooltip from "app/components/Tooltip"

import { SelectFormsy, TextFieldFormsy } from "../../../@fuse/components/formsy"
import { veryLightGray } from "../../theme"
import InfoTextTooltip from "../design-system/InfoTextTooltip"
import { ariaLabel } from "./helpers"

const useInputStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "white",
    fontSize: 14,
    padding: 12,
  },
  multiline: {
    padding: 0,
  },
}))

const useStyles = makeStyles((theme) => ({
  labelRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 2,
  },
}))

export const FormFieldLabel = ({
  label,
  infoText,
  labelClass,
  required = true,
}: {
  label: React.ReactNode
  infoText?: React.ReactNode
  labelClass?: string
  required?: boolean
}) => (
  <div className="mb-1 fs-unmask">
    <Typography
      color="textPrimary"
      className={cn("font-semibold inline", labelClass)}
    >
      {label}
    </Typography>
    {required && (
      <Typography color="error" className="font-semibold inline">
        {" *"}
      </Typography>
    )}
    {infoText && (
      <InfoTextTooltip className="inline ml-1 align-middle">
        {infoText}
      </InfoTextTooltip>
    )}
  </div>
)

interface FormTextFieldProps extends TextFieldFormsyProps {
  tooltipTitle?: React.ReactNode
  secondaryElement?: React.ReactNode
}

export const FormTextField = ({
  name,
  children,
  tooltipTitle,
  secondaryElement,
  required = true,
  label,
  InputProps,
  inputProps,
  ...props
}: FormTextFieldProps) => {
  const { labelRow } = useStyles({})
  const classes = useInputStyles({})

  const disableTooltip = !props?.disabled || !tooltipTitle

  return (
    <div>
      <div className={labelRow}>
        <FormFieldLabel label={label} required={required} />
        {secondaryElement}
      </div>
      <Tooltip
        arrow
        interactive
        placement="bottom"
        disableHoverListener={disableTooltip}
        disableFocusListener={disableTooltip}
        disableTouchListener={disableTooltip}
        title={tooltipTitle ?? ""}
      >
        <div>
          <TextFieldFormsy
            name={name}
            className="fs-exclude"
            InputProps={{ classes, ...InputProps }}
            inputProps={{
              "aria-label": ariaLabel({ label, ...props }),
              ...inputProps,
            }}
            type={props?.type || "text"}
            fullWidth={true}
            variant="outlined"
            required={required}
            {...props}
          >
            {children}
          </TextFieldFormsy>
        </div>
      </Tooltip>
    </div>
  )
}

const SelectInput = withStyles((theme) => ({
  root: {
    backgroundColor: veryLightGray,
    fontSize: 14,
  },
  input: {
    padding: "10px 12px",
  },
}))(OutlinedInput)

interface SelectFormFieldProps extends SelectFormsyProps {
  hideEmptyOption?: boolean
  labelClass?: string
  infoText?: React.ReactNode
}

export const SelectFormField = ({
  hideEmptyOption = false,
  labelClass,
  className,
  required,
  label,
  children,
  infoText,
  inputProps,
  ...props
}: SelectFormFieldProps) => {
  return (
    <div className={className}>
      <FormFieldLabel
        label={label}
        required={required}
        labelClass={labelClass}
        infoText={infoText}
      />
      <SelectFormsy
        className="fs-exclude w-full"
        input={<SelectInput />}
        variant={"outlined"}
        native={true}
        required={required}
        inputProps={{
          "aria-label": ariaLabel({ label, ...props }),
          ...inputProps,
        }}
        {...props}
      >
        {!hideEmptyOption && <option value="">Select</option>}
        {children}
      </SelectFormsy>
    </div>
  )
}
