import React from "react"

import clsx from "clsx"

import { Theme, useMediaQuery } from "@material-ui/core"

import { ReactComponent as RightCarrotIcon } from "app/assets/icons/labshops/carrot-right.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import BloodLabDashboardsBiomarkerStatusIndicator from "app/main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/statuses/BloodLabDashboardsBiomarkerStatusIndicator"
import NumericBiomarkerGraphic from "app/main/blood-lab-dashboards/NumericBiomarkerGraphic/NumericBiomarkerGraphic"
import { BiomarkerStatus } from "app/patient-portal/blood-lab-dashboard/constants"
import { PatientPortalPractitioner } from "app/patient-portal/types"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, navy, primaryColor, shadows } from "app/theme"
import { Practitioner } from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { BloodReportResult } from "types/blood-report-result"

import { getValueForNumericBiomarkerGraphicFromBloodReportResult } from "../NumericBiomarkerGraphic/utils"
import useBloodLabDashboardsBloodReportResultDetailModal from "../hooks/use-blood-lab-dashboards-blood-report-result-modal"
import { getBiomarkerStatus } from "../utils"

const useStyles = makeAppStyles((theme: Theme) => ({
  container: {
    width: "100%",
    background: "white",
    display: "flex",
    gap: 30,
    borderRadius: 10,
    border: `1px solid ${colors.blueGray[200]}`,
    boxShadow: shadows.sm,
    padding: 14,
    transition: "all 0.2s ease-in-out",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
      cursor: "pointer",
      border: "1px solid #60A5FA",
      boxShadow:
        "0px 0.99042px 1.98083px 0px rgba(0, 0, 0, 0.06), 0px 0.99042px 2.97125px 0px rgba(0, 0, 0, 0.10)",
      transform: "scale(1.03, 1.03)",
      transition: "all 0.2s ease-in-out",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  biomarkerValue: {
    fontSize: 22,
    fontWeight: 600,
    color: navy,
  },
  biomarkerUnits: {
    fontSize: 13,
    color: colors.blueGray[400],
    fontWeight: 600,
  },
  detailsText: {
    width: 233,
    minWidth: 233,
    display: "block",
    overflowWrap: "anywhere",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  detailsRangeBar: {
    width: "100%",
  },
  statusContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: 4,
    width: 285,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  redValueColor: {
    color: colors.red[900],
  },
  yellowValueColor: {
    color: colors.yellow[900],
  },
  greenValueColor: {
    color: colors.emerald[900],
  },
  redStatusColor: {
    color: colors.red[700],
  },
  yellowStatusColor: {
    color: colors.yellow[700],
  },
  greenStatusColor: {
    color: colors.emerald[700],
  },
}))

interface Props {
  bloodReportResult: BloodReportResult
  practitioner?: PatientPortalPractitioner | Practitioner
  clinicName?: string
  snapshotCreationDate: string
  labCompanyName?: string
  showHighLowDescriptions: boolean
}

export const BloodLabDashboardsSnapshotOutOfRangeCard = ({
  bloodReportResult,
  clinicName,
  snapshotCreationDate,
  labCompanyName,
  showHighLowDescriptions,
}: Props) => {
  const classes = useStyles()
  const biomarker = useCachedResource<Biomarker>(
    bloodReportResult.relationships.biomarker.data
  )
  const biomarkerStatus = getBiomarkerStatus(bloodReportResult)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const bloodLabDashboardsBloodReportResultDetailModal =
    useBloodLabDashboardsBloodReportResultDetailModal()

  const valueStyle = {
    [BiomarkerStatus.HIGH]: classes.redValueColor,
    [BiomarkerStatus.LOW]: classes.redValueColor,
    [BiomarkerStatus.ABNORMAL]: classes.redValueColor,
    [BiomarkerStatus.ABOVE_OPTIMAL]: classes.yellowValueColor,
    [BiomarkerStatus.BELOW_OPTIMAL]: classes.yellowValueColor,
    [BiomarkerStatus.NORMAL]: classes.greenValueColor,
    [BiomarkerStatus.OPTIMAL]: classes.greenValueColor,
  }

  const statusStyle = {
    [BiomarkerStatus.HIGH]: classes.redStatusColor,
    [BiomarkerStatus.LOW]: classes.redStatusColor,
    [BiomarkerStatus.ABNORMAL]: classes.redStatusColor,
    [BiomarkerStatus.ABOVE_OPTIMAL]: classes.yellowStatusColor,
    [BiomarkerStatus.BELOW_OPTIMAL]: classes.yellowStatusColor,
    [BiomarkerStatus.NORMAL]: classes.greenStatusColor,
    [BiomarkerStatus.OPTIMAL]: classes.greenStatusColor,
  }

  const shouldShowRangeBar =
    (bloodReportResult.attributes.standard_range_min ||
      bloodReportResult.attributes.standard_range_max) &&
    bloodReportResult.attributes.value

  if (!biomarker) {
    return null
  }

  const handleClick = () => {
    bloodLabDashboardsBloodReportResultDetailModal.show({
      biomarkerId: biomarker?.id,
      biomarkerShortName: biomarker?.attributes.short_name,
      biomarkerLongName: biomarker?.attributes.long_name,
      biomarkerDescription: biomarker?.attributes.description,
      biomarkerShortDescription: biomarker?.attributes.short_description,
      biomarkerLowDescription: biomarker?.attributes.low_description,
      biomarkerHighDescription: biomarker?.attributes.high_description,
      biomarkerLowSymptoms: biomarker?.attributes.low_symptoms,
      biomarkerHighSymptoms: biomarker?.attributes.high_symptoms,
      optimalRangeMin: bloodReportResult.attributes.optimal_range_min,
      optimalRangeMax: bloodReportResult.attributes.optimal_range_max,
      standardRangeMin: bloodReportResult.attributes.standard_range_min,
      standardRangeMax: bloodReportResult.attributes.standard_range_max,
      value: bloodReportResult.attributes.display_value || "",
      alternateValue: bloodReportResult.attributes.alternate_value,
      isRangeValue: bloodReportResult.attributes.is_range_value,
      unit: bloodReportResult.attributes.unit,
      status: bloodReportResult.attributes.status,
      clinicName: clinicName || "Practitioner's Clinic",
      activeResultDate: snapshotCreationDate,
      labCompanyName: labCompanyName ?? "",
      onClose: () => bloodLabDashboardsBloodReportResultDetailModal.remove(),
      showHighLowDescriptions,
    })
  }

  if (!biomarker) {
    return null
  }

  return (
    <div className={classes.container} onClick={handleClick}>
      <div className={classes.detailsText}>
        <BodyText size="md" weight="bold">
          {biomarker?.attributes.short_name}
        </BodyText>
        <DesignSystemButton
          color="text"
          loading={false}
          variant="text"
          endIcon={
            <RightCarrotIcon
              fill={primaryColor}
              width={8}
              height={13}
              viewBox="0 0 8 15"
            />
          }
        >
          Details
        </DesignSystemButton>
      </div>

      <div className={classes.statusContainer}>
        <BloodLabDashboardsBiomarkerStatusIndicator
          biomarkerStatus={biomarkerStatus}
        />{" "}
        <div>
          <span
            className={clsx(
              classes.biomarkerValue,
              biomarkerStatus ? valueStyle[biomarkerStatus] : ""
            )}
          >
            {bloodReportResult.attributes.display_value ??
              bloodReportResult.attributes.alternate_value}
          </span>
          {bloodReportResult.attributes.display_value && (
            <span
              className={clsx(
                classes.biomarkerUnits,
                biomarkerStatus ? statusStyle[biomarkerStatus] : ""
              )}
            >
              {bloodReportResult.attributes.unit}
            </span>
          )}
        </div>
      </div>
      <div className={classes.detailsRangeBar}>
        {shouldShowRangeBar && (
          <NumericBiomarkerGraphic
            normalMax={bloodReportResult.attributes.standard_range_max}
            normalMin={bloodReportResult.attributes.standard_range_min}
            optimalMax={bloodReportResult?.attributes.optimal_range_max}
            optimalMin={bloodReportResult?.attributes.optimal_range_min}
            value={getValueForNumericBiomarkerGraphicFromBloodReportResult(
              bloodReportResult
            )}
            singleLabel={isMobile}
            hideTooltip={true}
            status={bloodReportResult.attributes.status}
          />
        )}
      </div>
    </div>
  )
}
