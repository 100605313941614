import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { makeStyles, styled } from "@material-ui/core"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import DeleteIcon from "app/assets/icons/bundles/tooltip-nav-icons/delete-action.svg"
import CopyLinkIcon from "app/assets/icons/bundles/tooltip-nav-icons/link-action.svg"
import BodyText from "app/components/design-system/BodyText"
import DisplayText from "app/components/design-system/DisplayText"
import { Dialog } from "app/components/modals"
import ConfirmationModal from "app/components/modals/ConfirmationModal"
import BundleSidebar from "app/main/checkout/BundleSidebar"
import Favorites from "app/main/checkout/Favorites"
import useBundleFavorites from "app/main/checkout/hooks/use-bundle-favorites"
import BundleCardIcons from "app/main/settings/Bundles/BundleCardIcons"
import {
  trackBundleAccordionClick,
  trackBundleFavoriteClick,
} from "app/services/segment"
import * as Actions from "app/store/actions"
import { cancelRed, colors, navy, shadows } from "app/theme"
import {
  AnyLabTest,
  AnyLimitedLabTest,
  LabTest,
  labTestLocation,
  Order,
  Practitioner,
  PractitionerLabTestBundle,
} from "app/types"
import isEmpty from "lodash/isEmpty"

import LabTests from "../LabTests/LabTests"
import { NavItem } from "../design-system/TooltipNav/constants"
import LabTestModal from "./LabTestModal"

const styles = (theme) => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 7,
  },
})

const useStyles = makeStyles(styles)

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "stretch",
  width: "100%",
  position: "relative",

  [theme.breakpoints.up("md")]: {
    flexFlow: "row",
  },

  // As the checkout stretches the entire width of the screen, we need
  // an extra breakpoint for the lab test cards.
  "& .labTestCardGridItem": {
    "@media (min-width: 2000px)": {
      flexBasis: "20%",
      width: "20%",
    },
  },
}))

const TestsContainer = styled("div")({
  flex: 1,
  paddingRight: 24,
  position: "relative",
})

const SidebarContainer = styled("div")(({ theme }) => ({
  background: "white",
  padding: 24,
  boxShadow: shadows.md,
  width: 420,
  borderRadius: "10px",

  [theme.breakpoints.down("sm")]: {
    width: "94%",
    marginTop: "20px",
  },
}))

const TitleAndInfoContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  marginRight: 24,
})

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: navy,
  position: !theme.breakpoints.down("sm") ? "absolute" : "relative",
  top: 1,
  right: !theme.breakpoints.down("sm") ? 12 : 0,
  marginRight: -12,
}))

interface Props {
  open: boolean
  practitioner: Practitioner | undefined
  practitionerBundles: PractitionerLabTestBundle[]
  onClose: () => void
  handleToggleLabTestFavorite: (
    labTest: AnyLabTest,
    isFavorite: boolean
  ) => void
  usePhysicianAuthorizationForOrderingAccess: boolean
  location: labTestLocation
  order?: Order
  // bundle is undefined for create
  bundle?: PractitionerLabTestBundle | null
  storefrontId?: string
  ecommerceOrderingPractitioner?: Practitioner
}

const BundleModal: React.FC<Props> = ({
  open,
  practitioner,
  practitionerBundles,
  onClose,
  handleToggleLabTestFavorite,
  bundle,
  // Optional, determines if bundle modal is in checkout or in settings for override modal
  order,
  usePhysicianAuthorizationForOrderingAccess,
  // Last two are optional and used only for Ecommerce LabSHops
  ecommerceOrderingPractitioner,
  location,
  // optional, storefrontId if a storefront should be used in the context for calculating ordering rights
  storefrontId,
}) => {
  const classes = useStyles(styles)
  const dispatch = useDispatch()

  const { favoriteBundle, unfavoriteBundle } = useBundleFavorites()

  const [selectedLabTests, setSelectedLabTests] = useState<
    Record<string, LabTest>
  >({})

  const [labTestInModal, setLabTestInModal] = useState<AnyLabTest>()

  const [bundleConfirmationModalIsOpen, setBundleConfirmationModalIsOpen] =
    useState(false)

  const [
    closeBundleConfirmationModalIsOpen,
    setCloseBundleConfirmationModalIsOpen,
  ] = useState(false)

  const [bundleIsSubmitting, setBundleIsSubmitting] = useState(false)

  const [tooltipMenuIsOpen, setTooltipMenuIsOpen] = useState(false)

  const [hasBundleBeenEdited, setHasBundleBeenEdited] = useState(false)

  // Set selected lab tests when edit bundle modal is opened
  useEffect(() => {
    if (bundle) {
      setSelectedLabTests(
        Object.fromEntries(
          bundle?.lab_tests.map((labTest) => [labTest.id, labTest])
        )
      )
    } else {
      setSelectedLabTests({})
    }
  }, [bundle])

  function handleAdd(labTest) {
    setHasBundleBeenEdited(true)
    setSelectedLabTests({ ...selectedLabTests, [labTest.id]: labTest })
  }

  function handleRemove(labTest: AnyLimitedLabTest) {
    setHasBundleBeenEdited(true)
    const filteredSelectedLabTests = { ...selectedLabTests }

    // If its a parent test, First remove the selected addons
    if (labTest.add_ons.length) {
      labTest.add_ons.forEach((addonLabTest) => {
        delete filteredSelectedLabTests[addonLabTest.id]
      })
    }

    delete filteredSelectedLabTests[labTest.id]

    setSelectedLabTests(filteredSelectedLabTests)
  }

  const handleClose = () => {
    setSelectedLabTests({})
    setBundleIsSubmitting(false)
    setHasBundleBeenEdited(false)
    onClose()
  }

  function handleRemovePanel(labTests: AnyLimitedLabTest[]) {
    const filteredSelectedLabTests = { ...selectedLabTests }

    labTests.forEach((labTest) => {
      delete filteredSelectedLabTests[labTest.id]
    })

    setSelectedLabTests(filteredSelectedLabTests)
  }

  function closeBundleAndBundeConfirmationModal() {
    setBundleConfirmationModalIsOpen(false)
    setCloseBundleConfirmationModalIsOpen(false)
    handleClose()
  }

  function closeBundleHandler() {
    if ((isEmpty(bundle) && !selectedLabTests.length) || !hasBundleBeenEdited) {
      handleClose()
    } else {
      // Show confirm dialog before closing edit bundle and create bundle (if tests are selected)
      setCloseBundleConfirmationModalIsOpen(true)
    }
  }

  function handleDeleteBundle() {
    setBundleIsSubmitting(true)

    if (!bundle) {
      closeBundleAndBundeConfirmationModal()
    } else {
      dispatch(
        Actions.deleteBundle(bundle?.id, closeBundleAndBundeConfirmationModal)
      )
    }
  }

  function handleTooltipMenuClick(e) {
    e.stopPropagation()
    trackBundleAccordionClick(
      practitioner?.id,
      practitioner?.clinic.id,
      bundle?.id
    )
    setTooltipMenuIsOpen(!tooltipMenuIsOpen)
  }

  function handleCopyLinkClick(e) {
    e.stopPropagation()
    if (bundle) {
      dispatch(Actions.copyBundleLink(bundle?.id))
    }
    setTooltipMenuIsOpen(false)
  }

  function handleDeleteBundleClick(e) {
    e.stopPropagation()
    setTooltipMenuIsOpen(false)
    setBundleConfirmationModalIsOpen(true)
  }

  async function handleFavoriteClick(e) {
    e.stopPropagation()

    trackBundleFavoriteClick(
      practitioner?.id,
      practitioner?.clinic?.id,
      bundle?.id,
      bundle?.is_shared_bundle,
      bundle?.is_favorited,
      location
    )

    if (bundle?.is_favorited) {
      await unfavoriteBundle(bundle)
    } else if (bundle) {
      await favoriteBundle(bundle)
    }
  }

  const navItems: NavItem[] = [
    {
      id: "copy-link",
      icon: CopyLinkIcon,
      name: "Copy Link",
      onClick: handleCopyLinkClick,
    },
    {
      id: "delete-bundle",
      icon: DeleteIcon,
      name: "Delete Bundle",
      onClick: handleDeleteBundleClick,
      color: cancelRed,
      background: colors.red[50],
    },
  ]

  return (
    <>
      <Dialog
        onClose={closeBundleHandler}
        aria-labelledby="lab-test-bundle-title"
        open={open}
        className={"fs-unmask"}
        maxWidth="xl"
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <TitleSection
          title={
            !bundle
              ? "Create a Lab Test Bundle"
              : bundle.name ?? "Edit Your Lab Test Bundle"
          }
          icons={
            !!bundle && (
              <BundleCardIcons
                bundle={bundle}
                tooltipMenuIsOpen={tooltipMenuIsOpen}
                closeMenu={() => setTooltipMenuIsOpen(false)}
                handleTooltipMenuClick={handleTooltipMenuClick}
                navItems={navItems}
                handleFavoriteClick={handleFavoriteClick}
              />
            )
          }
          onClose={closeBundleHandler}
        />

        <div className="bg-slate-100 p-3 sm:p-6">
          <Container>
            <TestsContainer>
              {/* Without `&& open`, we fetch favorite lab tests unnecessarily */}
              {Boolean(practitioner?.favorite_lab_test_ids?.length && open) && (
                <Favorites
                  modalPage={labTestLocation.BUNDLES}
                  selectedLabTests={selectedLabTests}
                  handleAdd={handleAdd}
                  handleRemove={handleRemove}
                  handleToggleFavorite={handleToggleLabTestFavorite}
                  openModalWithLabTest={setLabTestInModal}
                  signingPractitioner={practitioner}
                  practitioner={practitioner}
                  practitionerBundles={practitionerBundles}
                  storefrontId={storefrontId}
                />
              )}
              <LabTests
                location={labTestLocation.BUNDLES}
                practitioner={practitioner}
                signingPractitioner={
                  ecommerceOrderingPractitioner
                    ? ecommerceOrderingPractitioner
                    : practitioner
                }
                onAddLabTest={handleAdd}
                onRemoveLabTest={handleRemove}
                selectedLabTests={selectedLabTests}
                order={order}
                vendorPhysicianServicesActivated={
                  usePhysicianAuthorizationForOrderingAccess
                }
                storefrontId={storefrontId}
              />
            </TestsContainer>
            <SidebarContainer>
              <BundleSidebar
                selectedLabTests={selectedLabTests}
                openModalWithLabTest={setLabTestInModal}
                handleRemove={handleRemove}
                handleRemovePanel={handleRemovePanel}
                onClose={handleClose}
                onDelete={() => setBundleConfirmationModalIsOpen(true)}
                bundle={bundle}
                handleBundleChange={() => setHasBundleBeenEdited(true)}
                location={labTestLocation.BUNDLES}
              />
            </SidebarContainer>
          </Container>
        </div>
      </Dialog>
      {labTestInModal && (
        <LabTestModal
          labTest={labTestInModal}
          isOpen={!!labTestInModal}
          practitioner={
            ecommerceOrderingPractitioner
              ? ecommerceOrderingPractitioner
              : practitioner
          }
          signingPractitioner={
            ecommerceOrderingPractitioner
              ? ecommerceOrderingPractitioner
              : practitioner
          }
          onAddLabTest={handleAdd}
          onRemoveLabTest={handleRemove}
          selectedLabTests={selectedLabTests}
          onClose={() => setLabTestInModal(undefined)}
          location={labTestLocation.BUNDLES}
          orderId={order?.id}
        />
      )}
      <ConfirmationModal
        open={bundleConfirmationModalIsOpen}
        message="This will delete this bundle from your account. This action cannot be reversed."
        backButtonTitle="Actually, Let’s Keep It"
        confirmButtonTitle="Delete Bundle"
        onClose={() => setBundleConfirmationModalIsOpen(false)}
        loading={bundleIsSubmitting}
        handleConfirm={handleDeleteBundle}
      />
      <ConfirmationModal
        open={closeBundleConfirmationModalIsOpen}
        message={
          isEmpty(bundle)
            ? "This bundle will not be saved."
            : "You’ll lose any edits you made to this bundle?."
        }
        backButtonTitle="Return to Bundle"
        confirmButtonTitle={isEmpty(bundle) ? "Discard" : "Cancel Edits"}
        confirmButtonColor="destructiveSecondary"
        onClose={() => setCloseBundleConfirmationModalIsOpen(false)}
        loading={bundleIsSubmitting}
        handleConfirm={closeBundleAndBundeConfirmationModal}
      />
    </>
  )
}

const TitleSection = ({ title, onClose, icons }) => {
  const closeButton = onClose && (
    <CloseButton aria-label="close" onClick={onClose} key="close-button">
      <CloseIcon />
    </CloseButton>
  )

  return (
    <MuiDialogTitle
      className="flex justify-between flex-wrap sm:flex-nowrap bg-white border-b border-slate-200 items-center"
      disableTypography
    >
      <TitleAndInfoContainer>
        <DisplayText
          weight="semibold"
          size="lg"
          style={{ display: "flex", width: "-webkit-fill-available" }}
        >
          <div>{title}</div>
          <div style={{ width: "auto", position: "relative", top: -4 }}>
            {icons}
          </div>
        </DisplayText>
        <BodyText size="base">
          Bundles let you save a group of lab tests for easy 1-click ordering,
          right from your Favorites. These can be custom blood panels or a mix
          of tests from different lab companies.
        </BodyText>
      </TitleAndInfoContainer>
      {closeButton}
    </MuiDialogTitle>
  )
}

export default BundleModal
