import { useRef, useState } from "react"
import { useDispatch } from "react-redux"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"

import { styled } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

import DesignSystemButton from "app/components/design-system/Button"
import { FormTextField } from "app/components/forms/fields"
import { Dialog, ModalHeader } from "app/components/modals"
import ConfirmationModal from "app/components/modals/ConfirmationModal"
import * as Actions from "app/store/actions"
import { colors } from "app/theme"
import isEmpty from "lodash/isEmpty"

const ActionsContainer = styled("div")({
  display: "flex",
  padding: "12px 20px",
  justifyContent: "flex-end",
})

const SnippetInfoWrapper = styled("div")({
  backgroundColor: colors.blueGray[100],
  padding: 24,
})

const StyledFormTextField = styled(FormTextField)({
  background: "white",
})

/**
 * Returns the modal for creating or editing a snippet
 * @param snippet - Empty on create
 * @param open
 * @param onClose
 */
const SnippetModal = ({ snippet, open, onClose }) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="snippet-title"
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <Form snippet={snippet} onClose={onClose} />
    </Dialog>
  )
}

export default SnippetModal

function Form({ snippet, onClose }) {
  const formRef = useRef(null)
  const dispatch = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)

  const [snippetConfirmationModalIsOpen, setSnippetConfirmationModalIsOpen] =
    useState(false)

  const handleClose = () => {
    setIsSubmitting(false)
    onClose()
  }

  const stopSubmitting = () => {
    setIsSubmitting(false)
  }

  const closeSnippetAndSnippetConfirmationModal = () => {
    setSnippetConfirmationModalIsOpen(false)
    handleClose()
  }

  const handleBackButtonAction = () => {
    if (isEmpty(snippet)) {
      handleClose()
    } else {
      // Show Delete Modal
      setSnippetConfirmationModalIsOpen(true)
    }
  }

  const formSubmit = (data) => {
    setIsSubmitting(true)

    if (isEmpty(snippet)) {
      // Create
      dispatch(
        Actions.createSnippet(
          data.title,
          data.text,
          handleClose,
          stopSubmitting
        )
      )
    } else {
      // Update
      dispatch(
        Actions.updateSnippet(
          snippet.id,
          data.title,
          data.text,
          handleClose,
          stopSubmitting
        )
      )
    }
  }

  const handleDeleteSnippet = () => {
    setIsSubmitting(true)

    dispatch(
      Actions.deleteSnippet(
        snippet.id,
        closeSnippetAndSnippetConfirmationModal,
        stopSubmitting
      )
    )
  }

  return (
    <>
      <Formsy
        onSubmit={formSubmit}
        ref={formRef}
        onValid={() => setIsFormValid(true)}
        onInvalid={() => setIsFormValid(false)}
      >
        <ModalHeader
          onClose={onClose}
          id="snippet-title"
          title={`${
            isEmpty(snippet) ? "Create" : "Edit"
          } Notes to Patient Snippet`}
        />

        <SnippetInfoWrapper>
          <SnippetInfoFields snippet={snippet} />
        </SnippetInfoWrapper>

        <ActionsContainer>
          <DesignSystemButton
            color={isEmpty(snippet) ? "noaction" : "destructiveSecondary"}
            onClick={handleBackButtonAction}
            className="mr-3"
          >
            {isEmpty(snippet) ? "Cancel" : "Delete Snippet"}
          </DesignSystemButton>
          <DesignSystemButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isFormValid}
            loading={isSubmitting}
          >
            {isEmpty(snippet) ? "Create" : "Save"} Snippet
          </DesignSystemButton>
        </ActionsContainer>
      </Formsy>
      <ConfirmationModal
        open={snippetConfirmationModalIsOpen}
        message="Are you sure you want to delete this snippet? This action cannot be reversed."
        backButtonTitle="Actually, Let’s Keep It"
        confirmButtonTitle="Delete Snippet"
        onClose={() => setSnippetConfirmationModalIsOpen(false)}
        loading={isSubmitting}
        handleConfirm={handleDeleteSnippet}
      />
    </>
  )
}

function SnippetInfoFields({ snippet }) {
  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={12}>
          <FormTextField
            label="Title"
            placeholder="Pause Supplements"
            name="title"
            required={true}
            value={isEmpty(snippet) ? "" : snippet.title}
            validations={{
              maxLength: 25,
            }}
            validationErrors={{
              maxLength: "Title is too long, please use a shorter title.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <StyledFormTextField
            label="Note to Your Patient"
            placeholder="Remember to stop taking your supplements 2 weeks before completing this test."
            multiline={true}
            rows={2}
            name="text"
            required={true}
            value={isEmpty(snippet) ? "" : snippet.text}
            validations={{
              maxLength: 2000,
            }}
            validationErrors={{
              maxLength: "Text is too long, please use a shorter text.",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
