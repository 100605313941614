import React, { useEffect } from "react"

import { ReactComponent as TestTubesIcon } from "app/assets/icons/test-tubes.svg"
import { VENDOR_PHYSICIAN_AUTHORIZATION_LABEL } from "app/constants"
import InOfficeKitsToggle from "app/main/settings/components/InOfficeKitsToggle"
import { trackIOKOnboardingLearnMoreClicked } from "app/services/segment"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import { MarkAsComplete } from "../MarkAsCompleteButton"
import TaskContainer from "../TaskContainer"
import taskStyles from "../TaskStyles"
import { OnboardingTaskItemProps } from "./OnboardingTaskItem"

const useStyles = makeAppStyles((theme) => ({
  subheaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 15,
    backgroundColor: colors.blue[50],
  },
  toggleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  linkText: {
    color: theme.palette.primary.main,
  },
}))

const InOfficeKits: React.FC<OnboardingTaskItemProps> = ({
  task,
  markTaskAsComplete,
  onTaskComplete,
  practitioner,
}) => {
  const classes = useStyles()
  const taskClasses = taskStyles()

  useEffect(() => {
    if (
      practitioner.clinic.clinic_features.includes("allow_in_office_kits") &&
      !task.complete
    ) {
      onTaskComplete(task.key)
    }
  }, [practitioner, task, onTaskComplete])

  const subCardContent = (
    <div className={classes.subheaderContainer}>
      <MarkAsComplete onClick={() => markTaskAsComplete(task.key)} />
    </div>
  )

  return (
    <TaskContainer
      taskKey={task.key}
      header="Stock Kits In-Office"
      isTaskComplete={task.complete}
      timeTag={"30 Secs"}
      subCardContent={subCardContent}
    >
      <TestTubesIcon
        className={taskClasses.physServicesIcon}
        style={{ marginTop: 5 }}
      />
      <div className={taskClasses.taskTextContainer}>
        <div className={taskClasses.taskText}>
          Rupa can manage your orders for kits handed out in person, or blood
          draws done in-house.
        </div>
        <div className={taskClasses.taskText} style={{ fontWeight: 400 }}>
          We'll handle ordering your supplies, managing your billing, and your
          patient's requisitions. Please note, In-Office Kits can't be ordered
          with {VENDOR_PHYSICIAN_AUTHORIZATION_LABEL}.{" "}
          <a
            href="https://www.rupahealth.com/in-office-kits"
            target="_blank"
            rel="noreferrer"
            className={classes.linkText}
            onClick={() => trackIOKOnboardingLearnMoreClicked(practitioner.id)}
          >
            Learn more
          </a>
        </div>
      </div>
      <div className={classes.toggleContainer}>
        <InOfficeKitsToggle
          name="in_office_kits"
          onSuccess={(clinic) => {
            if (
              clinic?.attributes.clinic_features.includes(
                "allow_in_office_kits"
              )
            ) {
              onTaskComplete(task.key)
            }
          }}
        />
        <div className={taskClasses.taskText} style={{ padding: 0 }}>
          Stock Kits In-Office
        </div>
      </div>
    </TaskContainer>
  )
}

export default InOfficeKits
