import ResultsExpectedDate from "app/components/StatusComponents/ResultsExpectedDate"
import { ORDERED_TEST_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-portal-types"
import { PROGRESS_PERCENTAGES } from "../consts"

const Title = ({ orderedTest }: StatusConfigurationProps) => {
  if (orderedTest.attributes.date_sample_received_at_lab) {
    return (
      <>{`Sample Arrived at Lab on ${formatDateForHuman(
        orderedTest.attributes.date_sample_received_at_lab
      )}`}</>
    )
  }

  return <>Sample Arrived at Lab</>
}

const SubTitle = ({
  orderedTest,
  labCompany,
  labCompanyAlert,
}: StatusConfigurationProps) => {
  if (orderedTest.attributes.estimated_results_date) {
    return (
      <ResultsExpectedDate
        dateResultsExpectedFromLab={
          orderedTest.attributes.estimated_results_date
        }
        resultsDelayTitle={labCompanyAlert?.attributes?.title}
        resultsDelaySubtitle={labCompanyAlert?.attributes?.subtitle}
        resultsDelayReason={orderedTest.attributes.results_delayed_reason}
        labProvidesResultsEta={labCompany?.attributes.provides_results_eta}
        labCompanyName={labCompany?.attributes.short_name}
      />
    )
  }
  return (
    <>{`${
      labCompany?.attributes.short_name || "The lab"
    } doesn't share estimates for when results will come in.`}</>
  )
}

const sampleAtLabStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { orderedTestStatus: ORDERED_TEST_STATUS.AT_LAB },
    progressPercentage: PROGRESS_PERCENTAGES.AT_LAB,
    title: Title,
    subTitle: SubTitle,
  }

export default sampleAtLabStatusConfiguration
