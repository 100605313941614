import { useState } from "react"
import { useDispatch } from "react-redux"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import {
  PANEL_BUILDER_TRACKING_EVENTS,
  trackPanelBuilderEvent,
} from "app/services/segment"
import { fetchPractitionerBundles } from "app/store/actions/practitionerBundles.actions"
import { shareBundleWithClinic } from "app/store/actions/shareBundles.actions"
import { handleApiError, handleApiSuccess } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"
import { BiomarkerIdentifier } from "types/biomarker"

import { createPanel, replacePanel } from "../panel-actions"
import { Panel } from "../types"
import useNamePanelModal from "../use-name-panel-modal"
import PanelDetails from "./PanelDetails/PanelDetails"
import PanelItemBiomarkers from "./PanelItemBiomarkers"
import PanelItemCreatePanel from "./PanelItemCreatePanel"
import PanelItemDetailsButton from "./PanelItemDetailsButton"
import PanelItemLabCompany from "./PanelItemLabCompany"
import PanelItemPricing from "./PanelItemPricing"

const useStyles = makeAppStyles((theme) => ({
  container: {
    minHeight: 100,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    padding: "10px 20px",
    alignItems: "center",
    gap: "42px",
    [theme.breakpoints.down("md")]: {
      gap: "24px",
    },
    alignSelf: "stretch",
    cursor: "pointer",
  },
}))

export default function PanelItem({
  panel,
  combinedSelectedBiomarkerIdentifiers,
  selectedBiomarkerIds,
  selectedBiomarkerGroupingIds,
  existingBundleId,
  existingBundleName,
  existingPanelLabCompanyKey,
  onClose,
  isDirty,
}: {
  panel?: Panel
  combinedSelectedBiomarkerIdentifiers: BiomarkerIdentifier[]
  selectedBiomarkerIds: string[]
  selectedBiomarkerGroupingIds: string[]
  existingBundleId?: number
  existingBundleName?: string
  existingPanelLabCompanyKey?: string
  onClose?: () => void
  isDirty?: boolean
}) {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))
  const dispatch = useDispatch()
  const namePanelModal = useNamePanelModal()

  const [detailsOpen, setDetailsOpen] = useState(false)

  async function onSubmit(name: string, shareWithClinic: boolean) {
    try {
      if (panel) {
        if (existingBundleId) {
          replacePanel(
            existingBundleId,
            name,
            panel?.lab_tests.map((lab_test) => lab_test.id),
            selectedBiomarkerIds,
            selectedBiomarkerGroupingIds
          ).then(([createPanelResponse, deletePanelResponse]) => {
            if (shareWithClinic) {
              dispatch(
                shareBundleWithClinic(createPanelResponse.data.id, (bundle) => {
                  dispatch(fetchPractitionerBundles())
                })
              )
            } else {
              // Fetch bundles to repopulate bundles elsewhere in the app redux state
              dispatch(fetchPractitionerBundles())
            }
            dispatch(handleApiSuccess("Panel updated"))
          })
        } else {
          trackPanelBuilderEvent(
            PANEL_BUILDER_TRACKING_EVENTS.PANEL_BUILDER_FINALIZE_CREATE_PANEL,
            {}
          )
          createPanel(
            name,
            panel?.lab_tests.map((lab_test) => lab_test.id),
            selectedBiomarkerIds,
            selectedBiomarkerGroupingIds
          ).then((response) => {
            // Fetch bundles to repopulate bundles elsewhere in the app redux state
            if (shareWithClinic) {
              dispatch(
                shareBundleWithClinic(response.data.id, (bundle) => {
                  dispatch(fetchPractitionerBundles())
                })
              )
            } else {
              dispatch(fetchPractitionerBundles())
            }
            dispatch(handleApiSuccess("Panel created"))
          })
        }
      }
      onClose?.()
      namePanelModal.remove()
    } catch (error) {
      dispatch(handleApiError(error))
    }
  }

  function handleCreate() {
    trackPanelBuilderEvent(
      PANEL_BUILDER_TRACKING_EVENTS.PANEL_BUILDER_CREATE_PANEL,
      {}
    )
    namePanelModal.show({
      onClose: () => {
        namePanelModal.remove()
      },
      onSubmit: (name, shareWithClinic) => {
        onSubmit(name, shareWithClinic)
      },
      existingName: existingBundleName,
    })
  }

  return (
    <>
      <div
        className={classes.container}
        // The whole container has onClick, so the Details button doesn't need it
        onClick={() => setDetailsOpen((prev) => !prev)}
      >
        <PanelItemLabCompany
          panel={panel}
          isSelectedLabCompany={
            existingPanelLabCompanyKey === panel?.lab_company?.key
          }
        />
        {!isMobile && (
          <PanelItemBiomarkers
            panel={panel}
            combinedSelectedBiomarkerIdentifiers={
              combinedSelectedBiomarkerIdentifiers
            }
          />
        )}
        <PanelItemPricing
          panel={panel}
          combinedSelectedBiomarkerIdentifiers={
            combinedSelectedBiomarkerIdentifiers
          }
        />
        {isMobile && (
          <PanelItemBiomarkers
            panel={panel}
            combinedSelectedBiomarkerIdentifiers={
              combinedSelectedBiomarkerIdentifiers
            }
          />
        )}
        <PanelItemDetailsButton isLoading={!panel} detailsOpen={detailsOpen} />
        <PanelItemCreatePanel
          onClick={handleCreate}
          isLoading={!panel}
          alreadyExists={existingBundleId !== undefined}
          isBlocked={
            existingPanelLabCompanyKey === panel?.lab_company?.key && !isDirty
          }
        />
      </div>
      {detailsOpen && (
        <PanelDetails
          panel={panel}
          combinedSelectedBiomarkerIdentifiers={
            combinedSelectedBiomarkerIdentifiers
          }
        />
      )}
    </>
  )
}
