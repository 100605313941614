import { useRef, useState } from "react"
import { useDispatch } from "react-redux"

// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"

import { styled } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import DisplayText from "app/components/design-system/DisplayText"
import { FormTextField, SelectFormField } from "app/components/forms/fields"
import { Dialog } from "app/components/modals"
import ConfirmationModal from "app/components/modals/ConfirmationModal"
import { US_STATES } from "app/main/patient-checkout/utils/usStates"
import * as Actions from "app/store/actions"
import { colors, navy } from "app/theme"
import isEmpty from "lodash/isEmpty"

const ActionsContainer = styled("div")({
  display: "flex",
  padding: "12px 20px",
  justifyContent: "flex-end",
})

const PhlebotomistInfoWrapper = styled("div")({
  backgroundColor: colors.blueGray[100],
  padding: 24,
})

const TitleAndInfoContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  marginRight: 24,
})

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: navy,
  position: !theme.breakpoints.down("sm") ? "absolute" : "relative",
  top: 1,
  right: !theme.breakpoints.down("sm") ? 12 : 0,
  marginRight: -12,
}))

const StyledMuiDialogTitle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: !theme.breakpoints.down("sm") ? "wrap" : "nowrap",
  backgroundColor: "white",
  borderBottomWidth: 1,
  borderColor: colors.blueGray[200],
  alignItems: "center",
  padding: "16px 24px",
}))

const StyledFormTextField = styled(FormTextField)({
  background: "white",
})

/**
 * Returns the modal for creating or editing a phlebotomist
 * @param phlebotomist - Empty on create
 * @param open
 * @param onClose
 */
const PhlebotomistModal = ({ phlebotomist, open, onClose }) => {
  const formRef = useRef(null)
  const dispatch = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)

  const [
    phlebotomistConfirmationModalIsOpen,
    setPhlebotomistConfirmationModalIsOpen,
  ] = useState(false)

  const [
    closePhlebotomistConfirmationModalIsOpen,
    setClosePhlebotomistConfirmationModalIsOpen,
  ] = useState(false)

  const handleClose = () => {
    setIsSubmitting(false)
    onClose()
  }

  const handleCloseModal = () => {
    if (isEmpty(phlebotomist)) {
      onClose()
    } else {
      setClosePhlebotomistConfirmationModalIsOpen(true)
    }
  }

  const handleBackButtonAction = () => {
    if (isEmpty(phlebotomist)) {
      handleClose()
    } else {
      // Show Delete Modal
      setPhlebotomistConfirmationModalIsOpen(true)
    }
  }

  const closePhlebotomistAndPhlebotomistConfirmationModal = () => {
    setPhlebotomistConfirmationModalIsOpen(false)
    handleClose()
  }

  const closePhlebotomistAndClosePhlebotomistConfirmationModal = () => {
    setClosePhlebotomistConfirmationModalIsOpen(false)
    handleClose()
  }

  const formSubmit = (data) => {
    setIsSubmitting(true)

    if (isEmpty(phlebotomist)) {
      // Create
      dispatch(
        Actions.createPhlebotomist(
          data.name,
          data.url,
          data.location_types === "both"
            ? ["clinic", "mobile"]
            : [data.location_types],
          data.email,
          data.phone,
          data.address_street,
          data.address_city,
          data.address_state,
          data.address_zip,
          data.hours,
          data.fee,
          data.external_notes,
          handleClose
        )
      )
    } else {
      // Update
      dispatch(
        Actions.updatePhlebotomist(
          phlebotomist.id,
          data.name,
          data.url,
          data.location_types === "both"
            ? ["clinic", "mobile"]
            : [data.location_types],
          data.email,
          data.phone,
          data.address_street,
          data.address_city,
          data.address_state,
          data.address_zip,
          data.hours,
          data.fee,
          data.external_notes,
          handleClose
        )
      )
    }
  }

  const handleDeletePhlebotomist = () => {
    setIsSubmitting(true)

    dispatch(
      Actions.deletePhlebotomist(
        phlebotomist.id,
        closePhlebotomistAndPhlebotomistConfirmationModal
      )
    )
  }

  return (
    <>
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="phlebotomist-title"
        open={open}
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
      >
        <Form
          phlebotomist={phlebotomist}
          onClose={handleCloseModal}
          handleBackButtonAction={handleBackButtonAction}
          formRef={formRef}
          isSubmitting={isSubmitting}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
          formSubmit={formSubmit}
        />
      </Dialog>
      <ConfirmationModal
        open={phlebotomistConfirmationModalIsOpen}
        message="This will delete this phlebotomist from your patient’s recommendations. This action cannot be reversed."
        backButtonTitle="Actually, Let’s Keep It"
        confirmButtonTitle="Remove Phlebotomist"
        onClose={() => setPhlebotomistConfirmationModalIsOpen(false)}
        loading={isSubmitting}
        handleConfirm={handleDeletePhlebotomist}
      />
      <ConfirmationModal
        open={closePhlebotomistConfirmationModalIsOpen}
        message="You’ll lose any edits you made to this phlebotomist."
        backButtonTitle="Keep Editing"
        confirmButtonTitle="Discard Edits"
        confirmButtonColor="destructiveSecondary"
        onClose={() => setClosePhlebotomistConfirmationModalIsOpen(false)}
        handleConfirm={closePhlebotomistAndClosePhlebotomistConfirmationModal}
      />
    </>
  )
}

export default PhlebotomistModal

function Form({
  phlebotomist,
  onClose,
  handleBackButtonAction,
  formRef,
  isSubmitting,
  isFormValid,
  setIsFormValid,
  formSubmit,
}) {
  return (
    <Formsy
      onSubmit={formSubmit}
      ref={formRef}
      onValid={() => setIsFormValid(true)}
      onInvalid={() => setIsFormValid(false)}
    >
      <TitleSection phlebotomist={phlebotomist} onClose={onClose} />

      <PhlebotomistInfoWrapper>
        <PhlebotomistInfoFields phlebotomist={phlebotomist} />
      </PhlebotomistInfoWrapper>

      <ActionsContainer>
        <DesignSystemButton
          color={isEmpty(phlebotomist) ? "noaction" : "secondary"}
          onClick={handleBackButtonAction}
          className={isEmpty(phlebotomist) ? "mr-12" : "text-red-700 mr-12"}
        >
          {isEmpty(phlebotomist) ? "Cancel" : "Remove Phlebotomist"}
        </DesignSystemButton>
        <DesignSystemButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isFormValid}
          loading={isSubmitting}
        >
          {isEmpty(phlebotomist) ? "Add Phlebotomist" : "Save"}
        </DesignSystemButton>
      </ActionsContainer>
    </Formsy>
  )
}

const TitleSection = ({ phlebotomist, onClose }) => {
  const closeButton = onClose && (
    <CloseButton aria-label="close" onClick={onClose} key="close-button">
      <CloseIcon />
    </CloseButton>
  )

  return (
    <StyledMuiDialogTitle>
      <TitleAndInfoContainer>
        <DisplayText weight="semibold" size="lg">
          {isEmpty(phlebotomist) ? "Add a " : "Edit "}Preferred Phlebotomist
        </DisplayText>
        <BodyText size="base">
          We’ll set this as a default recommendation for your clinic.
        </BodyText>
      </TitleAndInfoContainer>
      {closeButton}
    </StyledMuiDialogTitle>
  )
}

const PhlebotomistInfoFields = ({ phlebotomist }) => {
  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Phlebotomist or Clinic Name"
            name="name"
            required={true}
            value={isEmpty(phlebotomist) ? "" : phlebotomist.name}
            validations={{
              maxLength: 60,
            }}
            validationErrors={{
              maxLength: "Name is too long, please use a shorter name.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Website Link"
            name="url"
            required={false}
            value={isEmpty(phlebotomist) ? "" : phlebotomist.url}
            validations={{
              isUrl: true,
              maxLength: 100,
            }}
            validationErrors={{
              isUrl:
                "Please enter a valid url starting with https:// or http://",
              maxLength: "Url is too long, please use a shorter name.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectFormField
            label="Clinic, Mobile, or Both?"
            name="location_types"
            required={true}
            value={
              isEmpty(phlebotomist)
                ? ""
                : phlebotomist.location_types.length === 2
                ? "both"
                : phlebotomist.location_types[0]
            }
          >
            <option value="clinic">Clinic</option>
            <option value="mobile">Mobile</option>
            <option value="both">Both</option>
          </SelectFormField>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Email"
            name="email"
            required={false}
            value={isEmpty(phlebotomist) ? "" : phlebotomist.email}
            validations={{
              isEmail: true,
              maxLength: 60,
            }}
            validationErrors={{
              isEmail: "Please enter a valid email.",
              maxLength: "Email is too long, please use a shorter email.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Phone Number"
            name="phone"
            required={false}
            value={isEmpty(phlebotomist) ? "" : phlebotomist.phone}
            validations={{
              maxLength: 20,
            }}
            validationErrors={{
              maxLength:
                "Phone number is too long, please use a shorter phone number.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Street Address"
            name="address_street"
            required={false}
            value={isEmpty(phlebotomist) ? "" : phlebotomist.address_street}
            validations={{
              maxLength: 100,
            }}
            validationErrors={{
              maxLength:
                "Street address is too long, please use a shorter street address.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="City"
            name="address_city"
            required={false}
            value={isEmpty(phlebotomist) ? "" : phlebotomist.address_city}
            validations={{
              maxLength: 50,
            }}
            validationErrors={{
              maxLength: "City is too long, please use a shorter city.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectFormField
            label="State"
            name="address_state"
            value={isEmpty(phlebotomist) ? "" : phlebotomist.address_state}
            required={false}
          >
            {Object.entries(US_STATES).map(([code, name]) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </SelectFormField>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            label="Zip Code"
            name="address_zip"
            required={true}
            value={isEmpty(phlebotomist) ? "" : phlebotomist.address_zip}
            validations={{
              isLength: 5,
            }}
            validationErrors={{
              isLength: "Please enter a valid zip code.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormTextField
            label="Hours"
            placeholder="Example: 9am-3pm M-F"
            name="hours"
            required={false}
            value={isEmpty(phlebotomist) ? "" : phlebotomist.hours}
            validations={{
              maxLength: 200,
            }}
            validationErrors={{
              maxLength: "Hours is too long, please use a shorter hours.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormTextField
            label="Fee"
            placeholder="Example: $50 for one kit, $75 for two kits."
            name="fee"
            required={false}
            value={isEmpty(phlebotomist) ? "" : phlebotomist.fee}
            validations={{
              maxLength: 200,
            }}
            validationErrors={{
              maxLength: "Fee is too long, please use a shorter fee.",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <StyledFormTextField
            label="Notes that you want your patient to see"
            placeholder="Example: The phlebotomist's name is Stacy. You can call or text her to set up an appointment directly. Don't forget to bring your kit!"
            multiline={true}
            rows={2}
            name="external_notes"
            required={false}
            value={isEmpty(phlebotomist) ? "" : phlebotomist.external_notes}
            validations={{
              maxLength: 500,
            }}
            validationErrors={{
              maxLength: "Notes is too long, please use a shorter notes.",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
