import { useParams } from "react-router-dom"

import { Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import DisplayText from "app/components/design-system/DisplayText"
import { UserClinicAccessConfiguration } from "types/user-clinic-access-configuration"

import Card from "../Card"
import PatientPortalLabResultsAccessDocumentCard from "./components/PatientPortalLabResultsAccessDocumentCard"
import PatientPortalLabResultsAccessDocumentEmptyCard from "./components/PatientPortalLabResultsAccessDocumentEmptyCard"
import useUserClinicAccessConfigurations from "./hooks/use-user-clinic-access-configurations"

const PatientPortalLabResultsAccess = () => {
  const { userId } = useParams<{
    userId: string
  }>()
  const { userClinicAccessConfigurations, isLoading } =
    useUserClinicAccessConfigurations(userId)

  return (
    <Card className="pt-6 w-full h-full gap-2">
      <DisplayText size="lg" weight="semibold">
        Lab Results Access
      </DisplayText>
      <DisplayText>
        Manage the clinics that have access to your data.
      </DisplayText>
      <div className="bg-gray-200 h-[1px] w-full"></div>
      {!isLoading && userClinicAccessConfigurations.length === 0 && (
        <PatientPortalLabResultsAccessDocumentEmptyCard />
      )}
      <Grid container spacing={2}>
        {isLoading &&
          Array.from({ length: 4 }).map((_, index) => (
            <Grid item xs={12} sm={12} md={12} lg={6} key={index}>
              <Skeleton
                animation="wave"
                component="div"
                height={100}
                width="100%"
                variant="text"
                className="rounded-2xl"
              />
            </Grid>
          ))}

        {!isLoading &&
          userClinicAccessConfigurations.length > 0 &&
          userClinicAccessConfigurations.map(
            (
              userClinicAccessConfiguration: UserClinicAccessConfiguration,
              index: number
            ) => (
              <Grid item xs={12} sm={12} md={12} lg={6} key={index}>
                <PatientPortalLabResultsAccessDocumentCard
                  userClinicAccessConfiguration={userClinicAccessConfiguration}
                />
              </Grid>
            )
          )}
      </Grid>
    </Card>
  )
}

export default PatientPortalLabResultsAccess
