import { InputAdornment, makeStyles } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"

import InfoCircleIcon from "app/assets/images/info-circle-dark.svg"
import Tooltip from "app/components/Tooltip"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import TextField from "app/components/forms/TextFieldComponent"
import { PHYSICIAN_SERVICES_FEE_AMOUNT } from "app/constants"
import { cancelRed, colors, primaryColor, rupaTeal } from "app/theme"
import { formatDollars } from "app/utils"

import { MAX_CUSTOM_FEE_PRICE_LAB_SHOPS } from "../constants"

const useStyles = makeStyles((theme) => ({
  customFeeObject: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "flex-start",
    gap: 3,
  },
  customFeeFormContainer: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "flex-start",
    gap: 29,
    width: "100%",
  },
  customFeeFormSection: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "flex-start",
    gap: 10,
    flexGrow: 1,
  },
  inputContainer: {
    "& .MuiOutlinedInput-root": {
      paddingLeft: 0,
      borderColor: colors.blueGray[300],
      marginTop: -8,
      width: "100%",
    },
    "& .MuiInputAdornment-root": {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      background: colors.blueGray[100],
      color: colors.blueGray[400],
      padding: "19px 12px",
      borderRight: `1px solid ${colors.blueGray[400]}`,
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.blueGray[400],
    },
  },
  adornmentText: {
    color: colors.blueGray[400],
    fontWeight: 600,
    fontSize: 15,
  },
  customFeeDeleteBtn: {
    marginRight: 16,
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  errorText: {
    color: cancelRed,
  },
}))

interface Props {
  customFeeName: string
  customFeePrice: number
  priceWithoutCustomFee: number
  removeCustomFee: () => void
  updateCustomFeeAttributes: (name: string, price: number) => void
  loading: boolean
  customFeeNameError: string
  customFeePriceError: string
  hidePhysServicesPricing: boolean
}

const CustomFeeForm = ({
  customFeeName,
  customFeePrice,
  priceWithoutCustomFee,
  removeCustomFee,
  updateCustomFeeAttributes,
  loading,
  customFeeNameError,
  customFeePriceError,
  hidePhysServicesPricing,
}: Props) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.customFeeObject}>
        <div className={classes.customFeeFormContainer}>
          <div className={classes.customFeeFormSection}>
            <BodyText weight="semibold">
              Test Price
              {!hidePhysServicesPricing && (
                <Tooltip
                  arrow
                  interactive
                  placement="top"
                  title={`This price includes a $${PHYSICIAN_SERVICES_FEE_AMOUNT}/kit Physician Services fee`}
                >
                  <img
                    src={InfoCircleIcon}
                    alt="info circle"
                    style={{ marginLeft: 3 }}
                  />
                </Tooltip>
              )}
            </BodyText>
            <BodyText size="md" weight="semibold">
              {formatDollars(priceWithoutCustomFee)}
            </BodyText>
          </div>
          <div className={classes.customFeeFormSection} style={{ gap: 4 }}>
            <BodyText weight="semibold">Custom Fee </BodyText>
            <TextField
              value={customFeePrice > 0 ? customFeePrice / 100 : null}
              placeholder="Custom Fee Price"
              name="price"
              type="number"
              margin="dense"
              variant="outlined"
              fullWidth
              className={classes.inputContainer}
              InputProps={{
                inputProps: { max: MAX_CUSTOM_FEE_PRICE_LAB_SHOPS },
                startAdornment: (
                  <InputAdornment position="start">
                    <div className={classes.adornmentText}>$</div>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                const value = +e.target.value ?? 0

                updateCustomFeeAttributes(
                  customFeeName,
                  Math.round(value * 100)
                )
              }}
            />
            {customFeePriceError !== "" && (
              <BodyText className={classes.errorText}>
                {customFeePriceError}
              </BodyText>
            )}
          </div>
          <div className={classes.customFeeFormSection}>
            <BodyText weight="semibold">Price on LabShop</BodyText>
            <BodyText size="md" weight="semibold" style={{ color: rupaTeal }}>
              {formatDollars(priceWithoutCustomFee + customFeePrice / 100)}
            </BodyText>
          </div>
          <div className={classes.customFeeFormSection} style={{ gap: 4 }}>
            <BodyText weight="semibold">
              Your clients will see this fee title{" "}
              <Tooltip
                arrow
                interactive
                placement="top"
                title="Examples: 1:1 visit, Interpretation, Educational Resource."
              >
                <img
                  src={InfoCircleIcon}
                  alt="info circle"
                  style={{ marginLeft: 3 }}
                />
              </Tooltip>
            </BodyText>
            <TextField
              value={customFeeName}
              onChange={(e) =>
                updateCustomFeeAttributes(e.target.value, customFeePrice)
              }
              placeholder="Fee title"
              name="name"
              type="text"
              margin="dense"
              variant="outlined"
              fullWidth
              className={classes.inputContainer}
            />
            {customFeeNameError !== "" && (
              <BodyText className={classes.errorText}>
                {customFeeNameError}
              </BodyText>
            )}
          </div>
        </div>
      </div>
      <div>
        <DesignSystemButton
          color="text"
          startIcon={
            <DeleteIcon
              fontSize="small"
              style={{
                color: primaryColor,
                position: "relative",
                top: "2px",
              }}
            />
          }
          onClick={removeCustomFee}
          className={classes.customFeeDeleteBtn}
          loading={loading}
        >
          Remove Custom Fee
        </DesignSystemButton>
      </div>
    </>
  )
}

export default CustomFeeForm
