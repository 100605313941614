import { useState } from "react"

import { makeStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import CloseIcon from "@material-ui/icons/Close"

import TextField from "app/components/forms/TextFieldComponent"

const styles = (theme) => ({
  root: {
    minWidth: 380,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#333",
  },
})

const useStyles = makeStyles(styles)

export default function PasswordResetModal({ open, onClose, onResetPassword }) {
  const classes = useStyles(styles)
  const [email, setEmail] = useState("")

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && isEmail(email)) {
      e.preventDefault()
      handleResetPassword()
    }
  }

  const handleResetPassword = () => {
    onResetPassword(email)
    setEmail("")
    onClose()
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="password-reset-modal-title"
      open={open}
      className={classes.root}
    >
      <Title onClose={onClose} id="password-reset-modal-title">
        Request Password Reset
      </Title>
      <Content>
        <div className="flex items-center justify-between">
          <div className="w-24">
            <Typography className="text-gray-800 text-base15 font-semibold">
              Email
            </Typography>
          </div>
          <TextField
            autoFocus
            className="bg-gray-100 rounded border-0 w-80 fs-exclude"
            placeholder="Enter your email address"
            value={email}
            error={!!email && !isEmail(email)}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            margin="dense"
            variant="outlined"
          />
        </div>
      </Content>
      <Actions>
        <Button
          disabled={!isEmail(email)}
          autoFocus
          onClick={handleResetPassword}
          color="primary"
          variant="contained"
        >
          Request reset <ChevronRightIcon />
        </Button>
      </Actions>
    </Dialog>
  )
}

const Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className="bg-gray-100" {...other}>
      <Typography className="font-semibold text-base">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    background: "rgba(240, 242, 245, 0.6)",
  },
}))(MuiDialogActions)

function isEmail(email) {
  return /\S+@\S+\.\S+/.test(email)
}
