export enum ClinicInputFieldNames {
  ClinicName = "clinic_name",
  ClinicStreet = "clinic_street",
  ClinicCity = "clinic_city",
  ClinicProvinceRegion = "clinic_province_region",
  ClinicState = "clinic_state",
  ClinicCountry = "clinic_country",
  ClinicZipcode = "clinic_zipcode",
  ClinicSpecimenIssueEmailRecipient = "clinic_specimen_issue_email_recipient",
}

export type SpecimenIssueEmailRecipientId =
  | "patient_and_practitioners"
  | "practitioners_only"

export const SPECIMEN_ISSUE_EMAIL_RECIPIENT_CHOICES: {
  id: SpecimenIssueEmailRecipientId
  name: string
}[] = [
  { id: "patient_and_practitioners", name: "Patient and Practitioner(s)" },
  { id: "practitioners_only", name: "Practitioner's Point of Contact Only" },
]

export const DEFAULT_SPECIMEN_ISSUE_EMAIL_RECIPIENT =
  "patient_and_practitioners"

export const NJ_NY_RI_RESTRICTIONS_URL =
  "https://help.rupahealth.com/en/articles/8067351-what-can-i-order-for-patients-who-live-in-new-york-new-jersey-or-rhode-island"
