import { memo, useEffect, useState } from "react"

import classNames from "classnames"

import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { IconButton, Theme, useMediaQuery } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"

import DragHandleIcon from "app/assets/images/storefront/drag-handle.svg"
import TextField from "app/components/forms/TextFieldComponent"
import { StorefrontSectionType } from "app/hooks/use-resource"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

const styles = (theme: Theme) => ({
  sectionContainer: {
    width: "100%",
    background: "#fff",
    padding: "18px 15px",
    borderRadius: 8,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    display: "flex",
    "flex-direction": "column",
    gap: 16,
    height: "100%",
  },
  transparentSectionContainer: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    gap: 16,
    height: "100%",
  },
  sectionHeader: {
    width: "100%",
    display: "flex",
    "flex-direction": "row",

    justifyContent: "space-between",
  },
  sectionHeaderText: {
    display: "flex",
    "flex-direction": "row",
    gap: 10,
  },
  inputStyleTitle: {
    "& .MuiInputBase-input": {
      border: "none",
      width: "100%",
      padding: "3px 6px",
      fontSize: 15,
      fontWeight: 600,
      borderRadius: 5,
    },
    "& .MuiInputBase-input:focus": {
      background: colors.blueGray[100],
    },
    "& .MuiInputBase-input:hover": {
      background: colors.blueGray[100],
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  inputStyleDescription: {
    "& .MuiInputBase-input": {
      border: "none",
      width: "100%",
      padding: "3px 6px",
      fontSize: 13,
      borderRadius: 5,
    },
    "& .MuiInputBase-input:focus": {
      background: colors.blueGray[100],
    },
    "& .MuiInputBase-input:hover": {
      background: colors.blueGray[100],
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
})

const useStyles = makeAppStyles(styles)

export interface SectionData {
  id: string
  name: string
  description: string
  products?: any[]
}

interface Props {
  id: string
  items: {
    type: "storefront_product"
    id: string
  }[]
  section: StorefrontSectionType
  children: any
  openRemoveSectionModal: (sectionId: string) => void
  updateSection: (sectionData: SectionData) => void
}

const SortableSection = memo(
  ({
    id,
    items,
    section,
    openRemoveSectionModal,
    updateSection,
    ...props
  }: Props) => {
    const classes = useStyles()

    const [cardHovered, setCardHovered] = useState(false)
    const [sectionTitle, setSectionTitle] = useState(section.attributes.name)
    const [sectionDescription, setSectionDescription] = useState(
      section.attributes.description
    )

    const isMobile = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("sm")
    )

    const animateLayoutChanges = (args) =>
      defaultAnimateLayoutChanges({ ...args, wasDragging: true })
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({
        id: id,
        data: {
          type: "container",
          children: items,
        },
        animateLayoutChanges,
      })

    useEffect(() => {
      // Only send API update if title or description haven't been changed for 2 seconds
      const timeOutId = setTimeout(() => {
        if (
          sectionTitle !== section.attributes.name ||
          sectionDescription !== section.attributes.description
        ) {
          updateSection({
            id: section.id,
            name: sectionTitle,
            description: sectionDescription,
          })
        }
      }, 2000)
      return () => clearTimeout(timeOutId)
    }, [sectionTitle, sectionDescription])

    return (
      <div
        ref={setNodeRef}
        className={
          section.attributes.is_transparent
            ? classes.transparentSectionContainer
            : classes.sectionContainer
        }
        style={{ transform: CSS.Transform.toString(transform), transition }}
        onMouseEnter={() => !isMobile && setCardHovered(true)}
        onMouseLeave={() => !isMobile && setCardHovered(false)}
      >
        {!section.attributes.is_transparent && (
          <div className={classes.sectionHeader}>
            <div className={classes.sectionHeaderText}>
              <div {...attributes} {...listeners}>
                <img
                  src={DragHandleIcon}
                  style={{ position: "relative", top: 3 }}
                  alt="drag handle"
                />
              </div>
              <div>
                <TextField
                  value={sectionTitle}
                  placeholder="Enter a title here"
                  name="title"
                  type="text"
                  variant="outlined"
                  fullWidth
                  className={classes.inputStyleTitle}
                  onChange={(e) => setSectionTitle(e.target.value)}
                />
                <TextField
                  value={sectionDescription}
                  placeholder="Enter a description here"
                  name="description"
                  type="text"
                  variant="outlined"
                  fullWidth
                  className={classes.inputStyleDescription}
                  onChange={(e) => setSectionDescription(e.target.value)}
                />
              </div>
            </div>
            {!section.attributes.is_transparent && (cardHovered || isMobile) && (
              <div>
                <IconButton
                  className={classNames(
                    "hover:bg-gray-200",
                    "p-1 rounded-4 w-24"
                  )}
                  onClick={() => openRemoveSectionModal(section.id)}
                  aria-label="Test delete"
                >
                  <DeleteIcon
                    fontSize="small"
                    style={{
                      color: colors.blueGray[400],
                    }}
                  />
                </IconButton>
              </div>
            )}
          </div>
        )}

        {props.children}
      </div>
    )
  }
)

export default SortableSection
