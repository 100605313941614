import { useMemo } from "react"

import { IconButton } from "@material-ui/core"
import FavoriteIcon from "@material-ui/icons/Favorite"

import lockIcon from "app/assets/images/lock.svg"
import SampleTypePill from "app/components/SampleTypePill"
import Button from "app/components/design-system/Button"
import { QUEST_COMPANY_KEYS } from "app/constants"
import { ComboGroupConfigurationPayload } from "app/main/combo-groups/constants"
import { colors, favoritedColor, shadows } from "app/theme"
import {
  AnyLimitedLabTest,
  Practitioner,
  SigningPractitioner,
  labTestLocation,
} from "app/types"
import makeAppStyles from "app/utils/makeAppStyles"

import BodyText from "../design-system/BodyText"
import LabTestButtons from "./LabTestButtons"
import PendingPlaceholder from "./PendingPlaceholder"
import { SelectedLabTests } from "./types"

const useStyles = makeAppStyles<{
  isFavorited: boolean
  disabledStyle: boolean
}>({
  container: {
    borderRadius: 8,
    boxShadow: shadows.md,
    padding: 14,
    background: "white",
    minHeight: 180,
    display: "flex",
    flexFlow: "column",

    "&:not(:last-child)": {
      marginBottom: 16,
    },
  },
  content: {
    marginBottom: 16,
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    flex: 1,
    opacity: (props) => (props.disabledStyle ? 0.4 : 1),
  },
  labTestNameRow: {
    display: "flex",
    flexFlow: "row",
    alignItems: "flex-start",
    marginRight: 8,
  },
  labTestName: {
    marginRight: 4,
  },
  favoriteIconButton: {
    padding: 1,
    borderRadius: 4,
    width: 24,
  },
  favoriteIcon: {
    color: (props) =>
      props.isFavorited ? favoritedColor : colors.blueGray[200],
  },
  labCompanyName: { marginTop: 4 },
  additionalText: { marginTop: 4, color: colors.blueGray[500] },
  labTestTypes: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    marginTop: 4,
  },
  right: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  buttons: {
    display: "flex",
    width: "100%",
  },
  button: {
    flex: 1,
  },
  signUpButton: {
    display: "flex",
    alignItems: "center",
  },
  signUpText: {
    marginLeft: 8,
  },
})

interface BaseProps {
  labTest?: AnyLimitedLabTest
  practitioner?: Practitioner
  signingPractitioner?: Practitioner | SigningPractitioner
  isPending: boolean
  selectedLabTests?: SelectedLabTests
  onAddLabTest?: (labTest: AnyLimitedLabTest) => void
  onRemoveLabTest?: (labTest: AnyLimitedLabTest) => void
  location: labTestLocation
  createBundleClick?: () => void
  vendorPhysicianServicesActivated?: boolean
  onAddComboGroup?: (
    labTest: AnyLimitedLabTest,
    payload: ComboGroupConfigurationPayload
  ) => void
}

type PendingProps = BaseProps & {
  isPending: true
}

type LoadedProps = BaseProps & {
  labTest: AnyLimitedLabTest
  isPending: false
  setLabTestInModal: (labTest: AnyLimitedLabTest) => void
  onToggleFavorite: (labTest: AnyLimitedLabTest) => void
  additionalText?: string
}

type Props = PendingProps | LoadedProps

export function LabTestListMobileItem(props: Props) {
  // NOTE: We can't destructure the props in the function definition as it breaks
  // typeguarding on the discrimated union. So we destructure below so we can use
  // them when don't need to discriminate and retain `props` for when we do.

  const { practitioner, labTest } = props

  const isFavorited = Boolean(
    labTest && practitioner?.favorite_lab_test_id_set?.has(labTest.id)
  )
  const disabledStyle = useMemo(() => {
    if (props.location !== labTestLocation.CATALOG) {
      // The disabledStyle is only applied to the catalog
      return false
    }
    return labTest?.ordering_rights_status?.allowed === false
  }, [props.location, labTest?.ordering_rights_status?.allowed])
  const styles = useStyles({ isFavorited, disabledStyle })

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.labTestNameRow}>
            {!props.isPending ? (
              <BodyText className={styles.labTestName} weight="semibold">
                {props.labTest?.name}
              </BodyText>
            ) : (
              <PendingPlaceholder color={colors.blueGray[400]} width={148} />
            )}
            {practitioner && !props.isPending && (
              <IconButton
                className={styles.favoriteIconButton}
                onClick={() => props.onToggleFavorite(props.labTest)}
                aria-label="Favorite test"
              >
                <FavoriteIcon
                  fontSize="small"
                  className={styles.favoriteIcon}
                />
              </IconButton>
            )}
          </div>
          {!props.isPending ? (
            <div>
              <BodyText className={styles.labCompanyName}>
                {props.labTest?.lab_company.name}
              </BodyText>
              {props.additionalText && (
                <BodyText className={styles.additionalText}>
                  {props.additionalText}
                </BodyText>
              )}
            </div>
          ) : (
            <PendingPlaceholder width={100} />
          )}
          {!props.isPending && (
            <div className={styles.labTestTypes}>
              {props.labTest?.lab_test_types.map((type) => {
                return (
                  <SampleTypePill
                    name={type.name.toLowerCase()}
                    key={type.name}
                    disabled={disabledStyle}
                    style={{
                      marginLeft: 0,
                      marginRight: 2,
                    }}
                  />
                )
              })}
            </div>
          )}
        </div>
        <div className={styles.right}>
          {!props.isPending ? (
            QUEST_COMPANY_KEYS.includes(props.labTest.lab_company.key) ? (
              <BodyText weight="semibold">Varies by panel</BodyText>
            ) : practitioner ? (
              <BodyText weight="semibold">${props.labTest.rupa_price}</BodyText>
            ) : (
              <Button
                href="https://www.rupahealth.com/sign-up"
                color="text"
                className={styles.signUpButton}
              >
                <img src={lockIcon} alt="lock" width={17} height={17} />
                <BodyText weight="semibold" className={styles.signUpText}>
                  Sign up to view
                </BodyText>
              </Button>
            )
          ) : (
            <PendingPlaceholder />
          )}
        </div>
      </div>
      <div className={styles.buttons}>
        {!props.isPending && (
          <LabTestButtons
            labTest={props.labTest as AnyLimitedLabTest}
            setLabTestInModal={props.setLabTestInModal}
            selectedLabTests={props.selectedLabTests}
            onAddLabTest={props.onAddLabTest}
            onRemoveLabTest={props.onRemoveLabTest}
            location={props.location}
            signingPractitioner={props.signingPractitioner}
            practitioner={practitioner}
            createBundleClick={props.createBundleClick}
            vendorPhysicianServicesActivated={
              props.vendorPhysicianServicesActivated
            }
            onAddComboGroup={props.onAddComboGroup}
          />
        )}
      </div>
    </div>
  )
}
