import { forwardRef } from "react"

import clsx from "clsx"
import { isEmpty } from "lodash"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import { Theme, useMediaQuery } from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import { FadeProps } from "@material-ui/core/Fade"
import Grow from "@material-ui/core/Grow"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { Skeleton } from "@material-ui/lab"

import { ReactComponent as BarsDarkIcon } from "app/assets/icons/rupa-blood-dashboards/bars.svg"
import { ReactComponent as QuestionCircleOutlinedIcon } from "app/assets/icons/rupa-blood-dashboards/question-circle-outlined.svg"
import { ReactComponent as QuestionSquareDark } from "app/assets/icons/rupa-blood-dashboards/question-square-dark.svg"
import BodyText from "app/components/design-system/BodyText"
import DesignSystemButton from "app/components/design-system/Button"
import { Dialog } from "app/components/modals"
import NumericBiomarkerGraphic from "app/main/blood-lab-dashboards/NumericBiomarkerGraphic/NumericBiomarkerGraphic"
import useBiomarkerCustomDescriptions from "app/main/blood-lab-dashboards/hooks/use-biomarker-custom-descriptions"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { colors, navy, shadows } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"
import { Biomarker } from "types/biomarker"
import { DiscreteResult } from "types/discrete-result"
import { LabCompanyBiomarkerRange } from "types/lab-company-biomarker-range"

import BloodLabDashboardsBiomarkerStatusIndicator from "../../../main/blood-lab-dashboards/BloodLabDashboardsPatientPreview/statuses/BloodLabDashboardsBiomarkerStatusIndicator"
import { BiomarkerStatus } from "../constants"
import { parseStringValue } from "../utils"
import ContentCard from "./ContentCard"
import DiscreteResultDetailLegalDisclaimers from "./DiscreteResultDetailLegalDisclaimers"
import HighLowDescriptionSymptomCard from "./HighLowDescriptionSymptomCard"
import useAboutBloodLabsDashboardsModal from "./use-about-blood-labs-dashboards-modal"

interface DiscreteResultDetailModalProps {
  accessToken?: string
  discreteResult?: DiscreteResult
  optimalRange?: LabCompanyBiomarkerRange
  biomarkerStatus?: BiomarkerStatus
  practitionerId?: string
  clinicName: string
  useOptimalRanges?: boolean
  onClose: () => void
}

const useStyles = makeAppStyles((theme: Theme) => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 12,
    border: "4px solid white",
    boxShadow: shadows["2xl"],
    width: "100%",
    maxWidth: 800,
  },
  closeButton: {
    color: navy,
    position: "relative",
    top: 1,
    right: 0,
    marginRight: -12,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    backgroundColor: "white",
    alignItems: "center",
    padding: "16px 24px",
  },
  titleText: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    marginRight: 24,
  },
  content: {
    padding: 22.5,
    backgroundColor: colors.trueGray[100],
    display: "flex",
    flexDirection: "column",
    gap: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 12,
    },
  },
  optimalRangeAboutBtn: {
    fontSize: 14,
    fontWeight: 400,
  },
  biomarkerValue: {
    fontSize: 22,
    fontWeight: 600,
    color: navy,
  },
  biomarkerUnits: {
    fontSize: 13,
    color: colors.blueGray[400],
    fontWeight: 600,
  },
  redValueColor: {
    color: colors.red[900],
  },
  yellowValueColor: {
    color: colors.yellow[900],
  },
  greenValueColor: {
    color: colors.emerald[900],
  },
  redStatusColor: {
    color: colors.red[700],
  },
  yellowStatusColor: {
    color: colors.yellow[700],
  },
  greenStatusColor: {
    color: colors.emerald[700],
  },
}))

const GrowTransition = forwardRef<unknown, FadeProps>(function Transition(
  props,
  ref
) {
  return <Grow ref={ref} {...props} timeout={500} />
})

const DiscreteResultDetailModal = ({
  accessToken,
  discreteResult,
  optimalRange,
  biomarkerStatus,
  practitionerId,
  clinicName,
  useOptimalRanges,
  onClose,
}: DiscreteResultDetailModalProps) => {
  const classes = useStyles()

  const modal = useModal()
  const muiDialogControl = muiDialog(modal)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const biomarker = useCachedResource<Biomarker>(
    discreteResult?.relationships.biomarker.data
  )

  const { biomarkerCustomDescriptionsMapping, isLoading } =
    useBiomarkerCustomDescriptions({
      isPatientPreview: false,
      biomarkerIds: [biomarker?.id ? biomarker?.id : ""],
      practitionerId,
    })

  const biomarkerCustomDescription = biomarker?.id
    ? biomarkerCustomDescriptionsMapping[biomarker?.id]
    : undefined

  const aboutBloodDashboardsModal = useAboutBloodLabsDashboardsModal()

  const title =
    biomarker?.attributes.long_name === biomarker?.attributes.short_name
      ? biomarker?.attributes.long_name
      : `${biomarker?.attributes.long_name} (${biomarker?.attributes.short_name})`

  return (
    <>
      <Dialog
        {...muiDialogControl}
        disableEnforceFocus
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
        TransitionComponent={GrowTransition}
      >
        <TitleSection
          title={title}
          biomarkerStatus={biomarkerStatus}
          discreteResult={discreteResult}
          onClose={onClose}
        />
        <MuiDialogContent className={classes.content}>
          <ContentCard
            title={`What's ${biomarker?.attributes.short_name}?`}
            icon={<QuestionSquareDark />}
          >
            {isLoading ? (
              <Skeleton animation="wave" height={30} width="80%" />
            ) : (
              <BodyText>
                {biomarkerCustomDescription &&
                !isEmpty(biomarkerCustomDescription.attributes.description)
                  ? biomarkerCustomDescription.attributes.description
                  : biomarker?.attributes.description ||
                    "No description available."}
              </BodyText>
            )}
          </ContentCard>

          {biomarkerStatus && (
            <ContentCard
              title={`Your Levels of ${biomarker?.attributes.short_name}`}
              icon={<BarsDarkIcon />}
              actionButton={
                optimalRange && (
                  <DesignSystemButton
                    color="text"
                    onClick={() =>
                      aboutBloodDashboardsModal.show({
                        title: "About Optimal Ranges",
                        showAboutTheDashboardSection: false,
                        onClose: () => aboutBloodDashboardsModal.hide(),
                      })
                    }
                    endIcon={<QuestionCircleOutlinedIcon />}
                    loading={false}
                    className={classes.optimalRangeAboutBtn}
                  >
                    Optimal Range
                  </DesignSystemButton>
                )
              }
            >
              {isLoading ? (
                <Skeleton animation="wave" height={80} width="100%" />
              ) : (
                <>
                  <div>
                    <NumericBiomarkerGraphic
                      value={parseStringValue(discreteResult?.attributes.value)}
                      normalMax={
                        discreteResult?.attributes.normal_max
                          ? discreteResult?.attributes.normal_max
                          : useOptimalRanges
                          ? optimalRange?.attributes.standard_range_max || ""
                          : ""
                      }
                      normalMin={
                        discreteResult?.attributes.normal_min
                          ? discreteResult?.attributes.normal_min
                          : useOptimalRanges
                          ? optimalRange?.attributes.standard_range_min || ""
                          : ""
                      }
                      optimalMax={
                        (useOptimalRanges &&
                          optimalRange?.attributes.optimal_range_max) ||
                        ""
                      }
                      optimalMin={
                        (useOptimalRanges &&
                          optimalRange?.attributes.optimal_range_min) ||
                        ""
                      }
                      singleLabel={isMobile}
                      status={biomarkerStatus}
                    />
                  </div>
                  <HighLowDescriptionSymptomCard
                    biomarkerStatus={biomarkerStatus}
                    biomarkerCustomDescription={biomarkerCustomDescription}
                    biomarkerShortName={biomarker?.attributes.short_name}
                    biomarkerLowSymptoms={biomarker?.attributes.low_symptoms}
                    biomarkerHighSymptoms={biomarker?.attributes.high_symptoms}
                    biomarkerLowDescription={
                      biomarker?.attributes.low_description
                    }
                    biomarkerHighDescription={
                      biomarker?.attributes.high_description
                    }
                  />
                </>
              )}
            </ContentCard>
          )}

          {!isLoading && (
            <DiscreteResultDetailLegalDisclaimers
              biomarkerCustomDescription={biomarkerCustomDescription}
              clinicName={clinicName}
              isOptimalRangePresent={Boolean(optimalRange)}
            />
          )}
        </MuiDialogContent>
      </Dialog>
    </>
  )
}

const TitleSection = ({ title, biomarkerStatus, discreteResult, onClose }) => {
  const classes = useStyles()

  const valueStyle = {
    [BiomarkerStatus.HIGH]: classes.redValueColor,
    [BiomarkerStatus.LOW]: classes.redValueColor,
    [BiomarkerStatus.ABOVE_OPTIMAL]: classes.yellowValueColor,
    [BiomarkerStatus.BELOW_OPTIMAL]: classes.yellowValueColor,
    [BiomarkerStatus.NORMAL]: classes.greenValueColor,
    [BiomarkerStatus.OPTIMAL]: classes.greenValueColor,
  }

  const statusStyle = {
    [BiomarkerStatus.HIGH]: classes.redStatusColor,
    [BiomarkerStatus.LOW]: classes.redStatusColor,
    [BiomarkerStatus.ABOVE_OPTIMAL]: classes.yellowStatusColor,
    [BiomarkerStatus.BELOW_OPTIMAL]: classes.yellowStatusColor,
    [BiomarkerStatus.NORMAL]: classes.greenStatusColor,
    [BiomarkerStatus.OPTIMAL]: classes.greenStatusColor,
  }

  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle disableTypography className={classes.titleContainer}>
      <div className={classes.titleText}>
        <BodyText weight="bold" size="lg">
          {title}
        </BodyText>
        <div>
          <BloodLabDashboardsBiomarkerStatusIndicator
            biomarkerStatus={biomarkerStatus}
          />{" "}
          <span
            className={clsx(
              classes.biomarkerValue,
              biomarkerStatus ? valueStyle[biomarkerStatus] : ""
            )}
          >
            {discreteResult?.attributes.value}
          </span>
          <span
            className={clsx(
              classes.biomarkerUnits,
              biomarkerStatus ? statusStyle[biomarkerStatus] : ""
            )}
          >
            {discreteResult?.attributes.units}
          </span>
        </div>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}

const DiscreteResultDetailModalNiceModal =
  NiceModal.create<DiscreteResultDetailModalProps>(DiscreteResultDetailModal)

export default DiscreteResultDetailModalNiceModal
